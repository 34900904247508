import axios from "axios";
import { atom } from "jotai";
import produce from "immer";
import toast from "react-hot-toast";

const logsAtom = atom({
    data: [],
    pid: "",
    status: "not-started"
})

export const fetchAuditAtom = atom(
    null,
    async(get, set, args) => {
        let actType = null
        if(args.activity_type === 'cp'){
            actType = "CP"
        }
        if(args.activity_type === 'qc'){
            actType = "QC"
        }
        let res = await axios.post(`/progress-audit/download`, args)
        let projectData = await axios.get(`/project/get_projects`)
        let project_name = projectData.data.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        let csv = res.data
        const element = document.createElement("a");
        const file = new Blob([csv], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = `${project_name?.project_number}_${actType}_Log_${project_name?.name}`
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        set(logsAtom, produce((draft) => {draft.data = res}))
    }
)

export const fetchChecklistBlockAtom = atom(
    null,
    async(get, set, args) => {
        let res = await axios.post(`/progress/start_checksheet_generation?block_id=${args.block_id}`)
        set(logsAtom, produce((draft) => {draft.pid = res.data.pid}))
        set(logsAtom, produce((draft) => {draft.status = "activated"}))
        // window.open(res.data.url)
    }
)

export const fetchStatusCheckAtom = atom(
    null,
    async(get, set, args) => {
        let res = {...get(logsAtom)}
        let statusCheck = await axios.post(`/progress/progress_update_queue_status?type=qc_checksheet`,{
            status : 1,
            type: "qc_checksheet",
            response_type: "count",
            pids : [res.pid]
        })
        if(statusCheck.data === 0){
            let downloadRes = await axios.get(`/progress/get_checksheet?pid=${res.pid}`)
            window.open(downloadRes.data.url)
            set(logsAtom, produce((draft) => {draft.status = "not-started"}))
        }
        else{
            toast.error("File not yet Generated")
            set(logsAtom, produce((draft) => {draft.status = "activated"}))
        }
    }
)

export const fetchChecklistTrackerAtom = atom(
    null,
    async(get, set, args) => {
        let res = await axios.post(`/progress/start_checksheet_generation?block_id=${args.block_id}`, {tracker_row_ids: args.tracker_id})
        set(logsAtom, produce((draft) => {draft.pid = res.data.pid}))
        set(logsAtom, produce((draft) => {draft.status = "activated"}))
    }
)

export default logsAtom
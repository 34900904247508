import axios from "axios"
import produce from "immer"
import { atom } from "jotai"
import { Parser } from "json2csv"
import toast from "react-hot-toast"

const _ = require("lodash")

const layoutAtom = atom({
    data: [],
    deliveryData: [],
    bomLayoutData: [],
    scopeLaydownData: {},
    allScopeBlockData: {},
    blockCompleted: [],
    blockInprgress: [],
    deliveryLaydownIds: [],
    bomInventoryData: [],
    allInventoryDatas: [],
    deliveryCustomData: [],
    deliveryCustomFields: [],
    currentlaydown: 0,
    selectedLaydownData: {}
})

const handleFindDestinationName = (data, details) => {
    let temp
    temp = details.find(o => o.id === data)
    return temp?.name
}

const handleCheckAction = (data, destination, details) => {
    let temp
    if (data === 0) {
        temp = "Delivery"
    }
    if (data === 1) {
        temp = "Issue to site"
    }
    if (data === 2) {
        temp = `Intersite movement issued to ${handleFindDestinationName(destination, details)}`
    }
    if (data === 3) {
        temp = `Intersite movement received from ${handleFindDestinationName(destination, details)}`
    }
    if (data === 4) {
        temp = "Stock adjustment"
    }
    return temp
}

const handleDateTimeSplit = (time) => {
    let temp = new Date(time).toISOString().split("T")[0]
    let date = temp?.split("-")[2]
    let month = temp?.split("-")[1]
    let year = temp?.split("-")[0]
    let hour = new Date(time).getHours()
    let mins = new Date(time).getMinutes()
    let meridian
    if (hour > 12) {
        meridian = "PM"
        hour = hour - 12
    }
    else {
        meridian = "AM"
    }
    return `${date}-${month}-${year}  ${hour}:${mins} ${meridian}`
}

const fetchImages = async (data) => {
    try {
        let res = await axios.get(`/file_upload/download?id=${data}`)
        return res.data
    }
    catch (err) {
        console.log(err)
        return ""
    }
}

export const fetchGetLayout = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/laydown`)
        set(layoutAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const deleteLaydown = atom(
    null,
    async (get, set, args) => {
        await axios.delete(`/projects/laydown?laydown_id=${args.laydown_id}`)
        let res = await axios.get(`/projects/laydown`)
        set(layoutAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const fetchIndividualLayoutData = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/bom-laydown?laydown_id=${args.laydown_id}`)
        set(layoutAtom, produce((draft) => { draft.bomLayoutData = res.data }))
    }
)

export const fetchLaydownScopeDataAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/laydown-scope/laydown/auto-manage-scope?laydown_id=${args?.laydown_id}`)
        set(layoutAtom, produce((draft) => { draft.scopeLaydownData = res?.data }))
    }
)

export const fetchUpdateLayout = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/laydown`, args.data)
        let res = await axios.get(`/projects/laydown`)
        set(layoutAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const fetchInventoryBomLayout = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/inventory?bom_id=${args.bom_id}&laydown_id=${args.laydown_id}`)
        set(layoutAtom, produce((draft) => { draft.bomInventoryData = res.data }))
    }
)

export const deleteInventoryBomLaydown = atom(
    null,
    async (get, set, args) => {
        await axios.delete(`/projects/inventory/bom_laydown?laydown_id=${args.laydown_id}&bom_id=${args.bom_id}`)
        let res = await axios.get(`/projects/bom-laydown?laydown_id=${args.laydown_id}`)
        set(layoutAtom, produce((draft) => { draft.bomLayoutData = res.data }))
    }
)

export const addLayoutInventory = atom(
    null,
    async (get, set, args) => {
        if (args.action === 0) {
            await axios.post(`/projects/inventory_update?laydown_id=${args.laydownId}&destination_laydown_id=${args.destination}`, args.data)
        }
        else {
            await axios.post(`/projects/inventory_update?laydown_id=${args.laydownId}`, args.data)
        }
        let res = await axios.get(`/projects/inventory?bom_id=${args.bom_id}&laydown_id=${args.laydownId}`)
        set(layoutAtom, produce((draft) => { draft.bomInventoryData = res.data }))
    }
)

export const downloadLaydown = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/inventory/download?laydown_id=${args.laydown_id}&count=True`)
        toast("Download queued")
        let arr = []
        if (res.data.length > 0) {
            res.data.map((i) => {
                if (i.inventory_logs.length > 0) {
                    i.inventory_logs.map((j, index) => {
                        let temp = {}
                        if (index === 0) {
                            temp["Part Number"] = i.bom_part_name
                            temp["Part Description"] = i.bom_description
                            temp["Current Quantity"] = i.count
                        }
                        temp["Date & Time"] = handleDateTimeSplit(j.time)
                        temp["Delivery Reference"] = j.delivery_reference
                        temp["Received Qty"] = j.received_quantity
                        temp["Issued Qty"] = j.issued_quantity
                        temp["Action"] = handleCheckAction(j.purpose_code, j.destination_laydown_id, args.details)
                        temp["Comments"] = j.comments
                        arr.push(temp)
                    })
                }
                else {
                    let temp = {}
                    temp["Part Number"] = i.bom_part_name
                    temp["Part Description"] = i.bom_description
                    temp["Current Quantity"] = i.count
                    arr.push(temp)
                }
            })
        }
        else {
            let temp = {}
            temp["Part Number"] = null
            temp["Part Description"] = null
            temp["Current Quantity"] = null
            temp["Date & Time"] = null
            temp["Delivery Reference"] = null
            temp["Received Qty"] = null
            temp["Issued Qty"] = null
            temp["Action"] = null
            temp["Comments"] = null
            arr.push(temp)
        }
        let newFields = [
            "Part Number",
            "Part Description",
            "Current Quantity",
            "Date & Time",
            "Delivery Reference",
            "Received Qty",
            "Issued Qty",
            "Action",
            "Comments"
        ]
        let opts = { newFields }
        const parser = new Parser(opts)
        let csv = parser.parse(arr)
        csv = `"Project Number", ${args?.project_name?.project_number.toString()}\n` + `"Project Name", ${args?.project_name?.name} \n` + `"Report",${args.laydownName} Inventory\n` + `"Report Date", ${args.formattedDate}\n` + `\n` + csv
        const element = document.createElement("a")
        const file = new Blob([csv], { type: 'text/csv' })
        element.href = URL.createObjectURL(file)
        element.download = `${args?.project_name?.project_number}_${args.laydownName} Inventory_${args.project_name?.name}`
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
)

export const downloadDeliveryData = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/delivery/download`)
        let tempArr = []
        if (res.data) {
            res.data.map((deliveryData) => {
                let temp = {}
                temp["Delivery Name"] = deliveryData?.name
                temp["Comments"] = deliveryData?.comments
                if (deliveryData?.custom_response && deliveryData?.custom_response.length > 0) {
                    deliveryData?.custom_response?.map((cust_response) => {
                        temp[cust_response[0]?.name] = cust_response[1]?.response ? cust_response[1]?.response : "-"
                    })
                }
                if (deliveryData?.bom_laydown_array.length > 0) {
                    deliveryData?.bom_laydown_array.map((i, index) => {
                        if (index === 0) {
                            temp["Laydown"] = i?.laydown
                            temp["Part Number"] = i?.part_name
                            temp["Part Description"] = i?.description.replace(/,/g, "")
                            temp["Delivered Qty"] = i?.delivery_qty
                            temp["Damaged Qty"] = i?.damaged
                            tempArr.push(temp)
                        }
                        else {
                            let newTemp = {}
                            newTemp["Laydown"] = i?.laydown
                            newTemp["Part Number"] = i?.part_name
                            newTemp["Part Description"] = i?.description.replace(/,/g, "")
                            newTemp["Delivered Qty"] = i?.delivery_qty
                            newTemp["Damaged Qty"] = i?.damaged
                            tempArr.push(newTemp)
                        }
                    })
                }
                else {
                    temp["Laydown"] = null
                    temp["Part Number"] = null
                    temp["Part Description"] = null
                    temp["Delivered Qty"] = null
                    temp["Damaged Qty"] = null
                    tempArr.push(temp)
                }
            })
        }
        let newFields = [
            "Delivery Name",
            "Comments",
            "Laydown",
            "Part Number",
            "Delivery Name",
            "Part Description",
            "Delivered Qty",
            "Damaged Qty"
        ]
        args?.customFieldsData.map((i) => {
            newFields.push(i?.name)
        })
        let opts = { newFields }
        const parser = new Parser(opts)
        let csv = parser.parse(tempArr)
        csv = `"Project Number", ${args.project_name?.project_number}\n` + `"Project Name", ${args.project_name?.name}\n` + `"Report", Deliveries List\n` + `"Report Date", ${args.formattedDate}\n` + `\n` + csv
        const element = document.createElement("a")
        const file = new Blob([csv], { type: 'text/csv' })
        element.href = URL.createObjectURL(file)
        element.download = `${args.project_name?.project_number}_Delivery_${args.project_name?.name}`
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
)

export const fetchDeliveryData = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/delivery`)
        let sortedData = _.sortBy(res.data, [function (o) { return o.date_time }])
        set(layoutAtom, produce((draft) => { draft.deliveryData = sortedData }))
    }
)

export const deleteDelivery = atom(
    null,
    async (get, set, args) => {
        await axios.delete(`/projects/delivery?delivery_id=${args.delivery_id}`)
        let res = await axios.get(`/projects/delivery`)
        let sortedData = _.sortBy(res.data, [function (o) { return o.date_time }])
        set(layoutAtom, produce((draft) => { draft.deliveryData = sortedData }))
    }
)

export const editInventory = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/inventory_update?laydown_id=${args.laydown_id}&inventory_log_id=${args.inventory_log_id}`, args.data)
        let res = await axios.get(`/projects/inventory?bom_id=${args.bom_id}&laydown_id=${args.laydown_id}`)
        set(layoutAtom, produce((draft) => { draft.bomInventoryData = res.data }))
    }
)

export const deleteInventory = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/inventory_update?laydown_id=${args.laydown_id}`, args.data)
        let bomRes = await axios.get(`/projects/bom-laydown?laydown_id=${args.laydown_id}`)
        set(layoutAtom, produce((draft) => { draft.bomLayoutData = bomRes.data }))
        // let InventRes = await axios.get(`/projects/inventory?bom_id=${args.bom_id}&laydown_id=${args.laydown_id}`)
        // set(layoutAtom, produce((draft) => { draft.bomInventoryData = InventRes.data }))
    }
)

export const createDelivery = atom(
    null,
    async (get, set, args) => {
        let deliveryId = await axios.post(`/projects/delivery`, args.deliveryData)
        if (args?.inventoryData.length > 0) {
            for (let dataArr of args.inventoryData) {
                let deliveryLaydownId = await axios.post(`/projects/delivery-laydown`, {
                    "delivery_id": deliveryId.data,
                    "laydown_id": dataArr[0].laydown
                })
                let tempData = []
                for (let data of dataArr) {
                    let temp = {
                        bom_id: data.bom_id,
                        count: data.count,
                        time: data.time,
                        damaged: data?.damaged ? data?.damaged : 0,
                        purpose_code: 0,
                        comments: ""
                    }
                    tempData.push(temp)
                }
                await axios.post(`/projects/inventory_update?laydown_id=${dataArr[0].laydown}&received=True&delivery_laydown_id=${deliveryLaydownId.data}&delivery_id=${deliveryId.data}`, tempData)
                // await axios.post(`/projects/inventory?delivery_laydown_id=${deliveryLaydownId.data}&laydown_id=${dataArr[0].laydown}&update=True`, tempData)
            }
        }
        if (args?.customFieldsData?.length > 0) {
            let tempArr = []
            args?.customFieldsData.map((data) => {
                let temp = {}
                temp.delivery_id = deliveryId?.data
                temp.custom_input_delivery_id = data?.id
                temp.response = data?.responseData
                temp.delete = false
                tempArr.push(temp)
            })
            await axios.post(`/projects/delivery/custom_responses?delivery_id=${deliveryId?.data}`, tempArr)
        }
        let res = await axios.get(`/projects/delivery`)
        let sortedData = _.sortBy(res.data, [function (o) { return o.date_time }])
        set(layoutAtom, produce((draft) => { draft.deliveryData = sortedData }))
    }
)

export const fetchCustomFields = atom(
    null,
    async (get, set, args) => {
        if (args?.delivery_id) {
            let res = await axios.get(`/projects/delivery/custom_responses?delivery_id=${args?.delivery_id}`)
            set(layoutAtom, produce((draft) => { draft.deliveryCustomFields = res.data }))
        }
        else {
            let res = await axios.get(`/projects/delivery/custom_inputs`)
            set(layoutAtom, produce((draft) => { draft.deliveryCustomData = res.data }))

        }
    }
)

export const createCustomFields = atom(
    null,
    async (get, set, args) => {
        if (args?.delivery_id) {
            await axios.post(`/projects/delivery/custom_responses?delivery_id=${args?.delivery_id}`, args.data)
            let res = await axios.get(`/projects/delivery/custom_responses?delivery_id=${args?.delivery_id}`)
            set(layoutAtom, produce((draft) => { draft.deliveryCustomFields = res.data }))
        }
        else {
            await axios.post(`/projects/delivery/custom_inputs`, args.data)
            let res = await axios.get(`/projects/delivery/custom_inputs`)
            set(layoutAtom, produce((draft) => { draft.deliveryCustomData = res.data }))
        }
    }
)

export const fetchDeliveryLaydownId = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/delivery-laydown?delivery_id=${args.deliveryId}`)
        set(layoutAtom, produce((draft) => { draft.deliveryLaydownIds = res.data }))
    }
)

export const fetchAllInventoryDatas = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/inventory/delivery/bom_delivery_laydowns?delivery_id=${args.deliveryId}`)
        set(layoutAtom, produce((draft) => { draft.allInventoryDatas = res.data }))
    }
)

export const updateDamagedQtyAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/inventory_update?delivery_laydown_id=${args.deliveryLaydownId}&laydown_id=${args.laydownId}&received=True&delivery_id=${args.deliveryId}`, args.data)
    }
)

export const setCurrentLaydownId = atom(
    null,
    async (get, set, args) => {
        console.log(args, 'calling')
        set(layoutAtom, produce((draft) => { draft.currentlaydown = args}))
    }
)

  export const setlaydownData = atom(
    null, 
    (get, set, updatedData) => {
        set(layoutAtom, (prev) => ({ ...prev, selectedLaydownData: updatedData }));
    }
  );

export const createDeliveryLaydownId = atom(
    null,
    async (get, set, args) => {
        let deliveryLaydownid_res
        if (args.deliveryLaydownId) {
            await axios.post(`/projects/inventory_update?delivery_laydown_id=${args.deliveryLaydownId}&laydown_id=${args.laydownId}&received=True&delivery_id=${args.deliveryId}`, args.data)
            await axios.get(`/projects/delivery-laydown?delivery_id=${args.deliveryId}`)
        }
        else {
            deliveryLaydownid_res = await axios.post(`/projects/delivery-laydown`, {
                "delivery_id": args.deliveryId,
                "laydown_id": args.laydownId
            })
            await axios.post(`/projects/inventory_update?delivery_laydown_id=${deliveryLaydownid_res.data}&laydown_id=${args.laydownId}&received=True&delivery_id=${args.deliveryId}`, args.data)
        }
        // let res = await axios.get(`/projects/inventory/bom_delivery_laydowns?delivery_laydown_id=${deliveryLaydownid_res?.data}`)
    }
)
export const get_Laydown_scope = atom(
    null,
    async (get, set, args) => {
        await axios.get(`/projects/laydown_scope?project_id=${args.project}&laydown_id=${args.laydown}`)
    }
)

export const getAllLaydownDataAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/get_scope_laydown_wise`)
        set(layoutAtom, produce((draft) => { draft.allScopeBlockData = res.data }))
        // set(layoutAtom, produce((draft) => { draft.selectedLaydownData = res.data }))
    }
)

export const update_Laydown_scope = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/laydown_scope`, args)
    }
)

export const updateRemediatedQuantityAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/inventory/remediated-count/bom-delivery-laydown`, args)
    }
)


export default layoutAtom
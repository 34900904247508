import axios from "axios";
import { atom } from "jotai";

const trackerAtom = atom([])

export const fetchtracker = atom(
    null,
    async(get,set, args) => {
        const res = await axios.get(`/tracker/get_tracker_types`)
        set(trackerAtom, res.data)
    }
)

export default trackerAtom
import React, { useEffect, useState } from 'react';
import { FaTrash } from "react-icons/fa";
function haveCommonNumbers(range1, range2) {
  const [start1, end1] = range1;
  const [start2, end2] = range2;
  const maxStart = Math.max(start1, start2);
  const minEnd = Math.min(end1, end2);
  if (maxStart <= minEnd) {
    return true;
  }
  return false;
}

function check_overlap(scope, row) {
  const [Rblocks, Rsections, Rtrackers] = row;
  var overlaps = { ovelaps: false }
  //block only
  if ((Rsections[0] === '') && (Rtrackers[0] === '')) {
    var rcmp1 = Rblocks[0]
    var rcmp2 = Rblocks[0]
    if (Rblocks.length === 2) {
      rcmp2 = Rblocks[1]
    }
    scope.forEach(function (scp, i) {
      const [blocks, sections, trackers] = scp;
      var blk1 = blocks[0]
      var blk2 = blocks[0]
      if (blocks.length === 2) {
        blk2 = blocks[1]
      }
      const result = haveCommonNumbers([parseInt(rcmp1), parseInt(rcmp2)], [parseInt(blk1), parseInt(blk2)])
      if (result) {
        overlaps = { ovelaps: true, scope: scp, index: i }
      }
    })

  }
  //block and section
  else if ((Rsections[0] !== '') && (Rtrackers[0] === '')) {
    var rcmpb1 = Rblocks[0]
    var rcmpb2 = Rblocks[0]
    scope.forEach(function (scp, i) {
      const [blocks, sections, trackers] = scp;
      var blk1 = blocks[0]
      var blk2 = blocks[0]
      if (blocks.length === 2) {
        blk2 = blocks[1]
      }
      const result = haveCommonNumbers([parseInt(rcmpb1), parseInt(rcmpb2)], [parseInt(blk1), parseInt(blk2)])
      if ((result && (blocks.length === 2)) || (result && trackers[0] === '')) {
        overlaps = { ovelaps: true, scope: scp, index: i }
      } else if (result) {
        //check section
        var rcmps1 = Rsections[0]
        var rcmps2 = Rsections[0]
        if (Rsections.length === 2) {
          rcmps2 = Rsections[1]
        }

        var sec1 = sections[0]
        var sec2 = sections[0]
        if (sections.length === 2) {
          sec2 = sections[1]
        }

        const result = haveCommonNumbers([parseInt(rcmps1), parseInt(rcmps2)], [parseInt(sec1), parseInt(sec2)])
        if (result) {
          overlaps = { ovelaps: true, scope: scp, index: i }
        }
      }
    })
  }
  //block section and tracker
  else if ((Rsections[0] !== '') && (Rtrackers[0] !== '')) {
    var rcmpb1 = Rblocks[0]
    var rcmpb2 = Rblocks[0]
    scope.forEach(function (scp, i) {
      const [blocks, sections, trackers] = scp;
      var blk1 = blocks[0]
      var blk2 = blocks[0]
      if (blocks.length === 2) {
        blk2 = blocks[1]
      }
      const result = haveCommonNumbers([parseInt(rcmpb1), parseInt(rcmpb2)], [parseInt(blk1), parseInt(blk2)])
      if ((result && (blocks.length === 2)) || (result && sections[0] === '' && trackers[0] === '')) {
        overlaps = { ovelaps: true, scope: scp, index: i }
      } else if (result) {
        //check section
        var rcmps1 = Rsections[0]
        var rcmps2 = Rsections[0]
        if (Rsections.length === 2) {
          rcmps2 = Rsections[1]
        }

        var sec1 = sections[0]
        var sec2 = sections[0]
        if (sections.length === 2) {
          sec2 = sections[1]
        }

        const result = haveCommonNumbers([parseInt(rcmps1), parseInt(rcmps2)], [parseInt(sec1), parseInt(sec2)])
        if (result && (sections.length === 2)) {
          overlaps = { ovelaps: true, scope: scp, index: i }
        } else if (result) {
          //check tracker
          var rcmpt1 = Rtrackers[0]
          var rcmpt2 = Rtrackers[0]
          if (Rtrackers.length === 2) {
            rcmpt2 = Rtrackers[1]
          }
          var trk1 = trackers[0]
          var trk2 = trackers[0]
          if (trackers.length === 2) {
            trk2 = trackers[1]
          }
          const result = haveCommonNumbers([parseInt(rcmpt1), parseInt(rcmpt2)], [parseInt(trk1), parseInt(trk2)])
          if (result) {
            overlaps = { ovelaps: true, scope: scp, index: i }
          }
        }
      }
    })
  }
  return overlaps

}
function ScopeSelect({ id, set_scope }) {
  const [scopenum, selectScopeNum] = useState({ "block": "", "section": "", "tracker": "", "end": "", "end_ind": 0 });
  const [scope, setScope] = useState([]);
  const [addConflict, setAddConflict] = useState({ ovelaps: false })
  const numboxstyle = { outline: "none", textAlign: "center", width: "90px", height: "50px" }
  const normal = { borderBottom: "2px solid lightgrey", background: "whitesmoke" }
  const disabled = { borderBottom: "2px solid darkgrey", background: "lightgrey" }
  const appendscope = () => {
    var tmp = [[scopenum.block], [scopenum.section], [scopenum.tracker]]
    if ((parseInt(scopenum.end) > parseInt(tmp[scopenum.end_ind][0])) || ((scopenum.end === "") || (tmp[scopenum.end_ind][0] === ""))) {
      if (scopenum.end !== "" && tmp[scopenum.end_ind] !== "") {
        tmp[scopenum.end_ind] = [tmp[scopenum.end_ind][0], scopenum.end]
      }
      const overlap_data = check_overlap(scope, tmp)
      //console.log(overlap_data)
      if (overlap_data?.ovelaps) {
        setAddConflict(overlap_data)
      }
      else {
        setAddConflict(overlap_data)
        setScope([...scope, tmp])
        set_scope([...scope, tmp])
        selectScopeNum({ "block": "", "section": "", "tracker": "", "end": "", "end_ind": 0 })
      }
    }
  }
  const deletescope = (e) => {
    const newArray = scope.filter((_, index) => index !== e);
    setScope(newArray);
  }

  return (
    <div class="container mx-auto p-4" style={{ display: "flex", justifyContent: "" }}>
      <div style={{ border: " 1px groove #75757575", borderRadius: "7px", padding: "1%", marginLeft: "50px" }}>
        <div class="container mx-auto p-4"   >
          <div class="container mx-auto p-4" style={{ display: "flex", justifyContent: "space-around", color: "#1976d2" }}>
            <p class="text-base">Block</p>
            <p class="text-base">Section</p>
            <p class="text-base">Tracker</p>
          </div>
          <div class="container mx-auto p-4" style={{ display: "flex", justifyContent: "center", padding: "3%", }}>

            <div  >
              <input
                value={scopenum.block}
                onMouseDown={() => selectScopeNum({ ...scopenum, "end_ind": 0 })}
                onClick={() => { selectScopeNum({ ...scopenum, "section": "", "tracker": "", "end": "", "end_ind": 0 }) }}
                onChange={(e) => selectScopeNum({ ...scopenum, "block": e.target.value })}
                style={{ ...numboxstyle, ...normal }} type="number" />
              <div style={{ height: "20px", width: "20px" }} />
              {scopenum.end_ind === 0 ?
                <input
                  value={scopenum.end}
                  disabled={scopenum.block === ""}
                  error={parseInt(scopenum.block) >= parseInt(scopenum.end) && scopenum.end !== ""}
                  onChange={(e) => selectScopeNum({ ...scopenum, "end": e.target.value })}
                  style={scopenum.block === "" ? { ...numboxstyle, ...disabled } : { ...numboxstyle, ...normal }} type="number" /> : null}
            </div>
            <div style={{ height: "20px", width: "20px" }} />

            <div  >
              <input
                onMouseDown={() => selectScopeNum({ ...scopenum, "end_ind": 1 })}
                value={scopenum.section} disabled={scopenum.block === ""}
                onClick={() => { selectScopeNum({ ...scopenum, "end_ind": 1, "tracker": "", "end": "" }) }}
                onChange={(e) => selectScopeNum({ ...scopenum, "section": e.target.value })}
                style={scopenum.block === "" ? { ...numboxstyle, ...disabled } : { ...numboxstyle, ...normal }} type="number" />
              <div style={{ height: "20px", width: "20px" }} />
              {scopenum.end_ind === 1 ?
                <input
                  value={scopenum.end}
                  disabled={scopenum.section === ""}
                  error={parseInt(scopenum.section) >= parseInt(scopenum.end) && scopenum.end !== ""}
                  onChange={(e) => selectScopeNum({ ...scopenum, "end": e.target.value })}
                  style={scopenum.section === "" ? { ...numboxstyle, ...disabled } : { ...numboxstyle, ...normal }} type="number" /> : null}
            </div>
            <div style={{ height: "20px", width: "20px" }} />

            <div  >
              <input
                onMouseDown={() => selectScopeNum({ ...scopenum, "end_ind": 2 })}
                value={scopenum.tracker} disabled={scopenum.section === ""}
                onClick={() => selectScopeNum({ ...scopenum, "end_ind": 2, "end": "" })}
                onChange={(e) => selectScopeNum({ ...scopenum, "tracker": e.target.value })}
                style={scopenum.section === "" ? { ...numboxstyle, ...disabled } : { ...numboxstyle, ...normal }} type="number" />
              <div style={{ height: "20px", width: "20px" }} />
              {scopenum.end_ind === 2 ?
                <input
                  value={scopenum.end} disabled={scopenum.tracker === ""}
                  error={parseInt(scopenum.tracker) >= parseInt(scopenum.end) && scopenum.end !== ""}
                  onChange={(e) => selectScopeNum({ ...scopenum, "end": e.target.value })}
                  style={scopenum.tracker === "" ? { ...numboxstyle, ...disabled } : { ...numboxstyle, ...normal }} type="number" /> : null}
            </div>

          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}><button class="btn bg-red-550 hover:bg-red-500 text-white" disabled={scopenum.block === ""} onClick={appendscope}>+add more scope</button></div>

        </div>
      </div>
      <div style={{ border: " 1px groove #75757575", borderRadius: "7px", padding: "1%", marginLeft: "3%", minWidth: "400px" }}>
        <div class="overflow-x-auto">
          <table class="table w-full">
            <tbody>
              {scope ? scope.map((i, ind) => {
                return (
                  <tr style={addConflict?.ovelaps ? addConflict?.index === ind ? { border: "solid red 2px" } : null : null}>
                    <td style={{ padding: "0% 2%" }}>{i[0][0] === "" ? null : "block   "}{i[0].length === 1 ? i[0] : (`${i[0][0]}-${i[0][1]}`)}</td>
                    <td style={{ padding: "0% 2%" }}>{i[1][0] === "" ? null : "section "}{i[1].length === 1 ? i[1] : (`${i[1][0]}-${i[1][1]}`)}</td>
                    <td style={{ padding: "0% 2%" }}>{i[2][0] === "" ? null : "tracker "}{i[2].length === 1 ? i[2] : (`${i[2][0]}-${i[2][1]}`)}</td>
                    <td style={{ padding: "0% 2%" }}><button id={ind} onClick={(e) => deletescope(ind)}><FaTrash color="steelblue" /></button></td>
                  </tr>)
              }) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>)
}
export default ScopeSelect
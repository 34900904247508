import axios from "axios";
import { atom } from "jotai";

// const currentProject = atom(null)

const projectAtom = atom([])

export const fetchProjectAtom = atom(
    null,
    async(get, set, args) => {
        let res = await axios.get(`/project/get_projects`)
        set(projectAtom, res.data)
    }
)

export default projectAtom
import axios from "axios";
import { atom } from "jotai";
import produce from "immer"
import { CREATE_UPDATE_QC_CHECKPOINTS, GET_PIER_TT_PARTS } from "./constant";
import { create_update_apply_qc, create_update_qc } from "../../dal/qcCheckpoints/qc-dal";

const qcAtom = atom({
    typeMode: {},
    trackerDatas: [],
    checklistData: [],
    pierTtParts: [],
    pierLegends: [],
    trackerDataPierTT: {}
})

export const fetchTypeTracker = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/tracker_type_map_type`)
        set(qcAtom, produce((draft) => { draft.typeMode = res.data }))
    }
)

export const fetchPierTtParts = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`${GET_PIER_TT_PARTS}?tracker_type_id=${args.tracker_type}`)
        set(qcAtom, produce((draft) => { draft.pierTtParts = res.data }))
        let trackerRes = await axios.get(`/tracker_row/bulk/all?tracker_type_id=${args.tracker_type}`)
        set(qcAtom, produce((draft) => {draft.trackerDataPierTT = trackerRes.data}))
        let partIt
        let checklistDataRes
        if (args.tracker) {
            if (args.tab === "Piers") {
                console.log(res.data, 'piers')
                partIt = res.data?.pier_parts[0]?.id
                checklistDataRes = await axios.get(`/projects/get_checklist_checkpoints?tracker_type_id=${args.tracker_type}&type=${args.tab.toLowerCase()}`)
            }
            else {
                partIt = res.data?.torque_tube_parts[0]?.id
                checklistDataRes = await axios.get(`/projects/get_checklist_checkpoints?tracker_type_id=${args.tracker_type}&type=${args.tab.toLowerCase()}`)
            }
        }
        else {
            if (args.tab === "Piers") {
                console.log(res.data, 'piers')
                let pierData = trackerRes.data.piers[Object.keys(trackerRes.data.piers)[0]]
                partIt = [...pierData].sort((a, b) => a.latitude - b.latitude)[0]?.pier_type_id
                checklistDataRes = await axios.get(`/projects/get_checklist_checkpoints?tracker_type_id=${args.tracker_type}&type=${args.tab.toLowerCase()}&pier_part_id=${partIt}`)
            }
            else {
                let ttData = trackerRes.data.tt[Object.keys(trackerRes.data.tt)[0]]
                partIt = [...ttData].sort((a, b) => a.latitude - b.latitude)[0]?.tt_type_id
                checklistDataRes = await axios.get(`/projects/get_checklist_checkpoints?tracker_type_id=${args.tracker_type}&type=${args.tab.toLowerCase()}&torque_tube_part_id=${partIt}`)
            }
        }
        console.log(checklistDataRes.data)
        set(qcAtom, produce((draft) => { draft.checklistData = checklistDataRes.data }))
    }
)

export const fetchTrackerTypes = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`tracker/get_tracker_types`)
        set(qcAtom, produce((draft) => { draft.trackerDatas = res.data }))
    }
)

export const fetchPierLegends = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/pierslenged`)
        set(qcAtom, produce((draft) => { draft.pierLegends = res.data }))
    }
)

export const fetchQcChecklist = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/get_checklist_checkpoints?type=${args.tab.toLowerCase()}`)
        set(qcAtom, produce((draft) => { draft.checklistData = res.data }))
    }
)

export const fetchQcTrackerChecklist = atom(
    null,
    async (get, set, args) => {
        let res
        if (args.tab === "Piers") {
            res = await axios.get(`/projects/get_checklist_checkpoints?tracker_type_id=${args.tracker_type_id}&type=${args.tab.toLowerCase()}&pier_part_id=${args.selectedItem}`)
        }
        else {
            res = await axios.get(`/projects/get_checklist_checkpoints?tracker_type_id=${args.tracker_type_id}&type=${args.tab.toLowerCase()}&torque_tube_part_id=${args.selectedItem}`)
        }
        console.log(res.data)
        set(qcAtom, produce((draft) => { draft.checklistData = res.data }))
    }
)

export const createUpdateQcCheckpoints = atom(
    null,
    async (get, set, args) => {
        console.log(args)
        let res = await create_update_qc(args.checklistData, args.selectedItem, args.projectLevel, args.tracker_type_id, args.newId, args.itr)
        console.log(res)
    }
)

export const createUpdateApplyQc = atom(
    null,
    async (get, set, args) => {
        await create_update_apply_qc(args.checklistData, args.tracker_type_id, args.applyStatus, args.newId)
    }
)

export default qcAtom
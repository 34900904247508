import React, { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Container from '../../../components/container'
import { HiChartBarSquare } from 'react-icons/hi2'
import { SiAdguard } from 'react-icons/si'
import { BiArrowBack } from 'react-icons/bi'
import { useAtom } from 'jotai'
import projectAtom, { fetchProjectAtom } from '../../../atoms/projectAtom'
import project_orgAtom, { fetchProjOrgAtom } from '../../../atoms/permissionAtom'


function Summary() {

    const location = useLocation()
    const navigate = useNavigate()

    const [listProjects] = useAtom(projectAtom)
    const [, setFetchProjects] = useAtom(fetchProjectAtom)
    const [, fetchProjOrgPermission] = useAtom(fetchProjOrgAtom)
    const [getPermission] = useAtom(project_orgAtom)

    useEffect(() => {
        init()
    }, [])
    const navigation_permission = () => {//navigation by permission logic
        if (getPermission["mech-Construction Progress"]) {
            navigate("/reports/summary/constructionProgress")
        }
        else {
            if (location.pathname !== '/reports/summary/qualityCheck') {
                navigate("/reports/summary/qualityCheck")
            }
        }
        if (!getPermission["mech-Quality Check"]) {

            if (location.pathname !== '/reports/summary/constructionProgress') {
                navigate("/reports/summary/constructionProgress")
            }
        }
    }
    const init = async () => {
        try {
            fetchProjOrgPermission()
            await setFetchProjects().then(navigation_permission)
        }
        catch (err) {
            console.log(err)
        }
    }

    function checkQcEnable() {
        let currentProject = listProjects.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        return currentProject?.project_settings?.qc
    }

    const tabStyleCP = () => {
        if (location.pathname === '/reports/summary' || location.pathname === '/reports/summary/constructionProgress') {
            return {
                color: "#F16253",
                backgroundColor: "#FEF6F6",
                marginBottom: "8px"
            }
        }
        else {
            return {
                color: "#696F79",
                backgroundColor: "White",
                marginBottom: "8px"
            }
        }
    }

    const tabStyleQC = () => {
        if (location.pathname === '/reports/summary/qualityCheck') {
            return {
                color: "#F16253",
                backgroundColor: "#FEF6F6",
                marginBottom: "8px"
            }
        }
        else {
            return {
                color: !checkQcEnable() ? "black" : "#9CA3AF",
                backgroundColor: !checkQcEnable() ? "#b6bbc2" : "white",
                marginBottom: "8px"
            }
        }
    }
    const tabStyleWH = () => {
        if (location.pathname === '/reports/summary/workHours') {
            return {
                color: "#F16253",
                backgroundColor: "#FEF6F6",
                marginBottom: "8px"
            }
        }
        else {
            return {
                color: !checkQcEnable() ? "#404040" : "#9CA3AF",
                backgroundColor: !checkQcEnable() ? "#b6bbc2" : "white",
                marginBottom: "8px"
            }
        }
    }

    return (
        <Container>
            <div className='flex justify-between mb-5'>
                <div>
                    <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-3 ml-5 hover:bg-gray-100 rounded-xl active:bg-gray-200" onClick={() => navigate("/reports")} />
                    <div className='ml-6 mt-5 text-xl font-bold'>
                        Summary
                    </div>
                </div>
                <div className=' text-sm flex text-gray-500 font-semibold mt-10 mr-6' >
                    {getPermission["mech-Construction Progress"] ?
                        <NavLink className={`mr-6 cursor-pointer flex border-none rounded-2xl pr-4 p-2 pt-1.5 `} style={tabStyleCP} to={'constructionProgress'} >
                            {location.pathname === '/reports/summary' || location.pathname === '/reports/summary/constructionProgress' ?
                                <HiChartBarSquare style={{ width: '20px', height: '20px' }} className='mx-2 text-red-550' />
                                :
                                <HiChartBarSquare style={{ width: '20px', height: '20px' }} className='mx-2 text-gray-400' />
                            }
                            <p>Construction Progress</p>
                        </NavLink> : null}
                    {!getPermission["mech-Quality Check"] ? null :
                        checkQcEnable() ?
                            <NavLink className={`mr-0 flex border-none rounded-2xl pr-4 p-2 pt-1.5 cursor-pointer`} style={tabStyleQC} to={'qualityCheck'}>
                                {location.pathname === '/reports/summary/qualityCheck' ?
                                    <SiAdguard style={{ width: '20px', height: '20px' }} className='text-red-550 mx-2 mt-0.5' />
                                    :
                                    <SiAdguard style={{ width: '20px', height: '20px' }} className='text-gray-400 mx-2 mt-0.5' />
                                }
                                <p>Quality Check</p>
                            </NavLink>
                            :
                            null
                    }
                    {/* <NavLink className={`flex border-none rounded-2xl pr-4 p-2 pt-1.5 ${!checkQcEnable() ? "cursor-not-allowed" : "cursor-pointer"}`} style={tabStyleWH}
                        to={!checkQcEnable() ? null : 'workHours'}
                    >
                        {location.pathname === '/reports/summary/workHours' ?
                            <MdAccessTimeFilled style={{ width: '20px', height: '20px' }} className='text-red-550 mx-2' /> :
                            <BiTimeFive style={{ width: '20px', height: '20px' }} className='text-gray-400 mx-2' />}
                        <p>Work Hours</p>
                    </NavLink> */}
                </div>
            </div>
            <hr />
            {
                ((location.pathname === '/reports/summary/constructionProgress' && getPermission["mech-Construction Progress"] === false) || (location.pathname === '/reports/summary/qualityCheck' && getPermission["mech-Quality Check"] === false)) ?
                    null : <Outlet />
            }
        </Container>


    )
}

export default Summary
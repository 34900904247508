import axios from "axios";
import { atom } from "jotai";
import produce from "immer";

const workSummaryAtom = atom({
    users:[],
    work_data:[],
    activity:[]
})

export const fetchWorkSummaryAtom = atom (
    null,
    async(get,set,args) => {
          let res = await axios.post(`/projects/reports/summary?end_date=${args.end_date}&start_date=${args.start_date}`)
        if(res.data.status_code === 404) {
            set(workSummaryAtom, produce((draft) => {draft.work_data = []}))
        }
        else{
            set(workSummaryAtom, produce((draft) => {draft.work_data = res.data}))
        }
    }
)

export const fetchStaffDetailsAtom = atom(
    null,
    async (get,set,args) => {
        let res = await axios.get(`/staff/only_project`)

        for (let pic of res?.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(workSummaryAtom, produce((draft) => { draft.users = res.data }))
    }
)

export const fetchStaffActivityAtom = atom(
    null,
    async(get, set, args) => {
        let res = await axios.get(`/checklist/get_checklist?type=Construction`)
        let temp = []
        for (let activity of res.data.data) {
            temp.push({
                id: activity.activity_id,
                name: activity.activity
            })
            for (let sub_activity of activity.sub_activity) {
                temp.push({
                    id: sub_activity.activity_id,
                    name: sub_activity.activity
                })
            }
        }
        set(workSummaryAtom, produce((draft) => { draft.activity = temp}))
    }
)

export default workSummaryAtom
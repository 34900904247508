import { useAtom } from "jotai";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi"
import trackerAtom, { fetchtracker } from "../../../atoms/trackerAtom";
import Container from '../../../components/container'
import ConstructionActivity from "./ConstructionActivity";
import ConstructionCreated from "./ConstructionCreated";


function CheckBox({ name, onClick, border }) {
    return (
        <div onClick={onClick}>
            <button className={`w-72 p-1 m-4 pt-2 ml-6 border-2 ${border} flex flex-col justify-start items-start text-left rounded-xl h-full`}>
                <p className="font-bold pl-3 text-left text-wrap break-words">{name.split("-")[0]}-{name.split("-")[1]}<br />{name.split("-")[2]}</p>
                <p className=" h-7 m-3 rounded-xl w-36 pl-4 pt-1.5 text-xs text-left border-none border-2 bg-gray-100">Add Custom Notes</p>
            </button>
        </div>
    )
}

function ConstructionProgress() {

    const location = useLocation()
    const navigate = useNavigate();

    const [clicked, setClicked] = useState()
    const [newName, setNewName] = useState('')

    const [listTracker] = useAtom(trackerAtom)
    const [, fetchTrackerInit] = useAtom(fetchtracker)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await fetchTrackerInit()
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <Container>
            <div className='flex h-full w-full'>
                <div className='border-r-2 border-gray-200' style={{ width: '350px' }}>
                    <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/construction")} />
                    <div className='font-bold text-xl ml-5 my-5'>
                        CP Checklist
                    </div>
                    <button className=" flex justify-between bg-black text-white cursor-pointer text-left rounded-xl w-72 py-5 ml-5" onClick={() => { setClicked(); navigate("/construction/ConstructionProgress") }}>
                        <p className="ml-5 mt-1">Manage Checklist</p>
                        <p className="mr-5 text-2xl">{">"}</p>
                    </button>
                    {/* <button style={{ padding: '8px 0 8px 15px', backgroundColor: 'black', color: 'white', borderRadius: '10px', textAlign: 'left' }} className=" w-72 h-18 ml-5" onClick={() => {setClicked()}}>
                            <p style={{ fontSize: '14px', paddingBottom: '3px', position: 'absolute' }}>Create Checklist </p><span style={{ margin: '0 0 0 85%', position: 'relative', top: '5px', fontSize: '25px' }}>{">"}</span>
                            <p className="text-gray-300" style={{ fontSize: '10px', position: 'relative' }}>.</p>
                        </button> */}
                    <div className='overflow-y-scroll mt-4' style={{ height: 'calc(100vh - 285px)', width: '350px', marginLeft: '-2px' }}>
                        {listTracker.map((i, index) => {
                            return <NavLink to={`${i?.id}`}>
                                <CheckBox key={`ckd-${index}`} border={clicked === i.id ? "border-black" : "border-gray"} name={`${i.tracker_type}`} onClick={() => {
                                    setNewName({ name: `${i.tracker_type}`, tracker_type_id: i.id })
                                    setClicked(i.id)
                                }} />
                            </NavLink>
                        })}

                    </div>
                </div>
                <div style={{ width: "calc(100% - 350px)" }}>
                    {location.pathname === "/construction/ConstructionProgress" ? <ConstructionActivity /> : <ConstructionCreated name={newName} />}
                </div>
            </div>

        </Container>
    )
}

export default ConstructionProgress
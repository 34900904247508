import { useAtom } from 'jotai'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from "react-hot-toast";
import Modal from 'react-modal';
import accessAtom, { additionalRightsAtom, addNewUserAtom, fetchAccessAtom, updatePermissions, UpdateRightsAtom } from '../../atoms/accessAtom'
import Container from '../../components/container'
import { BsThreeDots } from "react-icons/bs"
import { MdModeEditOutline, MdOutlineCancel } from "react-icons/md"
import { VscClose } from 'react-icons/vsc';
import { TiTick } from 'react-icons/ti'
import MultiSelect from './multiSelect'
import { Button } from '@react-pdf-viewer/core'
import { countryCode } from "../misc/countryCode";

function AccessRights() {

    const { register, handleSubmit, reset } = useForm()
    const { register: register2, reset: reset2, handleSubmit: handleSubmit2 } = useForm()

    const [listOrgUsers] = useAtom(accessAtom)

    const [, setCreateStaff] = useAtom(addNewUserAtom)
    const [, setFetchStaffs] = useAtom(fetchAccessAtom)
    const [, setUpdateStaffs] = useAtom(UpdateRightsAtom)
    const [, setFetchRights] = useAtom(additionalRightsAtom)
    const [, setUpdatePermissions] = useAtom(updatePermissions)

    const [loading, setLoading] = useState(false)

    const [userData, setUserData] = useState()
    const [editChanges, setEditChanges] = useState(false)
    const [submitBool, setSubmitBool] = useState(0)
    const [emailId, setEmailId] = useState("")
    const [appId, setAppId] = useState([])
    const [webrights, setWebrights] = useState([false, false, false, false]);
    const [tabrights, setTabrights] = useState([false, false, false, false]);

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            await setFetchStaffs()
            //await setFetchRights()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }
    const handleWebChange = (index) => {
        const newCheckboxState = [...webrights];
        newCheckboxState[index] = !newCheckboxState[index];
        setWebrights(newCheckboxState);
    };
    const handleTabChange = (index) => {
        const newCheckboxState = [...tabrights];
        newCheckboxState[index] = !newCheckboxState[index];
        setTabrights(newCheckboxState);
    };
    const onSubmit = async (data) => {
        try {
            let tempUserAppId = listOrgUsers.users.find(k => k.user.email === data.email)
            if (tempUserAppId) {
                setLoading(true)
                await setUpdateStaffs({ user_id: tempUserAppId.user_app_id, device_type: { device_type: appId.length === 2 ? 3 : appId[0] } })
                let temp = []
                if (data?.addRights_1) {
                    temp.push(1)
                }
                if (data?.addRights_2) {
                    temp.push(2)
                }
                if (data?.addRights_3) {
                    temp.push(3)
                }
                if (data?.addRights_4) {
                    temp.push(4)
                }
                if (temp.length > 0) {
                    await setUpdatePermissions({ user_id: tempUserAppId.user_app_id, value: { permission: temp } })
                }
                toast.success("Invitation sent successfully")
            } else {
                setLoading(true)
                let tempData = {}
                tempData.first_name = data?.first_name
                tempData.last_name = data?.last_name
                tempData.email = data.email
                tempData.role_auth = 1
                tempData.device_type = appId.length === 2 ? 3 : appId[0]
                tempData.other_documents = []
                tempData.isStaff = false
                let temp = []
                if (data?.addRights_1) {
                    temp.push(1)
                }
                if (data?.addRights_2) {
                    temp.push(2)
                }
                if (data?.addRights_3) {
                    temp.push(3)
                }
                if (data?.addRights_4) {
                    temp.push(4)
                }
                tempData.permissions = []
                if (temp.length > 0) {
                    tempData.permissions = temp
                }
                await setCreateStaff(tempData)
            }
        }
        catch (err) {
            console.log(err)
            toast.error(err)
        }
        finally {
            reset()
            setLoading(false)
            setSubmitBool(submitBool + 1)
        }
    }

    const onSubmitEdit = async (data) => {
        try {
            setLoading(true)
            await setUpdateStaffs({ user_id: userData.user_app_id, device_type: { device_type: appId.length === 2 ? 3 : appId[0] } })
            toast.success("Updated successfully")
            let temp = []
            if (data?.addRights_1) {
                temp.push(1)
            }
            if (data?.addRights_2) {
                temp.push(2)
            }
            if (data?.addRights_3) {
                temp.push(3)
            }
            if (data?.addRights_4) {
                temp.push(4)
            }
            await setUpdatePermissions({ user_id: userData.user_app_id, value: { permission: temp } })
            setEditChanges(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Failed to Update")
        }
        finally {
            reset2()
            setLoading(false)
        }
    }

    let filteredArr = []

    for (let type of listOrgUsers.users) {
        filteredArr.push(type)
    }

    const editOnClick = (i) => {
        setUserData(i)
        setEditChanges(true)
    }

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = (i) => {
        setUserData(i)
        setIsOpen(true)
    }

    const afterOpenModal = () => {
        subtitle.style.color = '#f00'
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const revokeAccess = async () => {
        try {
            setLoading(true)
            await setUpdateStaffs({ user_id: userData.user_app_id, device_type: { device_type: 0 } })
            setIsOpen(false)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const findChecked = (data) => {
        let temp = userData.permissions.find(k => k.permission_id === data)
        if (temp) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <div>
            <div className='overflow-auto px-8' style={{ width: "calc(100vw-250px)", height: "calc(100vh - 130px)" }} >
                <p className='m-6 mb-3 font-bold text-base'>
                    Access Rights
                </p>
                <p className='text-gray-400 ml-6 mb-5 text-sm font-normal'>
                    Enter details of the member & send invite for access
                </p>
                {editChanges ?
                    <form onSubmit={handleSubmit2(onSubmitEdit)}>
                        <div className='flex justify-between mb-3'>
                            <div className='flex' style={{ width: "calc(100vw - 550px)" }}>
                                <input className=' appearance-none text-gray-700 border border-gray-300 rounded-xl text-gray-700 leading-tight px-3 px-4 ml-4 w-64' disabled style={{ width: "250px" }} value={userData?.user?.profile_data?.first_name} {...register2("first_name")} placeholder='Enter First Name' />
                                <input className=' appearance-none text-gray-700 border border-gray-300 rounded-xl text-gray-700 leading-tight px-3 px-4 ml-4 w-64' disabled style={{ width: "250px" }} value={userData?.user?.profile_data?.last_name ? userData?.user?.profile_data?.last_name : ""} {...register2("last_name")} placeholder='Enter Last Name' />
                                <input className=' appearance-none text-gray-700 border border-gray-300 rounded-xl text-gray-700 leading-tight px-3 px-4 mx-4 w-64' disabled style={{ width: '250px' }} value={userData?.user?.email} {...register2("email")} />
                                <MultiSelect onChange={e => { setAppId(e) }} bool={submitBool} defaultValue={[userData.device_type]} edit={true} />
                            </div>
                            <div className='flex'>
                                <button type='reset' className='border rounded-3xl mr-2 btn btn-outline text-gray-500 ml-3' onClick={() => { setEditChanges(false); reset2() }}>Cancel</button>
                                <input type="submit" value="Update Invite" className='border-none rounded-3xl bg-red-550 text-white btn pl-4 pr-6 mr-10' style={{ width: '135px' }} />
                            </div>
                        </div>
                        <p className='text-sm font-semibold text-gray-400 ml-6'>Additional Rights</p>
                        <div className='flex flex-wrap ml-5 mb-2'>
                            {listOrgUsers.rights.map((ri) => {
                                return (
                                    <div className="form-control mr-5">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" defaultChecked={findChecked(ri.id)} className="checkbox checkbox-accent checkbox-sm" {...register2(`addRights_${ri.id}`)} />
                                            <span className='text-sm'>{ri?.module}</span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </form>
                    :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex justify-between mb-3'>
                            <div className='flex'>
                                <input className=' appearance-none text-gray-700 border border-gray-300 rounded-xl  leading-tight px-4 ml-4 w-64' style={{ width: "250px" }} placeholder="Enter First name" {...register("first_name")} defaultValue="" />
                                <input className=' appearance-none border border-gray-300 rounded-xl text-gray-700 leading-tight px-4 ml-4 w-64' style={{ width: "250px" }} placeholder="Enter Last name" {...register("last_name")} defaultValue="" />
                                <input className=' appearance-none text-gray-700 border border-gray-300 rounded-xl  leading-tight px-4 mx-4 w-64' style={{ width: "250px" }} placeholder="Enter Email ID" {...register("email")} defaultValue="" />
                                <select style={{
                                    width: "47%", fontSize: "14px", fontWeight: "500", color: "#696F79", backgroundColor: "white",
                                    border: "lightgrey solid 1px", borderRadius: "14px", padding: "1%", minWidth: "200px"
                                }} select size="small" variant="outlined" id="outlined-basic" label="Country Code" name="KAM_CountryCode"  {...register("country_code")} >
                                    <option selected disabled hidden>Country Code</option>
                                    {countryCode.map((i, index) => {
                                        return (
                                            <option key={i.name} value={index}>
                                                {i.name + " (" + i.code + ")"}
                                            </option>
                                        )
                                    })}
                                </select>
                                <input className=' appearance-none text-gray-700 border border-gray-300 rounded-xl  leading-tight px-4 mx-4 w-64' style={{ width: "250px" }} placeholder="Enter Mobile Number" {...register("number")} defaultValue="" />
                            </div>
                            <input type="submit" value="Give Access" className='border-none rounded-3xl bg-red-550 text-white btn mr-10' style={{ width: '135px' }} />
                        </div>
                        <p className='text-sm font-semibold text-gray-400 ml-6'>Web App</p>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="0" checked={webrights[0]} onChange={() => handleWebChange(0)}></input>
                                <label > Full</label>
                            </div>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="1" checked={webrights[1]} onChange={() => handleWebChange(1)} ></input>
                                <label > Dashboard & Bill of materials</label>
                            </div>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="2" checked={webrights[2]} onChange={() => handleWebChange(2)}></input>
                                <label > Add / edit deliveries and inventory management</label>
                            </div>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="3" checked={webrights[3]} onChange={() => handleWebChange(3)}></input>
                                <label > Edit access rights (for their personnel)</label>
                            </div>
                        </div>
                        <p className='text-sm font-semibold text-gray-400 ml-6'>Tablet App</p>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="0" checked={tabrights[0]} onChange={() => handleTabChange(0)}></input>
                                <label > Full</label>
                            </div>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="1" checked={tabrights[1]} onChange={() => handleTabChange(1)}></input>
                                <label > Dashboard & Bill of materials</label>
                            </div>
                            <div style={{ marginRight: "4%" }}>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="2" checked={tabrights[2]} onChange={() => handleTabChange(2)}></input>
                                <label > Enter mechanical construction progress </label>
                            </div>
                            <div>
                                <input style={{ height: "20px", width: " 30px" }} type="checkbox" value="3" checked={tabrights[3]} onChange={() => handleTabChange(3)}></input>
                                <label > Enter mechanical quality control detail</label>
                            </div>
                        </div>
                        <div className='flex flex-wrap ml-5 mb-2'>
                            {listOrgUsers?.rights.map((ri, index) => {
                                return (
                                    <div key={index} className="form-control mr-5">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" className="checkbox checkbox-accent bg-white checkbox-sm" {...register(`addRights_${ri.id}`)} />
                                            <span className='text-sm'>{ri?.module}</span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </form>
                }
                <hr />

                {loading ?
                    <div className='' style={{ marginTop: "10%", marginLeft: "44%" }}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p>
                    </div>
                    :
                    <>
                        {filteredArr.length === 0 ?
                            <div className='text-center' style={{ marginTop: "13%" }}>
                                <img src="emptyContact.svg" alt='' style={{ marginLeft: "47%" }} />
                                <p className='font-medium text-sm text-gray-400'>No other users except KAM</p>
                            </div>
                            :
                            <div className="overflow-auto" style={{ height: "calc(100vh - 375px)" }}>
                                <table className='table table-compact w-full p-2 z-0'>
                                    <thead>
                                        <tr className='text-left font-bold text-xl sticky top-0 z-20'>
                                            <th className='normal-case pl-12'>Name</th>
                                            <th className='normal-case'>Email ID</th>
                                            <th className='normal-case'>Mobile</th>
                                            <th className='normal-case'>Web Rights</th>
                                            <th className='normal-case'>Tab Rights</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredArr.map((i) => {
                                            return (
                                                <tr>
                                                    <td>{i?.name}</td>
                                                    <td>{i?.email}</td>
                                                    <td>{i?.profile_data?.number}</td>
                                                    <td>web rights</td>
                                                    <td>tab rights</td>
                                                    <td><Button>edit</Button></td>
                                                </tr>
                                            )
                                        })}
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onAfterOpen={afterOpenModal}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Revoke Access"
                                        >
                                            <div className='mx-4 my-2'>
                                                <div className='flex justify-between'>
                                                    <p className='text-xl font-bold mt-1'>Do you want to revoke access?</p>
                                                    <VscClose className='cursor-pointer' onClick={closeModal} style={{ width: '40px', height: '40px' }} />
                                                </div>
                                                <p className='mt-3 mb-10 mr-10'>Once you cancel the access permission the member <br />won’t be able to access the app</p>
                                                <hr />
                                                <div className='flex justify-end mt-4'>
                                                    <button className='border rounded-3xl mr-4 btn btn-outline text-gray-500' onClick={closeModal} >Cancel</button>
                                                    <button className='btn bg-red-550 border-none rounded-3xl' onClick={revokeAccess}>yes, Revoke</button>
                                                </div>
                                            </div>
                                        </Modal>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </>
                }
            </div>

        </div>
    )
}

export default AccessRights
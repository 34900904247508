import { useNavigate } from "react-router-dom";
import Container from "../../components/container";
import { BiArrowBack } from "react-icons/bi";
import { useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { AiOutlineInfoCircle } from "react-icons/ai";
import projectAtom from "../../atoms/projectAtom";
import dashboardAtom, { addLockDetail, fetchBlocks, fetchBulkDashboardDataGet, fetchCoordinates, fetchListRows, fetchListSections, fetchLockDetails, fetchMapRender, fetchMapSize, fetchSelectedSection } from "../../atoms/dashboardAtom";
import { useAtom } from "jotai";
import produce from 'immer';
import { BlockMap } from "@product-fusion/surya-fw";
import { FaUser, FaUsers } from "react-icons/fa";
import { MultiSelect } from "react-multi-select-component";

const Index = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [projectsName] = useAtom(projectAtom);
    const [listblocks] = useAtom(dashboardAtom);
    let project_name = projectsName.find(item => item.id === parseInt(localStorage.getItem("project_id")));
    const [, setFetchMap] = useAtom(fetchMapRender);
    const [, setCoordinates] = useAtom(fetchCoordinates)
    const [, setFetchBlocks] = useAtom(fetchBlocks);
    const [, setSectionList] = useAtom(fetchListSections)
    const [, setRowsList] = useAtom(fetchListRows)

    const [viewType, setViewType] = useState(false)

    const [dumRow, setDumRow] = useState([])
    const [prevRow, setPrevRow] = useState(null)

    const [selectAll, setSelectAll] = useState(false)
    const [clearSt, setClearSt] = useState(false)
    const [selectedBlock, setSelectedBlock] = useState([]);
    const [showBlock, setShowBlock] = useState({ bool: true, block: "" })
    const [dum, setDum] = useState({ bool: false, id: null, name: null });
    const [, setMapSize] = useAtom(fetchMapSize);
    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet);
    const [current, setCurrent] = useState('qc_external');
    const [punchBool, setPunch] = useState(true);
    const [selectedRow, setSelectedRow] = useState([]);
    const [, setLockDetail] = useAtom(addLockDetail);
    const [, setFetchLockDetail] = useAtom(fetchLockDetails);
    const [sectionSelect, setSectionSelect] = useState([]);
    const [prevSection, setPrevSection] = useState(null);
    const [, setSelectedSection] = useAtom(fetchSelectedSection);
    const [btnloader, setBtnloader] = useState(false);
    const [btn1loader, setBtn1loader] = useState(false);

    const init = async () => {
        setSectionSelect([])
        try {
            setLoading(true)
            await setFetchMap()
            await setFetchBlocks()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (project_name?.type) {
            if (listblocks.piersList) {
                rowCallFn()
            }
        }
        else {
            if (listblocks.blockTtData) {
                sectionCallFn()
            }
        }
    }, [listblocks?.blockTtData, listblocks?.piersList])

    const sectionCallFn = async () => {
        try {
            await setSectionList({ data: listblocks.blockTtData.piers })
        }
        catch (err) {
            console.log(err)
        }
    }

    const rowCallFn = async () => {
        try {
            await setRowsList({ data: listblocks.piersList })
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (dum.bool) {
            handleDblClick()
        }
    }, [dum.id])

    const handleLock = async (value) => {
        if (value === 'lock') {
            setBtnloader(true)
        } else {
            setBtn1loader(true)
        }

        const result = selectedRow.map(item => {
            const parts = item.split('-');
            return parts[1];
        });
        const data = {
            "tracker_row_ids": result,
            "lock": value === 'lock' ? true : false,
            "entity": current
        }
        await setLockDetail([data]);
        await setFetchLockDetail(dum.id)
        setSelectedRow([])
        setSectionSelect([])
        setBtnloader(false)
        setBtn1loader(false)
    }


    const handleDblClick = async () => {
        try {
            setLoading(true)
            await setMapSize({ id: dum.id })
            if (project_name?.type) {
                await setCoordinates({ id: dum.id })
            }
            else {
                await setFetchBulkGet({ id: dum.id })
            }
            await setFetchLockDetail(dum.id)
            setShowBlock({ bool: false, block: dum.name })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        svgInit()
    }, [listblocks.blocks, showBlock?.bool, selectedBlock])

    useEffect(() => {
        init()
    }, [])


    const svgInit = () => {
        if (listblocks?.blocks.length > 0) {
            let block_svg_id
            let block_progress_id
            listblocks?.blocks.map((i) => {
                block_svg_id = document.getElementById(project_name?.type ? i?.name : i?.block_svg_id)
                block_progress_id = document.getElementById(i?.progress_svg_id)
                if (block_svg_id) {
                    block_svg_id.addEventListener("click", () => {
                        let temp = []
                        if (selectedBlock.length > 0 && selectedBlock.includes(i?.id)) {
                            temp = selectedBlock.filter((item) => item !== i?.id)
                        }
                        else {
                            temp = [...selectedBlock, i?.id]
                        }
                        setSelectedBlock(temp)
                    })
                    block_svg_id.addEventListener("dblclick", () => {
                        setDum({ bool: true, id: i?.id, name: i?.name })
                    })
                }
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = null
                    if (selectedBlock.includes(i?.id)) {
                        block_svg_id.style.fill = "#F6BE00"
                    }
                    else {
                        block_svg_id.style.fill = "white"
                    }
                }
            })
        }
    }

    const handleSectionSelect = (data) => {
        if (sectionSelect.includes(data)) {
            setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
            setPrevSection(data)
        }
        else {
            setSectionSelect(produce((draft) => { draft.push(data) }))
            setPrevSection(null)
        }
    }

    const handleMultiSelect = (e) => {
        console.log(e)
        setDumRow(e)
        let temp = []
        e.map((i) => {
            temp.push(i?.value)
        })
        console.log("sectionSelect", sectionSelect)
        if (sectionSelect?.length > e?.length) {
            sectionSelect?.map((ij) => {
                let temp = e.filter(o => parseInt(o?.value) !== ij)
                if (temp?.length === e?.length) {
                    console.log(temp)
                    setPrevRow(ij)
                }
            })
        }

        setSectionSelect(temp)
        if (e.length === 0) {
            setPrevRow(sectionSelect[0])
            setSectionSelect([])
        }
    }

    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        if (project_name?.type) {
            if (listblocks?.rowList) {
                setPrevRow(null)
                let temp = []
                listblocks?.rowList.map((i) => {
                    temp.push(i?.value)
                })
                setSectionSelect(temp)
                setDumRow(listblocks?.rowList)
            }
        }
        else {
            if (listblocks?.sectionList) {
                setSectionSelect(listblocks?.sectionList)
                setPrevSection(null)
            }
        }
    }

    const handleClear = () => {
        setClearSt(!clearSt)
        setSelectAll(false)
        setSectionSelect([])
        setPrevSection(null)
        setDumRow([])
        setPrevRow(null)
    }

    const handleBack = () => {
        if (showBlock.bool) {
            navigate("/quality")
        }
        else {
            setShowBlock({ bool: true, block: "" })
        }
    }

    return (
        <Container>
            <div className="flex justify-between items-center">
                <div className="mb-6 px-4">
                    <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-3 ml-5 hover:bg-gray-100 rounded-xl active:bg-gray-200" onClick={handleBack} />
                    <div className='ml-6 mt-5 text-xl font-bold'>
                        Lock and Unlock
                    </div>
                </div>
                <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 mr-5 border-red-100 h-[50px]'>
                    <div className={`flex items-center ${viewType ? "" : "bg-red-550 border-none rounded-full"}`}>
                        <FaUsers className={`ml-3 ${viewType ? "text-gray-400" : "text-white"}`} />
                        <p className={`${viewType ? "text-gray-400 pr-3 pl-2" : "text-white pr-3 pl-2"} mr-1`} onClick={() => { setViewType(false) }}>Lock / Unlock</p>
                    </div>
                    <div className={`flex items-center ${viewType ? "bg-red-550 border-none rounded-full" : ""}`}>
                        <FaUser className={`ml-3 ${viewType ? "text-white" : "text-gray-400"}`} />
                        <p className={`${viewType ? "text-white pr-3 pl-2" : "text-gray-400 pr-3 pl-2"} ml-1`} onClick={() => { setViewType(true); navigate("/quality/logsandchecklist") }}>Logs</p>
                    </div>
                </div>
            </div>
            <hr />
            <div>
                {loading ?
                    <div style={{ height: "calc(100vh - 390px)" }} className='w-[100%]  flex justify-center items-center'>
                        <p className='loader mb-1'></p>
                        <p className='font-bold ml-4 text-xl'>Fetching...</p>
                    </div>
                    :
                    showBlock.bool ?
                        <div className='w-[100%]' style={{ height: "calc(100vh - 390px)" }}>
                            <div className='cursor-grab' style={{ height: "calc(100vh - 250px)" }}>
                                <TransformWrapper
                                    centerZoomedOut
                                    minScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale)}
                                    initialScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_initial_scale)}
                                    initialPositionX={((window.innerWidth - 60) - (parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale) * project_name?.svg_dimension?.web?.web_svg_width)) / 2}
                                    initialPositionY={((window.innerHeight - 350) - (parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale) * project_name?.svg_dimension?.web?.web_svg_height) + 100) / 2}
                                >
                                    <TransformComponent
                                        contentStyle={{ width: `${project_name?.svg_dimension?.web?.web_svg_width}px` }}
                                        wrapperStyle={{ height: "100%", width: "100%" }}
                                    >
                                        <div className='m-auto inline-block align-middle'>
                                            <div className="svgmap svgSiblingDiv flex items-center justify-center" style={{ height: `${project_name?.svg_dimension?.web?.web_svg_height}px`, width: `${project_name?.svg_dimension?.web?.web_svg_width}px`, position: "relative" }} dangerouslySetInnerHTML={{ __html: listblocks.map }}></div>
                                        </div>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                            <div className='flex justify-center mt-3'>
                                <span data-tip data-for="svg" className='mt-1 ml-4'>
                                    <AiOutlineInfoCircle />
                                </span>
                                <p className='text-sm text-gray-400 mt-0.5 ml-2'>Double click on any block to dive in</p>
                            </div>
                        </div>
                        :
                        <div className='w-[100%]' style={{ height: "calc(100vh - 300px)" }}>
                            <div>
                                <div className='px-8 flex gap-6 items-center p-2'>
                                    <div className='ml-2 font-semibold my-3'>{showBlock?.block}</div>
                                    <div className='flex w-[215px] text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 border-red-100'>
                                        <div className={`flex ${punchBool ? "bg-red-550 border-none rounded-full" : ""}`}>
                                            <FaUsers className={`mt-3 ml-3 ${punchBool ? "text-white" : "text-gray-400"}`} />
                                            <p className={`${punchBool ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} onClick={() => { setPunch(true); setCurrent('qc_external') }}>External</p>
                                        </div>
                                        <div className={`flex ${punchBool ? "" : "bg-red-550 border-none rounded-full"}`}>
                                            <FaUser className={`mt-3 ml-3 ${punchBool ? "text-gray-400" : "text-white"}`} />
                                            <p className={`${punchBool ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} onClick={() => { setPunch(false); setCurrent('qc_internal') }}>Internal</p>
                                        </div>
                                    </div>
                                    <button
                                        className="border bg-[#f16253] border-[#fee2e2] text-white py-2 px-4 rounded-full hover:text-[#9ca3af] hover:bg-[#fef2f2]"
                                        onClick={() => handleLock('lock')}
                                    >
                                        <div className="flex gap-2">
                                            {btnloader ? <div className='loaders text-sm'></div> : <div></div>}
                                            <div className="text-sm py-1">{`Lock Selected Tracker(s)`}</div>
                                        </div>
                                    </button>
                                    <button
                                        onClick={() => handleLock('unlock')}
                                        className="border bg-[#f16253] border-[#fee2e2] text-white py-2 px-4 rounded-full hover:text-[#9ca3af] hover:bg-[#fef2f2]"
                                    >
                                        <div className="flex gap-2">
                                            {btn1loader ? <div className='loaders text-sm'></div> : <div></div>}
                                            <div className="text-sm py-1">{`Unlock Selected Tracker(s)`}</div>
                                        </div>
                                    </button>
                                    <div className="flex items-center gap-">
                                        <div className='text-gray-400 text-sm font-semibold mr-2'>{project_name?.type ? "Row" : "Section"}</div>
                                        {project_name?.type ?
                                            <MultiSelect
                                                className='w-[150px] rounded-2xl cursor-pointer'
                                                options={listblocks?.rowList}
                                                value={dumRow}
                                                onChange={e => handleMultiSelect(e)}
                                                labelledBy={`Select Row(s)`}
                                                hasSelectAll={false}
                                                disableSearch={true}
                                                ClearSelectedIcon={null}
                                            />
                                            :
                                            <div className='flex flex-wrap justify-left text-center max-w-[200px] max-h-[70px] overflow-y-auto'>
                                                {listblocks?.sectionList.map((section) => {
                                                    return (
                                                        <p className={`cursor-pointer m-1 w-10 py-1 text-xs ${sectionSelect?.includes(section) ? "bg-red-550 text-white border-red-550 border" : "bg-white text-red-550 border"}`} onClick={() => handleSectionSelect(section)}>{section}</p>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                    <div className="flex space-x-8 mx-5 justify-end">
                                        <button className="border-none bg-red-550 btn text-white rounded-full py-2 px-4 cursor-pointer" onClick={handleSelectAll}>Select All</button>
                                        <button className="border-none bg-red-550 btn text-white rounded-full py-2 px-4 cursor-pointer" disabled={!selectedRow?.length > 0} onClick={handleClear}>Clear Selection</button>
                                    </div>
                                </div>
                                <hr />
                                <BlockMap
                                    deviceType="web"
                                    zimmerMan={project_name?.type}
                                    bulkAllData={listblocks?.blockTtData}
                                    bulkPierData={listblocks?.piersList}
                                    bulkAllTTData={listblocks?.tableCoordinates}
                                    pierLegends={listblocks?.pierLegends}
                                    type="lock"
                                    typeData={listblocks.lockDetail}
                                    userSelection={{ showType: "lock", selection: current }}
                                    currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(dum.id))}
                                    cpTrackerRowWise={listblocks.cpTrackerRowWise}
                                    currentProjectData={projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                    showLegend={false}
                                    mapSize={listblocks.mapSize}
                                    selectAll={selectAll}
                                    section={sectionSelect}
                                    prevSection={prevSection}
                                    prevRow={prevRow}
                                    clearSelection={clearSt}
                                />
                            </div>
                        </div>
                }
            </div>
        </Container>
    )

}

export default Index;
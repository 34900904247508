import axios from "axios";
import { atom } from "jotai";
import produce from "immer"

const accessAtom = atom({
    users: [], 
    rights: []
})

export const fetchAccessAtom = atom(
    null,
    async(get, set, args) => {
        let res = await axios.get(`/projects/staff?is_staff=false`);

        for(let pic of res.data){
            let temp = pic.other_documents.find(k => k.type === 3)
            if(temp) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}`) 
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else{
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(accessAtom, produce((draft) => {draft.users = res.data}))
    }
)

export const assignRightsAtom = atom(
    null,
    async(get, set, args) => {
        await axios.post(`/projects/device-type?user_id=${args.user_id}`, args.device_type)
        let res = await axios.get(`/projects/staff?is_staff=false`);

        for(let pic of res.data){
            let temp = pic.other_documents.find(k => k.type === 3)

            if(temp) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}`) 
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else{
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(accessAtom, produce((draft) => {draft.users = res.data}))
    }
)

export const UpdateRightsAtom = atom(
    null,
    async(get, set, args) => {
        await axios.patch(`/projects/staff/${args.user_id}?type=user_project`, args.device_type)
        let res = await axios.get(`/projects/staff?is_staff=false`);

        for(let pic of res.data){
            let temp = pic.other_documents.find(k => k.type === 3)

            if(temp) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}`) 
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else{
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(accessAtom, produce((draft) => {draft.users = res.data}))
    }
)

export const additionalRightsAtom = atom(
    null, 
    async(get, set, async) => {
        let res = await axios.get(`/admin/permission?level=0`)
        set(accessAtom, produce((draft) => {draft.rights = res.data}))
    }
)

export const updateAddRightsAtom = atom(
    null,
    async(get, set, args) => {
        await axios.post(`/admin/user-permission`, args)
        let res = await axios.get(`/projects/staff?is_staff=false`);

        for(let pic of res.data){
            let temp = pic.other_documents.find(k => k.type === 3)

            if(temp) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}`) 
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else{
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(accessAtom, produce((draft) => {draft.users = res.data}))
    }
)

export const addNewUserAtom  = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/projects/staff`,args)
        let res = await axios.get(`/projects/staff?is_staff=false`);

        for(let pic of res.data){
            let temp = pic.other_documents.find(k => k.type === 3)

            if(temp) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}`) 
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else{
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(accessAtom, produce((draft) => { draft.users = res.data }))
    }
)

export const updatePermissions = atom(
    null,
    async(get, set, args) => {
        await axios.post(`/projects/user-permission?user_id=${args.user_id}`, args.value)
        let res = await axios.get(`/projects/staff?is_staff=false`);

        for(let pic of res.data){
            let temp = pic.other_documents.find(k => k.type === 3)
            if(temp) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}`) 
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else{
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(accessAtom, produce((draft) => {draft.users = res.data}))
    }
)

export default accessAtom
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import {BsChevronDown, BsChevronUp} from "react-icons/bs"
import { IoMdClose } from "react-icons/io";


const SelectBox = ({onChange, hidden, className, width, value, bool}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState('')
    const [selectedItem, setSelectedItem] = useState()
    const dropdownRef = useRef(null);
    const [inputValue, setInputValue] = useState('');


    useEffect(() => {
        setSelectedItem()
    }, [bool])

    useEffect(() => {
       formateValue(selectedItem)
    }, [selectedItem])

    const handleFocus = () => {
        setIsOpen(!isOpen)
    }

    const handleClickOutside = (e) => {
        if(dropdownRef.current && !dropdownRef.current.contains(e.target)){
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleOnClick = (id) => {
        setSelectedItem(id)
        onChange(id)
        setInputValue(id)
    }

    const formateValue = (item) => {
        setData(value.find(temp => temp.plant_project_id === item)?.name)
    }

    const renderValue = (i) => {
        const {plant_project_id, name} = i
        const isActive = selectedItem
        return(
            <li
                key={plant_project_id}
                className={`flex bg-white font-medium ${isActive === plant_project_id ? "bg-gray-200" : "hover: bg-gray-100"} cursor-pointer`}
                onClick={() => {handleOnClick(plant_project_id)}}
                style={{textTransform: "capitalize"}}    
            >
                <span className='mx-2 py-2 flex'>
                    <p className=''>{name}</p>
                </span>
            </li>
        )
    }

    const handleClearClick = () => {
        onChange('')
        setData('')
        setInputValue('')
        setSelectedItem(0)
    }

    return (
        <div className='relative flex' ref={dropdownRef}>
            <div>
                <input 
                    type='text'
                    className={className}
                    style={{minWidth: width}}
                    placeholder={hidden}
                    onClick={handleFocus}
                    value={data}
                    readOnly
                    />
                    {isOpen && (
                        <div className='absolute z-10 bg-white shadow border border-gray-300 ml-4 rounded-xl' style={{minWidth: width}}>
                            <ul className='py-2 max-h-60 overflow-y-auto'>
                                {value.map((i, index) => renderValue(i, index))}
                            </ul>
                        </div>
                    )}
            </div>
            {isOpen ?
                <BsChevronUp onClick={handleFocus} className={`cursor-pointer absolute right-16 top-10`}/>
            :
            inputValue ? <IoMdClose onClick={handleClearClick} className={`cursor-pointer absolute right-16 top-10`}/>
                : <BsChevronDown onClick={handleFocus} className={`cursor-pointer absolute right-16 top-10`}/>
            }
            </div>
    )

}

export default SelectBox
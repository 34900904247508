import React, { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const ViewByDropDown = ({ onSelect, defaultValue }) => {

    const dropdownRef = useRef(null)

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(defaultValue || 'Mechanical Progress'); // Default value

    const options = ['Mechanical Progress', 'Laydown'];

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleSelect = (option) => {
        setSelected(option);
        setIsOpen(false);
        onSelect(option); // Pass the selected value to the parent component
    };
    const handleFocus = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="relative inline-block w-full" ref={dropdownRef}>
            <button
                style={{fontWeight: 600}}
                onClick={() => setIsOpen(!isOpen)}
                className="w-full flex justify-between rounded-xl cursor-pointer text-xs border-none items-center bg-red-550 text-white text-600 px-3 py-1 focus:outline-none hover:bg-red-500"
            >
                <span className="truncate">{selected}</span>
                {isOpen ?
                    <MdKeyboardArrowUp size={24} className='text-white' onClick={handleFocus} />
                    :
                    <MdKeyboardArrowDown size={24} className='text-white' onClick={handleFocus} />
                }
            </button>
            {isOpen && (
                <ul className={`absolute z-10 text-sm w-full bg-white py-1 border border-gray-300 rounded-md shadow-lg`}>
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className={`cursor-pointer px-4 py-1 hover:bg-gray-200 rounded-md`}
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ViewByDropDown;



import React, { useState } from "react";
import Container from "../components/container";
import {BiArrowBack} from "react-icons/bi"
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import {MdModeEditOutline} from "react-icons/md"
import { VscClose } from "react-icons/vsc";
import {IoIosLock} from "react-icons/io" 
import { IoMdSunny, IoMdMoon } from "react-icons/io";
import {TfiReload} from "react-icons/tfi"
import { useForm } from "react-hook-form";
import { useAtom } from "jotai";
import userAtom from "../atoms/userAtom";
import { toast } from "react-hot-toast";
import axios from "axios";
import themeAtom, { fetchThemeAtom } from "../atoms/themeAtom";
import {VscEye, VscEyeClosed} from "react-icons/vsc"

function ProfileSettings () {

    const navigate = useNavigate()
    const {register, handleSubmit} = useForm()
    const {register: register2, handleSubmit: handleSubmit2, reset} = useForm()

    const [editData, setEditData] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const [theme] = useAtom(themeAtom)
    const [, fetchTheme] = useAtom(fetchThemeAtom)
    const [listUser] = useAtom(userAtom)

    const toggleTheme = () => {
        fetchTheme(theme.theme === 'dark' ? 'light' : 'dark')
      }

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const afterOpenModal = () => {
        subtitle.style.color = '#f00'
    }

    const closeModal = () => {
        setIsOpen(false)
        reset()
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
    };

    const handlePassword = async(data) => {
        try{
            if(data.re_new_password !== data.new_password){
                toast.error("Password Doesn't Match")
            }
            else{
                await axios.post(`auth/reset-password`, {email: listUser?.data?.email, new_password: data.new_password, old_password: data.old_password})
                toast.success("Password changed Successfully")
                reset()
                setIsOpen(false)
            }
        }
        catch(err) {
            console.log(err)
            toast.error("Something Went Wrong")
        }
    }

    const onSubmit = (data) => {
    }

    const handleCheckCategory = (data) => {
        let temp
        if(data === 0 ){
            temp = "EPC"
        }
        else if(data === 1 ){
            temp = "Contractor"
        }
        else if(temp === 2 ){
            temp = "Project Developer"
        }
        else{
            temp = "Supplier"
        }
        return temp
    }

    return(
        <div>
            {!editData ? 
            <div>
                <div className="flex justify-between mt-5">
                    <div className="flex items-center mb-5 ml-5 px-8">
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="mt-1 cursor-pointer" onClick={() => navigate(-1)}/>
                        <p className="font-bold text-base ml-5 mt-1">Profile & Settings</p>
                    </div>
                    <div className="flex mr-10">
                        {/* Reset */}
                        {/* <div className="flex cursor-pointer mr-4 mt-2">
                            <TfiReload className="text-gray-500 mt-1 mr-1.5" />
                            <p className="text-gray-500">Reset</p>
                        </div> */}
                        <div className="flex cursor-pointer" onClick={openModal}>
                            <IoIosLock className="mt-2 text-lg mr-1.5 text-gray-500"/>
                            <p className="text-gray-500 mt-2">Change Password</p>
                        </div>
                        {/* Theme */}
                        {/* <div className="flex mx-4 ml-8 mt-2 cursor-pointer" onClick={toggleTheme}>
                            {theme.theme === 'light' ? 
                            <IoMdSunny className="mt-1 text-lg mr-1.5 text-gray-500"/>:
                            <IoMdMoon className="mt-1 text-lg mr-1.5 text-gray-500" />}
                            <p className="text-gray-500">{theme.theme === 'dark' ? 'Dark' : 'Light'} Theme</p>
                        </div> */}
                    </div>
                </div>
                <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Password Modal"
                    >
                        <div className="flex justify-between">
                        <p className="text-lg font-bold my-2 mb-8">Change Password</p>
                            <VscClose className="cursor-pointer mt-1.5 active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{width: "33px", height: "33px"}} onClick={closeModal} />
                        </div>
                        <form onSubmit={handleSubmit2(handlePassword)}>
                            <div>
                                <label>
                                    Old Password
                                    <p className='absolute' style={{top: "257px", right: "55px"}}>
                                        {showPassword ? 
                                            <VscEyeClosed className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)}/>
                                        : 
                                            <VscEye className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)}/>
                                        }
                                    </p>
                                    <input type={`${showPassword ? "text": "password"}`} className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-6 mr-3" placeholder="Enter Old Password" style={{width: "400px"}} {...register2("old_password")} />
                                </label>
                                <label>
                                    New Password
                                    <p className='absolute' style={{top: "142px", right: "55px"}}>
                                        {showPassword ? 
                                            <VscEyeClosed className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)}/>
                                        : 
                                            <VscEye className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)}/>
                                        }
                                    </p>
                                    <input type={`${showPassword ? "text": "password"}`} className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-6 mr-3" placeholder="Enter New Password" style={{width: "400px"}} {...register2("new_password")} />
                                </label>
                                <label>
                                    Confirm New Password
                                    <p className='absolute' style={{bottom: "108px", right: "55px"}}>
                                       {showPassword ? 
                                            <VscEyeClosed className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)}/>
                                        : 
                                            <VscEye className='text-xl cursor-pointer' onClick={() => setShowPassword(current => !current)}/>
                                        } 
                                    </p>
                                    <input type={`${showPassword ? "text": "password"}`} className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-6 mr-3" placeholder="Re-Enter New Password" style={{width: "400px"}} {...register2("re_new_password")} />
                                </label>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button onClick={closeModal} className="border rounded-3xl mr-4 btn btn-outline text-gray-500" style={{ width: '100px' }}>Cancel</button>
                                <button type="submit" className='btn bg-red-550 border-none rounded-3xl'>Update Password</button>
                            </div>
                        </form>
                    </Modal>
                <hr/>
                <div className="flex mt-10 ml-14">
                    <div className="border rounded-xl border-2 mr-5 p-6 w-96">
                        <p className="mb-6 font-bold text-base">Company Details</p>
                        <hr/>
                        <p className="mt-8 text-sm font-medium text-gray-400">Company Name</p>
                        <p className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.name}</p>
                        <p className="mt-4 text-sm font-medium text-gray-400">Website Link</p>
                        <p className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.website ? listUser?.data?.organizations[0]?.website : "-"}</p>
                        <p className="mt-4 text-sm font-medium text-gray-400">Address</p>
                        <p className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.address ? listUser?.data?.organizations[0]?.address : "-"}</p>
                        <p className="mt-4 text-sm font-medium text-gray-400">Category</p>
                        <p className="mt-2 text-base font-medium">{handleCheckCategory(listUser?.data?.organizations[0]?.type)}</p>
                        <p className="mt-4 text-sm font-medium text-gray-400">About</p>
                        <p className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.about ? listUser?.data?.organizations[0]?.about : "-"}</p>
                    </div>
                    <div className="border rounded-xl border-2 mr-5 p-6 w-96">
                        <div className="flex">
                            <p className="mb-6 font-bold text-base">Key Account Manager Details</p>
                            {/* Edit button */}
                            {/* <MdModeEditOutline style={{ width: "20px", height: '20px' }} className="mt-1 ml-4 cursor-pointer" onClick={() => setEditData(true)}/> */}
                        </div>
                        <hr/>
                        <p className="mt-8 text-sm font-medium text-gray-400">Name</p>
                        <p style={{textTransform: "capitalize"}} className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.kam?.profile_data?.first_name} {listUser?.data?.organizations[0]?.kam?.profile_data?.last_name}</p>
                        <p className="mt-4 text-sm font-medium text-gray-400">Email ID</p>
                        <p className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.kam?.email}</p>
                        <p className="mt-4 text-sm font-medium text-gray-400">Phone</p>
                        <p className="mt-2 text-base font-medium">{listUser?.data?.organizations[0]?.kam?.profile_data?.number}</p>
                    </div>
                </div>
            </div>
            :
            <div>
                <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Password Modal"
                    >
                        <div className="flex justify-between">
                        <p className="text-lg font-bold my-2 mb-8">Change Password</p>
                            <VscClose className="cursor-pointer mt-1.5" style={{width: "33px", height: "33px"}} onClick={closeModal} />
                        </div>
                        <form onSubmit={handleSubmit2(handlePassword)}>
                            <div>
                                <label>
                                    New Password
                                    <input type="password" className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-6 mr-3" placeholder="Enter New Password" style={{width: "400px"}} {...register2("new_password")} />
                                </label>
                                <label>
                                    Confirm New Password
                                    <input type="password" className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-6 mr-3" placeholder="Re-Enter New Password" style={{width: "400px"}} {...register2("re_new_password")} />
                                </label>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button onClick={closeModal} className="border rounded-3xl mr-4 btn btn-outline text-gray-500" style={{ width: '100px' }}>Cancel</button>
                                <button type="submit" className='btn bg-red-550 border-none rounded-3xl'>Update Password</button>
                            </div>
                        </form>
                    </Modal>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between mt-14 mb-8 ml-4">
                        <div className="flex">
                            <BiArrowBack style={{ width: '25px', height: '25px' }} className="mt-1 cursor-pointer" onClick={() => setEditData(false)}/>
                            <p className="font-bold text-2xl ml-6">Profile & Settings</p>
                        </div>
                        <div className="flex">
                            <div className="flex cursor-pointer mr-4 mt-2">
                                <TfiReload className="text-gray-500 mt-1 mr-1.5" />
                                <p className="text-gray-500">Reset</p>
                            </div>
                            <div className="flex cursor-pointer" onClick={openModal}>
                                <IoIosLock className="mt-2 text-lg mr-1.5 text-gray-500"/>
                                <p className="text-gray-500 mt-2">Change Password</p>
                            </div>
                            <div className="flex mx-4 ml-8 mr-8 mt-2 cursor-pointer" onClick={toggleTheme}>
                                {theme.theme === 'light' ? 
                                <IoMdSunny className="mt-1 text-lg mr-1.5 text-gray-500"/>:
                                <IoMdMoon className="mt-1 text-lg mr-1.5 text-gray-500" />}
                                <p className="text-gray-500">{theme.theme === 'dark' ? 'Dark' : 'Light'} Theme</p>
                            </div>
                            <input className="mr-10 btn bg-red-550 text-white border-none rounded-3xl w-20" type="submit" value="Save" />
                        </div>
                    </div>
                    
                    <hr/>
                    <div className="mt-10 ml-14 overflow-auto">
                        <div className="flex">
                            <div className="mr-28">
                                <p className="mb-6 font-bold text-xl">Company Details</p>
                                <p className="mt-8 text-sm font-medium text-gray-400">Company Name</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("company_name")} disabled defaultValue="Surya Enterprises" />
                                <p className="mt-8 text-sm font-medium text-gray-400">Website Link</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("website_link")} disabled defaultValue="surya.co.in" />
                                <p className="mt-8 text-sm font-medium text-gray-400">Address</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("address")} disabled defaultValue="760 Market St Floor 10, San Francisco, CA 94102, United States" />
                                <p className="mt-8 text-sm font-medium text-gray-400">Category</p>                                
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("category")} disabled defaultValue="Surya Enterprises" />
                                <p className="mt-8 text-sm font-medium text-gray-400">About</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" placeholder="Write here..." {...register("about")} disabled defaultValue="Surya Enterprises" />
                            </div>
                            <div>
                                <p className="mb-6 font-bold text-xl">Key Account Manager Details</p>
                                <p className="mt-8 text-sm font-medium text-gray-400">Name</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("name")} defaultValue={`${listUser?.data?.profile_data?.first_name} ${listUser?.data?.profile_data?.last_name}`} />
                                <p className="mt-8 text-sm font-medium text-gray-400">Email ID</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("email")} defaultValue={listUser?.data?.email} />
                                <p className="mt-8 text-sm font-medium text-gray-400">Phone</p>
                                <input className="appearance-none text-gray-700 border rounded-xl border-gray-300 py-3 px-4 mt-4 leading-tight w-72" {...register("phone")} defaultValue={listUser?.data?.profile_data?.number} />
                            </div>
                        </div>
                    </div>
                </form>              
            </div>}
        </div>
    )
}

export default ProfileSettings
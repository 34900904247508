import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Container from '../../../components/container'
import Modal from 'react-modal';
import { RiFolderDownloadFill } from 'react-icons/ri'
import { FiChevronDown } from 'react-icons/fi'
import { IoCopy } from 'react-icons/io5'
import { BsFillFileEarmarkImageFill, BsFillFileBarGraphFill } from "react-icons/bs"
import { BiArrowBack } from "react-icons/bi"
import { VscClose } from 'react-icons/vsc'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { FaFileUpload } from "react-icons/fa";
import { exportComponentAsJPEG } from 'react-component-export-image';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import ReactApexChart from 'react-apexcharts';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useAtom } from 'jotai';
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import statAtom, { fetchDownloadData, fetchForecastAtom, fetchForecastTemplateDownloadData, uploadForecastTemplateFileAtom } from '../../../atoms/statsAtom';
import partsAtom, { fetchPartsAtom } from '../../../atoms/partsAtom';
import projectAtom from '../../../atoms/projectAtom';
import { Parser } from 'json2csv';
import { forecastFormatDate } from '../../../components/dateFormat';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
);


function ForeCast() {

    const navigate = useNavigate()
    const csvRef = useRef(null)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [loading, setLoading] = useState(false)
    const [dataDate, setDataDate] = useState([])
    const [dataCount, setDataCount] = useState([])
    const [activity, setActivity] = useState()
    const [dum, setDum] = useState()
    const [filterData, setFilterData] = useState()
    const [forecastPopulatioLoading, setForecastPopulatioLoading] = useState(false)

    const [listId] = useAtom(activitiesAtom)
    const [, setIDMap] = useAtom(fetchActivitiesAtom)
    const [listStats] = useAtom(statAtom)
    const [, setFetchStat] = useAtom(fetchForecastAtom)
    const [listParts] = useAtom(partsAtom)
    const [, fetchPartsInit] = useAtom(fetchPartsAtom)
    const [projectsName] = useAtom(projectAtom)
    const [, setDownloadData] = useAtom(fetchDownloadData)
    const [, setForecastTemplateDownloadData] = useAtom(fetchForecastTemplateDownloadData)
    const [, uploadForecastTemplateFile] = useAtom(uploadForecastTemplateFileAtom)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            await setIDMap()
            await fetchPartsInit()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went Wrong")
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleStat()
    }, [startDate, endDate, activity])

    useEffect(() => {
        handleDetailFetch()
    }, [listStats.data])

    useEffect(() => {
        handleSortAct()
    }, [listId?.construction])



    const handleStat = async () => {
        try {
            if (activity && startDate && endDate) {
                // let start_temp = startDate.toLocaleDateString()
                // let end_temp = endDate.toLocaleDateString()
                let start_temp = forecastFormatDate(startDate)
                let end_temp = forecastFormatDate(endDate)
                setLoading(true)
                await setFetchStat({ startDate: `${start_temp.split("/")[0]}-${start_temp.split("/")[1]}-${start_temp.split("/")[2]}`, endDate: `${end_temp.split("/")[0]}-${end_temp.split("/")[1]}-${end_temp.split("/")[2]}`, actId: activity, partId: listId?.construction[activity]?.part_id })
                handleDetailFetch()
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const handleDetailFetch = () => {
        let tempDate = []
        let tempCount = []
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        listStats.data.map((i) => {
            tempDate.push(i.date.split("/")[1] + "-" + months[parseInt(i.date.split("/")[0]) - 1] + "-" + i.date.split("/")[2])
            tempCount.push(i?.eodc)
        })
        setDataDate(tempDate)
        setDataCount(tempCount)
    }


    let prtAct = []
    if (listId?.construction) {
        Object.keys(listId.construction).map((ct) => {
            if (!listId.construction[ct].parent_activity_id) {
                prtAct.push(ct)
            }
        })
    }

    let finalArr = []

    const handleSortAct = () => {
        prtAct.map((pt) => {
            finalArr.push(parseInt(pt))
            Object.keys(listId?.construction).map((j) => {
                if (listId?.construction[j].parent_activity_id === parseInt(pt)) {
                    finalArr.push(parseInt(j))
                }
            })
        })
        setFilterData(finalArr)
    }
    const handleDownloadForecastTemplate = async () => {
        try {
            setForecastTemplateDownloadData()
        } catch (err) {
            console.log(err)
        }
    }

    const handleActSearch = (e) => {
        handleSortAct()
        let quaryFilter = e.target.value
        var updateList = [...finalArr]
        updateList = updateList.filter((item) =>
            listId.construction[item].name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
        )
        finalArr = updateList
        setFilterData(finalArr)
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function closeModal() {
        setIsOpen(false);
        handleSortAct()
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            height: '500px'
        },
    };

    const handleActivityChange = (e, ar) => {
        if (e.target.checked && ar) {
            setDum(e.target.value)
        }
    }

    const handleActivitySubmit = () => {
        setActivity(dum)
        setIsOpen(false)
        handleSortAct()
    }

    const handleFindLinkedParts = (data) => {
        let temp
        listParts.map((j) => {
            if (j.id === parseInt(data)) {
                temp = j?.part_name
            }
        })
        return temp
    }

    const options = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                tools: {
                    download: true
                }
            }
        },
        legend: {
            show: true,
            floating: false,
            fontSize: "15px"
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        markers: {
            size: 4,
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
        },
        labels: dataDate,
        xaxis: {
            type: 'Datetime'
        },
        yaxis: [{
            title: {
                text: activity ? `${handleFindLinkedParts(listId?.construction[activity]?.part_id)} counts` : "",
            },

        }]
    }

    const series = [{
        name: activity ? `${handleFindLinkedParts(listId?.construction[activity]?.part_id)} counts` : "",
        type: 'line',
        data: dataCount
    }]

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    const handleDownloadData = async () => {
        try {
            toast("Download queued")
            let arr = []
            listStats.data.map((i) => {
                let temp = {}
                temp["Date"] = i.formattedDate
                temp["Actual Count"] = i.eodc
                temp["Forecast Count"] = i.forecasted_count
                arr.push(temp)
            })
            let newFields = ["Date", "Actual Count", "Forecast Count"]
            let opts = { newFields }
            const parser = new Parser(opts)
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", Forecast Vs Actuals\n` + `"Report Date", ${formattedDate}\n` + csv
            const element = document.createElement("a")
            const file = new Blob([csv], { type: 'text/csv' })
            element.href = URL.createObjectURL(file)
            element.download = `${project_name?.project_number}_Forecast Vs Actuals_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
    }

    const handleDownloadCSV = () => {
        toast("Download queued")
        exportComponentAsJPEG(csvRef)
    }

    const handleForecastFileUpload = async (e) => {
        setForecastPopulatioLoading(true)
        const file = e.target.files[0];
        try {
            await uploadForecastTemplateFile(file)
            setForecastPopulatioLoading(false)
            toast.success("Populated Forecast Successfully")
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    return (
        <Container>
            <div>
                <div className='flex justify-between mb-5'>
                    <div>
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/reports")} />
                        <div className='ml-6 mt-5 text-xl font-bold'>
                            Forecast Vs Actuals
                        </div>
                    </div>
                    <div className='flex mt-3 cursor-pointer items-center mx-5' onClick={handleDownloadData}>
                        <RiFolderDownloadFill className='mr-1' style={{ width: '20px', height: '20px' }} />
                        <p>Download</p>
                    </div>
                </div>
                <hr />
                <div className='flex justify-between mx-6 my-4' style={{ height: '40px' }}>
                    <div className='flex'>
                        <DatePicker dateFormat="dd-MMMM-yyyy" onChange={setStartDate} className="border border-2 rounded-xl w-44 py-2 pl-2 mr-5" showIcon selected={startDate} placeholderText='Start date' />
                        <DatePicker dateFormat="dd-MMMM-yyyy" onChange={setEndDate} className="border border-2 rounded-xl w-44 py-2 pl-2 " showIcon selected={endDate} placeholderText='End date' />
                    </div>
                    <div className='flex space-x-5 '>
                        {/* {forecastPopulatioLoading === true ?
                            <div className='flex space-x-0.5 cursor-pointer items-center flex text-base'>
                                <p className='loader' style={{ width: "25px", height: "25px" }}></p>
                                <p>Populating Forecast </p>
                            </div> : */}
                            <div className='flex space-x-0.5 cursor-pointer items-center flex text-base'>
                                {forecastPopulatioLoading === true ? <p className='loader' style={{ width: "25px", height: "25px" }}></p> :
                                    <FaFileUpload />}
                                <form action="/form/submit"
                                    method="post"
                                    encType="multipart/form-data">
                                    <label class="label">
                                        <input
                                            type="file"
                                            accept=".xlsx"
                                            onChange={handleForecastFileUpload}
                                            style={{ display: 'none' }}
                                        />
                                        <p>Populate Forecast</p>
                                    </label>
                                </form>

                            </div>
                            {/* } */}
                        <p className='cursor-pointer items-center flex text-base space-x-2 mt-1' onClick={handleDownloadForecastTemplate}><RiFolderDownloadFill className='mr-2' />Download Forecast Template </p>
                        <p className='cursor-pointer items-center flex text-base space-x-2 mt-1' onClick={handleDownloadCSV}><RiFolderDownloadFill className='mr-2' />Download Chart</p>
                        <div className='flex cursor-pointer' onClick={() => setIsOpen(true)}>
                            {activity ?
                                <p className='mt-2 font-semibold text-gray-500 text-xl mr-4'>{listId.construction[activity]?.name}</p>
                                :
                                <p className='mt-2 font-semibold text-xl text-gray-500 mr-4'>Select Activity</p>
                            }
                            <FiChevronDown className='mt-3 mr-5 text-gray-500' style={{ width: '20px', height: '20px' }} />
                        </div>
                    </div>
                </div>
                <hr />
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Activity List"
                >
                    <div>
                        <div className='flex justify-between'>
                            <p className='font-bold text-xl' >Select Activity</p>
                            <VscClose onClick={closeModal} className="cursor-pointer mt-1 active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: '25px', height: '25px' }} />
                        </div>
                        <input className='appearance-none rounded-full w-full mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Activity' onChange={e => handleActSearch(e)} />

                        <form>
                            <div className='overflow-auto p-3' style={{ height: '290px' }}>
                                {filterData?.map((ar, index) => {
                                    return (
                                        <div key={index} className='my-4'>
                                            <label>
                                                <input type='radio' className={`${listId.construction[ar].parent_activity_id ? "ml-5" : null}`} style={{ display: listId.construction[ar].parent_activity_id ? "" : "none" }} value={ar} checked={parseInt(dum) === ar} onChange={(e) => handleActivityChange(e, listId.construction[ar].parent_activity_id)} />
                                                <span className={`text-sm font-semibold ml-2 ${listId.construction[ar].parent_activity_id ? "font-normal cursor-pointer" : "font-extrabold"}`}>{listId.construction[ar].name}</span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex justify-end'>
                                <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => { setIsOpen(false); setDum(); closeModal() }}>Cancel</button>
                                <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={handleActivitySubmit}>Generate Summary</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                {loading ?
                    <div style={{ marginTop: "14%", marginLeft: "44%" }}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p>
                    </div>
                    :
                    activity ?
                        <div>
                            {listStats.data.length > 0 ?
                                <div className="m-auto mt-12 ml-24" ref={csvRef} >
                                    <LineChart width={window.innerWidth - 250} height={400} className='m-auto' style={{ padding: "20px" }} data={listStats.data}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="formattedDate" />
                                        <YAxis label={{ value: handleFindLinkedParts(listId.construction[dum].part_id), angle: -90, position: 'insideLeft' }} />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            name="Actual count"
                                            type="monotone"
                                            dataKey="eodc"
                                            stroke="#8884d8"
                                            activeDot={{ r: 8 }}
                                        />
                                        <Line name="Forecast count" type="monotone" dataKey="forecasted_count" stroke="#82ca9d" />
                                    </LineChart>
                                    {/* <ReactApexChart options={options} series={series} type="line" height={350} width={"90%"} /> */}
                                </div>
                                :
                                <div>
                                    <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
                                    <p className='text-gray-400 mt-4 text-center font-medium'>No records</p>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            <BsFillFileBarGraphFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
                            <p className='text-gray-400 mt-4 text-center font-medium'>Select a date range and an activity</p>
                        </div>
                }
            </div>
        </Container>
    )
}

export default ForeCast
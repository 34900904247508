import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import userAtom from "../atoms/userAtom";
import produce from "immer"
import { getToken } from "../utils/auth";
import { decodeJWT } from "../utils/jwt";

export default function AuthProvider(props) {

    const [loading, setLoading] = useState(true);

    const [, setUser] = useAtom(userAtom)

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        try {
            let token = await getToken();
            if (token) {
                let data = decodeJWT(token);
                if (!localStorage.getItem("tenant-name")) {
                    localStorage.setItem("tenant-name", data["organizations"][0]["name"]);
                }
                setUser(produce((draft) => {
                    draft.data = data;
                    draft.loggedIn = true;
                }))
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <p>loading...</p>

    return (
        <div>{props.children}</div>
    )

}
import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

function SelectUser({StaffList, onChange, bool}) {

    const [isOpen, setIsOpen] = useState(false)
    const [selectStaff, setSelectStaff] = useState([])
    const [searchStaff, setSearchStaff] = useState([])
    const dropdownRef = useRef(null)
    const initialLoadRef = useRef(false)

    useEffect(() => {
        setSearchStaff(StaffList)
    }, [StaffList])

    const handleFocus = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        setSelectStaff([])
    }, [bool])

    const handleClickOutside = (e) => {
        if(dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleSearchStaff = (e) => {
        let quaryFilter = e.target.value
        var updateList = [...StaffList]
        updateList = updateList.filter((item) => item?.user?.name.toLowerCase().includes(quaryFilter.toLowerCase()) ||
            item?.user?.profile_data?.last_name.toLowerCase().includes(quaryFilter.toLowerCase()))
        setSearchStaff(updateList)
    }

    const handleStaffChange = (data) => {
        let temp = []
        if(selectStaff.includes(data)){
            temp = selectStaff.filter((item) => item !== data)
        }
        else{
            temp = [...selectStaff, data]
        }
        setIsOpen(false)
        setSelectStaff(temp)
        onChange(temp)
    }
    const renderStaffs = (data) => {
        return (
            <li
                key={data.id}
                className='flex my-1 rounded-xl mx-4 cursor-pointer text-black'
                onClick={() => handleStaffChange(data?.staff_project_id)}
                style={{textTransform: "capitalize"}}    
            >
                <div className='hover:bg-gray-100'>
                    <input type='checkbox' className='cursor-pointer' checked={selectStaff.includes(data?.staff_project_id)} onChange={() => setIsOpen(true)}/>
                    <div className='flex'>
                        {data?.user?.profile_data?.profile_url ? 
                            <img src={data?.user?.profile_data?.profile_url} alt='' className='rounded-full w-8 h-8' />
                        :
                            <div className="avatar placeholder">
                                <div className=" bg-yellow-400 text-neutral-content rounded-full w-9">
                                    <span className="text-base font-semibold">{data?.user?.name?.charAt(0).toUpperCase()}</span> 
                                </div>
                            </div>
                        }
                        <div className='ml-3'>
                            <p className='text-sm font-semibold'>{data?.user?.name} {data?.user?.profile_data?.last_name}</p>  
                            <p className='font-medium text-xs' style={{color: "#696F79"}}>{data?.user?.profile_data?.role_auth}</p>                  
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    const findStaffName = (data) => {
        let temp
        StaffList.map((i) => {
            if(i?.staff_project_id === data){
                temp = i?.user?.name
            }
        })
        return temp
    }

    const formateName = () => {
        if(selectStaff.length === 0){
            return ""
        }
        else if(selectStaff.length === 1){
            return `${findStaffName(selectStaff[0])}` || ""
        }
        else if(selectStaff.length === 2){
            return `${findStaffName(selectStaff[0])}, ${findStaffName(selectStaff[1])}` || ""
        }
         else{
            return `${findStaffName(selectStaff[0])}, ${findStaffName(selectStaff[1])} + ${selectStaff.length - 2} other Staffs`
         }      
    }

  return (
    <div className='relative flex' ref={dropdownRef}>
        <div>
            <input
                type='text'
                className='cursor-pointer pl-4 py-2 mt-7 ml-4 rounded-xl text-base border'
                style={{minWidth: "480px"}}
                placeholder='Add Staffs'
                onClick={handleFocus}
                value={formateName()}
                readOnly
            />
            {isOpen && (
                <div className='absolute z-10 bg-white shadow border border-gray-300 ml-4 rounded-xl' style={{minWidth: "480px"}}>
                    <input type='search' className='border rounded-2xl mt-4 ml-4 py-2 px-4' placeholder='Search Staff' style={{minWidth: "450px"}} onChange={e => handleSearchStaff(e)} /> 
                    <ul className='py-2 max-h-80 overflow-y-auto'>
                        {searchStaff.map((stf) => renderStaffs(stf))}
                    </ul>
                </div>
            )}
        </div>
        {isOpen ? 
            <BsChevronUp onClick={handleFocus} className='cursor-pointer absolute right-16 top-10' />
        :
            <BsChevronDown onClick={handleFocus} className='cursor-pointer absolute right-16 top-10' />
        }
    </div>
  )
}

export default SelectUser

import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginContainer from '../../components/loginContainer'
import axios from 'axios'
import { BiArrowBack } from "react-icons/bi"

function ForgetPassword() {

    const [resetform, setResetForm] = useState()

    const handleFormChange = (e) => {
        setResetForm(e.target.value)
    }

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true);
            let res = await axios.post(`/auth/forget-password?email=${resetform}`);
            if (res.status === 200) {
                navigate("/forgetPassword/success", { state: { resetform } })
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="flex flex-col h-screen w-screen justify-center items-center">
                <LoginContainer />
                <div>
                    <div className='flex items-center ml-12'>
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer" onClick={() => navigate("/")} />
                        <p className='ml-9 font-bold text-2xl'>Forgot Password</p>
                    </div>
                    <p className='ml-12 mt-12 mb-6 font-bold text-xl'>Don{"’"}t worry! we got your back</p>
                    <form onSubmit={handleFormSubmit}>
                        <div className="flex flex-col">
                            <label className='ml-12'>Email</label>
                            <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 ml-12 mt-4' type="email" name="email" required style={{ width: '432px', height: '64px' }} placeholder='Enter Your email' onChange={(e) => handleFormChange(e)} />
                            <button type='submit' className='border-none rounded-full bg-red-550 text-white btn ml-12 mt-12' style={{ width: '432px', height: '64px' }} onClick={handleFormSubmit}>Send Reset Link</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default ForgetPassword
import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useAtom } from 'jotai'
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import qcAtom, { createUpdateQcCheckpoints, fetchQcChecklist, } from '../../../atoms/QcChecklistAtom/qcAtom'
import toast from 'react-hot-toast'
import { unstable_usePrompt } from 'react-router-dom/dist';
import { RiEdit2Fill } from 'react-icons/ri';
import MultipleValueTextInput from 'react-multivalue-text-input'
import { VscClose } from 'react-icons/vsc';

function Tab({ currentTab, text, editMode, onClick }) {
    return (
        <div className='w-1/3 text-center h-full'>
            {editMode ?
                <p className={`text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`}>{text}</p>
                :
                <p className={`cursor-pointer text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`} onClick={() => onClick(text)}>{text}</p>
            }
        </div>
    )
}

function QcChecklists() {

    const [listQc] = useAtom(qcAtom)
    const [, setFetchChecklist] = useAtom(fetchQcChecklist)
    const [, setCreateUpdateQc] = useAtom(createUpdateQcCheckpoints)

    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState({ boolean: false, itr: false })
    const [editMode, setEditMode] = useState(false)
    const [checklistData, setChecklistData] = useState({ tab: "Piers", status: "", type: 1, lable: "", checklist_id: null, design_pier_ht: "", custom_inputs: [], data: [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }] })
    const [confimationModal, setConformationModal] = useState(false)
    const [dropdownModal, setDropdownModal] = useState({ boolean: false, index: null, values: { values: [] } })
    const [fieldConfModal, SetFieldConfModal] = useState({ boolean: false, index: null, changedValue: null })

    const QcTabs = ["Piers", "Structure", "Module"]

    useEffect(() => {
        init()
    }, [checklistData.tab])

    const init = async () => {
        try {
            setLoading(true)
            setChecklistData(produce((draft) => { draft.tab = checklistData.tab; draft.type = 1; draft.status = '0' }))
            await setFetchChecklist({ tab: checklistData.tab })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    unstable_usePrompt({
        when: editMode,
        message: "You are leaving the page without completing the edits, the Checkpoint will revert to last saved version"
    })

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    function fetchChecklistDatas() {
        let customInputs = [
            {
                custom_input_type: 0,
                custom_input_values: {},
                custom_input_name: "Custom Label 1"
            },
            {
                custom_input_type: 0,
                custom_input_values: {},
                custom_input_name: "Custom Label 2"
            },
            {
                custom_input_type: 0,
                custom_input_values: {},
                custom_input_name: "Custom Label 3"
            }
        ]
        if (listQc.checklistData[0]) {
            setChecklistData(produce((draft) => {
                draft.data = listQc.checklistData[0]?.checkpoints;
                draft.status = listQc.checklistData[0]?.checklist_status;
                draft.checklist_id = listQc.checklistData[0]?.checklist_id;
                draft.lable = listQc.checklistData[0]?.checklist_name;
                draft.custom_inputs = listQc.checklistData[0]?.custom_inputs ? listQc.checklistData[0]?.custom_inputs : []
            }))
        }
        else {
            setChecklistData(produce((draft) => {
                draft.data = [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }];
                draft.checklist_id = null;
                draft.lable = "";
                draft.custom_inputs = []
            }))
        }
    }

    useEffect(() => {
        fetchChecklistDatas()
    }, [listQc.checklistData])

    const handleCancel = () => {
        setEditMode(false)
        fetchChecklistDatas()
    }

    const submitChecklist = async (itrValue) => {
        try {
            setBtnLoader({ boolean: true, itr: itrValue })
            await setCreateUpdateQc({ checklistData: checklistData, projectLevel: true, itr: itrValue })
            await setFetchChecklist({ tab: checklistData.tab })
            toast.success("Created Successfully")
            setEditMode(false)
            setConformationModal(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBtnLoader(({ boolean: false, itr: itrValue }))
        }
    }

    const onChecklistSubmit = async (itrValue) => {
        try {
            if (!itrValue) {
                setBtnLoader(({ boolean: true, itr: itrValue }))
                if (checklistData.checklist_id) {
                    setConformationModal(true)
                }
                else {
                    await submitChecklist(itrValue)
                }
            }
            else {
                await submitChecklist(itrValue)
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setBtnLoader(({ boolean: false, itr: false }))
        }
    }

    const handleAddCheckPoint = () => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.data.push({ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }) }))
        }
    }

    const handleRemoveCheckpoint = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.data[index]["delete"] = true }))
        }
    }

    const handleAddCustom = () => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                draft.custom_inputs.push({
                    custom_input_type: 0,
                    custom_input_values: {},
                    custom_input_name: "Custom Label"
                })
            }))
        }
    }

    const handleDeleteCustomFields = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                // draft.custom_inputs.splice(index, 1)
                draft.custom_inputs[index]["delete"] = true
            }))
        }
    }

    const handleCheckCountCustomFields = () => {
        let temp = []
        checklistData?.custom_inputs.map((i) => {
            if (!i?.delete) {
                temp.push(i)
            }
        })
        return temp
    }

    console.log(checklistData.custom_inputs)

    const handleChangeFields = (e, index) => {
        if (parseInt(e.target.value) === 3) {
            setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.custom_input_values?.values ? checklistData.custom_inputs[index]?.custom_input_values?.values : [] } })
        }
        setChecklistData(produce((draft) => { draft.custom_inputs[index].custom_input_type = parseInt(e.target.value) }))
    }

    const handleFieldConfirmCancel = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].custom_input_type = 3 }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    const handleFieldConfirm = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].custom_input_type = fieldConfModal.changedValue }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    const handleMultiInputSubmit = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[dropdownModal.index].custom_input_values = dropdownModal.values }))
        setDropdownModal({ boolean: false, index: null, values: { values: [] } })
    }

    if (loading) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full' style={{ height: "calc(100vh - 200px)" }}>
                <p className='loader mb-1'></p>
                <p className='font-bold text-lg ml-3'>Fetching...</p>
            </div>
        )
    }

    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center border-b-2'>
                <p className='text-gray-500 font-medium text-base flex items-center'><AiOutlineInfoCircle className='mx-4' />Note - This will be set as ITR template</p>
                <div className='py-3 flex items-center space-x-5 px-5'>
                    {editMode ?
                        <div className='flex space-x-5'>
                            <button className='border-none btn bg-red-550 text-white rounded-full px-4' onClick={() => onChecklistSubmit(true)}>
                                {btnLoader.boolean && btnLoader.itr &&
                                    <span className="loader mr-2" style={{ width: "25px", height: "25px" }}></span>
                                }
                                Save as ITR template only</button>
                            <button className='border-none btn bg-red-550 text-white rounded-full px-4' onClick={() => onChecklistSubmit(false)}>
                                {btnLoader.boolean && !btnLoader.itr && !confimationModal &&
                                    <span className="loader mr-2" style={{ width: "25px", height: "25px" }}></span>
                                }
                                Save and apply to all {checklistData.tab === QcTabs[0] ? "Piers" : "Bays"}</button>

                            <button className='border btn border-2 cursor-pointer px-4 rounded-full bg-white text-gray-500 hover:text-white' onClick={() => handleCancel()}>Cancel</button>
                        </div>
                        :
                        <button className='border btn border-2 cursor-pointer px-5 rounded-full bg-white text-gray-500 hover:text-white' onClick={() => setEditMode(true)}>Edit</button>
                    }
                </div>
                <Modal
                    isOpen={confimationModal}
                    style={customConfirmStyle}
                    contentLabel='QC Confirmation'
                >
                    <div className='p-2 w-[40vw]'>
                        <div className='flex justify-between mb-4'>
                            <p className='text-xl font-semibold'>Update Checklist</p>
                            <VscClose className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} onClick={() => setConformationModal(false)} />
                        </div>
                        <hr />
                        <p className='my-4'>This checklist will get applied across all the tracker types and across all the elements. This will overwrite any previously setup checklist on these elements. Please confirm</p>
                        <hr />
                        <div className='mt-4 flex flex-col space-y-3'>
                            <p className='text-right cursor-pointer text-red-550 font-semibold flex justify-end' onClick={() => submitChecklist(false)}>
                                {btnLoader.boolean &&
                                    <span className="loader mr-2 border" style={{ width: "25px", height: "25px" }}></span>
                                }
                                <p>Understood, proceed with save and apply</p>
                            </p>
                            <p className='text-right cursor-pointer text-gray-500 font-semibold' onClick={() => setConformationModal(false)}>Cancel</p>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className={`flex justify-around border-b w-full h-[40px]`}>
                {QcTabs.map((tab) => {
                    return (
                        <Tab currentTab={checklistData.tab} text={tab} editMode={editMode} onClick={e => {
                            if (!editMode) {
                                setChecklistData(produce((draft) => { draft.tab = e }))
                            }
                        }} />
                    )
                })}
            </div>
            <div className={`w-full overflow-auto border ${editMode ? "" : "bg-gray-100 cursor-auto"}`} style={{ height: "calc(100vh - 195px)" }}>
                <div className='w-full'>
                    <div className='w-[50%] flex items-center space-x-8 mb-3 mt-5 justify-between'>
                        <p className='text-base font-bold w-[45%] pl-6 flex'>
                            Field Name
                            <span data-tip data-for="QC field name" className='ml-2 mt-1' >
                                <AiOutlineInfoCircle className='cursor-pointer' />
                            </span>
                        </p>
                        <ReactTooltip backgroundColor='black' place="bottom" clickable effect="solid" id="QC field name">
                            <p className='w-[200px]'>Use these custom labels and fields to set the required input fields from site as they do QC, like recording pier heights, plant identifier etc.,</p>
                        </ReactTooltip>
                        <p className='text-base font-bold w-[40%] pl-3'>Field Type</p>
                    </div>
                    {checklistData?.custom_inputs.map((i, index) => {
                        if (!i?.delete) {
                            return (
                                <div className='flex space-x-8 px-5 items-center space-y-3'>
                                    <div className='w-[50%] flex items-center space-x-8 space-y-3 justify-between'>
                                        <div className='flex space-x-2 items-center w-[45%]'>
                                            <input className={`text-base font-medium border-2 rounded-xl px-4 py-2 mt-2 flex items-center w-full focus:outline-none ${!editMode && "cursor-pointer"}`} disabled={!editMode} value={i?.custom_input_name} name="label_01" onChange={e => setChecklistData(produce((draft) => { draft.custom_inputs[index].custom_input_name = e.target.value }))} />
                                            <RiEdit2Fill className='text-xl mt-1' />
                                        </div>
                                        <select required disabled={!editMode} className={`px-4 py-2 rounded-xl select-bordered border-2 w-[40%] ${editMode ? "cursor-pointer" : "bg-gray-100"}`} onChange={e => handleChangeFields(e, index)}>
                                            <option selected={checklistData.custom_inputs[index]?.custom_input_type === 0} value={0}>Integer</option>
                                            <option selected={checklistData.custom_inputs[index]?.custom_input_type === 1} value={1}>Float</option>
                                            <option selected={checklistData.custom_inputs[index]?.custom_input_type === 2} value={2}>Free Text</option>
                                            <option selected={checklistData.custom_inputs[index]?.custom_input_type === 3} value={3}>Dropdown</option>
                                        </select>
                                        <MdDelete className='cursor-pointer' onClick={() => handleDeleteCustomFields(index)} />
                                    </div>
                                    <div style={{ display: checklistData.custom_inputs[index]?.custom_input_type === 3 ? "" : "none" }}>
                                        <p className={`${editMode ? "cursor-pointer underline underline-offset-2 text-blue-500" : "text-gray-500"} text-sm`} onClick={() => { editMode && setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.custom_input_values ? checklistData.custom_inputs[index]?.custom_input_values : [] } }) }}>View & edit dropdown options</p>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    {handleCheckCountCustomFields().length < 3 && <p className='cursor-pointer flex justify-end w-[40%] my-2' onClick={handleAddCustom}>+ Add more</p>}
                    <Modal
                        isOpen={fieldConfModal.boolean}
                        style={customConfirmStyle}
                        contentLabel='Type Confirmation'
                    >
                        <div className='p-2'>
                            <p className='mb-4'>The dropdown options added already will get deleted, please confirm</p>
                            <div className='flex justify-end space-x-5 font-semibold'>
                                <p className='cursor-pointer text-gray-500' onClick={handleFieldConfirmCancel}>Cancel</p>
                                <p className='cursor-pointer text-red-550' onClick={handleFieldConfirm}>Confirm</p>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={dropdownModal.boolean}
                        style={customConfirmStyle}
                        contentLabel='MultipleValueTextInput'
                    >
                        <div className='p-3 w-[500px]'>
                            <p className='font-medium text-base mb-2'>{checklistData.custom_inputs[dropdownModal.index]?.custom_input_name}</p>
                            <MultipleValueTextInput
                                onItemAdded={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                onItemDeleted={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                name="item-input"
                                className={"w-[40%] p-1 mt-5 border-b-2"}
                                placeholder="Add the required drop down options and press Enter"
                                values={checklistData.custom_inputs[dropdownModal.index]?.custom_input_values?.values ? checklistData.custom_inputs[dropdownModal.index]?.custom_input_values?.values : []}
                            />
                            <div className='flex space-x-5 justify-end my-3'>
                                <p className='cursor-pointer font-medium text-gray-500' onClick={() => setDropdownModal({ boolean: false, index: null, values: { values: [] } })}>Cancel</p>
                                <button className='cursor-pointer font-medium text-red-550' onClick={handleMultiInputSubmit}>Save</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className='w-full mt-6'>
                    <div className='flex justify-between px-5 mb-2'>
                        <p className='font-bold w-[30%]'>Checkpoint</p>
                        <p className='font-bold w-[30%]'>Acceptance Criteria</p>
                        <p className='font-bold w-[30%]'>Inspection Type</p>
                        <p></p>
                    </div>
                    <hr />
                    <div className='w-full'>
                        {checklistData.data.map((formData, formIndex) => {
                            if (!formData.delete) {
                                return (
                                    <div className='w-full flex justify-between px-5 my-4' key={`${formIndex}`}>
                                        <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' value={formData.checkpoint_name} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.data[formIndex].checkpoint_name = e.target.value }))} />
                                        <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' value={formData.acceptance_criteria} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.data[formIndex].acceptance_criteria = e.target.value }))} />
                                        <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' value={formData.inspection_type} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.data[formIndex].inspection_type = e.target.value }))} />
                                        {/* <p className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)}>D</p> */}
                                        <p><MdDelete className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)} /></p>
                                    </div>
                                )
                            }
                        })}
                        <p className='cursor-pointer mx-5 text-base font-bold' onClick={handleAddCheckPoint}>+ Add More</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QcChecklists

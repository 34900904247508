import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip'

function Dropdown(props) {
    const {
        options,
        className,
        placeholder,
        defaultValue,
        tableRef,
        keyToDisplay,
        search,
        hoverOnTooltip,
        option,
        onChange
    } = props;

    const dropdownRef = useRef(null)
    const optionsRef = useRef(null)

    const [showAbove, setShowAbove] = useState(false)
    const [dropDownData, setDropDownData] = useState(options ? options : [])
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState()

    const calculatePosition = useCallback(() => {
        if (dropdownRef.current && optionsRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const optionsRect = optionsRef.current.getBoundingClientRect();
            const viewportHeight = tableRef.current.getBoundingClientRect();

            const spaceBelow = viewportHeight.bottom - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;

            setShowAbove(spaceBelow < optionsRect.height && spaceAbove > spaceBelow);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            calculatePosition();
            window.addEventListener('resize', calculatePosition);
            return () => window.removeEventListener('resize', calculatePosition);
        }
    }, [isOpen, calculatePosition]);

    useEffect(() => {
        setSelectedItem(defaultValue)
    }, [defaultValue])

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
            setDropDownData(options)
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleFocus = () => {
        setIsOpen(!isOpen)
    };

    const formatSelectedNames = () => {
        if (selectedItem) {
            return selectedItem
        }
        else {
            return ""
        }
    }

    const handleClickData = (data) => {
        setSelectedItem(data[keyToDisplay])
        setDropDownData(options)
        onChange(data)
        setIsOpen(!isOpen)
    }

    const handleSearchFields = (searchData) => {
        var updateList = [...options]
        updateList = updateList.filter((item) =>
            item[keyToDisplay].toLowerCase().indexOf(searchData.toLowerCase()) !== -1
        )
        setDropDownData(updateList)
    }

    return (
        <div className='relative' ref={dropdownRef}>
            <input
                type='text'
                className={`cursor-pointer ${className}`}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                placeholder={placeholder}
                onClick={handleFocus}
                value={formatSelectedNames()}
                readOnly
            />
            {isOpen && options && (
                <ul ref={optionsRef} className={`absolute z-50 bg-white shadow mt-1 w-full py-2 ${showAbove ? 'bottom-full mb-1' : 'top-full mt-1'} overflow-y-auto border border-gray-300`}
                    style={{
                        maxWidth: option?.width ? option?.width : "auto",
                        maxHeight: option?.height ? option?.height : "auto"
                    }}
                >
                    {search && (
                        <input
                            className='py-1 px-2 my-2 border rounded-xl w-[90%] ml-2 focus:outline-none'
                            placeholder='Search...'
                            type='search'
                            onChange={e => handleSearchFields(e.target.value)}
                        />
                    )}
                    {dropDownData.map((data, index) => {
                        return (
                            <li
                                data-tip data-for={`${hoverOnTooltip ? data?.bom_id : index}`}
                                className='cursor-pointer hover:bg-gray-100 px-2 py-1.5 my-0.5'
                                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                onClick={() => handleClickData(data)}
                            >
                                {data[keyToDisplay]}
                                {hoverOnTooltip &&
                                    <ReactTooltip effect='solid' place='top' id={`${data?.bom_id}`}>
                                        <div style={{ textTransform: 'capitalize' }}>{data[keyToDisplay]}</div>
                                    </ReactTooltip>
                                }
                            </li>
                        )
                    })}
                </ul>
            )}
            {isOpen ?
                <BsChevronUp className="cursor-pointer absolute right-5 bottom-[35%]" onClick={handleFocus} />
                :
                <BsChevronDown className="absolute right-5 bottom-[35%] cursor-pointer" onClick={handleFocus} />
            }
        </div>
    )
}

export default Dropdown

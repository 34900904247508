import { useAtom } from "jotai";
import React, {  useState } from "react";
import { MdModeEditOutline, MdDelete } from "react-icons/md"
import workHourAtom from "../../../atoms/workHoursAtom";
import { BsThreeDots } from "react-icons/bs"

export default function StaffList({ workhour, handleEditClick, handleDeletetable }) {
    const [listWorkHour] = useAtom(workHourAtom)

    const findActivityTitle = (activity_id, type) => {
        let activity = listWorkHour.activities.find(lw => lw.id.toString() === activity_id.toString() && type === lw.type)
        if (activity) {
            return activity.name
        }
        return activity_id
    }

    const findUserName = (user_id) => {
        let user = listWorkHour.users.find(user => user?.staff_project_id === user_id);
        if (user) {
            return user.user.name +" "+ user.user?.profile_data?.last_name
        }
        return user_id
    }

    const findUserFirstLetter = (user_id) => {
        let user = listWorkHour.users.find(user => user?.staff_project_id === user_id);
        if (user) {
            return user.user?.name?.charAt(0).toUpperCase()
        }
        return user_id
    }

    const getValue = (value) => {
        return listWorkHour?.plantId.find(data => data?.plant_project_id === value)?.name
    }

    return (
        <tr className='hover'>
            <td className='flex'>
                {workhour?.profile_url === null ? 
                    <div className="avatar">
                        <div className="mt-1.5 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base">
                            <span className="text-base" style={{ textTransform: 'capitalize'}}>{findUserFirstLetter(workhour?.user_app_id)}</span>
                        </div>
                    </div>
                    :
                    <div className="avatar" >
                        <div className="w-8 rounded-full">
                            <img src={workhour?.profile_url} alt='' />
                        </div>
                    </div>
                }
                <p className='mt-2 ml-4' style={{ textTransform: 'capitalize'}}>{findUserName(workhour?.user_app_id)}</p>
            </td>
            <td>{workhour?.plant_type ? workhour?.plant_type : "-"}</td>
            <td>{workhour.plant_id ? `${getValue(workhour?.plant_project_id)}  ${workhour.plant_id}` : "-"}</td>
            <td style={{ textTransform: 'capitalize'}}>{findActivityTitle(workhour?.activity_id, workhour?.type)}</td>
            <td>{workhour?.hours}</td>
            <td>
                <div tabIndex={0} className="dropdown dropdown-left">
                    <div className='ml-5 cursor-pointer bg-white border-2 rounded-md border-gray-100 border-solid px-2 text-gray-700'>
                        <BsThreeDots size={20} />
                    </div>
                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow-lg bg-base-100 rounded-xl">
                        <li onClick={e => handleEditClick(e, workhour)}><a className="active:bg-red-550 active:text-white">
                            <MdModeEditOutline className="mr-2 mt-0.5" style={{ width: "20px", height: '20px' }} />
                            <p>Edit</p>
                        </a></li>
                        <li onClick={e => handleDeletetable(e, workhour.id)}><a className="active:bg-red-700 active:text-white">
                            <MdDelete className="mr-2 mt-0.5" style={{ width: "20px", height: '20px' }} />
                            <p>Delete</p>
                        </a></li>
                    </ul>
                </div>
            </td>
        </tr>
    )
}

import React from 'react'
import { useAtom } from 'jotai';
import reportsAtom, { fetchReportsAtom } from '../../../atoms/reportsAtom';
import { useState } from 'react'
import { useEffect } from 'react';
import Modal from 'react-modal';
import {CgSoftwareDownload} from "react-icons/cg"
import {BiTimeFive} from 'react-icons/bi'
import {VscClose} from 'react-icons/vsc'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import workSummaryAtom, { fetchStaffActivityAtom, fetchStaffDetailsAtom, fetchWorkSummaryAtom } from '../../../atoms/workSummaryAtom';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-hot-toast';
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


function WorkHoursSummary() {

    const [loading, setLoading] = useState(false)

    const [workSummary] = useAtom(workSummaryAtom)

    const [reportsAtoms] = useAtom(reportsAtom)

    const [, setFetchUsers] = useAtom(fetchStaffDetailsAtom)

    const [, setFetchActivity] = useAtom(fetchStaffActivityAtom)

    const [, setFetchWorkSummary] = useAtom(fetchWorkSummaryAtom)
    const [listId] = useAtom(activitiesAtom)
    const [, setIDMap] = useAtom(fetchActivitiesAtom)

    const [datesRange, setDatesRange] = useState([])

    const [startDate, setStartDate] = useState(new Date())

    const [endDate, setEndDate] = useState(new Date())

    function getDatesFromDateRange() {
        let dates = new Set([]);
        for(let wh of workSummary.work_data) {
            dates.add(new Date(wh.date * 1000).toISOString().split('T')[0]) 
        }
        dates = Array.from(dates)
        return dates
      }

      const [staffSearch, setStaffSearch] = useState([])
      const [activitySearch, setActivitySearch] = useState([])

      useEffect(() => {
        setStaffSearch(workSummary.users)
        setActivitySearch(workSummary.activity)
      },[workSummary.users, workSummary.activity])

    useEffect(() => {
        init()
    },[startDate,endDate])

    useEffect(() => {
        setDatesRange(getDatesFromDateRange())
    },[workSummary])

    const init = async () => {
        try{
            setLoading(true)
            await setFetchActivity()
            await setFetchUsers()
            await setIDMap()
            await setFetchWorkSummary({
                start_date: `${startDate.toISOString().split('T')[0]}`,
                end_date: `${endDate.toISOString().split('T')[0]}`
            })
        }
        catch(err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally{
            setLoading(false)
        }
    }


    let staff_data = []

    for(let work_data of workSummary.work_data) {
  
        let staff_index = staff_data.findIndex(sd => sd.user_app_id === work_data.user_app_id);
        
        if(staff_index >=0) {
          let activity_index = staff_data[staff_index]["activity"].findIndex(act => act.activity_id === work_data.activity_id);
          staff_data[staff_index]["total_work_hours"] += work_data.hours;

          if(activity_index >=0) {
            staff_data[staff_index]["activity"][activity_index]["work_hours"].push({
             hours: work_data.hours,
             date: new Date(work_data.date * 1000).toISOString().split('T')[0]
           })
           staff_data[staff_index]["activity"][activity_index]["total_hours"] += work_data.hours;
          } 
          else {
            staff_data[staff_index]["activity"].push({  
              activity_id: work_data.activity_id,
              total_hours: work_data.hours,
              work_hours: [
                {
                  hours: work_data.hours,
                  date: new Date(work_data.date * 1000).toISOString().split('T')[0]
                }
              ]
            })
          }
        } 
        else {
            staff_data.push({
            user_app_id: work_data.user_app_id,
            total_work_hours: work_data.hours,
            activity: [
              {
                activity_id: work_data.activity_id,
                total_hours: work_data.hours,
                work_hours: [
                  {
                    hours: work_data.hours,
                    date: new Date(work_data.date * 1000).toISOString().split('T')[0]
                  }
                ]
              }
            ]
          })
        }
      }

    let activity_data = []

    for(let wh of workSummary.work_data) {
        let activity_index = activity_data.findIndex(act => act.activity_id === wh.activity_id)
        
        if(activity_index >= 0){
            activity_data[activity_index]["total_hours"] += wh.hours
            activity_data[activity_index]["work_hours"].push({
                hours: wh.hours,
                date: new Date(wh.date * 1000).toISOString().split('T')[0]
            })
        } else {
            activity_data.push({
                activity_id: wh.activity_id,
                total_hours: wh.hours,
                work_hours: [{
                    hours: wh.hours,
                    date: new Date(wh.date * 1000).toISOString().split('T')[0]
                }]
            })
        }
    }

    let total_activity_work_hours = []
    total_activity_work_hours.push(activity_data.map(i => i.total_hours))


    const [summaryChart, setSummaryChart] = useState()

    const [staffWork, setStaffWork] = useState()

    const [activityWork, setActivityWork] = useState()

    const [tableWork, setTableWork] = useState(true)

    const filterBySearchStaff = (e) => {
        let quaryStaff = e.target.value
        var updatedListStaff = [...workSummary.users]
        updatedListStaff = updatedListStaff.filter((item) =>
            item?.name.toLowerCase().indexOf(quaryStaff.toLowerCase()) !== -1
        )
        setStaffSearch(updatedListStaff)
    }

    const filterBySearchActivity = (e) => {
        let quaryActivity = e.target.value
        var updatedListActivity = [...workSummary.activity]
        updatedListActivity = updatedListActivity.filter((item) =>
            item.name.toLowerCase().indexOf(quaryActivity.toLowerCase()) !== -1
        )
        setActivitySearch(updatedListActivity)
    }

    const handleStaffChange = (e,i) => {
        if (e.target.checked) {
            setStaffWork(e.target.value);
            setActivityWork()
          }
    }

    const handleActivityChange = (e) => {
        if (e.target.checked) {
            setActivityWork(e.target.value);
            setStaffWork()
          }
    }

    const handleActivity = () => {
        if (summaryChart === "Staff" )  {
            setIsOpen(false);
            setTableWork(false)
        } else {
            setTableWork(false)
            setModalActivityOpen(false)
        }   
    }

    let prtAct = []
    if(listId?.construction){
        Object.keys(listId?.construction).map((ct) => {
            if(!listId?.construction[ct].parent_activity_id){
                prtAct.push(ct)
            }
        })
    }

    let finalArr = []
    prtAct.map((pt) => {
        finalArr.push(parseInt(pt))
        Object.keys(listId?.construction).map((ct) => {
            if(listId?.construction[ct].parent_activity_id === parseInt(pt)){
                finalArr.push(parseInt(ct))
            }
        })
    })


    let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalActivityOpen, setModalActivityOpen] = useState(false)

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    setModalActivityOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width:'500px',
      height:'500px'
    },
  };

  let dsDatahr  = staff_data.find(i => i.user_app_id === parseInt(staffWork))?.activity.map(j => j.total_hours)


  let dsDataAct = staff_data.find(i => i.user_app_id === parseInt(staffWork))?.activity.map(j => j.activity_id)

  const doughtnutStaffData = {
    datasets:[
        {
            label: "#...",
            data: dsDatahr,
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(210, 30, 86, 1)',
                'rgba(90, 206, 99, 1)',
                'rgba(02, 88, 12, 1)',
              ],
            borderWidth:0,
        }
    ],
    labels: dsDataAct
  }

  const doughtnutActivityData = {
    datasets:[
        {
            label: "#...",
            data:[activity_data.find(i => i.activity_id === parseInt(activityWork))?.total_hours,total_activity_work_hours[0].reduce((a,b) => a+b ,0) - activity_data.find(i => i.activity_id === parseInt(activityWork))?.total_hours],
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
              ],
            borderWidth:0,
        }
    ],
    labels:[activityWork, 'Others']
  }

  const doughtnutStaffoptions = {
    cutout: 90,
    plugins:{
        legend:{
            display:false
        }
    }
  }

  const doughtnutActivityoptions = {
    cutout: 90,
    plugins:{
        legend:{
            display:false
        }
    }
  }

  const lineStaffdata={
    // x-axis label values
    labels: datesRange,
    datasets: [
       {
           label: ' hours',
           // y-axis data plotting values
           data: [1100,195,47],
           fill: false,
           backgroundColor: "#186DEC",
           borderColor:'#186DEC',
           borderWidth:2,
           responsive:true
         }],
    }

    let lactdt = activity_data.find(i => i.activity_id === parseInt(activityWork))?.work_hours.map(j => j.date)


    let lacthr = activity_data.find(i => i.activity_id === parseInt(activityWork))?.work_hours.map(j => j.hours)


    const lineActivityData = {
        labels: lactdt,
        datasets: [
            {
                label: ' hours',
                // y-axis data plotting values
                data: lacthr,
                fill: false,
                backgroundColor: "#186DEC",
                borderColor:'#186DEC',
                borderWidth:2,
                responsive:true
                }],
    }

   const lineStaffOptions = {
    tension:0.3,
    scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: true
          }
        }
      },
    responsive: true,
    plugins: {
      legend: {
        display:false
      },
      title: {
        display: true,
      },
    },
  }

  const lineActivityOptions = {
    tension:0.3,
    scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: true
          }
        }
      },
    responsive: true,
    plugins: {
      legend: {
        display:false
      },
      title: {
        display: true,
      },
    },
  }


  const getWHDate = (date, staff_index, activity_index) => {
    let whrs = staff_data[staff_index]["activity"][activity_index]["work_hours"].find(wh=>wh.date === date);
    if(whrs) {
      return whrs.hours;
    }
    return 0
  }

  return (
    <div>
        <div className='flex justify-between mx-6 my-4'>
            <div className='flex'>
                <DatePicker dateFormat="dd-MMMM-yyyy" onChange={setStartDate} className="border border-2 rounded-xl w-40 py-2 pl-2 mt-1 mr-5" showIcon selected={startDate} />
                <DatePicker dateFormat="dd-MMMM-yyyy" onChange={setEndDate} className="border border-2 rounded-xl w-40 py-2 pl-2 mt-1" selected={endDate} />
            </div>
            <div>
                <div className='flex'>
                    <div className='dropdown dropdown-end my-3'>
                        <label tabIndex={0} className="m-1 pr-4 cursor-pointer text-text-sm font-semibold text-gray-400">View Summary Chart</label>
                            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                <li onClick={() => {
                                    setSummaryChart("Staff")
                                    setIsOpen(true)}}><a>Staff</a></li>
                                <li onClick={() => {
                                    setSummaryChart("Activity")
                                    setModalActivityOpen(true)}}><a>Activity</a></li>
                                {staffWork || activityWork ?
                                <li onClick={() => {
                                    setSummaryChart("Table")
                                    setTableWork(true)
                                    setActivityWork()
                                    setStaffWork()}}><a>Table</a></li> : null}
                            </ul>
                    </div>
                </div>
                <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="BOM status"
                >
                    <div>
                        <div className='flex justify-between'>
                            <p className='font-bold text-xl' >Select Staff</p>
                            <VscClose onClick={closeModal} className="cursor-pointer mt-1" style={{width:'25px', height: '25px'}} />
                        </div>
                        <input className='appearance-none rounded-full w-full mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Staff' onChange={filterBySearchStaff} />
                        <form onSubmit={handleActivity}>
                            {loading ? 
                            <div className='' style={{marginTop:"20%", marginLeft:"44%"}}>
                            <p className='loader ml-3 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p></div>:
                            <div className='overflow-auto' style={{height:'290px'}}>

                                {staffSearch.map((i) => {
                                    return<>      
                                        <label>
                                            <div className='flex mt-2' style={{marginBottom:"-14px"}}>
                                                <input type="radio" className='mr-2' value={i.id} checked={parseInt(staffWork) === i.id} onChange={e => handleStaffChange(e,i)} />
                                                {i?.profile_data?.profile_url === null ?
                                                    <div className='avatar'>
                                                        <div className='mt-1.5 text-neutral-content rounded-full w-8 pt-1 text-center p-0.5 bg-yellow-400 text-white text-base'>
                                                            <span className='text-base' style={{textTransform: "capitalize"}}>{i?.name?.charAt(0)}</span>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className='avatar w-8 h-8 mt-1.5'>
                                                        <img src={i?.profile_data?.profile_url} alt='' className='rounded-full ' />
                                                    </div>
                                                }
                                                <div className='ml-2'>
                                                    <span className='text-sm font-semibold' style={{ textTransform: 'capitalize'}}>{i?.name} {i?.profile_data?.last_name}</span>
                                                    <p className='text-xs font-normal text-gray-500' style={{ textTransform: 'capitalize'}}>{i?.profile_data?.project_role}</p>
                                                </div>
                                            </div>
                                        </label><br/>
                                    </>
                                })}
                            </div>}
                            <div className={`flex justify-end ${loading ? "mt-32": null}`}>
                                <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => setIsOpen(false)}>Cancel</button>
                                <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={handleActivity}>Generate Summary</button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal
                isOpen={modalActivityOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="BOM status"
                >
                    <div>
                        <div className='flex justify-between'>
                            <p className='font-bold text-xl' >Select Activity</p>
                            <VscClose onClick={closeModal} className="cursor-pointer mt-1" style={{width:'25px', height: '25px'}} />
                        </div>
                        <input className='appearance-none rounded-full w-full mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Activity' onChange={filterBySearchActivity} />
                        <form>
                        {loading ? 
                            <div className='' style={{marginTop:"20%", marginLeft:"44%"}}>
                            <p className='loader ml-3 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p></div>:
                            <div className='overflow-auto p-3' style={{height:'290px'}}>
                                    {finalArr.map((i) => {
                                        return<>
                                            <label>
                                                <input type="radio" className={`${listId.construction[i].parent_activity_id ? "ml-5" : null}`} value={i} checked={parseInt(activityWork) === i} onChange={handleActivityChange} />
                                                <span className={`text-sm font-semibold ml-2 cursor-pointer ${listId.construction[i].parent_activity_id ? "font-normal" : "font-extrabold"}`}>{listId.construction[i].name}</span>
                                            </label><br/><br/>
                                        </>
                                    })}
                            </div>}
                            <div className={`flex justify-end ${loading ? "mt-32": null}`}>
                                <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => setModalActivityOpen(false)}>Cancel</button>
                                <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={handleActivity}>Generate Summary</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        </div>
        <hr/>
        { staff_data.find(i => i.user_app_id === parseInt(staffWork)) ? 
            <>
                <div className='flex justify-between ml-5 my-3 '>
                    <div className='flex'>
                        <div className='avatar w-8 h-8'>
                            <img src="https://placeimg.com/192/192/people" alt='' className='rounded-full mt-1' />
                        </div>
                        <div className='ml-2'>
                            <span className='text-sm font-semibold'>{workSummary.users.find(i => i.user_app_id === parseInt(staffWork)).user.profile_data.firstname}</span>
                            <p className='text-xs font-normal text-gray-500'>{workSummary.users.find(i => i.user_app_id === parseInt(staffWork)).role_auth}</p>
                        </div>
                    </div>
                    <div className='flex'>
                        <CgSoftwareDownload className=' mr-2 cursor-pointer mt-2' style={{width:'20px', height:'20px'}} />
                        <button className='font-semibold text-sm text-gray-400 mr-8'>Download</button>
                    </div>
                </div><hr/>
                {loading ? 
                    <div style={{marginTop: "15%", marginLeft: "44%"}}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p>
                    </div>
                :
                    <div className='flex'>
                        <div className='border overflow-scroll' style={{width:'calc(100vw - 250px - 40%)', height:'calc( 100vh - 330px )'}}>
                            <p className='font-bold text-lg m-4'>Work Hour Summary</p>
                            <p className='ml-4 font-medium text-xs text-gray-400'>Total Work Hours <span className='text-gray-500'> {staff_data.find(i => i.user_app_id === parseInt(staffWork))?.total_work_hours} Hours</span></p>
                            <div className='m-3'>
                                <Line options={lineStaffOptions} data={lineStaffdata}  />
                            </div>
                        </div>

                        <div className='overflow-scroll' style={{width:'calc(100vw - 250px - 60%)' ,height:'calc( 100vh - 330px )'}}>
                            <p className='font-bold text-lg mx-4 mt-4 mb-0.5'>Summary Based <br/>on Activities</p>
                            <div className='m-auto relative'style={{width:'250px',height:'250px'}} >
                                <p className='absolute left-20 font-medium text-xs text-gray-400' style={{top:'43%'}}>Total Work Hours</p>
                                <div className='flex absolute font-semibold text-xs text-gray-500'  style={{top:'52%', left:'91px'}}>
                                    <BiTimeFive style={{width:'15px', height:'15px'}} />
                                    <p className='ml-1'>{staff_data.find(i => i.user_app_id === parseInt(staffWork))?.total_work_hours} Hrs</p>
                                </div>
                                <Doughnut data={doughtnutStaffData} options={doughtnutStaffoptions} />
                            </div>
                            {dsDataAct.map((i,index) => {return<>
                                <div className='ml-5 mt-4 text-gray-500'>
                                    <div className='flex justify-between mb-2'>
                                        <div className='flex'>
                                            <p className='border rounded-full mt-0.5 mr-3' style={{width:'20px', height:'20px',backgroundColor:doughtnutStaffData.datasets[0].backgroundColor[index]}}></p>
                                            <p>{workSummary.activity.find(j => j.id === i)? workSummary.activity.find(j => j.id === i)?.name : "Deleted Activity"}</p>
                                        </div>
                                        <div className='mr-4 text-black'>
                                            <p>{doughtnutStaffData.datasets[0].data[index]} Hours</p>
                                        </div>
                                    </div>
                                </div>
                            </>})}                        
                        </div>
                    </div>
                }
            </>:
                activity_data.find(i => i.activity_id === parseInt(activityWork)) ? 
            <>
                <div className='flex justify-between my-5 ml-5'>
                    <p className='text-lg font-semibold'>{activity_data.find(i => i.activity_id === parseInt(activityWork))? workSummary.activity.find(j => j.id === parseInt(activityWork)).name : workSummary.activity.find(i => i.id === parseInt(activityWork)).name +"(Deleted Activity)"}</p>
                    <div className='flex'>
                        <CgSoftwareDownload className='mt-1 mr-2 cursor-pointer' style={{width:'20px', height:'20px'}} />
                        <button className='font-semibold text-sm text-gray-400 mr-8'>Download</button>
                    </div>
                </div>
                <hr/>
                {loading ? 
                    <div style={{marginTop: "15%", marginLeft: "44%"}}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p>
                    </div>
                :
                    <div className='flex'>
                        <div className='border overflow-scroll' style={{width:'calc(100vw - 250px - 40%)', height:'calc( 100vh - 335px )'}}>
                            <p className='font-bold text-lg m-4'>Activity Summary</p>
                            <p className='ml-4 font-medium text-xs text-gray-400'>Total Work Hours <span className='text-gray-500'> {total_activity_work_hours[0].reduce((a,b) => a+b ,0)} Hours</span></p>
                            <div className='m-3'>
                                <Line options={lineActivityOptions} data={lineActivityData} />
                            </div>
                        </div>

                        <div className='overflow-scroll' style={{width:'calc(100vw - 250px - 60%)' ,height:'calc( 100vh - 335px )'}}>
                            <p className='font-bold text-lg mx-4 mt-4 mb-0.5'>Summary Based <br/>on Activities</p>
                            <div className='m-auto relative'style={{width:'250px',height:'250px'}} >
                                <p className='absolute left-20 font-medium text-xs text-gray-400' style={{top:'43%'}}>Total Work Hours</p>
                                <div className='flex absolute font-semibold text-xs text-gray-500'  style={{top:'52%', left:'91px'}}>
                                    <BiTimeFive style={{width:'15px', height:'15px'}}  />
                                    <p className='ml-1'>{total_activity_work_hours[0].reduce((a,b) => a+b ,0)} Hrs</p>
                                </div>
                                <Doughnut data={doughtnutActivityData} options={doughtnutActivityoptions} />
                            </div>
                            <div className='ml-5 mt-5 text-gray-500'>
                                <div className='flex justify-between mb-2'>
                                    <div className='flex'>
                                        <p className='border rounded-full mt-0.5 mr-3' style={{width:'20px', height:'20px',backgroundColor:doughtnutActivityData.datasets[0].backgroundColor[0]}}></p>
                                        <p>{activity_data.find(i => i.activity_id === parseInt(activityWork))? workSummary.activity.find(j => j.id === parseInt(activityWork)).name : workSummary.activity.find(i => i.id === parseInt(activityWork)).name +"(Deleted Activity)"}</p>
                                    </div>
                                    <div className='mr-4 text-black'>
                                        <p>{activity_data.find(i => i.activity_id === parseInt(activityWork))? activity_data.find(i => i.activity_id === parseInt(activityWork))?.total_hours : "-"} Hours</p>
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    <div className='flex'>
                                        <p className='border rounded-full mt-0.5 mr-3' style={{width:'20px', height:'20px',backgroundColor:doughtnutActivityData.datasets[0].backgroundColor[1]}}></p>
                                        <p>{doughtnutActivityData.labels[1]}</p>
                                    </div>
                                    <div className='mr-4 text-black'>
                                        {activity_data.find(i => i.activity_id === parseInt(activityWork))?.total_hours ? 
                                            <p>{total_activity_work_hours[0].reduce((a,b) => a+b ,0) - activity_data.find(i => i.activity_id === parseInt(activityWork))?.total_hours} Hours</p> 
                                            :
                                            <p>{total_activity_work_hours[0].reduce((a,b) => a+b ,0)} Hours</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>: tableWork ?<>

            {startDate && endDate ?<>
                {loading ? 
                    <div style={{marginTop:"15%", marginLeft:"44%"}}>
                        <p className='loader ml-3 mb-1'></p>
                        <p className='font-bold text-xl'>Fetching...</p>
                    </div>
                :
                    <div className='overflow-scroll' style={{width:'calc( 100vw - 250px )', height:'calc( 100vh - 274px )'}}>
                        {staff_data.length > 0 ?
                        <table className="table table-compact w-full z-0" >
                            <thead>
                                <tr>
                                    <th className='normal-case' rowSpan="2">Staff</th>
                                    <th className='normal-case' rowSpan="2">Activity</th>
                                    <th className='normal-case' rowSpan="2">Total Work Hours</th>
                                    <th className='normal-case' rowSpan="2">Work Hours breakdown</th>
                                    <th className='normal-case text-center' colSpan={datesRange.map(i => i.length)}>Daily Work Hours</th>
                                </tr>
                                <tr>
                                    {datesRange.map((i, index) => {
                                        return(
                                            <th className='normal-case' key={index}>{i}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody> 
                                {staff_data.map((i, staff_index) => {
                                    return (
                                        <tr>
                                            <td>{workSummary.users.map((j) => <>
                                                {i.user_app_id === j.user_app_id ? <>{j.user.profile_data.firstname}</>: null}
                                            </>)}</td>
                                            <td>
                                                {/* {i.activity.map(act => <tr><td >{act.activity_id}</td></tr>)} */}
                                                {workSummary.activity.map((j) => <>
                                                    {i.activity.map(act => <>{act.activity_id === j.id ? <tr><td >{j.name}</td></tr> : null}</>)}
                                                </>)}
                                            </td>
                                            <td>{i.total_work_hours}</td>
                                            <td>{i.activity.map(wb => <tr><td>{wb.total_hours}</td></tr>)}</td>
                                            {datesRange.map(k => {return<td>
                                                {i.activity.map((j, activity_index) => <tr><td>
                                                    {getWHDate(k, staff_index, activity_index)}
                                                </td></tr>)}
                                            </td>})
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        :
                            <p className='font-bold text-xl' style={{marginTop:'10%', marginLeft: '44%'}}>No Records</p>
                        }
                    </div>
                }

                </> : <div>
                    <img src='/Checklist.jpg' alt='' className='m-auto mt-52' />
                    <p className='text-gray-300 text-center mt-2'>Your summary will appear here</p>
                </div>}

                </>
                :
                <div>
                    <img src='/EmptyStat.svg' alt='' className='m-auto' style={{marginTop: "15%"}} />
                    <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                </div>                
             }
    </div>
  )
}

export default WorkHoursSummary
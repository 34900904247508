import { useAtom } from 'jotai'
import React, { useCallback, useRef } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dashboardAtom, { fetchBlockProgress, fetchBlocks, fetchBomBlocks, fetchBOMproject, fetchCount, fetchCountOverall, fetchMapRender, fetchoverallCP, fetchOverallCpSVG, fetchoverallQC, fetchoverallTracker, fetchTrackerName } from '../../atoms/dashboardAtom'
import Container from '../../components/container'
import { CgSoftwareDownload } from "react-icons/cg"
import { MdModeEditOutline } from "react-icons/md"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { MdDelete } from "react-icons/md"
import { RiEdit2Fill } from "react-icons/ri"
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { BsBoxArrowUpRight, BsChevronDown, BsChevronUp } from "react-icons/bs"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { VscClose } from "react-icons/vsc"
import Modal from 'react-modal';
import activitiesAtom, { fetchActivitiesAtom } from '../../atoms/activitiesAtom'
import { useState } from 'react'
import partsAtom, { fetchPartsAtom } from '../../atoms/partsAtom'
import 'react-dropdown-tree-select/dist/styles.css'
import projectAtom from '../../atoms/projectAtom'
import { toast } from 'react-hot-toast'
import { Parser } from 'json2csv'
import { ActivitySelectBox } from '@product-fusion/surya-fw';
import ScopeSelect from '../../components/scope'
import layoutAtom, { addLayoutInventory, deleteInventory, deleteLaydown, downloadLaydown, editInventory, fetchGetLayout, fetchIndividualLayoutData, fetchInventoryBomLayout, fetchLaydownScopeDataAtom, fetchUpdateLayout, get_Laydown_scope, update_Laydown_scope } from '../../atoms/layoutAtom'
import { useForm } from 'react-hook-form'
import NestedTable from './nestedTable'
import produce from 'immer'
import mileStone, { fetchMileStone, fetchOnsiteQtyAgg, getDeliveryReferenceAliasNameAtom } from '../../atoms/mileStoneAtom'
import Svg from './svg'
import roundOff from '../../utils/roundOff'
import TableComp from '../../components/tableComp'
import { BOM_FOR_BLOCKS } from '../../components/BOM'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import Laydown from './laydown'
import ViewByDropDown from './components/viewByDropDown'

const useLatest = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

function BlockList() {

    const _ = require("lodash")
    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm()
    //svg

    const [loading, setLoading] = useState(false)
    const [countLoading, setCountLoading] = useState(false)
    const [bomLoading, setBomLoading] = useState(false)
    const [inventoryLoader, setInventoryLoader] = useState(false)
    const [addEntry, setAddEntry] = useState({ boolean: false, data: [{ bom_id: null, purpose_code: 1, destination: null, comments: "", time: null, issued: null, received_quantity: null }] })
    const [overallProgress, setOverallProgress] = useState("All")
    const [showDescription, setShowDescription] = useState()
    const [laydownId, setLaydownId] = useState(null)
    const [bomLaydownId, setBomLaydownId] = useState(null)
    const [laydown, setLaydown] = useState({ boolean: false, enableAdd: false })
    const [svgMap, setSvgMap] = useState()
    const [scopeMap, setScopeMap] = useState()
    const [editInventoryNameModal, setEditInventoryNameModal] = useState({ boolean: false, data: {} })
    const [manage, setManage] = useState(0)
    const [scp, setscp] = useState([])
    const [showScopeData, setShowScopeData] = useState({ bool: false, laydown_id: null })
    const [viewScope, setviewScope] = useState(false)
    const [confimationModal, setConfirmationModal] = useState(false)
    const [showProgress, setShowProgress] = useState(true)
    const [selectedBlocks, setSelectedBlocks] = useState({ bool: false, data: [] })
    const [svgScale, setSvgScale] = useState(0.5)
    const [openEditRemediateQty, setOpenRemediateQty] = useState(false)
    const [inventryLaydownId, setInventoryLaydownId] = useState(0)
    const [isInventryRemediateQtyEdit, setIsInventryRemediateQtyEdit] = useState(false)
    const [remediateQtyBomDetails, setRemediateQtyBomDetails] = useState({})
    const [inventryBomId, setInventryBomId] = useState(0)


    const isBlocksSelectEnabledRef = useLatest(selectedBlocks?.bool);

    const selectedBlockIdsRef = useLatest(selectedBlocks?.data);
    const laydownScopeRef = useLatest(showScopeData?.bool)
    const laydownSvgRef = useLatest(laydown.boolean)
    const updateLaydownInputRef = useRef(null)

    const [listblocks] = useAtom(dashboardAtom)
    const [listId] = useAtom(activitiesAtom)
    const [listPartsAtom] = useAtom(partsAtom)
    const [listLayout] = useAtom(layoutAtom)
    const [listMileStone] = useAtom(mileStone)
    const [, setEditInventory] = useAtom(editInventory)
    const [, setFetchMileStone] = useAtom(fetchMileStone)
    const [, setFetchSingleLayout] = useAtom(fetchIndividualLayoutData)
    const [, setFetchScopeLaydown] = useAtom(fetchLaydownScopeDataAtom)
    const [, setDownloadLaydown] = useAtom(downloadLaydown)
    const [, setDeleteLaydown] = useAtom(deleteLaydown)
    const [, setUpdateLayout] = useAtom(fetchUpdateLayout)
    const [, setFetchBomInventory] = useAtom(fetchInventoryBomLayout)
    const [, setAddLayoutInventory] = useAtom(addLayoutInventory)
    const [, setFetchDeleteInventory] = useAtom(deleteInventory)
    const [, setFetchLayout] = useAtom(fetchGetLayout)
    const [, setFetchParts] = useAtom(fetchPartsAtom)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setFetchoverallCP] = useAtom(fetchoverallCP)
    const [, setFetchoverallQC] = useAtom(fetchoverallQC)
    const [, setFetchoverallTracker] = useAtom(fetchoverallTracker)
    const [, setfetchActivitiesAtom] = useAtom(fetchActivitiesAtom)
    const [, setFetchTotalBOM] = useAtom(fetchBOMproject)
    const [, setBlocksBom] = useAtom(fetchBomBlocks)
    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setTrackerName] = useAtom(fetchTrackerName)
    const [, setDeployCount] = useAtom(fetchCount)
    const [, setDeployCountOverall] = useAtom(fetchCountOverall)
    const [, setFetchBlockProgress] = useAtom(fetchBlockProgress)
    const [, setFetchOverallSVG] = useAtom(fetchOverallCpSVG)
    const [projectsName] = useAtom(projectAtom)
    const [, getLaydownScope] = useAtom(get_Laydown_scope)
    const [, setLaydownScope] = useAtom(update_Laydown_scope)
    const [, fetchInventryLogDeliveryQty] = useAtom(fetchOnsiteQtyAgg)
    const [, getDeliveryReferenceAliasName] = useAtom(getDeliveryReferenceAliasNameAtom)

    useEffect(() => {
        if (parseInt(localStorage.getItem("project_id"))) {
            init()
        }
    }, [parseInt(localStorage.getItem("project_id"))])

    function handleCheckZimmerman() {
        let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))
        if (currentProject && currentProject?.type) {
            return true
        }
        else {
            return false
        }
    }

    function qcCheck() {
        let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        return project_name?.project_settings?.qc
    }

    const handleClickOutside = (event) => {
        if (updateLaydownInputRef.current && !updateLaydownInputRef.current.contains(event.target)) {
            setEditInventoryNameModal({ boolean: false, data: {} })
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getDeliveryReferenceName = () => {
        let name = ""
        if (listMileStone?.deliveryReferenceAliasName !== null && Object.keys(listMileStone?.deliveryReferenceAliasName).length !== 0 && listMileStone?.deliveryReferenceAliasName?.message !== 'Field alias not found') {
            name = listMileStone?.deliveryReferenceAliasName?.field_alias_name
        }
        return name
    }

    const handleBlockClick = useCallback(_.debounce((block, block_svg_id) => {
        if (!laydownScopeRef.current) {
            if (isBlocksSelectEnabledRef.current) {
                setSelectedBlocks((prevSelectedBlockIds) => {
                    const newSelectedBlockIds = new Set(prevSelectedBlockIds?.data);
                    if (newSelectedBlockIds.has(block.id)) {
                        newSelectedBlockIds.delete(block.id);
                        block_svg_id.style.fill = ""; // Reset the color if deselected
                    } else {
                        newSelectedBlockIds.add(block.id);
                        block_svg_id.style.fill = "yellow"; // Set color if selected
                    }
                    return { bool: true, data: Array.from(newSelectedBlockIds) };
                });
            } else {
                block_svg_id.style.fill = "";
            }
        }
    }, 100), []);

    const handleDblClick = (block) => {
        if (!isBlocksSelectEnabledRef.current && selectedBlockIdsRef.current.length === 0 && !laydownScopeRef.current && !laydownSvgRef.current) {
            navigate(`/dashboard/summary/${block.id}`);
        }
    }

    const svgBlocksSelect = useCallback(() => {
        if (listblocks?.blocks?.length > 0) {
            let block_svg_id;
            let block_progress_id;
            let constructionProgressOfAllActivitiesInBlock = {}
            if (listblocks?.overAllSVG) {
                Object.keys(listblocks?.overAllSVG).map((i) => {
                    let subList = []
                    let subWight = []
                    listblocks?.overAllSVG[i].map((j) => {
                        if (listId?.construction[j?.activity_id]?.parent_activity_id) {
                            subWight.push(listId?.construction[j?.activity_id]?.weight)
                            subList.push(listId?.construction[j?.activity_id]?.weight * j?.progress)
                        }
                    })
                    constructionProgressOfAllActivitiesInBlock[i] = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
                })
            }

            listblocks?.blocks.forEach((block) => {
                block_svg_id = document.getElementById(handleCheckZimmerman() ? block?.name : block?.block_svg_id);
                block_progress_id = document.getElementById(block?.progress_svg_id);

                if (block_svg_id) {
                    block_svg_id.removeEventListener('click', () => handleBlockClick(block, block_svg_id));
                    block_svg_id.addEventListener('click', () => handleBlockClick(block, block_svg_id));
                    block_svg_id.addEventListener('dblclick', () => handleDblClick(block));
                }

                if (isBlocksSelectEnabledRef.current) {
                    if (selectedBlockIdsRef.current.includes(block.id)) {
                        block_svg_id.style.fill = "yellow";
                    }
                    else {
                        block_svg_id.style.fill = ""; // Reset the color if not selected
                    }
                } else {

                    if (block_svg_id) {

                        const updateProgress = (progress) => {
                            if (block_progress_id && overallProgress !== "None Selected") {
                                block_progress_id.querySelector("tspan").innerHTML = `${isNaN(progress) ? 0 : progress} %`;
                            }
                            if (isNaN(progress)) {
                                block_svg_id.style.fill = ""
                            }
                            else {
                                if (progress === 0) {
                                    block_svg_id.style.fill = "#FFFFFF";
                                } else if (progress === 100) {
                                    block_svg_id.style.fill = "#B4F8D7";
                                } else {
                                    block_svg_id.style.fill = "#FCDDD9";
                                }
                            }
                        };

                        if (overallProgress === "All" && constructionProgressOfAllActivitiesInBlock.length !== 0) {
                            const progress = constructionProgressOfAllActivitiesInBlock[block.id];
                            updateProgress(progress);
                        }
                        else {
                            if (overallProgress === "None Selected") {
                                if (block_progress_id) {
                                    block_progress_id.querySelector("tspan").innerHTML = ``;
                                }
                                block_svg_id.style.fill = ""
                            }
                            else {
                                const progress = handleFindProgress(block.id);
                                updateProgress(progress);
                            }
                        }
                    }
                }
            });
        }
    }, [listblocks, listId, overallProgress, navigate, localStorage.getItem("selected-activity")]);

    const handleOpenEditRemediateQty = (data) => {
        setOpenRemediateQty(true)
        setRemediateQtyBomDetails(data)
        setInventoryLaydownId(parseInt(data?.laydown_id))
        getDeliveryReferenceAliasName()
        fetchInventryLogDeliveryQty({ bomId: data?.bom_id })
    }

    const handleChangeRemediatedQty = (e, index) => {
        console.log(e.target.value)
    }

    const handleClickRemediateQtyButton = () => {
        if (isInventryRemediateQtyEdit === false) {
            setIsInventryRemediateQtyEdit(true)
        } else {
            setIsInventryRemediateQtyEdit(false)
        }
    }

    const handleCloseEditRemediateQty = () => {
        setOpenRemediateQty(false)
    }

    useEffect(() => {
        svgBlocksSelect();
        return () => {
            listblocks?.blocks?.forEach(block => {
                let block_svg_id;
                block_svg_id = document.getElementById(block?.block_svg_id);
                if (block_svg_id) {
                    const handleClick = () => { }; // Dummy function to remove actual listener
                    block_svg_id.removeEventListener('click', handleClick);
                }
            });
        };
    }, [listblocks?.blocks, listblocks?.blockProgress, listblocks?.overAllSVG, overallProgress, selectedBlocks, laydown.boolean]);

    const handleEnableSelectBlocks = async () => {
        setSelectedBlocks(produce((draft) => { draft.bool = !selectedBlocks?.bool }))
        if (selectedBlocks?.bool) {
            setSelectedBlocks(produce((draft) => { draft.data = [] }));
        }
    };

    const handleSelectAllBlocks = () => {
        const allBlockIds = listblocks?.blocks.map(block => block.id);
        setSelectedBlocks(produce((draft) => { draft.data = allBlockIds }));
    };

    useEffect(() => {
        if (listblocks.map) {
            setSvgMap(listblocks.map)
            setScopeMap(listblocks.map)
        }
    }, [listblocks.map])

    const init = async () => {
        try {
            setLoading(true)
            setCountLoading(true)
            setTrackerName()
            await setFetchMap()
            setfetchActivitiesAtom()
            await setFetchLayout()
            await setFetchBlocks()
            setFetchOverallSVG()
            setFetchoverallCP()
            setFetchoverallQC()
            setFetchoverallTracker()
            setFetchParts()
            await setFetchMileStone()
            setOverallProgress(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
            setCountLoading(false)
        }
    }

    useEffect(() => {
        handleFetchDeployCount()
    }, [listId?.construction])

    function showLaydownFlag() {
        let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        return project_name?.project_settings?.laydown_delivery
    }

    const handleFetchDeployCount = async () => {
        try {
            console.log("setFetchBlockProgress", localStorage.getItem("selected-activity"), listId?.construction)
            if (localStorage.getItem("selected-activity") !== "All" && localStorage.getItem("selected-activity") !== null && localStorage.getItem("selected-activity") !== "None Selected" && listId?.construction) {
                await setFetchBlockProgress({ act_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id })
                await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id })
            }
            else {
                await setDeployCountOverall()
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleFindProgress = (data) => {
        let temp = 0
        if (listblocks.blockProgress) {
            listblocks.blockProgress.map((i) => {
                if (i?.block_id === data) {
                    temp = roundOff(i?.progress)
                }
            })
        }
        return temp
    }

    const handleCreateBeacon = (x, y, classId) => {
        let data = document.getElementsByClassName("svgParentDiv")[0]
        var svgData = data.querySelectorAll("svg")[0]
        svgData.setAttribute("style", `width: 40vw; height: 40vh`)
        var z = document.createElement('div')
        z.setAttribute("style", `position: absolute; top: ${y}%; left: ${x}%; color: #F16253; border: 1px solid #F16253; padding: 5px; border-radius: 100%; background-color: #F16253;`)
        z.setAttribute("xml:space", "preserve")
        z.setAttribute("font-family", "Tenor Sans")
        z.setAttribute("font-size", "28")
        if (classId) {
            z.setAttribute("class", classId)
        }
        z.setAttribute("letter-spacing", "0em")
        var layName = document.createElement('div')
        layName.setAttribute("style", `position: absolute; top: ${y - 5}%; left: ${x}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
        if (classId) {
            layName.setAttribute("class", `${classId}__name`)
            layName.innerHTML = classId?.split("__")[1]
        }
        data.appendChild(layName)
        data.appendChild(z)
    }

    const handleSVGAppend = () => {
        laydownSvgRef.current = !laydownSvgRef.current
        setLaydown(produce((draft) => { draft.boolean = !laydown.boolean }))
        let data = document.getElementsByClassName("svgParentDiv")?.[0];
        if (data) {
            var svgData = data?.querySelectorAll("svg")?.[0];
            svgData.setAttribute("style", `width: 40vw; height: 40vh`)
        }
    }


    useEffect(() => {
        if (laydown.boolean) {
            for (let key of listLayout.data) {
                handleCreateBeacon(key.latitude, key.longitude, `${key.id}__${key.name}`)
            }
            let data = document.getElementsByClassName("svgParentDiv")[0]
            var svgData = data.querySelectorAll("svg")[0]
            svgData.setAttribute("style", `width: 40vw; height: 40vh`)
        }
    }, [laydown.boolean, listLayout.data])

    const handleAddLaydown = () => {
        if (!laydown.enableAdd) {
            for (let key of listLayout.data) {
                let data = document.getElementsByClassName(`${key.id}__${key.name}__name`)[0]
                data.remove()
            }
        }
        else {
            for (let key of listLayout.data) {
                let data = document.getElementsByClassName("svgParentDiv")[0]
                var layName = document.createElement('div')
                layName.setAttribute("style", `position: absolute; top: ${key.longitude - 5}%; left: ${key.latitude}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
                layName.setAttribute("class", `${key.id}__${key.name}__name`)
                layName.innerHTML = key.name
                data.appendChild(layName)
            }
        }
        setLaydown(produce((draft) => { draft.enableAdd = !laydown.enableAdd }))
    }

    const handleCoordinates = (e) => {
        let rect = e.currentTarget.getBoundingClientRect();
        let x = (e.clientX - rect.left) / (svgScale);
        let y = (e.clientY - rect.top) / (svgScale);
        if (laydown.enableAdd) {
            setLaydown(produce((draft) => { draft.enableAdd = false }))
            setAddLayoutModal(produce((draft) => { draft.boolean = true; draft.data = { name: "", x: x, y: y } }))
        }
    }

    const handleOpenLaydown = (e) => {
        if (laydown.enableAdd) {
            handleCoordinates(e)
        }
        else {
            if (e.target.className && !["svg", "tspan", "path"].includes(e.target.nodeName)) {
                let temp = listLayout.data.filter(o => o.id === parseInt(e.target?.className?.split("__")[0]))
                if (temp.length > 0) {
                    handleClickLayoutDropdown(parseInt(e.target.className?.split("__")[0]))
                }
            }
        }
    }

    const ttlOverAllPercent = () => {
        let subList = []
        let subWight = []
        if (listId.construction) {
            Object?.keys(listId?.construction)?.map((i) => {
                if (listId?.construction[i]?.parent_activity_id) {
                    subWight.push(listId?.construction[i]?.weight)
                    subList.push(listId?.construction[i]?.weight * findCPprogressCalc(parseInt(i)))
                }
            })
        }
        let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const ttlOverAllQCPercent = () => {
        let subList = []
        let subWight = []
        if (listId.qc) {
            Object?.keys(listId?.qc)?.map((i) => {
                if (listId?.qc[i]?.parent_activity_id) {
                    subWight.push(listId?.qc[i]?.weight)
                    subList.push(listId?.qc[i]?.weight * findQCprogress(parseInt(i)))
                }
            })
        }
        let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
        if (isNaN(temp)) {
            return 0
        }
        else {
            return temp
        }
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [layoutModal, setLayoutModal] = useState(false)
    const [addlayoutLoader, setAddLayoutLoader] = useState(false)
    const [addLayoutmodal, setAddLayoutModal] = useState({ boolean: false, data: { name: "", x: null, y: null } })

    const closeAddLayoutModal = async () => {
        setAddLayoutModal(produce((draft) => { draft.boolean = false }))
        setLaydown(produce((draft) => { draft.enableAdd = false }))
        for (let key of listLayout.data) {
            let data = document.getElementsByClassName("svgParentDiv")[0]
            var layName = document.createElement('div')
            layName.setAttribute("style", `position: absolute; top: ${key.longitude - 5}%; left: ${key.latitude}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
            layName.setAttribute("class", `${key.id}__${key.name}__name`)
            layName.innerHTML = key.name
            data.appendChild(layName)
        }
    }

    const closeLayoutModal = async () => {
        setLayoutModal(false)
        setExpanded(null)
        setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, purpose_code: 1, destination: null, comments: "", time: null, issued: null, received_quantity: null }] }))
    }

    const [expanded, setExpanded] = useState(-1)

    const [bomLaydownLoader, setBomLaydownLoader] = useState(false)
    const [loadingMsg, setLoadingMsg] = useState(false)

    const handleClickLayoutDropdown = async (id) => {
        try {
            setLaydownId(id)
            setLayoutModal(true)
            setInventoryLoader(true)
            await setFetchSingleLayout({ laydownId: id })
            await setFetchScopeLaydown({ laydownId: id })
            await getLaydownScope({ project: project_name?.project_number, laydown: id })
        }
        catch (err) {
            console.log(err)
            // toast.error("Something went wrong")
        }
        finally {
            setInventoryLoader(false)
        }
    }

    const handleChangeView = (value) => {
        if (value === "Mechanical Progress") {
            navigate("/dashboard")
        } else {
            navigate("/dashboard/laydown-view")
        }
    }

    const handleFindLaydownName = (data) => {
        let temp
        temp = listLayout.data.find(o => o.id === data)
        return temp?.name
    }

    const createLayoutSubmit = async (data) => {
        try {
            let prtSvg = document.getElementsByClassName("svgParentDiv")[0]
            setAddLayoutLoader(true)
            await setUpdateLayout({
                data: {
                    name: data.name,
                    latitude: ((parseInt(addLayoutmodal.data.x)) / prtSvg.offsetWidth) * 100,
                    longitude: ((parseInt(addLayoutmodal.data.y)) / prtSvg.offsetHeight) * 100
                }
            })
            toast.success("Created Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to create layout")
        }
        finally {
            setAddLayoutLoader(false)
            setAddLayoutModal(produce((draft) => { draft.boolean = false }))
            reset()
        }
    }

    const handleUpdateLaydownName = async (data) => {
        try {
            setAddLayoutLoader(true)
            if (data && data !== handleFindLaydownDetail(editInventoryNameModal?.data)?.name) {
                await setUpdateLayout({
                    data: {
                        name: data,
                        latitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.latitude,
                        longitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.longitude,
                        id: editInventoryNameModal?.data
                    }
                })
                toast.success("Updated Successfully")
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to update layout")
        }
        finally {
            setEditInventoryNameModal({ boolean: false, data: {} })
        }
    }

    const updateLayoutSubmit = async (data) => {
        try {
            setAddLayoutLoader(true)
            await setUpdateLayout({
                data: {
                    name: data.updatedName,
                    latitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.latitude,
                    longitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.longitude,
                    id: editInventoryNameModal?.data
                }
            })
            toast.success("Updated Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to update layout")
        }
        finally {
            setAddLayoutLoader(false)
            setEditInventoryNameModal({ boolean: false, data: {} })
            reset()
        }
    }

    const handleFindLaydownDetail = (data) => {
        let temp
        temp = listLayout.data.find(o => o.id === data)
        return temp
    }

    const handleDeleteConfirmation = () => {
        setConfirmationModal(true)
    }

    const handleDeleteLaydown = async () => {
        try {
            await setDeleteLaydown({ laydown_id: laydownId })
            setLayoutModal(false)
            let temp = listLayout.data.filter(o => o.id === laydownId)
            let layName = document.getElementsByClassName(`${temp[0].id}__${temp[0].name}__name`)[0]
            let layBeacon = document.getElementsByClassName(`${temp[0].id}__${temp[0].name}`)[0]
            layName.remove()
            layBeacon.remove()
            toast.success("Deleted successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setConfirmationModal(false)
        }
    }

    const openModal = async () => {
        try {
            setBomLoading(true)
            await setFetchTotalBOM()
            setIsOpen(true);
            setBomLoading(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleSelectedBomBlock = async () => {
        try {
            setBomLoading(true)
            await setBlocksBom({ selectedBlocks: selectedBlocks?.data })
            setIsOpen(true);
            setBomLoading(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '95vw',
            height: "650px",
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const customStylesLayout = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: "85vh",
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }
    const customStylesEditRemediatyQty = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const customStylesAddLayout = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleBomLaydownId = async (bomId) => {
        try {
            setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: bomId.bom_id, purpose_code: 1, destination: null, comments: "", time: "", issued: null, received_quantity: null }] }))
            setBomLaydownLoader(true)
            setBomLaydownId(bomId.bom_laydown_id)
            await setFetchBomInventory({ bom_id: bomId.bom_id, laydown_id: bomId.laydown_id })
            setExpanded(bomId.bom_laydown_id)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleSaveEdit = async (data, epoch) => {
        try {
            setBomLaydownLoader(true)
            setLoadingMsg(true)
            let temp = [
                {
                    bom_id: addEntry.data[0].bom_id,
                    purpose_code: data.data.purpose_code,
                    comments: data.data.comments,
                    time: Math.round(epoch / 1000),
                    issued: data.data.issued_quantity ? data.data.issued_quantity : 0,
                    received_quantity: data.data.received_quantity ? data.data.received_quantity : 0,
                    inventory_log_id: data.data.inventory_log_id
                }
            ]
            if (data.data.purpose_code === 0) {
                temp[0]["destination_laydown_id"] = data.destination
            }
            await setEditInventory({ data: temp, laydown_id: laydownId, inventory_log_id: data.data.inventory_log_id, bom_id: addEntry.data[0].bom_id })
            await setFetchSingleLayout({ laydownId: laydownId })
            toast.success("Updated Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBomLaydownLoader(false)
            setLoadingMsg(false)
        }
    }

    const handleDeleteInventory = async (laydown_id, data) => {
        try {
            setInventoryLoader(true)
            await setFetchDeleteInventory({ laydown_id: laydown_id.laydown_id, data: laydown_id.data })
            await setFetchBomInventory({ bom_id: addEntry.data[0].bom_id, laydown_id: laydown_id.laydown_id })
            toast.success("Deleted Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setInventoryLoader(false)
        }
    }

    const handleDownloadLaydown = async () => {
        try {
            await setDownloadLaydown({ laydown_id: laydownId, laydownName: handleFindLaydownName(laydownId), project_name: project_name, formattedDate: formattedDate, details: listLayout.data })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const data = []

    if (listId?.construction) {
        Object.keys(listId?.construction).map((i) => {
            let temp = {}
            if (listId?.construction[i]?.parent_activity_id) {
                temp.label = listId?.construction[listId?.construction[i]?.parent_activity_id].name
                temp.value = listId?.construction[listId?.construction[i]?.parent_activity_id].id
                temp.children = []
                temp.children.push({
                    label: listId?.construction[i].name,
                    value: listId?.construction[i].id
                })
                data.push(temp)
            }
            else {
                temp.label = listId?.construction[i].name
                temp.value = listId?.construction[i].id
                data.push(temp)
            }
        })
    }

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    const handleDownloadSelectedBlockBom = () => {
        try {
            toast("Download queued")
            let arr = []
            listblocks?.totalBOM.map((i, index) => {
                let temp = {}
                temp['Blocks'] = listblocks?.blocks?.find(o => o?.id === selectedBlocks?.data[index])?.name
                temp['Part Name'] = i?.part_name?.toUpperCase()
                temp["Description"] = i?.description
                temp["Required Quantity"] = i?.project_quantity_calc
                temp["Distribution Progress %"] = `${roundOff(i?.distribution_progress)}%`
                temp["Distributed Quantity"] = i?.distributed_quantity
                temp["Installed Progress %"] = `${roundOff(i?.installed_progress)}%`
                temp["Installed Quantity"] = i?.installed_quantity
                arr.push(temp)
            })
            let newFields = [
                "Blocks",
                "Part Name",
                "Description",
                "Required Quantity",
                "Distribution Progress %",
                "Distributed Quantity",
                "Installed Progress %",
                "Installed Quantity"
            ]
            let opts = { newFields }
            const parser = new Parser(opts)
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", Selected Blocks BOM Status\n` + `"Report Date", ${formattedDate}\n` + `\n` + csv
            const element = document.createElement("a")
            const file = new Blob([csv], { type: 'text/csv' })
            element.href = URL.createObjectURL(file)
            element.download = `${project_name?.project_number}_Selected Blocks BOM_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            console.log(err)
            toast.error("Download failed")
        }
    }

    const handleDownload = () => {
        try {
            toast("Download queued")
            let arr = []
            listblocks?.totalBOM.map((i) => {
                let temp = {}
                temp['Part Name'] = i?.part_name?.toUpperCase()
                temp["Description"] = i?.description
                temp["Project Quantity"] = i?.default_project_quantity
                temp["Construction Spares"] = i?.spare_count ? i?.spare_count : 0
                temp["Addl Qty Incl.O&M Spares"] = i?.additional_count ? i?.additional_count : 0
                temp["Total Quantity"] = i?.project_quantity
                temp["On site quantity"] = i?.received_quantity ? i?.received_quantity : 0
                temp["Stock quantity"] = i?.current_quantity ? i?.current_quantity : 0
                temp["Distribution Progress %"] = `${roundOff(i?.distribution_progress)}%`
                temp["Distributed Quantity"] = i?.distributed_quantity
                temp["Installed Progress %"] = `${roundOff(i?.installed_progress)}%`
                temp["Installed Quantity"] = i?.installed_quantity
                arr.push(temp)
            })
            let newFields = [
                "Part Name",
                "Description",
                "Project Quantity",
                "Construction Spares",
                "Addl Qty Incl.O&M Spares",
                "Total Quantity",
                "On site quantity",
                "Stock quantity",
                "Distribution Progress %",
                "Distributed Quantity",
                "Installed Progress %",
                "Installed Quantity"
            ]
            let opts = { newFields }
            const parser = new Parser(opts)
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", Project BOM Status\n` + `"Report Date", ${formattedDate}\n` + `\n` + csv
            const element = document.createElement("a")
            const file = new Blob([csv], { type: 'text/csv' })
            element.href = URL.createObjectURL(file)
            element.download = `${project_name?.project_number}_Project BOM_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            console.log(err)
            toast.error("Download failed")
        }
    }

    const handleProgressChange = async (e) => {
        try {
            if (e === "All" || e === "None Selected") {
                localStorage.setItem("selected-activity", e)
                setOverallProgress(e)
            }
            else {
                localStorage.setItem("selected-activity", e[0])
                setOverallProgress(e[0])
            }
            setCountLoading(true)
            if (e !== "All" && e.length !== 0 && e !== "None Selected") {
                await setFetchBlockProgress({ act_id: e[0], part_id: listId?.construction[e[0]]?.part_id })
                await setDeployCount({ activity_id: e[0], part_id: listId?.construction[e[0]]?.part_id })
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setCountLoading(false)
        }
    }

    const findTrackerName = (data) => {
        let name = listblocks?.trackerName.find(k => k.id === data)
        return name?.tracker_type
    }

    let trackerSort = []
    let prt = []
    listblocks?.overallTracker.map((i) => {
        if (handleCheckZimmerman()) {
            prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}`)
        }
        else {
            prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]}`)
        }
        return i
    })

    prt = [...new Set(prt)]

    prt.map((i) => {
        let trName = {}
        trName.name = i
        let tcount = 0
        listblocks.overallTracker.map((j) => {
            if (handleCheckZimmerman()) {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
                    tcount += j?.count
                }
            }
            else {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
                    tcount += j?.count
                }
            }
            return j
        })
        trName.count = tcount
        trackerSort.push(trName)
        listblocks.overallTracker.map((j) => {
            if (handleCheckZimmerman()) {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
                    let newtrName = {}
                    newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
                    newtrName.count = j?.count
                    trackerSort.push(newtrName)
                }
            }
            else {
                if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
                    let newtrName = {}
                    newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
                    newtrName.count = j?.count
                    trackerSort.push(newtrName)
                }
            }
            return j
        })
        return i
    })

    let CPparActIds = []
    let QCparActIds = []

    if (listblocks.overallCP) {
        for (let cp in listblocks.overallCP) {
            if (!listId.construction[cp]?.parent_activity_id) {
                CPparActIds.push(cp)
            }
        }
    }

    if (listblocks.overallQC) {
        listblocks.overallQC.map((qc) => {
            if (!listId.qc[qc.activity_id]?.parent_activity_id) {
                QCparActIds.push(qc.activity_id)
            }
        })
    }

    let finalCPArr = []
    let finalQCArr = []

    CPparActIds.map((pt) => {
        finalCPArr.push(parseInt(pt))
        Object.values(listId?.construction)?.map((ij) => {
            if (ij.parent_activity_id === parseInt(pt)) {
                finalCPArr.push(ij.id)
            }
        })
    })

    QCparActIds.map((pt) => {
        finalQCArr.push(parseInt(pt))
        Object.values(listId?.qc)?.map((ij) => {
            if (ij.parent_activity_id === parseInt(pt)) {
                finalQCArr.push(ij.id)
            }
        })
    })

    const findCPprogressCalc = (data) => {
        let temp = 0
        if (listblocks.overallCP) {
            for (let i in listblocks.overallCP) {
                if (parseInt(i) === data) {
                    temp = listblocks.overallCP[i]
                }
            }
        }
        return temp
    }

    const findCPprogress = (data) => {
        let temp = 0
        if (listblocks.overallCP) {
            for (let i in listblocks.overallCP) {
                if (parseInt(i) === data) {
                    temp = listblocks.overallCP[i]
                }
            }
        }
        if (listId.construction[data]?.parent_activity_id) {
            return temp
        }
        else {
            let sub_weight_progress = []
            let sub_weight = []
            for (let actKey in listId.construction) {
                if (listId.construction[actKey]?.parent_activity_id === data) {
                    sub_weight_progress.push(listId.construction[actKey]?.weight_decimal * findCPprogress(parseInt(actKey)))
                    sub_weight.push(listId.construction[actKey]?.weight_decimal)
                }
            }
            let progressData = sub_weight_progress.reduce((partialSum, a) => partialSum + a, 0) / sub_weight.reduce((partialSum, a) => partialSum + a, 0)
            return progressData
        }
    }

    const findQCprogress = (data) => {
        let temp = 0
        if (listblocks.overallQC) {
            listblocks.overallQC.map((i) => {
                if (i?.activity_id === data) {
                    temp = i?.progress
                }
            })
        }
        return temp
    }

    const handleAddBomInventory = async () => {
        try {
            setBomLaydownLoader(true)
            let temp = {}
            temp.bom_id = addEntry.data[0].bom_id
            temp.issued = addEntry.data[0].issued ? addEntry.data[0].issued : 0
            temp.received_quantity = addEntry.data[0].received_quantity ? addEntry.data[0].received_quantity : 0
            temp.purpose_code = addEntry.data[0].purpose_code
            temp.comments = addEntry.data[0].comments
            if (addEntry.data[0].time) {
                temp.time = Math.round(addEntry.data[0].time / 1000)
            }
            else {
                temp.time = Math.round(new Date().getTime() / 1000)
            }
            if (addEntry.data[0].purpose_code === 0) {
                temp.destination_laydown_id = addEntry.data[0].destination
            }
            await setAddLayoutInventory({ laydownId: laydownId, bomLaydownId: bomLaydownId, data: [temp], action: addEntry.data[0].purpose_code, destination: addEntry.data[0].destination, bom_id: temp.bom_id })
            setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: addEntry.data[0].bom_id, count: null, purpose_code: 1, destination: null, comments: "", time: "" }] }))
            await setFetchSingleLayout({ laydownId: laydownId })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went Wrong")
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleCheckBomInventorySave = () => {
        let temp = true
        let data = addEntry.data[0]
        if (data.purpose_code === 4) {
            if (data.issued || data.received_quantity) {
                temp = false
            }
        }
        else {
            if (data.issued) {
                temp = false
            }
        }
        return temp
    }

    const handleCheckQuantity = () => {
        let temp = []
        for (let data of listLayout.bomLayoutData) {
            temp.push(data.count)
        }
        let result
        if (temp.reduce((accumulator, currentValue) => accumulator + currentValue, 0) === 0) {
            result = false
        }
        else {
            result = true
        }
        return result
    }

    const handleExitScope = () => {
        setShowScopeData({ bool: false, laydown_id: null })
        laydownScopeRef.current = false
        laydownSvgRef.current = !laydownSvgRef.current
        setLaydown(produce((draft) => { draft.boolean = !laydown.boolean }))
    }

    return (
        <Container>
            {showScopeData.bool ?
                <Laydown laydownData={listLayout?.data} showScopeData={showScopeData.bool} currentLaydownId={laydownId} scopeMap={scopeMap} onExitScope={handleExitScope} />
                :
                <div className='flex justify-between overflow-auto w-full h-full'>
                    <div className='h-full' style={{ width: showProgress ? "calc(100vw - 465px)" : "100%" }}>
                        <div className='flex justify-between flex-column mt-5 ml-5'>
                            <div className='flex space-x-5'>
                                <div className='flex mr-10'>
                                    <div className='bg-gray-50 rounded-l-3xl border pl-3' style={{ width: "225px", height: "84px" }}>
                                        <p className='text-xs pt-5 pl-4'>{overallProgress !== "All" && overallProgress !== "None Selected" && listId?.construction[overallProgress]?.parent_activity_id ? listId?.construction[listId?.construction[overallProgress]?.parent_activity_id]?.name : "Construction Progress"}</p>
                                        {listId?.construction ?
                                            <ActivitySelectBox
                                                defaultValue={localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : overallProgress}
                                                placeholder="Select an Activity"
                                                className="pl-4 py-1.5 bg-gray-50 rounded focus:outline-none focus:border-gray-300 w-44 font-bold text-sm mt-1"
                                                Overall={true}
                                                NoneSelectedActivity={true}
                                                page="Dashboard"
                                                activities={listId?.construction}
                                                activities_disabled={false}
                                                sub_activities_disabled={false}
                                                multiselect={false}
                                                onChange={handleProgressChange} />
                                            :
                                            null}
                                    </div>
                                    <p className='bg-gray-50 rounded-r-3xl border pt-7 pl-5 font-bold text-red-550 text-xl' style={{ width: "80px", height: "84px" }}>
                                        {!loading ?
                                            overallProgress === "All" ?
                                                <>{ttlOverAllPercent()}%</>
                                                :
                                                overallProgress === "None Selected" ?
                                                    "-"
                                                    :
                                                    <>{roundOff(findCPprogress(parseInt(overallProgress)))}%</>

                                            :
                                            <p className='loader mt-1' style={{ width: "25px", height: "25px" }}></p>}
                                    </p>
                                </div>
                                <div className='flex border-none'>
                                    <div className='bg-gray-50 rounded-l-3xl border-r' style={{ width: "150px", height: "84px" }}>
                                        <p className='pl-7 pt-5 text-xs'>Counts</p>
                                        <p className='pt-3 pl-7 text-sm font-bold' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {countLoading ?
                                                <p className='loader' style={{ width: "15px", height: "15px" }}></p>
                                                :
                                                overallProgress === "All" || overallProgress === "None Selected" ?
                                                    <p className='font-bold text-xl ml-3'>-</p>
                                                    :
                                                    listPartsAtom.map((j) => {
                                                        return <>
                                                            {j.id === listId?.construction[overallProgress]?.part_id ?
                                                                j?.part_name
                                                                : null}
                                                        </>
                                                    })
                                            }
                                        </p>
                                    </div>
                                    {countLoading ?
                                        <div className='bg-gray-50 rounded-r-3xl' style={{ width: "180px", height: "84px" }}>
                                            <p className='loader m-auto mt-7' style={{ width: "25px", height: "25px" }}></p>
                                        </div>
                                        :
                                        <div>
                                            {overallProgress === "All" || overallProgress === "None Selected" ?
                                                <div className='flex font-bold text-xl pl-10 text-red-550 bg-gray-50 rounded-r-3xl' style={{ width: "180px", height: "84px" }}>
                                                    <p className='pt-7'>-</p>
                                                    <p className='px-3 text-4xl pt-5'>/</p>
                                                    <p className='pt-7'>-</p>
                                                </div>
                                                :
                                                <div className='flex font-bold text-xl justify-center px-5 min-w-[180px] text-red-550 bg-gray-50 rounded-r-3xl' style={{ height: "84px" }}>
                                                    <p className='pt-7'>{listblocks?.count?.deployed_part_count && listblocks?.count?.deployed_part_count[0]?.deployed_count !== null ? listblocks?.count?.deployed_part_count[0]?.deployed_count?.toLocaleString() : "-"}</p>
                                                    <p className='px-3 text-4xl pt-5'>/</p>
                                                    <p className='pt-7'>{listblocks?.count?.total_part_count?.toLocaleString()}</p>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='flex items-center space-x-4 justify-end'>
                                {!laydown.boolean &&
                                    <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleEnableSelectBlocks}>
                                        {selectedBlocks.bool ? "Clear Blocks" : "Select Blocks"}
                                    </button>
                                }
                                {selectedBlocks.bool &&
                                    <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleSelectAllBlocks}>
                                        Select All
                                    </button>
                                }
                                {showLaydownFlag() && !selectedBlocks.bool &&
                                    <div className='flex items-center space-x-4 mr-4 w-36'>
                                        {laydown.boolean && <button className='btn rounded-full border-none bg-red-550 text-white text-sm' onClick={handleAddLaydown}>{laydown.enableAdd ? "Cancel" : "+ Add new laydown"}</button>}
                                        {/* <button className='btn rounded-full text-white bg-red-550 cursor-pointer border-none text-sm px-4 py-1' disabled={laydown.enableAdd} onClick={() => handleSVGAppend()}>{laydown.boolean ? "Hide Laydown" : "Show Laydown"}</button> */}
                                        {/* <select className='cursor-pointer rounded-xl px-4 py-1 w-full bg-red-550 text-white py-3 text-ellipsis focus:outline-none hover:bg-red-500 appearance-none' onChange={(e) => handleChangeView(e)}>
                                            <option className='bg-white text-black ' value={'Mechanical'} selected >Mechanical view</option>
                                            <option className='bg-white text-black ' value={'Laydown'}>Laydown view</option>
                                        </select> */}
                                        {/* <select className="select select-bordered w-full max-w-xs border-none focus:outline-none rounded-full text-white bg-red-550" onChange={handleChangeView}>
                                            <option className='bg-white text-black' value={'Mechanical'} selected>Mechanical</option>
                                            <option className='bg-white text-black' value={'Laydown'}>Laydown</option>
                                        </select> */}
                                        <ViewByDropDown onSelect={handleChangeView} defaultValue="Mechanical Progress" />
                                    </div>
                                }
                                {!showProgress && (
                                    <div className='text-xl text-black border bg-gray-200 mx-2'>
                                        <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowProgress(true)} />
                                    </div>
                                )}
                                <Modal
                                    isOpen={layoutModal}
                                    onRequestClose={closeLayoutModal}
                                    style={customStylesLayout}
                                    contentLabel="Layout table">
                                    <div className='flex flex-col justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                                        <p>Inventory Log</p>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex flex-col'>
                                                <div className='flex space-x-4 mt-2 items-center'>
                                                    {editInventoryNameModal?.boolean ?
                                                        <input ref={updateLaydownInputRef} className='border rounded-xl px-1.5 py-0.5 text-base font-medium' defaultValue={handleFindLaydownDetail(editInventoryNameModal?.data)?.name} onBlur={(e) => handleUpdateLaydownName(e.target.value)} onKeyDown={(e) => {
                                                            if (e.keyCode === 13) {
                                                                handleUpdateLaydownName(e.target.value)
                                                            }
                                                        }} />
                                                        :
                                                        <p className='font-medium text-base border border-white py-0.5'>{handleFindLaydownName(laydownId)}</p>
                                                    }
                                                    <MdModeEditOutline className='cursor-pointer' onClick={() => setEditInventoryNameModal({ boolean: true, data: laydownId })} />
                                                </div>
                                            </div>
                                            <div className='flex items-center space-x-4'>
                                                {manage === 1 && <p className='cursor-pointer text-blue-400 underline underline-offset-4 text-sm font-medium' onClick={() => { setShowScopeData({ bool: true, laydown_id: laydownId }); laydownScopeRef.current = true }}>View / Edit scope</p>}
                                                <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 border-red-100 mr-4'>
                                                    <div className={`flex items-center ${manage === 0 ? "bg-red-550 border-none rounded-full" : ""} px-1`} onClick={() => setManage(0)}>
                                                        <p className={`${manage === 0 ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} mr-1`}>Manage as a Store</p>
                                                    </div>
                                                    <div className={`flex ${manage === 1 ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => setManage(1)}>
                                                        <p className={`${manage === 1 ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`}>
                                                            <p>Auto-Manage through Scope</p>
                                                        </p>
                                                    </div>
                                                </div>

                                                <button className='cursor-pointer flex font-semibold text-sm' onClick={handleDownloadLaydown}>
                                                    <CgSoftwareDownload className='w-7 h-7' />
                                                </button>
                                                <button onClick={handleDeleteConfirmation}><MdDelete className='w-7 h-7' /></button>
                                                <VscClose onClick={closeLayoutModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    {viewScope && manage === 1 ? <div style={{ paddingBottom: "2%" }}>
                                        <div style={{ display: "inline-block" }}><ScopeSelect set_scope={(sc) => setscp(sc)} /></div>
                                        <button className='btn bg-red-550 ' style={{ display: "inline-block", margin: "2%", marginLeft: "15%" }} onClick={async () => { await setLaydownScope({ project: project_name?.project_number, laydown: laydownId, scope: scp }) }}>Save</button>
                                    </div> : null}
                                    {inventoryLoader ?
                                        <div className='absolute ml-[45%] mt-[15%]'>
                                            <p className='loader ml-7 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                            <p className='font-bold text-xl'>Fetching...</p>
                                        </div>
                                        :
                                        manage === 0 ?
                                            <div>
                                                {listLayout.bomLayoutData.length === 0 ?
                                                    <div>
                                                        <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl mt-32' />
                                                        <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                    </div>
                                                    :
                                                    <table className='table table-compact z-0 relative overflow-auto w-full'>
                                                        <thead>
                                                            <tr className='sticky z-20' style={{ top: "113px" }}>
                                                                <th className='normal-case w-[13%]'>Part Number</th>
                                                                <th className='normal-case w-[47%]'>Part Description</th>
                                                                <th className='normal-case w-[12%]'>
                                                                    <div className='flex space-x-2 items-center'>
                                                                        <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_current_qty`} />
                                                                        <p>Current Qty</p>
                                                                    </div>
                                                                </th>
                                                                <th className='normal-case w-[12%]'>
                                                                    <div className='flex space-x-2 items-center'>
                                                                        <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_damaged_qty`} />
                                                                        <p>Damaged Qty</p>
                                                                    </div>
                                                                </th>
                                                                <th className='normal-case w-[12%]'>
                                                                    <div className='flex space-x-2 items-center'>
                                                                        <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_damaged_qty`} />
                                                                        <p>Remediated Qty</p>
                                                                    </div>
                                                                </th>
                                                                <th className='normal-case w-[4%]'></th>
                                                            </tr>
                                                        </thead>
                                                        <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_current_qty`}>
                                                            <p className='w-[300px]'>
                                                                {`Current quantity is total quantity of the part currently available in the laydown, it includes damaged quantity, calculated by subtracting built (issued) quantities from delivered quantities`}
                                                            </p>
                                                        </ReactTooltip>
                                                        <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_damaged_qty`}>
                                                            <p className='w-[300px]'>
                                                                {`Damaged quantity is the total quantity of the part reported as damaged while adding delivery records in Bill of materials page`}
                                                            </p>
                                                        </ReactTooltip>
                                                        {bomLaydownLoader ?
                                                            <div className='absolute ml-[45%] mt-[15%]'>
                                                                <p className='loader ml-7 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                                <p className='font-bold text-xl'>{loadingMsg ? "Updating" : "Fetching"}...</p>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {listLayout.bomLayoutData.length === 0 &&
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='py-14'>
                                                                        <div className='m-auto'>
                                                                            <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                                            <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                        {listLayout.bomLayoutData.length && listLayout.bomLayoutData?.map((i) => {
                                                            return (
                                                                <tbody>
                                                                    <tr className={`${bomLaydownLoader ? "opacity-20" : null}`}>
                                                                        <td>{i?.part_name}</td>
                                                                        <td className='pr-12 relative max-w-[28vw]' onMouseEnter={() => { setShowDescription(i?.bom_laydown_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                                            {showDescription === i?.bom_laydown_id ?
                                                                                <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                                    {i?.description}
                                                                                </p>
                                                                                :
                                                                                <p className='max-w-[28vw]' style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", zIndex: 0 }}>
                                                                                    {i?.description}
                                                                                </p>
                                                                            }
                                                                        </td>
                                                                        <td>{i?.count}</td>
                                                                        <td>{i?.damaged ? i?.damaged : 0}</td>
                                                                        <td>
                                                                            <div className='flex items-center'>
                                                                                <p className='pr-5'>{i?.remediated_quantity === null ? "0" : i?.remediated_quantity}</p>
                                                                                <RiEdit2Fill onClick={() => handleOpenEditRemediateQty(i)} />
                                                                            </div></td>
                                                                        <td>
                                                                            <div className='flex space-x-4'>
                                                                                {expanded === i?.bom_laydown_id ?
                                                                                    <BsChevronUp className='cursor-pointer' onClick={() => { setExpanded(null); setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" }] })) }} />
                                                                                    :
                                                                                    <BsChevronDown className='cursor-pointer' onClick={() => handleBomLaydownId(i)} />
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {expanded === i?.bom_laydown_id && !bomLaydownLoader ?
                                                                        <td colSpan="6">
                                                                            {expanded === i?.bom_laydown_id && !bomLaydownLoader ?
                                                                                <div className='border pb-3 w-full'>
                                                                                    <NestedTable
                                                                                        className="min-h-[265px]"
                                                                                        bom_id={addEntry.data[0].bom_id}
                                                                                        handleSaveEdit={(e, epoch) => handleSaveEdit(e, epoch)}
                                                                                        handleDeleteInventory={(laydown_id, data) => handleDeleteInventory(laydown_id, data)}
                                                                                        laydownId={laydownId}
                                                                                        nestedData={listLayout.bomInventoryData.Inventory_logs}
                                                                                        addEntry={addEntry.boolean}
                                                                                        destinationLaydown={listLayout.data.filter(o => o.id !== laydownId)}
                                                                                        onAdjustmentChange={e => setAddEntry(produce((draft) => { draft.data[0].received_quantity = e }))}
                                                                                        onIssuedChange={e => setAddEntry(produce((draft) => { draft.data[0].issued = e }))}
                                                                                        onActionChange={e => setAddEntry(produce((draft) => { draft.data[0].purpose_code = e }))}
                                                                                        onDesitantionChange={e => setAddEntry(produce((draft) => { draft.data[0].destination = e }))}
                                                                                        onCommentsChange={e => setAddEntry(produce((draft) => { draft.data[0].comments = e }))}
                                                                                        onDateChange={e => setAddEntry(produce((draft) => { draft.data[0].time = e }))}
                                                                                    />
                                                                                    <div className='flex justify-between py-3 w-[95%] m-auto'>
                                                                                        <div className='flex py-3 rounded-2xl space-x-10 items-center'>
                                                                                            <p className='text-sm pl-3 font-bold'>Current Quantity</p>
                                                                                            <p className='text-sm'>{listLayout.bomInventoryData.current_quantity}</p>
                                                                                        </div>
                                                                                        {addEntry.boolean ?
                                                                                            <div className='flex space-x-5 px-2 justify-end'>
                                                                                                <button className='btn bg-red-550 text-white rounded-full px-6 border-none' onClick={handleAddBomInventory} disabled={handleCheckBomInventorySave()}>Save</button>
                                                                                                <button className='btn bg-red-550 text-white rounded-full px-4 border-none' onClick={() => setAddEntry(produce((draft) => { draft.boolean = false; draft.data[0] = { bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" } }))}>Cancel</button>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        {!addEntry.boolean && <button className='btn bg-red-550 text-white rounded-full px-4 mx-2 border-none' onClick={() => { setAddEntry(produce((draft) => { draft.boolean = true })) }}>+ Add Entry</button>}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </td>
                                                                        :
                                                                        <hr />
                                                                    }
                                                                </tbody>
                                                            )
                                                        })}
                                                    </table>
                                                }
                                            </div>
                                            :
                                            <table className='table table-compact z-0 relative overflow-auto w-full'>
                                                <thead>
                                                    <tr>
                                                        <th className='normal-case' rowSpan='2'>Part Number</th>
                                                        <th className='normal-case' rowSpan='2'>Part Description</th>
                                                        <th className='normal-case text-center' colspan='5'>Quantities</th>
                                                    </tr>
                                                    <tr>
                                                        <th className='normal-case'>Required Qty</th>
                                                        <th className='normal-case'>Delivered Qty</th>
                                                        <th className='normal-case'>Distributed Qty</th>
                                                        <th className='normal-case'>Installed Qty</th>
                                                        <th className='normal-case'>Current Qty</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listLayout?.scopeLaydownData?.bom_laydown_data?.length && listLayout?.scopeLaydownData?.bom_laydown_data?.map((scopeData) => {
                                                        return (
                                                            <tr>
                                                                <td>{scopeData?.part_number}</td>
                                                                <td className='pr-12 relative py-2 max-w-[23vw]' onMouseEnter={() => { setShowDescription(scopeData?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                                    {showDescription === scopeData?.bom_id ?
                                                                        <p className='border px-5 absolute bg-white top-2 z-10' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                            {scopeData?.part_description}
                                                                        </p>
                                                                        :
                                                                        <p className='w-[22vw]' style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{scopeData?.part_description}</p>
                                                                    }
                                                                </td>
                                                                <td>{scopeData?.required_qty ? scopeData?.required_qty : 0}</td>
                                                                <td>{scopeData?.delivered_qty ? scopeData?.delivered_qty : 0}</td>
                                                                <td>{scopeData?.distributed_qty ? scopeData?.distributed_qty : 0}</td>
                                                                <td>{scopeData?.installed_qty ? scopeData?.installed_qty : 0}</td>
                                                                <td>{scopeData?.current_qty ? scopeData?.current_qty : 0}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                    }
                                    <Modal
                                        isOpen={openEditRemediateQty}
                                        onRequestClose={handleCloseEditRemediateQty}
                                        style={customStylesEditRemediatyQty}
                                        contentLabel="BOM status"
                                    >
                                        <div className='p-3 w-[50vw]'>
                                            <div className='flex justify-between items-center m-2'>
                                                <div >
                                                    <p className='mt-4 font-bold text-xl'>
                                                        {remediateQtyBomDetails?.part_name}
                                                    </p>
                                                    <p className='mt-2'>{remediateQtyBomDetails?.description}</p>
                                                </div>
                                                <button className='bg-red-550 text-white border-none rounded-3xl btn drawer-button px-6' onClick={handleClickRemediateQtyButton}>{isInventryRemediateQtyEdit ? "Save" : "Edit"}</button>
                                            </div>
                                            <table className='table table-compact w-full overflow-auto z-0 h-full my-3'>
                                                <thead>
                                                    <tr>
                                                        <th className='normal-case bg-red-200 w-[40%]'>{getDeliveryReferenceName() !== "" ? getDeliveryReferenceName() : "Delivery Reference"}</th>
                                                        <th className='normal-case bg-red-200 w-[20%]'>Delivered Qty</th>
                                                        <th className='normal-case bg-red-200 w-[20%]'>Damaged Qty</th>
                                                        <th className='normal-case bg-red-200 w-[20%]'>Remediated Qty</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listMileStone?.qtyDataArr && Object.keys(listMileStone?.qtyDataArr).map((laydownIdKey, index) => {
                                                        if (parseInt(laydownIdKey) === inventryLaydownId) {
                                                            return (
                                                                <tr>
                                                                    <td>{listMileStone?.qtyDataArr[laydownIdKey]?.name}</td>
                                                                    <td>{listMileStone?.qtyDataArr[laydownIdKey]?.total_received_quantity}</td>
                                                                    <td>{listMileStone?.qtyDataArr[laydownIdKey]?.damaged_quantity}</td>
                                                                    <td>
                                                                        {isInventryRemediateQtyEdit ?
                                                                            <input className='border w-[98%] p-1' placeholder='Remediated Qty' defaultValue={listMileStone?.qtyDataArr[laydownIdKey]?.remediated_quantity ? listMileStone?.qtyDataArr[laydownIdKey]?.remediated_quantity === null ? 0 : listMileStone?.qtyDataArr[laydownIdKey]?.remediated_quantity : 0} onChange={e => handleChangeRemediatedQty(e, index)} />
                                                                            : <p lassName='border border-white'>{listMileStone?.qtyDataArr[laydownIdKey]?.remediated_quantity === null ? 0 : listMileStone?.qtyDataArr[laydownIdKey]?.remediated_quantity}</p>}

                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Modal>
                                </Modal>
                                <Modal
                                    isOpen={confimationModal}
                                    style={customConfirmStyle}
                                    contentLabel='Laydown Confirmation'
                                >
                                    <div className='p-2 w-[40vw]'>
                                        <div className='flex justify-between mb-4'>
                                            <p className='text-xl font-semibold'>Delete Laydown</p>
                                            <VscClose className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} onClick={() => setConfirmationModal(false)} />
                                        </div>
                                        <hr />
                                        {handleCheckQuantity() ?
                                            <p className='my-4'>You are deleting the laydown with some current quantities and / or damaged quantities reported, this deletion will modify the initial delivery records accordingly. You may download the inventory log for your reference if required</p>
                                            :
                                            <p className='my-4'>Deleting the laydown will delete all the associated inventory log as well. Please download the log for your reference if required and proceed with deletion if needed</p>
                                        }
                                        <hr />
                                        <div className='mt-4 flex flex-col space-y-3'>
                                            <p className='text-right cursor-pointer text-gray-500 font-semibold' onClick={() => setConfirmationModal(false)}>Cancel</p>
                                            <p className='text-right cursor-pointer font-semibold text-black' onClick={handleDownloadLaydown}>Download inventory log</p>
                                            <p className='text-right cursor-pointer text-red-550 font-semibold' onClick={handleDeleteLaydown}>Understood, proceed with deletion</p>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    isOpen={addLayoutmodal.boolean}
                                    style={customStylesAddLayout}
                                    contentLabel="Add Layout table">
                                    <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                                        <p className='mt-2'>Add Laydown</p>
                                    </div>
                                    <hr />
                                    <form className='w-[400px] m-auto' onSubmit={handleSubmit(createLayoutSubmit)}>
                                        <div className='flex justify-between space-x-5'>
                                            <p className='mt-7'>Name :</p>
                                            <input required className="appearance-none w-[80%] block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7" placeholder='Enter Laydown Name' {...register("name")} />
                                        </div>
                                        <div className='flex justify-end space-x-5 mt-5'>
                                            <p className='btn rounded-full px-4 cursor-pointer bg-white border-2 text-gray-500 hover:text-white' onClick={closeAddLayoutModal}>Cancel</p>
                                            {addlayoutLoader ?
                                                <button
                                                    className="border-none rounded-3xl bg-red-550 text-white btn loading"
                                                >
                                                    Creating
                                                </button>
                                                :
                                                <input type='submit' value={"Create"} className='bg-red-550 text-white px-4 py-2 rounded-3xl cursor-pointer' />
                                            }
                                        </div>
                                    </form>
                                </Modal>
                            </div>
                        </div>
                        <div
                            style={{ height: "calc(100vh - 270px)" }}
                        >
                            <div className='w-full' style={{ display: laydown.boolean ? "" : "none", height: "calc(100vh - 270px)" }}>
                                {laydown.boolean &&
                                    <TransformWrapper
                                        centerZoomedOut
                                        // centerOnInit
                                        minScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale)}
                                        initialScale={0.5}
                                        initialPositionX={((window.innerWidth - 760) - (parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale) * project_name?.svg_dimension?.web?.web_svg_width)) / 2}
                                        initialPositionY={((window.innerHeight - 350) - (parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale) * project_name?.svg_dimension?.web?.web_svg_height) + 100) / 2}
                                        onZoom={e => setSvgScale(e?.state?.scale)}
                                    >
                                        <TransformComponent
                                            contentStyle={{ width: `${project_name?.svg_dimension?.web?.web_svg_width}px` }}
                                            wrapperStyle={{ height: "100%", width: "100%" }}
                                        >
                                            <div className='m-auto inline-block align-middle'>
                                                <div className="svgmap svgParentDiv flex items-center justify-center cursor-pointer" onClick={e => handleOpenLaydown(e)} style={{ height: `50%`, width: `50%`, position: "relative" }} dangerouslySetInnerHTML={{ __html: listblocks.map }}></div>
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>
                                }
                            </div>
                            {!laydown.boolean &&
                                <div className='cursor-grab w-full' style={{ display: !laydown.boolean ? "" : "none", height: "calc(100vh - 270px)" }}>
                                    <Svg project_name={project_name} svgMap={svgMap} className="svgSiblingDiv" />
                                </div>
                            }
                        </div>
                        <div className='relative w-[100%]'>
                            {selectedBlocks?.bool &&
                                <div className={`absolute animate-slide-up flex ${selectedBlocks?.bool ? "bottom-14" : "bottom-[-150px]"}`} style={{ left: "calc(50% - 100px)", display: selectedBlocks?.bool ? "" : "none" }}>
                                    <p className='text-white bg-black border px-4 py-2 rounded-2xl'>Click on any block to select</p>
                                </div>
                            }
                            <div className={`absolute animate-slide-up flex ${laydown.enableAdd ? "bottom-14" : "bottom-[-150px]"}`} style={{ left: "calc(50% - 200px)", display: laydown.enableAdd ? "" : "none" }}>
                                <p className='text-white bg-black border px-4 py-2 rounded-2xl'>Select any area of the map to set the laydown point</p>
                            </div>
                            <div className='flex justify-center mt-3'>
                                <span data-tip data-for="svg" className='mt-1 ml-4'>
                                    <AiOutlineInfoCircle />
                                </span>
                                <p className='text-sm text-gray-400 mt-0.5 ml-2'>
                                    <p className='text-sm text-gray-400 mt-0.5 ml-2'>
                                        Double click on any block to dive in
                                    </p>
                                </p>
                            </div>
                            <div className='flex justify-center mt-1.5'>
                                <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#66f1ad" }}></p>
                                <p className='mr-2'>Completed</p>
                                <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#FCDDD9" }}></p>
                                <p className='mr-2'>In progress</p>
                                <p className='w-5 h-5 border border-1 border-black rounded-full mx-2 mt-0.5 bg-white' style={{ backgroundColor: "FFFFFF" }}></p>
                                <p>Not Started</p>
                            </div>
                        </div>
                    </div>
                    {showProgress && <div className='border-x h-full'>
                        <div className='overflow-auto relative h-full pr-2' style={{ height: "calc(100vh - 100px)", width: "445px" }} >
                            <div className='flex w-full bg-white text-blue-500 font-semibold text-sm py-14 px-6'>
                                <div className='text-xl text-black border bg-gray-200 mx-2'>
                                    <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowProgress(false)} />
                                </div>
                                <div className='mr-10'>
                                    {selectedBlocks?.bool ?
                                        <div className={`flex ${selectedBlocks?.data?.length ? "cursor-pointer" : "text-gray-400"}`} onClick={handleSelectedBomBlock}>
                                            <p>BOM Status for Selected Blocks</p>
                                            {bomLoading ?
                                                <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                                :
                                                <BsBoxArrowUpRight alt='' className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                                            }
                                        </div>
                                        :
                                        <div className='flex cursor-pointer' onClick={openModal}>
                                            <p>BOM Status for Project</p>
                                            {bomLoading ?
                                                <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                                                :
                                                <BsBoxArrowUpRight alt='' className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="BOM status"
                            >
                                <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                                    <p className='mt-2'>Bill of Materials - {selectedBlocks?.bool ? "Selected Blocks" : "Project"}</p>
                                    <div className='flex'>
                                        <div className='flex font-semibold text-sm mr-10 mt-1 cursor-pointer' onClick={selectedBlocks?.bool ? handleDownloadSelectedBlockBom : handleDownload}>
                                            <CgSoftwareDownload className='text-gray-500' style={{ width: '20px', height: '20px' }} />
                                            <p className='text-gray-500 ml-1.5'>Download</p>
                                        </div>
                                        <VscClose onClick={closeModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                                    </div>
                                </div>
                                {/* <TableComp
                                heading={BOM_FOR_BLOCKS?.heading}
                                secondHeading={BOM_FOR_BLOCKS?.secondHeading}
                                className="z-0 overflow-auto w-full"
                                data={listblocks?.totalBOM}
                                dataKey={BOM_FOR_BLOCKS?.dataKey}
                                hover={true}
                            /> */}
                                <table className='z-0 overflow-auto w-full'>
                                    <thead>
                                        {selectedBlocks?.bool ?
                                            <tr className='sticky z-20' style={{ top: "76px" }}>
                                                <th className='text-sm text-left py-2 pl-2 w-[14%]' rowSpan='2'>Selected Blocks</th>
                                                <th className='text-sm text-left py-2 w-[12%]' rowSpan='2'>Part Number</th>
                                                <th className='text-sm text-left py-2 w-[30%]' rowSpan='2'>Part Description</th>
                                                <th className='text-sm text-left py-2 w-[12%]' rowSpan='2'>Required Qty</th>
                                                <th className='text-sm text-left py-2 w-[16%]' colspan="2">Distributed</th>
                                                <th className='text-sm text-left py-2 w-[16%]' colspan="2">Installed</th>
                                            </tr>
                                            :
                                            <tr className='sticky z-20' style={{ top: "76px" }}>
                                                <th className='text-sm text-left py-2 pl-2 w-[8%]' rowSpan='2'>Part Number</th>
                                                <th className='text-sm text-left py-2 w-[22%]' rowSpan='2'>Part Description</th>
                                                <th className='text-sm text-left py-2 w-[8%]' rowSpan='2'>Project Qty</th>
                                                <th className='text-sm text-left py-2 w-[8%]' rowSpan='2'>Construction<br />Spares</th>
                                                <th className='text-sm text-left py-2 w-[10%]' rowSpan='2'>Addl Qty<br />Incl.O&M Spares</th>
                                                <th className='text-sm text-left py-2 w-[7%]' rowSpan='2'>Total<br />Quantity</th>
                                                <th className='text-sm text-left py-2 w-[7%]' rowSpan='2'>On-site Qty</th>
                                                <th className='text-sm text-left py-2 w-[8%]' rowSpan='2'>Stock Qty</th>
                                                <th className='text-sm text-left py-2 w-[11%]' colspan="2">Distributed</th>
                                                <th className='text-sm text-left py-2 w-[11%]' colspan="2">Installed</th>
                                            </tr>
                                        }
                                        <tr className='sticky z-20 border-t border-white' style={{ top: "112.5px" }}>
                                            <th className='text-sm text-left py-2'>%</th>
                                            <th className='text-sm text-left py-2'>Qty</th>
                                            <th className='text-sm text-left py-2'>%</th>
                                            <th className='text-sm text-left py-2'>Qty</th>
                                        </tr>
                                    </thead>
                                    {selectedBlocks?.bool ?
                                        <tbody>
                                            {listblocks?.blocksBOM.map((i, index) => {
                                                return (
                                                    <tr className='hover:bg-gray-200 text-sm border-b-2 border-gray-100'>
                                                        <td className='py-2 pl-2'>{listblocks?.blocks?.find(o => o?.id === selectedBlocks?.data[index])?.name}</td>
                                                        <td className='py-2' style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                                                        <td className='pr-12 relative py-2' style={{ width: "24vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                            {showDescription === i?.bom_id ?
                                                                <p className='border px-5 absolute bg-white top-2 z-10' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                    {i?.description}
                                                                </p>
                                                                :
                                                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "23vw" }}>{i?.description}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">{i?.project_quantity_calc ? i?.project_quantity_calc : 0}</td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                :
                                                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                :
                                                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_progress)}%</p>
                                                                :
                                                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_quantity)}</p>
                                                                :
                                                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        :
                                        <tbody>
                                            {listblocks?.totalBOM.map((i) => {
                                                return (
                                                    <tr className='hover:bg-gray-200 text-sm border-b-2 border-gray-100'>
                                                        <td className='py-2 pl-2' style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                                                        <td className='pr-12 relative py-2' style={{ width: "18vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                            {showDescription === i?.bom_id ?
                                                                <p className='border px-5 absolute bg-white top-2 z-10' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                    {i?.description}
                                                                </p>
                                                                :
                                                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "17vw" }}>{i?.description}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">{i?.default_project_quantity ? i?.default_project_quantity : 0}</td>
                                                        <td className="py-2">{i?.spare_count}</td>
                                                        <td className="py-2">{i?.additional_count ? i?.additional_count : 0}</td>
                                                        <td className="py-2">{i?.project_quantity}</td>
                                                        <td className="py-2">{i?.received_quantity ? i?.received_quantity : 0}</td>
                                                        <td className="py-2">{i?.current_quantity}</td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distribution_progress)}%</p>
                                                                :
                                                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.distribution_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.distributed_quantity)}</p>
                                                                :
                                                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_progress)}%</p>
                                                                :
                                                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                                            }
                                                        </td>
                                                        <td className="py-2">
                                                            {roundOff(i?.installed_progress) === 100 ?
                                                                <p style={{ backgroundColor: "#66f1ad" }}>{roundOff(i?.installed_progress)}</p>
                                                                :
                                                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                                                    <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                                                    :
                                                                    <p style={{ color: "black" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </Modal>
                            <div className='border rounded-xl ml-6 p-3' style={{ width: '400px' }}>
                                <div>
                                    <div className='flex justify-between font-bold text-base'>
                                        <p>Construction Progress</p>
                                        <p className='text-red-550'>
                                            {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                {ttlOverAllPercent()}%</>}
                                        </p>
                                    </div>
                                    <div className='font-medium text-xs'>
                                        {loading ?
                                            <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                <p className='font-medium text-sm'>Fetching...</p>
                                            </div>
                                            :
                                            finalCPArr.map((i) => {
                                                return (
                                                    <div className='flex justify-between mt-4'>
                                                        {listId.construction[i]?.parent_activity_id ?
                                                            <p className='flex pl-4 w-[155px]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                            :
                                                            <p className='font-extrabold w-[155px]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                                                        }
                                                        <div className='flex justify-between' style={{ width: '215px' }}>
                                                            <progress className={`progress w-40 mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                                                            <p>{roundOff(findCPprogress(i))}%</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`border rounded-xl ml-6 mt-6 p-3`} style={{ width: '400px' }}>
                                <div>
                                    <div className='flex justify-between font-bold text-base'>
                                        <p>Quality Check Progress</p>
                                        <p className='text-red-550'>
                                            {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                                                {qcCheck() ? ttlOverAllQCPercent() : 0}%</>}
                                        </p>
                                    </div>
                                    {qcCheck() ?
                                        <div className='font-medium text-xs'>
                                            {loading ?
                                                <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                    <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                    <p className='font-medium text-sm'>Fetching...</p>
                                                </div>
                                                :
                                                finalQCArr.map((i) => {
                                                    return (
                                                        <div className='flex justify-between mt-4'>
                                                            {listId.qc[i]?.parent_activity_id ?
                                                                <p className='flex pl-4 w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                                                :
                                                                <p className='font-extrabold w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                                                            }
                                                            <div className='flex justify-between' style={{ width: '215px' }}>
                                                                <progress className={`progress w-40 mt-1 ${findQCprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findQCprogress(i)} max="100"></progress>
                                                                <p>{roundOff(findQCprogress(i))}%</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '400px' }}>
                                <div>
                                    <div className='flex justify-between font-bold text-base'>
                                        <p>{handleCheckZimmerman() ? "Table Type" : "Tracker Type"}</p>
                                        <p>Quantities</p>
                                    </div>
                                    <div className='font-medium text-xs'>
                                        {loading ?
                                            <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                                                <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                                                <p className='font-medium text-sm'>Fetching...</p>
                                            </div>
                                            :
                                            trackerSort.map((i) => {
                                                return (
                                                    <div className='flex justify-between mt-4'>
                                                        {i?.name.split("+_+").length === 2 ?
                                                            <p className='pl-4'>{i?.name.split("+_+")[0]}</p>
                                                            :
                                                            <p className='font-extrabold'>{i?.name}</p>
                                                        }
                                                        <p className='mr-1'>{i?.count}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            }
        </Container>
    )
}

export default BlockList
import React from 'react'
import LoginContainer from '../components/loginContainer'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'

function InviteUser() {

    const {register, handleSubmit, reset} = useForm()

    const onSubmit = async(data) => {
        try{
        }
        catch(err){
            console.log(err)
            toast.error("Something went wrong")
        }
    }

  return (
    <div>
        <LoginContainer/>
        <div className='w-screen h-screen flex flex-col items-center justify-center'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <p className='font-bold text-2xl pb-5'>Invite User</p>
                    <label>
                        <p>New Password</p>
                        <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-9 ' required style={{ width: '432px', height: '64px' }} type="password" placeholder="Enter New Password" {...register('new_password')} />
                    </label>
                    <label>
                        <p>Confirm Password</p>
                        <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-9 ' required style={{ width: '432px', height: '64px' }} type="password" placeholder="Re-Enter New Password" {...register('re_new_password')} />
                    </label>
                    <p></p>
                    <input type="submit" value="Save" className='border-none rounded-full bg-red-550 text-white btn' style={{ width: '432px', height: '59px' }} />
                </div>
            </form>
        </div>
    </div>
  )
}

export default InviteUser

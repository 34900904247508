import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { BsChevronDown, BsChevronUp } from "react-icons/bs"

const ActivitySelect = ({
    placeholder,
    className,
    Overall,
    NoneSelectedActivity,
    NoSelection,
    activities,
    defaultValue,
    activities_disabled,
    sub_activities_disabled,
    multiselect,
    onChange,
    from
}) => {

    const rearrangeData = (data) => {
        const result = [];

        // Step 1: Find activities with null parent_activity_id
        const activitiesWithNullParent = Object.values(data).filter(activity => activity.parent_activity_id === null);

        // Step 2: Loop through activities and their corresponding subactivities
        activitiesWithNullParent.forEach(activity => {
            const { id } = activity;
            result.push(activity)

            const subactivities = Object.values(data).filter(activity => activity.parent_activity_id === id);
            subactivities.forEach(subactivity => {
                result.push(subactivity)
            });
        });
        return result;
    };

    const [selectedItems, setSelectedItems] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showAbove, setShowAbove] = useState(false)
    const dropdownRef = useRef(null);
    const optionsRef = useRef(null)

    const calculatePosition = useCallback(() => {
        if (dropdownRef.current && optionsRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const optionsRect = optionsRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            const spaceBelow = viewportHeight - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;

            setShowAbove(spaceBelow < optionsRect.height && spaceAbove > spaceBelow);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            calculatePosition();
            window.addEventListener('resize', calculatePosition);
            return () => window.removeEventListener('resize', calculatePosition);
        }
    }, [isOpen, calculatePosition]);

    const handleItemChange = (itemId) => {
        if (itemId !== 'All' && itemId !== "None Selected") {
            let updatedItems = [];

            if (multiselect) {
                if (selectedItems.includes(itemId)) {
                    if (activities[itemId].parent_activity_id) {
                        //De-Selecting Sub-Activity 
                        updatedItems = selectedItems.filter((item) => item !== itemId)
                    }
                    else {
                        // De-Selecting Parent Activity
                        Object.keys(activities).forEach((key) => {
                            const activity = activities[key];
                            if (activity.parent_activity_id === itemId || selectedItems.includes(parseInt(key))) {
                                delete activity.selected; // Remove the 'selected' property from the activity object
                            }
                        });
                        updatedItems = selectedItems.filter((item) => item !== itemId && activities[item]?.parent_activity_id !== itemId);
                    }
                } else {
                    if (activities[itemId].parent_activity_id) {
                        updatedItems = [...selectedItems, itemId];
                    } else {
                        const subactivities = Object.values(activities).filter(
                            (activity) => activity.parent_activity_id === itemId
                        );
                        const subactivityIds = subactivities.map((subactivity) => subactivity.id);
                        updatedItems = [...selectedItems, itemId, ...subactivityIds];
                    }
                }
            } else {
                updatedItems = [itemId];
            }

            updatedItems = [...new Set(updatedItems)];
            setSelectedItems(updatedItems);
            if (updatedItems.length === 0) {
                onChange('All')
            }
            else {
                onChange(updatedItems);
            }
        }
        else {
            setSelectedItems([]);
            onChange([]);
        }
    };

    const handleFocus = () => {
        setIsOpen(!isOpen)
    };

    const handleActivityClick = (itemId) => {
        if (itemId !== 'All' && itemId !== "None Selected") {
            if (multiselect) {
                handleItemChange(itemId);
            } else {
                setSelectedItems([itemId]);
                setIsOpen(false);
                onChange([itemId]);
            }
        }
        else {
            handleItemChange(itemId)
            onChange(itemId)
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatSelectedNames = () => {
        if (selectedItems.length === 0) {
            if (defaultValue !== "All" && defaultValue !== null && defaultValue && activities[defaultValue]?.name) {
                return activities[defaultValue]?.name
            }
            else {
                if (defaultValue === "All") {
                    return "Overall"
                }
                if (defaultValue === "None Selected") {
                    return "None Selected"
                }
            }
        }
        else if (multiselect) {
            if (selectedItems.length === 0) {
                return selectedItems
                    .map((itemId) => activities[itemId]?.name)
                    .filter(Boolean)
                    .join(', ');
            }
            else {
                if (from === "CP") {
                    return "Selected Activities"
                }
                if (from === "QC") {
                    return "Selected Checkpoints"
                }
            }
        }
        else {
            return activities[selectedItems[0]]?.name || '';
        }
    };

    const renderActivity = (activity) => {
        const { id, name, parent_activity_id } = activity;
        const isSubActivity = parent_activity_id !== null;
        const marginLeft = isSubActivity ? 'ml-5' : '';

        const isActive = selectedItems.includes(id);

        let disabled = false

        if ((sub_activities_disabled && parent_activity_id !== null) || (activities_disabled && parent_activity_id === null)) {
            disabled = true; // Disable selection if sub_activities_disabled is true or activities_disabled is true
        }

        return (
            <li
                key={id}
                className={`flex 
                    ${parent_activity_id ? "font-medium" : "font-extrabold"} items-center 
                    ${marginLeft} 
                    ${isActive ? 'bg-gray-200' : 'hover:bg-gray-100'}  
                    ${activities_disabled && parent_activity_id === null ? "hover:bg-white" : "cursor-pointer"} 
                    ${disabled ? "text-gray-400" : "text-black"}`}
                onClick={() => {
                    if (!disabled) {
                        handleActivityClick(id)
                    }
                }}
                disabled={disabled}
                style={{ textTransform: "capitalize" }}
            >
                {multiselect && (
                    <input
                        type="checkbox"
                        checked={isActive}
                        onChange={() => handleItemChange(id)}
                    />
                )}
                <span className={`ml-2 flex ${activities_disabled && !parent_activity_id ? 'text-gray-400' : ''} ${disabled ? "text-gray-400" : "text-black"}`}>
                    <p className={`${parent_activity_id ? "pl-1" : null}`}>{name}</p>
                </span>
            </li>
        );
    };

    console.log(showAbove)

    return (
        <div className="relative flex justify-between" ref={dropdownRef}>
            <div>
                <input
                    type="text"
                    className={`cursor-pointer ${className}`}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    placeholder={placeholder}
                    onClick={handleFocus}
                    value={formatSelectedNames()}
                    readOnly
                />
                {isOpen && (
                    <ul ref={optionsRef} className={`absolute z-10 bg-white ${showAbove ? 'bottom-full mb-1' : 'top-full mt-1'} shadow mt-1 py-2 ${multiselect ? "px-5 " : null} w-full max-h-40 overflow-y-auto border border-gray-300`}>

                        {multiselect ?
                            <li className={`cursor-pointer hover:bg-gray-100`} onClick={() => handleActivityClick('All')}>
                                <span value='All'>All {from === "CP" ? "Activities" : "Checkpoints"}</span>
                            </li>
                            :
                            null
                        }

                        {NoneSelectedActivity ?
                            <li className={`cursor-pointer hover:bg-gray-100 pl-2`} onClick={() => { handleActivityClick('None Selected'); setIsOpen(!isOpen) }}>
                                <span value='None Selected'>None Selected</span>
                            </li>
                            :
                            null
                        }

                        {Overall ?
                            <li className={`cursor-pointer hover:bg-gray-100 pl-2`} onClick={() => { handleActivityClick('All'); setIsOpen(!isOpen) }}>
                                <span value='OverAll'>Overall</span>
                            </li>
                            :
                            null
                        }

                        {NoSelection ?
                            <li className={`cursor-pointer hover:bg-gray-100 pl-2 font-medium`} onClick={() => { handleActivityClick(null); setIsOpen(!isOpen) }}>
                                <span value='OverAll'>No Selection</span>
                            </li>
                            :
                            null
                        }

                        {Object.values(rearrangeData(activities)).map((activity) => renderActivity(activity))}
                    </ul>
                )}
            </div>
            {isOpen ? <BsChevronUp className="cursor-pointer absolute right-4 bottom-[18%]" onClick={handleFocus} /> : <BsChevronDown className="absolute right-4 bottom-[18%] cursor-pointer" onClick={handleFocus} />}
        </div>
    );
};

export default ActivitySelect
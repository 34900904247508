import React from 'react'
import { useLocation } from 'react-router-dom'

function ImageViewerComp() {
    const location = useLocation()
    return (
        <div>
            <img src={location.search.split("?url=")[1]} className='w-full h-full' />
        </div >
    )
}

export default ImageViewerComp

import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useEffect } from 'react'
import projectAtom, { fetchProjectAtom } from '../atoms/projectAtom'
import userAtom from '../atoms/userAtom'

function ProjectProvider(props) {

    const [loading, setLoading] = useState(false)
    const [projects] = useAtom(projectAtom);
    const [user] = useAtom(userAtom);
    const [, fetchProject] = useAtom(fetchProjectAtom)

    useEffect(() =>{
        init()
    },[])

    const init = async () => {
        try{
            setLoading(true)
            if(user.loggedIn){
                await fetchProject()
            }
        }
        catch(err){
            console.log(err)
        }
        finally{
            setLoading(false)
        }
    }

    // useEffect (() => {
    //     if(projects.length > 0 && !localStorage.getItem("project_id")) {
    //         localStorage.setItem("project_id",projects[0].id)
    //     }
    // },[projects])

    if(loading) {
        return <p className='loader m-auto' style={{marginTop:'22vw'}}></p>
    }

    if(projects.length < 1 && user.loggedIn ) {
        return <p> No Projects Available</p>
    }

  return (
    <div>{props.children}</div>
  )
}

export default ProjectProvider

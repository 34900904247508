import React from 'react'
import roundOff from '../utils/roundOff'

function TableComp(props) {

    const {
        heading,
        secondHeading,
        className,
        data,
        dataKey,
        hover
    } = props

    const roundOffData = ["distribution_progress",
        "distributed_quantity",
        "installed_progress",
        "installed_quantity"]

    return (
        <table className={className}>
            <thead>
                <tr className='sticky z-20' style={{ top: "76px" }}>
                    {heading.map((tableHead) => {
                        if (tableHead?.colspan === "0") {
                            return (
                                <th className={`text-sm text-left py-2 pl-2 ${tableHead?.className}`} rowSpan={tableHead?.rowSpan} >
                                    {tableHead?.name}
                                </th>
                            )
                        }
                        else if (tableHead?.rowSpan === "0") {
                            return (
                                <th className={`text-sm text-left py-2 pl-2 ${tableHead?.className}`} colspan={tableHead?.colspan} >
                                    {tableHead?.name}
                                </th>
                            )
                        }
                        else {
                            return (
                                <th className={`text-sm text-left py-2 pl-2 ${tableHead?.className}`}>
                                    {tableHead?.name}
                                </th>
                            )
                        }
                    })}
                </tr>

                <tr className='sticky z-20 border-t border-white' style={{ top: "112.5px" }}>
                    {secondHeading && secondHeading?.map((secondHeading) => {
                        if (secondHeading?.colspan === "0") {
                            return (
                                <th className={`${secondHeading?.className} text-sm text-left `} rowSpan={parseInt(secondHeading?.rowSpan)}>
                                    {secondHeading?.name}
                                </th>
                            )
                        }
                        else if (secondHeading?.rowSpan === "0") {
                            return (
                                <th className={`${secondHeading?.className} text-sm text-left `} colspan={parseInt(secondHeading?.colspan)} >
                                    {secondHeading?.name}
                                </th>
                            )
                        }
                        else {
                            return (
                                <th className={`${secondHeading?.className} text-sm text-left `} >
                                    {secondHeading?.name}
                                </th>
                            )
                        }
                    })}
                </tr>
            </thead>
            <tbody>
                {data?.map((tableData) => {
                    console.log(tableData)
                    return (
                        <tr className={`${hover ? "hover:bg-gray-200" : ""} text-sm border-b-2 border-gray-100`}>
                            {dataKey?.map((key, tableDataIndex) => {
                                // console.log(dataKey)
                                return (
                                    <td className={`py-2 ${tableDataIndex === 0 ? "pl-2" : ""}`}>
                                        {roundOffData.includes(key) ? roundOff(tableData[key]) : tableData[key]}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default TableComp

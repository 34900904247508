import { useAtom } from 'jotai'
import React from 'react'
import punchListAtom, { fetchQcPhotos } from '../../../atoms/punchListAtom'
import { useEffect } from 'react'
import ImageViewer from "react-simple-image-viewer";
import { useState } from 'react';

function PhotoView() {

    const [isViewerOpen, setIsViewerOpen] = useState(false)
    const [imageViewer, setImages] = useState([])

    const [listQC] = useAtom(punchListAtom)
    const [, setQcPhotos] = useAtom(fetchQcPhotos)

    useEffect(() => {
        init()
    }, [])

    const init = async() => {
        try{
            await setQcPhotos({
                project_id: window.location.pathname.split("/")[3],
                id: window.location.pathname.split("/")[4]
            })
        }
        catch(err){
            console.log(err)
        }
    }

    const openImageViewer = (photourl) => {
        setImages([photourl])
        setIsViewerOpen(true)
    }

    const closeImageViewer = () => {
        setImages(0)
        setIsViewerOpen(false)
    }

    return (
        <div className='flex'>
            {listQC.photos.map((i, index) => {
                return(
                    <div>
                        <img key={index} src={i} alt='' onClick={() => openImageViewer(i)} className='m-5 cursor-pointer w-96 h-96 object-cover' />
                        {isViewerOpen && (
                            <ImageViewer
                                src={imageViewer}
                                onClose={closeImageViewer}
                                disableScroll={false}
                                backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)"
                                }}
                                closeOnClickOutside={true} />
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default PhotoView

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Container from '../../../components/container'
import ImageViewer from "react-simple-image-viewer";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md"
import { Parser } from "json2csv"
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import { RiFolderDownloadFill } from 'react-icons/ri'
import { TbFilterOff } from "react-icons/tb"
import { BiArrowBack } from "react-icons/bi"
import { FaUsers, FaUser } from 'react-icons/fa'
import { useAtom } from 'jotai';
import punchListAtom, { fetchPunchListAtom, movePunchListAtom } from '../../../atoms/punchListAtom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom';
import projectAtom from '../../../atoms/projectAtom';
import produce from 'immer';
import {DateFormat} from '../../../components/dateFormat';

const handleTimeSplit = (data) => {
    let timeData = new Date(data)
    let hours = timeData.getHours()
    let mins = timeData.getMinutes()
    if (mins - 10 < -1) {
        mins = `0${mins}`
    }
    let meridian
    if (hours > 12) {
        meridian = "PM"
        hours = hours - 12
    }
    else {
        meridian = "AM"
    }
    return `${hours}:${mins} ${meridian}`
}

function QCPunchlist() {

    const { register, handleSubmit, reset } = useForm()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [punchBool, setPunchBool] = useState(false)
    const [blockIdValue, setBlockIdValue] = useState('All')
    const [sectionIdValue, setSectionIdValue] = useState('All')
    const [rowIdValue, setRowIdValue] = useState('All')
    const [viewBy, setViewBy] = useState("All")
    const [filter, setFilter] = useState({ block: "All", section: "All", row: "All", status: "All" })
    const [showTop, setShowTop] = useState([])
    const [punchlistPermission, setPunchlistPermission] = useState(false)
    const [viewType, setViewType] = useState(false)

    const [fetchPunchList] = useAtom(punchListAtom)
    const [, setFetchPunchList] = useAtom(fetchPunchListAtom)
    const [, setMovePunchList] = useAtom(movePunchListAtom)
    const [listId] = useAtom(activitiesAtom)
    const [, setFetchId] = useAtom(fetchActivitiesAtom)
    const [projectsName] = useAtom(projectAtom)

    const [punchListData, setPunchListData] = useState([])

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            await setFetchId()
            await setFetchPunchList({ loadImages: true })
            if (JSON.parse(localStorage.getItem("project_permissions")).find((j) => j.project_id === parseInt(localStorage.getItem("project_id")))?.permission_id === 20) {
                setPunchlistPermission(true)
            }
            else {
                setPunchlistPermission(false)
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
            setShowTop([])
        }
    }

    useEffect(() => {
        if (fetchPunchList.data) {
            setPunchListData(fetchPunchList.data)
        }
    }, [fetchPunchList.data])

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imageViewer, setImages] = useState([])

    useEffect(() => {
        let temp = fetchPunchList.data
        if (filter.status !== "All") {
            temp = temp.filter(o => o.status === parseInt(filter.status))
            setPunchListData(temp)
        }
        if (filter.block !== "All") {
            temp = temp.filter(o => o.block_name === filter.block)
            setPunchListData(temp)
        }
        if (filter.block === "All" && filter.section === "All" && filter.row === "All" && filter.status === "All") {
            setPunchListData(fetchPunchList.data)
        }
    }, [filter])

    const openImageViewer = (photourl) => {
        setImages(photourl)
        setIsViewerOpen(true)
    }

    const closeImageViewer = () => {
        setImages(0)
        setIsViewerOpen(false)
    }

    const onSubmit = async (value) => {
        let selectedRowId = []
        Object.keys(value).map((j) => {
            if (value[j] === true) {
                selectedRowId.push(j)
            }
        })
        let data = []
        let blockIdList = []

        selectedRowId.map((j) => {
            fetchPunchList?.data.map((i) => {
                if (i?.QCProgress?.id === parseInt(j)) {
                    let temp = {}
                    temp.tracker_row_id = i?.QCProgress?.tracker_row_id
                    temp.block_id = i?.block_id
                    temp.activity_id = i?.QCProgress?.activity_id
                    temp.comments = i?.QCProgress?.comments
                    temp.visibility = (punchBool ? 1 : 0)
                    temp.status = i?.QCProgress?.punchlist_status
                    temp.photos = i?.QCProgress?.photos
                    data.push(temp)
                    blockIdList.push(i?.block_id)
                }
            })
        })
        try {
            setLoading(true)
            await setMovePunchList({ data: { data: data }, visibility: punchBool ? 0 : 1 })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
            setShowTop([])
            reset()
        }
    }

    let blockIds = []
    fetchPunchList?.data.map((i) => {
        if (sectionIdValue === 'All' && rowIdValue === 'All') {
            blockIds.push(i?.block_name)
            blockIds = [...new Set(blockIds)]
        }
        else if (parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
            blockIds.push(i?.block_name)
            blockIds = [...new Set(blockIds)]
        }
        else if (sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
            blockIds.push(i?.block_name)
            blockIds = [...new Set(blockIds)]
        }
        else if (parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
            blockIds.push(i?.block_name)
            blockIds = [...new Set(blockIds)]
        }
    })

    let sectionIds = []
    fetchPunchList?.data.map((i) => {
        if (blockIdValue === 'All' && rowIdValue === 'All') {
            sectionIds.push(i.section)
            sectionIds = [...new Set(sectionIds)]
        }
        else if (blockIdValue === i.block_name && rowIdValue === 'All') {
            sectionIds.push(i.section)
            sectionIds = [...new Set(sectionIds)]
        }
        else if (blockIdValue === 'All' && parseInt(rowIdValue) === i.row) {
            sectionIds.push(i.section)
            sectionIds = [...new Set(sectionIds)]
        }
        else if (blockIdValue === i.block_name && parseInt(rowIdValue) === i.row) {
            sectionIds.push(i.section)
            sectionIds = [...new Set(sectionIds)]
        }
    })

    let rowIds = []
    fetchPunchList?.data.map((i) => {
        if (sectionIdValue === 'All' && blockIdValue === 'All') {
            rowIds.push(i.row)
            rowIds = [...new Set(rowIds)]
        }
        else if (sectionIdValue === 'All' && blockIdValue === i.block_name) {
            rowIds.push(i.row)
            rowIds = [...new Set(rowIds)]
        }
        else if (parseInt(sectionIdValue) === i.section && blockIdValue === 'All') {
            rowIds.push(i.row)
            rowIds = [...new Set(rowIds)]
        }
        else if (parseInt(sectionIdValue) === i.section && blockIdValue === i.block_name) {
            rowIds.push(i.row)
            rowIds = [...new Set(rowIds)]
        }
    })

    let statusIds = []
    fetchPunchList?.data.map((i) => {
        if (blockIdValue === i.block_name && sectionIdValue === 'All' && rowIdValue === 'All') {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        else if (blockIdValue === 'All' && parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        else if (blockIdValue === 'All' && sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        // dual row sorting
        else if (blockIdValue === i.block_name && parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        else if (blockIdValue === i.block_name && sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        else if (blockIdValue === 'All' && parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        // all row sorting
        else if (blockIdValue === i.block_name && parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
        // No Sorting
        else if (blockIdValue === 'All' && sectionIdValue === 'All' && rowIdValue === 'All') {
            statusIds.push(i?.QCProgress?.punchlist_status)
            statusIds = [...new Set(statusIds)]
        }
    })

    let filteredDetails = []
    fetchPunchList?.data.map((i) => {
        // individual row sorting
        if (blockIdValue === i.block_name && sectionIdValue === 'All' && rowIdValue === 'All') {
            filteredDetails.push(i)
        }
        else if (blockIdValue === 'All' && parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
            filteredDetails.push(i)
        }
        else if (blockIdValue === 'All' && sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
            filteredDetails.push(i)
        }
        // dual row sorting
        else if (blockIdValue === i.block_name && parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
            filteredDetails.push(i)
        }
        else if (blockIdValue === i.block_name && sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
            filteredDetails.push(i)
        }
        else if (blockIdValue === 'All' && parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
            filteredDetails.push(i)
        }
        // all row sorting
        else if (blockIdValue === i.block_name && parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
            filteredDetails.push(i)
        }
    })

    let filterbyviewAll = []
    if (viewBy !== 'All') {
        fetchPunchList?.data.map((i) => {
            if (i?.QCProgress?.punchlist_status === parseInt(viewBy)) {
                filterbyviewAll.push(i)
            }
        })
    }

    let filterarrview = []
    if (filterbyviewAll) {
        filterbyviewAll.map((i) => {
            if (blockIdValue === i.block_name && sectionIdValue === 'All' && rowIdValue === 'All') {
                filterarrview.push(i)
            }
            else if (blockIdValue === 'All' && parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
                filterarrview.push(i)
            }
            else if (blockIdValue === 'All' && sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
                filterarrview.push(i)
            }
            // dual row sorting
            else if (blockIdValue === i.block_name && parseInt(sectionIdValue) === i.section && rowIdValue === 'All') {
                filterarrview.push(i)
            }
            else if (blockIdValue === i.block_name && sectionIdValue === 'All' && parseInt(rowIdValue) === i.row) {
                filterarrview.push(i)
            }
            else if (blockIdValue === 'All' && parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
                filterarrview.push(i)
            }
            /// all row sorting
            else if (blockIdValue === i.block_name && parseInt(sectionIdValue) === i.section && parseInt(rowIdValue) === i.row) {
                filterarrview.push(i)
            }
        })
    }

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;


    const downloadFileCurrent = async () => {
        try {
            toast("Download queued")
            let fields = []
            let arr = []
            let filteredData = filteredDetails
            if (filteredDetails.length === 0) {
                filteredData = fetchPunchList?.data
            }
            else {
                filteredData = filteredData
            }
            filteredData.map((i) => {
                let temp = {}
                temp['Time Stamp'] = handleGetTimeStamp(i?.QCProgress?.created_at)
                temp['Block'] = i?.block_name
                temp['Section'] = i?.section
                temp['Row'] = i?.row
                temp['Checkpoint'] = listId?.qc[i?.QCProgress?.activity_id]?.name
                temp['Acceptance Criteria'] = i?.acceptance
                temp['Comments'] = i?.QCProgress?.comments
                temp['Status'] = i?.QCProgress?.punchlist_status === 0 ? "Open" : i?.QCProgress?.punchlist_status === 1 ? "Ready for Inspection" : "Resolved"
                if (i?.QCProgress?.photos.length > 0) {
                    temp['photo'] = `https://suryaweb.app/public/images/${i?.QCProgress?.project_id}/${i?.QCProgress?.id}`
                }
                else {
                    temp['photo'] = ""
                }
                arr.push(temp)
            })
            let newFields = [
                "Time Stamp",
                "Block",
                "Section",
                "Row",
                "Checkpoint",
                "Acceptance Criteria",
                "Comments",
                "Photos",
                "Status"
            ]

            Object.entries(filteredData[0]).forEach((k) => {
                fields.push(k[0])
            })
            let opts = { newFields };
            const parser = new Parser(opts);
            let csv = parser.parse(arr);
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", "QC Punchlist"\n` + `"Report Date", ${formattedDate}\n` + `"Total Item", ${filteredData?.length}\n` + csv
            const element = document.createElement("a");
            const file = new Blob([csv], { type: 'text/csv' });
            element.href = URL.createObjectURL(file);
            element.download = `${project_name?.project_number}_QC Punchlist_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            toast.error("Download failed")
            console.log(err)
        }
    }

    console.log("project_name", project_name)

    const downloadFileAll = async () => {
        try {
            toast("Download queued")
            let fields = []
            let arr = []
            let filteredData = fetchPunchList?.data
            filteredData.map((i) => {
                let temp = {}
                temp['Time Stamp'] = handleGetTimeStamp(i?.created_at * 1000)
                temp['Block'] = i?.block_name
                temp['Section'] = i?.section
                temp['Row'] = i?.row
                temp['Checkpoint'] = i?.checkpoint_name
                temp['Acceptance Criteria'] = i?.acceptance_crieteria
                temp['Comments'] = i?.comments
                temp['Status'] = findStatusCode(i?.status)
                if (i?.photos.length > 0) {
                    temp['photo'] = `https://suryaweb.app/public/images/${project_name?.id}/${i?.QCProgress?.id}`
                }
                else {
                    temp['photo'] = ""
                }
                arr.push(temp)
            })
            let newFields = [
                "Time Stamp",
                "Block",
                "Section",
                "Row",
                "Checkpoint",
                "Acceptance Criteria",
                "Comments",
                "Photos",
                "Status"
            ]
            Object.entries(filteredData[0]).forEach((k) => {
                fields.push(k[0])
            })
            let opts = { newFields };
            const parser = new Parser(opts);
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", "QC Punchlist"\n` + `"Report Date", ${formattedDate}\n` + `"Total Item", ${filteredData?.length}\n` + csv
            const element = document.createElement("a");
            const file = new Blob([csv], { type: 'text/csv' });
            element.href = URL.createObjectURL(file);
            element.download = `${project_name?.project_number}_QC Punchlist_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {
            toast.error("Download failed")
            console.log(err)
        }
    }

    const handleDownload = async () => {
        try {
            toast("Download queued")
            let fields = []
            let arr = []
            let filteredData = fetchPunchList?.data
            filteredData.map((i) => {
                let temp = {}
                temp['Time stamp'] = handleGetTimeStamp(i?.created_at * 1000)
                temp['Block'] = i?.block_name
                temp['Section'] = i?.section
                temp['Row'] = i?.row
                temp['Checkpoint'] = i?.checkpoint_name
                temp["Scope"] = i?.scope
                temp['Acceptance Criteria'] = i?.acceptance_crieteria
                temp['Comments'] = i?.comments
                temp['Status'] = findStatusCode(i?.status)
                if (i?.photos.length > 0) {
                    temp['photo'] = `https://suryaweb.app/public/images/${project_name?.id}/${i?.QCProgress?.id}`
                }
                else {
                    temp['photo'] = ""
                }
                arr.push(temp)
            })
            let newFields = [
                "Time Stamp",
                "Block",
                "Section",
                "Row",
                "Checkpoint",
                "Scope",
                "Acceptance Criteria",
                "Comments",
                "Photos",
                "Status"
            ]
            Object.entries(filteredData[0]).forEach((k) => {
                fields.push(k[0])
            })
            let opts = { newFields };
            const parser = new Parser(opts);
            let csv = parser.parse(arr)
            csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", "QC Punchlist"\n` + `"Report Date", ${formattedDate}\n` + `"Total Item", ${filteredData?.length}\n` + csv
            const element = document.createElement("a");
            const file = new Blob([csv], { type: 'text/csv' });
            element.href = URL.createObjectURL(file);
            element.download = `${project_name?.project_number}_QC Punchlist_${project_name?.name}`
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        catch (err) {

        }
    }

    // const handleGetTimeStamp = (data) => {
    //     const months = [
    //         'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    //     ];
    //     let date = new Date(data).toISOString().split("T")[0]
    //     return `${date.split("-")[2]}-${months[date.split("-")[1] - 1]}-${date.split("-")[0]}`
    // }

    function handleGetTimeStamp(epochMillis, timeZone) {
        const date = new Date(epochMillis);
        if (project_name?.timezone) {
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone: project_name?.timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short'
            });
            const formatDate = formatter.format(date)
            let formattedDate = formatDate.split(",")[0]
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let monthIndex = formattedDate.split("/")[0]
            return `${formattedDate.split("/")[1]}-${months[parseInt(monthIndex) - 1]}-${formattedDate.split("/")[2]}`
        }
        else {
            let dateFormate = date.toISOString().split("T")[0]
            return `${dateFormate.split("-")[2]}-${dateFormate.split("-")[1]}-${dateFormate.split("-")[0]}`
        }
    }

    function handleGetTime(epochMillis) {
        const date = new Date(epochMillis);
        if (project_name?.timezone) {
            const formatter = new Intl.DateTimeFormat('en-US', {
                timeZone: project_name?.timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short'
            });
            const formatDate = formatter.format(date)
            let formattedTime = formatDate.split(",")[1].split("M")[0]
            return `${formattedTime}M`
        }
        else {
            let dateFormate = date.toISOString().split("T")[0]
            return `00:00:00`
        }
    }

    const handleClearFilter = () => {
        setFilter({ block: "All", section: "All", row: "All", status: "All" })
        setPunchListData(fetchPunchList.data)
    }

    const handleTotalCount = () => {
        let temp = []
        punchListData.map((i) => {
            if (punchBool) {
                if (i?.visibility === 0) {
                    temp.push(i)
                }
            }
            else {
                if (i?.visibility === 1) {
                    temp.push(i)
                }
            }
        })
        return temp.length
    }

    function findStatusCode(data) {
        if (data === 0) {
            return "Open"
        }
        else if (data === 1) {
            return "Ready for Inspection"
        }
        else if (data === 2) {
            return "Resolved"
        }
    }

    return (
        <Container>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-between my-3'>
                        <div>
                            <div className='flex space-x-5 items-center'>
                                <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer ml-5 hover:bg-gray-100 rounded-xl active:bg-gray-200" onClick={() => navigate("/quality")} />
                                <p className='ml-5 font-bold text-xl pb-5 mt-4'>QC Punchlist</p>
                                <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 py-1 bg-red-50 border-red-100'>
                                    <div className={`flex items-center ${viewType ? "" : "bg-red-550 border-none rounded-full py-2"}`}>
                                        <FaUsers className={`ml-3 ${viewType ? "text-gray-400" : "text-white"}`} />
                                        <p className={`${viewType ? "text-gray-400 pr-3 pl-2" : "text-white pr-3 pl-2"} mr-1`} onClick={() => { setViewType(false); navigate("/quality/qc_punchlist") }}>List View</p>
                                    </div>
                                    <div className={`flex items-center ${viewType ? "bg-red-550 border-none rounded-full py-2" : ""}`}>
                                        <FaUser className={`ml-3 ${viewType ? "text-white" : "text-gray-400"}`} />
                                        <p className={`${viewType ? "text-white pr-3 pl-2" : "text-gray-400 pr-3 pl-2"} ml-1`} onClick={() => { setViewType(true); navigate("/quality/dashboard") }}>Map View</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            {loading ?
                                <div className=' mr-7 flex'>
                                    <p className='text-gray-400 font-semibold mr-1'>Total Item : </p>
                                    <p className='loader mt-1' style={{ width: "15px", height: "15px" }}></p>
                                </div>
                                :
                                <div className=' mr-7 flex'>
                                    <p className='text-gray-400 font-semibold mr-1'>Total Item : </p>
                                    <p>
                                        {handleTotalCount()}
                                    </p>
                                </div>
                            }
                            {punchlistPermission ?
                                <div className='flex mr-5'>
                                    <FaUsers className='text-red-550 text-xl mr-2' />
                                    <p className='text-red-550 font-semibold'>External punchlist</p>
                                </div>
                                :
                                <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 mt-1 bg-red-50 border-red-100 mr-4'>
                                    <div className={`flex ${punchBool ? "" : "bg-red-550 border-none rounded-full"}`}>
                                        <FaUsers className={`mt-3 ml-3 ${punchBool ? "text-gray-400" : "text-white"}`} />
                                        <p className={`${punchBool ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} onClick={() => { setPunchBool(false); setBlockIdValue("All"); setRowIdValue("All"); setSectionIdValue("All"); setViewBy("All") }}>External</p>
                                    </div>
                                    <div className={`flex ${punchBool ? "bg-red-550 border-none rounded-full" : ""}`}>
                                        <FaUser className={`mt-3 ml-3 ${punchBool ? "text-white" : "text-gray-400"}`} />
                                        <p className={`${punchBool ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} onClick={() => { setPunchBool(true); setBlockIdValue("All"); setRowIdValue("All"); setSectionIdValue("All"); setViewBy("All") }}>Internal</p>
                                    </div>
                                </div>
                            }
                            {filter.block !== "All" || filter.section !== "All" || filter.row !== "All" || filter.status !== "All" ?
                                <div className='mr-5  flex cursor-pointer' onClick={handleClearFilter}>
                                    <TbFilterOff className='mr-1 mt-1 text-gray-500' />
                                    <p className='text-gray-500'>Clear Filter</p>
                                </div>
                                :
                                <div className='mr-5  flex cursor-auto'>
                                    <TbFilterOff className='mr-1 mt-1 text-gray-200' />
                                    <p className='text-gray-200'>Clear Filter</p>
                                </div>
                            }
                            <div className='cursor-pointer flex items-center mr-4' onClick={handleDownload}>
                                <RiFolderDownloadFill className='mr-2' style={{ width: '20px', height: '20px' }} />
                                <p>Download</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {loading ?
                        <div style={{ marginTop: "15%", marginLeft: "44%" }}>
                            <p className='loader ml-3 mb-1'></p>
                            <p className='font-bold text-xl'>Fetching...</p></div> :
                        <div className='overflow-scroll border' style={{ height: 'calc(100vh - 170px)' }}>
                            <table className='table table-compact w-full z-0'>
                                <thead>
                                    <tr className='sticky top-0 z-20'>
                                        <th className='normal-case pl-5' style={{ width: "160px" }}>Time Stamp</th>
                                        <th className='normal-case pl-4' style={{ width: "100px" }}>
                                            <select className={`focus:outline-none max-w-xs cursor-pointer pl-0 text-black bg-red-550 text-white`} value={filter.block === 'All' ? "Block" : filter.block} style={{ width: `115px` }} onChange={e => setFilter(produce((draft) => { draft.block = e.target.value }))}>
                                                <option className='bg-white text-black' value="All">{filter.block === 'All' ? "Block" : "All"}</option>
                                                {blockIds?.map((i) => {
                                                    return <option className='bg-white text-black' value={i}>{i}</option>
                                                })}
                                            </select>
                                        </th>
                                        <th className='normal-case' style={{ width: "170px" }}>Section
                                            {/* <select className='focus:outline-none max-w-xs cursor-pointer text-black' value={sectionIdValue === 'All' ? "Section" : sectionIdValue} style={{ width: `${sectionIdValue === 'All' ? "82px" : "70px"}`, backgroundColor: "#F8F8F8" }} onChange={e => setSectionIdValue(e.target.value)}>
                                                <option value="All">{sectionIdValue === 'All' ? "Section" : "All"}</option>
                                                {sectionIds?.map((i) => {
                                                    return <option value={i}>{i}</option>
                                                })}

                                            </select> */}
                                        </th>
                                        <th className='normal-case' style={{ width: "130px" }}>Row
                                            {/* <select className='focus:outline-none max-w-xs cursor-pointer text-black' value={rowIdValue === 'All' ? "Row" : rowIdValue} style={{ width: `${rowIdValue === 'All' ? "77px" : "70px"}`, backgroundColor: "#F8F8F8" }} onChange={e => setRowIdValue(e.target.value)}>
                                                <option value="All">{rowIdValue === 'All' ? "Row" : "All"}</option>
                                                {rowIds?.map((i) => {
                                                    return <option value={i}>{i}</option>
                                                })}
                                            </select> */}
                                        </th>
                                        <th className='normal-case' style={{ width: "150px" }}>Scope</th>
                                        <th className='normal-case' style={{ width: "250px" }}>Checkpoint</th>
                                        <th className='normal-case' style={{ width: "290px" }}>Acceptance Criteria</th>
                                        <th className='normal-case' style={{ width: "300px" }}>Comments</th>
                                        <th className='normal-case' style={{ width: "500px" }}>Photos</th>
                                        <th className='normal-case' style={{ width: "150px" }}>
                                            <select className='focus:outline-none max-w-xs cursor-pointer pl-0 text-black bg-red-550 text-white' value={filter.status === 'All' ? "Status" : filter.status} style={{ width: `110px` }} onChange={e => setFilter(produce((draft) => { draft.status = e.target.value }))}>
                                                <option className='bg-white text-black' value="All">{filter.status === "All" ? "Status" : "All"}</option>
                                                <option className='bg-white text-black' value={0}>Open</option>
                                                <option className='bg-white text-black' value={1}>Ready for Inspection</option>
                                                <option className='bg-white text-black' value={2}>Resolved</option>
                                            </select>
                                        </th>
                                    </tr>
                                </thead>
                                {fetchPunchList?.data.length > 0 ?
                                    <tbody>
                                        {punchListData.map((i) => {
                                            if (punchBool) {
                                                if (i?.visibility === 0) {
                                                    return (
                                                        <tr>
                                                            <td className='pl-5'>{handleGetTimeStamp(i?.created_at * 1000)}<br />{handleGetTime(i?.created_at * 1000)} </td>
                                                            <td className='pl-4'>{i?.block_name}</td>
                                                            <td>{i?.section}</td>
                                                            <td>{i?.row}</td>
                                                            <td style={{ textTransform: "capitalize" }}>{i?.scope}</td>
                                                            <td>
                                                                {i?.checklistType === 1 ?
                                                                    <div style={{ textTransform: "capitalize" }}>
                                                                        {i.label}
                                                                        <div className='flex mt-1 ml-5 space-x-1'>
                                                                            <MdOutlineSubdirectoryArrowRight />
                                                                            <p>{i?.checkpoint_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div style={{ textTransform: "capitalize" }}>
                                                                        {i?.checkpoint_name}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>{i?.acceptance_crieteria}</td>
                                                            <td>{i?.comments}</td>
                                                            <td className='text-center m-auto'>
                                                                {i?.photos.length < 4 ?
                                                                    <div className='grid grid-cols-2 gap-2 m-auto text-center'>
                                                                        {i?.photos.map((src, index) => (
                                                                            <img
                                                                                src={src}
                                                                                onClick={() => openImageViewer(i?.photos)}
                                                                                width="100px"
                                                                                height="100px"
                                                                                className='cursor-pointer'
                                                                                key={index}
                                                                                alt="" />
                                                                        ))}
                                                                        <div className='z-20'>
                                                                            {isViewerOpen && (
                                                                                <ImageViewer
                                                                                    src={imageViewer}
                                                                                    // currentIndex={currentImage}
                                                                                    onClose={closeImageViewer}
                                                                                    disableScroll={false}
                                                                                    backgroundStyle={{
                                                                                        backgroundColor: "rgba(0,0,0,0.9)"
                                                                                    }}
                                                                                    closeOnClickOutside={true} />
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    <div className='grid grid-cols-2 gap-2 m-auto text-center'>
                                                                        <img src={i?.photos[0]} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={0} alt="" />
                                                                        <img src={i?.photos[1]} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={1} alt="" />
                                                                        <img src={i?.photos[2]} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={2} alt="" />
                                                                        <div className='relative'>
                                                                            <img src={i?.photos[3]} style={{ filter: "blur(2px)" }} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={3} alt="" />
                                                                            <p className='absolute font-medium top-2 left-2'>+ {i?.photos.length - 3} </p>
                                                                        </div>
                                                                        <div className='z-20'>
                                                                            {isViewerOpen && (
                                                                                <ImageViewer
                                                                                    src={imageViewer}
                                                                                    // currentIndex={currentImage}
                                                                                    onClose={closeImageViewer}
                                                                                    disableScroll={false}
                                                                                    backgroundStyle={{
                                                                                        backgroundColor: "rgba(0,0,0,0.9)"
                                                                                    }}
                                                                                    closeOnClickOutside={true} />
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>{findStatusCode(i?.status)}</td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                            else {
                                                if (i?.visibility === 1) {
                                                    return (
                                                        <tr>
                                                            <td className='pl-5'>{handleGetTimeStamp(i?.created_at * 1000)}<br />{handleGetTime(i?.created_at * 1000)}</td>
                                                            <td className='pl-4'>{i?.block_name}</td>
                                                            <td>{i?.section}</td>
                                                            <td>{i?.row}</td>
                                                            <td style={{ textTransform: "capitalize" }}>{i?.scope}</td>
                                                            <td>
                                                                {i?.checklistType === 1 ?
                                                                    <div style={{ textTransform: "capitalize" }}>
                                                                        {i.label}
                                                                        <div className='flex mt-1 ml-5 space-x-1'>
                                                                            <MdOutlineSubdirectoryArrowRight />
                                                                            <p>{i?.checkpoint_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div style={{ textTransform: "capitalize" }}>
                                                                        {i?.checkpoint_name}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>{i?.acceptance_crieteria}</td>
                                                            <td>{i?.comments}</td>
                                                            <td className='text-center m-auto'>
                                                                {i?.photos.length < 4 ?
                                                                    <div className='grid grid-cols-2 gap-2 m-auto text-center'>
                                                                        {i?.photos.map((src, index) => (
                                                                            <img
                                                                                src={src}
                                                                                onClick={() => openImageViewer(i?.photos)}
                                                                                width="100px"
                                                                                height="100px"
                                                                                className='cursor-pointer'
                                                                                key={index}
                                                                                alt="" />
                                                                        ))}
                                                                        <div className='z-20'>
                                                                            {isViewerOpen && (
                                                                                <ImageViewer
                                                                                    src={imageViewer}
                                                                                    // currentIndex={currentImage}
                                                                                    onClose={closeImageViewer}
                                                                                    disableScroll={false}
                                                                                    backgroundStyle={{
                                                                                        backgroundColor: "rgba(0,0,0,0.9)"
                                                                                    }}
                                                                                    closeOnClickOutside={true} />
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    <div className='grid grid-cols-2 gap-2 m-auto text-center'>
                                                                        <img src={i?.photos[0]} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={0} alt="" />
                                                                        <img src={i?.photos[1]} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={1} alt="" />
                                                                        <img src={i?.photos[2]} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={2} alt="" />
                                                                        <div className='relative'>
                                                                            <img src={i?.photos[3]} style={{ filter: "blur(2px)" }} onClick={() => openImageViewer(i?.photos)} width="100px" height="100px" className='cursor-pointer' key={3} alt="" />
                                                                            <p className='absolute font-medium top-2 left-2'>+ {i?.photos.length - 3} </p>
                                                                        </div>
                                                                        <div className='z-20'>
                                                                            {isViewerOpen && (
                                                                                <ImageViewer
                                                                                    src={imageViewer}
                                                                                    // currentIndex={currentImage}
                                                                                    onClose={closeImageViewer}
                                                                                    disableScroll={false}
                                                                                    backgroundStyle={{
                                                                                        backgroundColor: "rgba(0,0,0,0.9)"
                                                                                    }}
                                                                                    closeOnClickOutside={true} />
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>{findStatusCode(i?.status)}</td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                        })}
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan={10} className='py-24'>
                                                <div className='m-auto'>
                                                    <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                    <p className='text-gray-400 mt-4 text-center text-base font-bold'>No records available</p>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* <th colSpan="10" className="text-center">
                                            <p className='text-base font-bold' style={{ marginTop: "15%", marginBottom: "calc(100vh - 501px)" }}>No records Found</p>
                                        </th> */}
                                    </tbody>
                                }
                            </table>
                        </div>}
                </form>
            </div>
        </Container>
    )
}

export default QCPunchlist
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

function ErrorBoundary() {

    const error = useRouteError();
    const navigate = useNavigate()

    console.error("Caught by ErrorBoundary:", error);

    const handleNavigateDashboard = () => {
        window.location.reload()
        navigate("/dashboard")
    }

    return (
        <div className='flex flex-col items-center justify-center h-screen space-y-4'>
            <h1 className='text-xl'>Oops an error encountered, please bear with us.</h1>
            <button className='btn rounded-full border-none bg-red-550 text-white' onClick={handleNavigateDashboard}>Go Home</button>
        </div>
    );
}

export default ErrorBoundary;
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import Container from '../../../components/container'
import Modal from 'react-modal';
import { RiFolderDownloadFill } from 'react-icons/ri'
import { FiChevronDown } from 'react-icons/fi'
import { IoCopy } from 'react-icons/io5'
import { BsFillFileEarmarkImageFill, BsFillFileBarGraphFill } from "react-icons/bs"
import { BiArrowBack } from "react-icons/bi"
import { VscClose } from 'react-icons/vsc'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ReactApexChart from 'react-apexcharts';
import { useAtom } from 'jotai';
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import statAtom, { fetchDownloadData, fetchStatAtom } from '../../../atoms/statsAtom';
import partsAtom, { fetchPartsAtom } from '../../../atoms/partsAtom';
import projectAtom from '../../../atoms/projectAtom';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function InitialStats() {

  const navigate = useNavigate()

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [loading, setLoading] = useState(false)
  const [dataDate, setDataDate] = useState([])
  const [dataCount, setDataCount] = useState([])
  const [dataPercentage, setDataPercentage] = useState([])
  const [activity, setActivity] = useState()
  const [dum, setDum] = useState()
  const [filterData, setFilterData] = useState()

  const [listId] = useAtom(activitiesAtom)
  const [, setIDMap] = useAtom(fetchActivitiesAtom)
  const [listStats] = useAtom(statAtom)
  const [, setFetchStat] = useAtom(fetchStatAtom)
  const [listParts] = useAtom(partsAtom)
  const [, fetchPartsInit] = useAtom(fetchPartsAtom)
  const [projectsName] = useAtom(projectAtom)
  const [, setDownloadData] = useAtom(fetchDownloadData)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setLoading(true)
      await setIDMap()
      await fetchPartsInit()
    }
    catch (err) {
      console.log(err)
      toast.error("Something went Wrong")
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleStat()
  }, [startDate, endDate, activity])

  useEffect(() => {
    handleDetailFetch()
  }, [listStats.data])

  useEffect(() => {
    handleSortAct()
  }, [listId?.construction])

  const handleStat = async () => {
    try {
      if (activity) {
        let start_temp = startDate.toLocaleDateString()
        let end_temp = endDate.toLocaleDateString()
        setLoading(true)
        await setFetchStat({ startDate: `${start_temp.split("/")[1]}-${start_temp.split("/")[0]}-${start_temp.split("/")[2]}`, endDate: `${end_temp.split("/")[1]}-${end_temp.split("/")[0]}-${end_temp.split("/")[2]}`, actId: activity })
        handleDetailFetch()
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoading(false)
    }
  }

  let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

  const handleDetailFetch = () => {
    let tempDate = []
    let tempCount = []
    let tempPercentage = []
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    listStats.data.map((i) => {
      tempDate.push(i.date.split("/")[1] + "-" + months[parseInt(i.date.split("/")[0]) - 1] + "-" + i.date.split("/")[2])
      tempCount.push(i?.deployed)
      tempPercentage.push(i?.remaining)
    })
    setDataDate(tempDate)
    setDataCount(tempCount)
    setDataPercentage(tempPercentage)
  }


  let prtAct = []
  if (listId?.construction) {
    Object.keys(listId.construction).map((ct) => {
      if (!listId.construction[ct].parent_activity_id) {
        prtAct.push(ct)
      }
    })
  }

  let finalArr = []

  const handleSortAct = () => {
    prtAct.map((pt) => {
      finalArr.push(parseInt(pt))
      Object.keys(listId?.construction).map((j) => {
        if (listId?.construction[j].parent_activity_id === parseInt(pt)) {
          finalArr.push(parseInt(j))
        }
      })
    })
    setFilterData(finalArr)
  }

  const handleActSearch = (e) => {
    handleSortAct()
    let quaryFilter = e.target.value
    var updateList = [...finalArr]
    updateList = updateList.filter((item) =>
      listId.construction[item].name.toLowerCase().indexOf(quaryFilter.toLowerCase()) !== -1
    )
    finalArr = updateList
    setFilterData(finalArr)
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    handleSortAct()
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '500px'
    },
  };

  const handleActivityChange = (e) => {
    if (e.target.checked) {
      setDum(e.target.value)
    }
  }

  const handleActivitySubmit = () => {
    setActivity(dum)
    setIsOpen(false)
    handleSortAct()
  }

  const handleFindLinkedParts = (data) => {
    let temp
    listParts.map((j) => {
      if (j.id === parseInt(data)) {
        temp = j?.part_name
      }
    })
    return temp
  }

  const options = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        tools: {
          download: true
        }
      }
    },
    legend: {
      show: true,
      floating: false,
      fontSize: "15px"
    },
    stroke: {
      width: [0, 4]
    },
    title: {
      text: ''
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: dataDate,
    xaxis: {
      type: 'Datetime'
    },
    yaxis: [{
      title: {
        text: activity ? `${handleFindLinkedParts(listId?.construction[activity]?.part_id)} counts` : "",
      },

    },
    {
      opposite: true,
      title: {
        text: 'Total Remaining'
      }
    }]
  }

  const series = [{
    name: activity ? `${handleFindLinkedParts(listId?.construction[activity]?.part_id)} counts` : "",
    type: 'column',
    data: dataCount
  }, {
    name: 'Total Remaining',
    type: 'line',
    data: dataPercentage
  }]

  const handleDownloadData = async () => {
    try {
      await setDownloadData()
    }
    catch (err) {
      toast.error("Something went wrong")
      console.log(err)
    }
  }

  return (
    <Container>
      <div>
        <div className='flex justify-between mb-5'>
          <div>
            <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/reports")} />
            <div className='ml-6 mt-5 text-xl font-bold'>
              Install Stats
            </div>
          </div>
          <div className=' text-sm flex text-gray-500 font-semibold mt-8 mr-10'>
            {/* <div className='flex mt-3'>
              <IoCopy className='mr-1 cursor-pointer' style={{ width: '20px', height: '20px' }} />
              <p className='mr-6 cursor-pointer'>Copy Image</p>
            </div> */}
            {listStats.downloadData ?
              <div className='flex mt-3 text-gray-400 cursor-pointer' onClick={handleDownloadData}>
                <p className='loader mr-2 mt-0.5' style={{ width: "15px", height: "15px" }}></p>
                <p>Downloading...</p>
              </div>
              :
              <div className='flex mt-3 text-gray-400 cursor-pointer' onClick={handleDownloadData}>
                <RiFolderDownloadFill className='mr-1' style={{ width: '20px', height: '20px' }} />
                <p>Download All</p>
              </div>
            }
          </div>
        </div>
        <hr />
        <div className='flex justify-between mx-6 my-4' style={{ height: '40px' }}>
          <div className='flex'>
            <DatePicker dateFormat="dd-MMMM-yyyy" onChange={setStartDate} maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)} className="border border-2 rounded-xl w-44 py-2 pl-2  mr-5" showIcon selected={startDate} placeholderText='Start date' />
            <DatePicker dateFormat="dd-MMMM-yyyy" onChange={setEndDate} maxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)} className="border border-2 rounded-xl w-44 py-2 pl-2 " selected={endDate} placeholderText='End date' />
          </div>
          <div className='flex cursor-pointer' onClick={() => setIsOpen(true)}>
            {activity ?
              <p className='mt-2 font-semibold text-gray-500 text-xl mr-4'>{listId.construction[activity]?.name}</p>
              :
              <p className='mt-2 font-semibold text-xl text-gray-500 mr-4'>Select Activity</p>
            }
            <FiChevronDown className='mt-3 mr-5 text-gray-500' style={{ width: '20px', height: '20px' }} />
          </div>
        </div>
        <hr />
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Activity List"
        >
          <div>
            <div className='flex justify-between'>
              <p className='font-bold text-xl' >Select Activity</p>
              <VscClose onClick={closeModal} className="cursor-pointer mt-1 active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: '25px', height: '25px' }} />
            </div>
            <input className='appearance-none rounded-full w-full mt-5 text-gray-700 border border-gray-300 py-3 px-4 leading-tight' type="search" placeholder='Search Activity' onChange={e => handleActSearch(e)} />

            <form>
              <div className='overflow-auto p-3' style={{ height: '290px' }}>
                {filterData?.map((ar, index) => {
                  return (
                    <div key={index} className='my-4'>
                      <label>
                        <input type='radio' className={`${listId.construction[ar].parent_activity_id ? "ml-5" : null}`} value={ar} checked={parseInt(dum) === ar} onChange={handleActivityChange} />
                        <span className={`text-sm font-semibold ml-2 cursor-pointer ${listId.construction[ar].parent_activity_id ? "font-normal" : "font-extrabold"}`}>{listId.construction[ar].name}</span>
                      </label>
                    </div>
                  )
                })}
              </div>
              <div className='flex justify-end'>
                <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' type="reset" onClick={() => { setIsOpen(false); setDum(); closeModal() }}>Cancel</button>
                <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={handleActivitySubmit}>Generate Summary</button>
              </div>
            </form>
          </div>
        </Modal>
        {loading ?
          <div style={{ marginTop: "14%", marginLeft: "44%" }}>
            <p className='loader ml-3 mb-1'></p>
            <p className='font-bold text-xl'>Fetching...</p>
          </div>
          :
          activity ?
            <div>
              {/* <div className='flex justify-end m-6'>
                <p className='font-semibold'>{listId.construction[activity].name}</p>
                <div className='flex cursor-pointer mr-20 pr-2'>
                  <IoCopy className='text-gray-500 mt-1 mr-2'  />
                  <p className='mr-4 text-gray-500 font-semibold'>Copy Image</p>
                </div>
              </div> */}
              {listStats.data.length > 0 ?
                <div className="m-auto mt-12 ml-24" >
                  <ReactApexChart options={options} series={series} type="line" height={350} width={"90%"} />
                </div>
                :
                <div>
                  <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
                  <p className='text-gray-400 mt-4 text-center font-medium'>No records</p>
                </div>
              }
            </div>
            :
            <div>
              <BsFillFileBarGraphFill className='m-auto text-gray-300 text-6xl' style={{ marginTop: "15%" }} />
              <p className='text-gray-400 mt-4 text-center font-medium'>Select a date range and an activity</p>
            </div>
        }
      </div>
    </Container>
  )
}

export default InitialStats

// for(let i = startDate.getTime(); Math.round(i) <= Math.round(endDate.getTime())+100000000; i = i+100000000){
//   dateRange.push(new Date(Math.round(i)))
// }
function roundOff(number, digits) {
    if (digits) {
        let decimal = Math.pow(10, digits)
        return Math.floor(number * decimal) / decimal
    }
    else{
        return Math.floor(number)
    }
}

export default roundOff

import axios from "axios"

export async function get_progress_activitywise_for_project() {
    let responseData = await axios.post(`/progress/get_progress_activity_wise?activity_type=construction`)
    responseData = responseData.data
    let activityProgress = {}
    for (let i of responseData) {
        activityProgress[i?.activity_id] = i?.progress
    }
    return activityProgress
}
import * as d3 from "d3";
import { scaleLinear } from "d3-scale";
import React, { useRef, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import produce from "immer";
import { saveSvgAsPng } from "save-svg-as-png"
import { toLower } from "lodash";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaArrowRight, FaArrowLeft, FaArrowUp, FaArrowDown } from "react-icons/fa";
import proj4 from "proj4";

const getLuminance = (color) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

function getContrastColor(bgColor) {
    const luminance = getLuminance(bgColor);
    return luminance > 128 ? '#000000' : '#FFFFFF';
}

export default function SuryaBlockMap(props) {
    const {
        deviceType,
        loader,
        bulkAllData,
        pierLegends,
        type,
        typeData,
        userSelection,
        scopeSelection,
        showLaydownNameHover,
        laydownData,
        currentLaydownData,
        initialLoadSelectedLaydownTracker,
        currentBlock,
        currentProjectData,
        downloadD3,
        disableSelection,
        selectedRow,
        setSelectedRow,
        showLegend,
        mapSize,
        selectAll,
        section,
        prevSection,
        trackerSelect,
        prevTracker,
        clearSelection,
        enableGPSLocation,
        controls
    } = props;

    const [position, setPosition] = useState([0, 0])
    const [adjust, setAdjust] = useState({ x: 0, y: 0 })
    const [loading, setLoading] = useState(true)

    const containerRef = useRef()
    const downloadRef = useRef()
    const selectedRowRef = useRef([])

    let lineObjs = {}
    let rowObjs = {}
    let sectionObjs = {}
    let labelObjs = {}
    let selectObjs = {}
    let ttObjs = {}
    let pierObjs = {}

    const getColorCode = (value, data) => {
        let prlegend = pierLegends[value]
        if (type === "CP" && userSelection.showType === "bha") {
            let label
            if (data?.pier_type_motor) {
                label = "sg_labels"
            }
            else {
                label = "bha_labels"
            }
            if (data?.bha_sg_labels[0] === "E") {
                return prlegend?.[label]?.["E.Hvy"]
            }
            else if (data?.bha_sg_labels[0] === "L") {
                return prlegend?.[label]?.["Light"]
            }
            else if (data?.bha_sg_labels[0] === "H") {
                return prlegend?.[label]?.["Hvy"]
            }
            else {
                return `#4287f5`
            }
        }
        else {
            if (prlegend?.map_colour) {
                return prlegend?.map_colour;
            } else {
                return `#4287f5`
            }
        }

    }

    const getPrLegend = (data) => {
        let prlegend = pierLegends[data]
        if (pierLegends && prlegend) {
            return prlegend
        }
        else {
            return {
                shape: "circle",
                concentric: false,
                border: "black",
                background: "white",
                width: 1,
                map_color: "#4287f5"
            }
        }
    }

    function ttExterior(ref, ttDataLatitude, pierData01Longitude, pierData02Longitude, color, selectionIncludes) {
        return d3.select(ref.current)
            .append("rect")
            .attr("class", "react-tt cursor-pointer")
            .attr("x", ttDataLatitude - 2)
            .attr("y", pierData02Longitude)
            .attr("width", "5px")
            .attr("height", pierData01Longitude - pierData02Longitude)
            .attr("stroke", selectedRow.includes(selectionIncludes) ? "gray" : color ? "#66f1ad" : "gray")
            .attr("fill", selectedRow.includes(selectionIncludes) ? "gray" : color ? "#66f1ad" : "gray")
    }

    function ttInterior(ref, ttDataLatitude, pierData01Longitude, pierData02Longitude, color, selectionIncludes) {
        return d3.select(ref.current)
            .append("rect")
            .attr("class", "react-tt cursor-pointer")
            .attr("x", ttDataLatitude - 2)
            .attr("y", pierData02Longitude)
            .attr("width", "5px")
            .attr("height", pierData01Longitude - pierData02Longitude)
            .attr("stroke", selectedRow.includes(selectionIncludes) ? "gray" : color ? "#66f1ad" : "gray")
            .attr("stroke-width", "1px")
            .attr("fill", selectedRow.includes(selectionIncludes) ? "white" : color ? "#66f1ad" : "white")
    }

    function ttEdge(ref, pierData01Latitude, pierData01Longitude, pierData02Latitude, pierData02Longitude, color, selectionIncludes) {
        // if (pierData02Latitude && pierData02Longitude) {
        return d3.select(ref.current)
            .append("line")
            .attr("class", "react-tt cursor-pointer")
            .attr("x1", pierData01Latitude)
            .attr("y1", pierData01Longitude)
            .attr("x2", pierData02Latitude)
            .attr("y2", pierData02Longitude)
            .attr("stroke-width", "5px")
            .attr("stroke", selectedRow.includes(selectionIncludes) ? "gray" : color ? "#66f1ad" : "gray")
            .attr("stroke-dasharray", "1 1")
        // }
    }

    const handleSelectTracker = (key) => {
        if (!disableSelection) {
            if (scopeSelection) {
                if (key?.laydown_id === null || key?.laydown_id === currentLaydownData) {
                    let rowName = `trow-${key?.tracker_row_id}-${key?.tracker_type_id}-${key?.row}-${key?.section}`;
                    if (!selectedRow.includes(rowName)) {
                        setSelectedRow(
                            produce((draft) => {
                                draft.push(rowName);
                            })
                        );
                        let tempSelectedRefValue = [...selectedRowRef.current]
                        tempSelectedRefValue.push(rowName)
                        selectedRowRef.current = tempSelectedRefValue
                    } else {
                        setSelectedRow(
                            produce((draft) => {
                                draft.splice(
                                    draft.findIndex((r) => r === rowName),
                                    1
                                );
                            })
                        );
                        let tempSelectedRefValue = [...selectedRowRef.current]
                        tempSelectedRefValue.splice(
                            selectedRowRef.current.findIndex((r) => r === rowName),
                            1
                        )
                        selectedRowRef.current = tempSelectedRefValue
                    }
                }
            }
            else {
                let rowName = `trow-${key?.tracker_row_id}-${key?.tracker_type_id}-${key?.row}-${key?.section}`;
                if (!selectedRowRef.current.includes(rowName)) {
                    setSelectedRow(
                        produce((draft) => {
                            draft.push(rowName);
                        })
                    );
                    selectedRowRef.current.push(rowName);
                } else {
                    setSelectedRow(
                        produce((draft) => {
                            draft.splice(
                                draft.findIndex((r) => r === rowName),
                                1
                            );
                        })
                    );
                    selectedRowRef.current.splice(
                        selectedRowRef.current.findIndex((r) => r === rowName),
                        1
                    );
                }
            }
        }
    }

    useEffect(() => {
        if (enableGPSLocation === true) {
            const watchId = navigator.geolocation.watchPosition(
                (pos) => {
                    const { latitude, longitude } = pos.coords;
                    const [east, north] = proj4(`+proj=utm +zone=60 +north +ellps=WGS84 +datum=WGS84 +units=m +no_defs`).forward([longitude, latitude]);
                    setPosition({
                        latitude: east,
                        longitude: north
                    });
                },
                (err) => {
                    console.error(err);
                }
            );
            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        } else {
            setPosition([0, 0])
        }
    }, [enableGPSLocation]);

    useEffect(() => {
        init();
    }, [selectedRow])

    useEffect(() => {
        sectionCallFn()
    }, [bulkAllData, currentLaydownData])

    useEffect(() => {
        if (selectAll === true && bulkAllData?.piers) {
            let temp = new Set()
            for (let pr in bulkAllData?.piers) {
                for (let pierIndex in bulkAllData?.piers[pr]) {
                    let pierData = bulkAllData?.piers[pr][pierIndex]
                    temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                }
            }
            setSelectedRow(Array.from(temp))
            selectedRowRef.current = Array.from(temp)
        }
    }, [selectAll])

    useEffect(() => {
        if (bulkAllData?.piers) {
            let temp = new Set()
            for (let pr in bulkAllData?.piers) {
                for (let pierIndex in bulkAllData?.piers[pr]) {
                    let pierData = bulkAllData?.piers[pr][pierIndex]
                    section?.map((sectionNumber) => {
                        if (pierData.section === parseInt(sectionNumber)) {
                            if (scopeSelection) {
                                if (pierData?.laydown_id === null || pierData?.laydown_id === currentLaydownData) {
                                    temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                                }
                            }
                            else {
                                temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                            }
                        }
                    })
                }
            }
            selectedRow.map((i) => {
                if (prevSection !== parseInt(i.split("-")[4])) {
                    temp.add(i)
                }
            })
            setSelectedRow(Array.from(temp))
            selectedRowRef.current = Array.from(temp)
        }
    }, [section])

    useEffect(() => {
        if (bulkAllData?.piers && trackerSelect) {
            let temp = new Set()
            for (let pr in bulkAllData?.piers) {
                for (let pierIndex in bulkAllData?.piers[pr]) {
                    let pierData = bulkAllData?.piers[pr][pierIndex]
                    trackerSelect?.map((tracker) => {
                        if (pierData.tracker_type_id === parseInt(tracker)) {
                            temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        }
                    })
                }
            }
            selectedRow.map((i) => {
                if (prevTracker !== parseInt(i.split("-")[2])) {
                    temp.add(i)
                }
            })
            setSelectedRow(Array.from(temp))
            selectedRowRef.current = Array.from(temp)
        }
    }, [trackerSelect])

    useEffect(() => {
        setSelectedRow([])
        selectedRowRef.current = []
    }, [clearSelection, typeData])

    const sectionCallFn = async () => {
        try {
            if (bulkAllData) {
                let temp = new Set()
                for (let i of reArrangeBlockPierData(bulkAllData)) {
                    temp.add(i.section)
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const init = async () => {
        try {
            setSelectedRow(selectedRow)
            selectedRowRef.current = selectedRow
        } catch (err) {
            console.log(err);
        }
    };

    function isNumber(value) {
        return typeof value === 'number';
    }

    function partNumberSplit(data) {
        if (isNumber(data)) {
            return data
        }
        else {
            let temp = data?.split(".")[0]
            return temp
        }
    }

    function reArrangebulkAllData(data) {
        let temp = []
        let trackerRows = []
        for (let key in data.tt) {
            trackerRows.push(key)
        }
        for (let tracker in trackerRows) {
            temp = temp.concat(data.tt[trackerRows[tracker]])
        }
        return temp
    }

    function reArrangeBlockPierData(data) {
        let temp = []
        let trackerRows = []
        if (data?.piers) {
            for (let key in data?.piers) {
                trackerRows.push(key)
            }
            for (let tracker in trackerRows) {
                temp = temp.concat(data?.piers[trackerRows[tracker]])
            }
        }
        return temp
    }

    useEffect(() => {
        if (type === "CP" && userSelection.showType === "torque tube") {
            if (downloadD3) {
                renderMapDownload(bulkAllData?.tt)
            }
            renderMap(bulkAllData?.tt)
        }
        else {
            if (bulkAllData?.piers) {
                if (downloadD3) {
                    renderMapDownload(bulkAllData?.piers)
                }
                renderMap(bulkAllData?.piers)
            }
        }
    }, [bulkAllData, typeData, position, disableSelection, userSelection.selection, selectedRow, typeData, userSelection.showType, adjust, currentLaydownData]);

    const findSvgWidth = () => {
        let tempTT = new Set()
        let temp = new Set()
        let result
        if (type === "CP" && userSelection.showType === "torque tube") {
            reArrangebulkAllData(bulkAllData)?.map((i) => {
                tempTT.add(i?.row)
            })
            result = Array.from(tempTT).length * 26
        }
        else {
            reArrangeBlockPierData(bulkAllData)?.map((i) => {
                temp.add(i?.row)
            })
            result = Array.from(temp).length * 26
        }
        return result
    }

    const getColorCodeQC = (values) => {
        let temp = `${values.split("-")[1]}_${userSelection.selection}`
        let successCount = []
        let failCount = []
        let notCount = []
        typeData[temp]?.map((i) => {
            if (i?.qc_progress_status === 0 || i?.qc_progress_status === null) {
                notCount.push(notCount.length + 1)
            }
            else if (i?.qc_progress_status === 1) {
                successCount.push(successCount.length + 1)
            }
            else if (i?.qc_progress_status === 2) {
                failCount.push(failCount.length + 1)
            }
        })
        if (successCount.length === typeData[temp]?.length) {
            return "#66f1ad"
        }
        else if (failCount.length > 0) {
            return "red"
        }
        else {
            return "white"
        }
    }

    const getColorCodeQCIndi = (data, ttDataLatitude, pierData01Longitude, pierData02Longitude) => {
        let temp = `${data.tracker_row_id}_${data.id}_${userSelection.selection}`
        let successCount = []
        let failCount = []
        let notCount = []
        typeData[temp]?.map((i) => {
            if (i?.qc_progress_status === 0 || i?.qc_progress_status === null) {
                notCount.push(notCount.length + 1)
            }
            else if (i?.qc_progress_status === 1) {
                successCount.push(successCount.length + 1)
            }
            else if (i?.qc_progress_status === 2) {
                failCount.push(failCount.length + 1)
            }
        })
        if (typeData[temp]) {
            if (userSelection.selection === "piers") {
                if (successCount.length === typeData[temp]?.length) {
                    return d3.select(containerRef.current)
                        .append("rect")
                        .attr("class", "react-pier-in-progress")
                        .attr("x", data.latitude - 7.5)
                        .attr("y", data.longitude - 7.5)
                        .attr("width", "15px")
                        .attr("height", "15px")
                        .attr("stroke", "#66f1ad")
                        .attr("fill", "#66f1ad")
                }
                else if (failCount.length > 0) {
                    return d3.select(containerRef.current)
                        .append("rect")
                        .attr("class", "react-pier-in-progress")
                        .attr("x", data.latitude - 7.5)
                        .attr("y", data.longitude - 7.5)
                        .attr("width", "15px")
                        .attr("height", "15px")
                        .attr("stroke", "red")
                        .attr("fill", "red")
                }
            }
            else {
                if (successCount.length === typeData[temp]?.length) {
                    return d3.select(containerRef.current)
                        .append("rect")
                        .attr("class", "react-pier-in-progress")
                        .attr("x", ttDataLatitude - 6)
                        .attr("y", pierData02Longitude)
                        .attr("width", "13px")
                        .attr("height", pierData01Longitude - pierData02Longitude)
                        .attr("stroke", selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) ? "#F6BE00" : "#66f1ad")
                        .attr("fill", selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) ? "#F6BE00" : "#66f1ad")
                }
                else if (failCount.length > 0) {
                    return d3.select(containerRef.current)
                        .append("rect")
                        .attr("class", "react-pier-in-progress")
                        .attr("x", ttDataLatitude - 6)
                        .attr("y", pierData02Longitude)
                        .attr("width", "13px")
                        .attr("height", pierData01Longitude - pierData02Longitude)
                        .attr("stroke", selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) ? "#F6BE00" : "red")
                        .attr("fill", selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) ? "#F6BE00" : "red")
                }
            }
        }
    }

    const getTorqueTubeColor = (values, selection, completionData, rowId) => {
        let item
        if (selection === "All") {
            item = completionData
        }
        else {
            item = completionData?.completion_percentage
        }
        if (type === "CP" && ['bha', 'pier', 'part number', 'torque tube'].includes(userSelection.showType)) {
            if (Math.round(item) === 100) {
                return selectedRow.includes(values) ? "#F6BE00" : "#66f1ad"
            }
            else if (Math.round(item) > 0 && Math.round(item) < 100) {

                if (selection === "All" || (completionData?.piers?.length === 0 && completionData?.torque_tubes?.length === 0)) {
                    return selectedRow.includes(values) ? "#F6BE00" : "#FC8EAC"
                } else if (selection !== "All" && ((completionData?.piers?.length === 0 && completionData?.torque_tubes?.length === 0) || (completionData?.piers === undefined && completionData?.torque_tubes === undefined))) {
                    return selectedRow.includes(values) ? "#F6BE00" : "#FC8EAC"
                }
                else {
                    return selectedRow.includes(values) ? "#F6BE00" : "white"
                }
            }
            else {
                return selectedRow.includes(values) ? "#F6BE00" : "white"
            }
        } else if (type === 'lock') {
            return selectedRow.includes(values) ? "#F6BE00" : typeData?.[rowId]?.[userSelection.selection] ? "#529ef1" : '#FFF'
        }
        else if (type === "QC") {
            return getColorCodeQC(values)
        }
    }

    function trackerHoverText(data) {
        let temp
        if (showLaydownNameHover) {
            if (data?.laydown_id) {
                temp = `Laydown-${laydownData.find(o => o?.id === data?.laydown_id)?.name}`
            }
        }
        else {
            temp = `Section-${data?.section}`
        }
        return temp
    }

    const renderMap = async (renderData) => {

        containerRef.current.innerHTML = "";

        let wrapper = document.getElementById("wrapper");

        let xScalerangeVal = (type === "CP" && userSelection.showType === "pier") || type === "lock" ? 100 : 50

        let xScale = scaleLinear()
            .domain([
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.minimum_latitude : mapSize?.piers?.minimum_latitude,
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.maximum_latitude : mapSize?.piers?.maximum_latitude,
            ])
            .range([xScalerangeVal, wrapper.offsetWidth - xScalerangeVal]);

        let yScalerangeVal = (type === "CP" && userSelection.showType === "pier") || type === "lock" ? 100 : 50

        let yScale = scaleLinear()
            .domain([
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.maximum_longitude : mapSize?.piers?.maximum_longitude,
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.minimum_longitude : mapSize?.piers?.minimum_longitude,
            ])
            .range([yScalerangeVal, wrapper.offsetHeight - yScalerangeVal]);

        for (let pr in bulkAllData?.tt) {
            ttObjs[`trow-${pr}`] = bulkAllData?.tt[pr]
        }

        for (let pr in renderData) {
            for (let pierIndex in renderData[pr]) {
                let pierData = renderData[pr][pierIndex]

                let lineObj = lineObjs[`trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}-${pierData["tracker_row_type"]}`]
                if (!lineObj) {
                    lineObjs[`trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}-${pierData["tracker_row_type"]}`] = {
                        x: [xScale(pierData["latitude"])],
                        y: [yScale(pierData["longitude"])]
                    }
                }
                if (lineObj) {
                    lineObjs[`trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}-${pierData["tracker_row_type"]}`] = {
                        x: lineObj?.x.concat([xScale(pierData["latitude"])]),
                        y: lineObj?.y.concat([yScale(pierData["longitude"])])
                    }
                }

                let rowObj =
                    rowObjs[
                    `trow-${pierData["row"]}`
                    ];

                if (!rowObj) {
                    rowObjs[
                        `trow-${pierData["row"]}`
                    ] = {
                        x: [xScale(pierData["latitude"])],
                        y: [yScale(pierData["longitude"])]
                    };
                }
                if (rowObj) {
                    rowObjs[
                        `trow-${pierData["row"]}`
                    ] = {
                        x: rowObj?.x.concat([xScale(pierData["latitude"])]),
                        y: rowObj?.y.concat([yScale(pierData["longitude"])]),
                    };
                }

                let selectObj =
                    selectObjs[
                    `trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}`
                    ];

                if (!selectObj) {
                    selectObjs[
                        `trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}`
                    ] = {
                        x: [xScale(pierData["latitude"])],
                        y: [yScale(pierData["longitude"])],
                    };
                }
                if (selectObj) {
                    selectObjs[
                        `trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}`
                    ] = {
                        x: selectObj?.x.concat([xScale(pierData["latitude"])]),
                        y: selectObj?.y.concat([yScale(pierData["longitude"])]),
                    };
                }

                let sectionObj =
                    sectionObjs[
                    `tsection-${pierData["section"]}`
                    ];

                if (!sectionObj) {
                    sectionObjs[
                        `tsection-${pierData["section"]}`
                    ] = [{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"]),
                    }];
                }
                if (sectionObj) {
                    sectionObjs[
                        `tsection-${pierData["section"]}`
                    ] = sectionObj.concat([{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"]),
                    }]);
                }

                let labelObj = labelObjs[`tlabel-${pierData["section"]}-${pierData["label"]}`]

                if (!labelObj) {
                    labelObjs[`tlabel-${pierData["section"]}-${pierData["label"]}`] = [{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"])
                    }]
                }
                if (labelObj) {
                    labelObjs[
                        `tlabel-${pierData["section"]}-${pierData["label"]}`
                    ] = labelObj.concat([{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"])
                    }]);
                }

                let pierObj = pierObjs[`pier-${getPrLegend(partNumberSplit(pierData.part_number)).shape}-${getPrLegend(partNumberSplit(pierData.part_number)).concentric}-${partNumberSplit(pierData.part_number)}`]

                let pierDatas = {
                    bha_sg_labels: pierData?.bha_sg_labels,
                    block: pierData?.block,
                    block_id: pierData?.block_id,
                    id: pierData?.id,
                    latitude: xScale(pierData?.latitude),
                    longitude: yScale(pierData?.longitude),
                    pier_part_order: pierData?.pier_part_order,
                    row: pierData?.row,
                    section: pierData?.section,
                    torque_tube_label: pierData?.torque_tube_label,
                    laydown_id: pierData?.laydown_id ? pierData?.laydown_id : null,
                    tracker_row_id: pr,
                    damper_label: pierData?.damper_label,
                    pier_type_motor: pierData?.pier_type_motor,
                    tracker_type_id: pierData?.tracker_type_id,
                    stroke: getPrLegend(partNumberSplit(pierData?.part_number))?.border ? getPrLegend(partNumberSplit(pierData?.part_number))?.border : "black",
                    fill: getPrLegend(partNumberSplit(pierData?.part_number))?.background ? getPrLegend(partNumberSplit(pierData?.part_number))?.background : "white",
                    stroke_width: getPrLegend(partNumberSplit(pierData?.part_number))?.width ? getPrLegend(partNumberSplit(pierData?.part_number))?.width : 1,
                    map_colour: getPrLegend(partNumberSplit(pierData?.part_number))?.map_colour ? getPrLegend(partNumberSplit(pierData?.part_number))?.map_colour : "#4287f5"
                }

                if (!pierObj) {
                    pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`] = [
                        pierDatas
                    ]
                }

                if (pierObj) {
                    pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`] = pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`].concat(pierDatas)
                }
                // if (scopeSelection && currentLaydownData !== pierData?.laydown_id && pierData?.laydown_id !== null) {
                //     d3.select(containerRef.current)
                //         .append("rect")
                //         .attr("x", Math.min.apply(Math, selectObjs[key]?.x) - 7.5)
                //         .attr("y", Math.min.apply(Math, selectObjs[key]?.y) - 6)
                //         .attr("width", "15px")
                //         .attr(
                //             "height",
                //             Math.max.apply(Math, selectObjs[key]?.y) -
                //             Math.min.apply(Math, selectObjs[key]?.y) + 12
                //         )
                //         .attr("fill", "gray");
                // }
            }
        }

        if (scopeSelection) {
            for (let key in selectObjs) {
                let trackerData = bulkAllData?.piers[key?.split("-")[1]]
                if (trackerData[0]?.laydown_id !== currentLaydownData && trackerData[0]?.laydown_id !== null) {
                    d3.select(containerRef.current)
                        .append("rect")
                        .attr("x", Math.min.apply(Math, selectObjs[key]?.x) - 7.5)
                        .attr("y", Math.min.apply(Math, selectObjs[key]?.y) - 6)
                        .attr("width", "15px")
                        .attr(
                            "height",
                            Math.max.apply(Math, selectObjs[key]?.y) -
                            Math.min.apply(Math, selectObjs[key]?.y) + 12
                        )
                        .attr("fill", "green")
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(trackerData[0]));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                }
            }
        }


        for (let key in rowObjs) {

            let rowNumber = key.split('-')[1]

            d3.select(containerRef.current)
                .append("path")
                .attr("d", "M 6 -5 L 15 0 L 15 9 L 6 15 L -3 9 L -3 0 Z")
                .attr("transform", `translate(${Math.min.apply(Math, rowObjs[key]?.x) - 12 / 2}, ${Math.min.apply(Math, rowObjs[key]?.y) - 30 - 12 / 2})`)
                .attr("stroke", "black")
                .attr("stroke-width", "1px")
                .attr("fill", "white")


            d3.select(containerRef.current)
                .append("text")
                .attr("fill", "black")
                .attr("x", Math.min.apply(Math, rowObjs[key]?.x))
                .attr("y", Math.min.apply(Math, rowObjs[key]?.y) - 27.5)
                .attr("text-anchor", "middle")
                .style("font-size", "10px")
                .style("font-weight", "600")
                .text(rowNumber)


            d3.select(containerRef.current)
                .append("path")
                .attr("d", "M 6 -5 L 15 0 L 15 9 L 6 15 L -3 9 L -3 0 Z")
                .attr("transform", `translate(${Math.max.apply(Math, rowObjs[key]?.x) - 12 / 2}, ${Math.max.apply(Math, rowObjs[key]?.y) + 40 - 12 / 2})`)
                .attr("stroke", "black")
                .attr("stroke-width", "1px")
                .attr("fill", "white")

            d3.select(containerRef.current)
                .append("text")
                .attr("fill", "black")
                .attr("x", Math.max.apply(Math, rowObjs[key]?.x))
                .attr("y", Math.max.apply(Math, rowObjs[key]?.y) + 42)
                .attr("text-anchor", "middle")
                .style("font-size", "10px")
                .style("font-weight", "600")
                .text(rowNumber)
        }

        for (let key of selectedRow) {
            d3.select(containerRef.current)
                .append("rect")
                .attr("x", Math.min.apply(Math, selectObjs[key]?.x) - 7.5)
                .attr("y", Math.min.apply(Math, selectObjs[key]?.y) - 6)
                .attr("width", "15px")
                .attr(
                    "height",
                    Math.max.apply(Math, selectObjs[key]?.y) -
                    Math.min.apply(Math, selectObjs[key]?.y) + 12
                )
                .attr("fill", "#F6BE00");
        }

        if (userSelection.selection !== "None Selected") {
            for (let key in selectObjs) {
                let trackerRowId = key.split("-")[1]
                let activityId = userSelection.selection;
                let pk = userSelection.selection === "All" ? `${trackerRowId}_all` : `${trackerRowId}_${toLower(activityId)}`
                let trackerRowWiseValue = typeData[pk]
                d3.select(containerRef.current)
                    .append("rect")
                    .attr("x", Math.min.apply(Math, selectObjs[key]?.x) - 7.5)
                    .attr("y", Math.min.apply(Math, selectObjs[key]?.y) - 6)
                    .attr("width", "15px")
                    .attr(
                        "height",
                        Math.max.apply(Math, selectObjs[key]?.y) -
                        Math.min.apply(Math, selectObjs[key]?.y) + 12
                    )
                    .attr("fill", getTorqueTubeColor(key, userSelection.selection, trackerRowWiseValue, trackerRowId));
            }
        }


        for (let ttKey in ttObjs) {
            let key = ttKey.split("-")[1]
            let activityId = userSelection.selection;
            let pk = userSelection.selection === "All" ? `${key}_all` : `${key}_${toLower(activityId)}`
            let trackerRowWiseValue = typeData[pk]
            let torqueTubeInProgressData = []
            if (userSelection.selection !== "All" && trackerRowWiseValue?.status === 1 && trackerRowWiseValue?.piers?.length > 0 && trackerRowWiseValue?.torque_tubes?.length > 0) {
                trackerRowWiseValue?.torque_tubes.map((ttMap) => {
                    if (ttMap.status === 1) {
                        torqueTubeInProgressData.push(ttMap.torque_tube_id)
                    }
                })
                // torqueTubeInProgressData = trackerRowWiseValue?.torque_tubes
            }
            for (let trackerData in ttObjs[ttKey]) {
                let pierData01 = bulkAllData?.piers[key][trackerData]
                let ttData = ttObjs[ttKey][trackerData]
                let pierData02 = bulkAllData?.piers[key][parseInt(trackerData) + 1]
                if (type === "QC") {
                    getColorCodeQCIndi(ttData, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude))
                }
                if (ttData.tt_part_tracker_row_type === 0) {
                    // ttExterior(downloadRef, xScale(ttData.latitude), yScale(pierData01.longitude), yScale(pierData02.longitude), torqueTubeInProgressData.includes(ttData?.id))
                    ttExterior(containerRef, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
                else if (ttData.tt_part_tracker_row_type === 1) {
                    // ttInterior(downloadRef, xScale(ttData.latitude), yScale(pierData01.longitude), yScale(pierData02.longitude), torqueTubeInProgressData.includes(ttData?.id))
                    ttInterior(containerRef, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
                else if (ttData.tt_part_tracker_row_type === 2) {
                    if (pierData02) {
                        ttEdge(containerRef, xScale(pierData01?.latitude), yScale(pierData01?.longitude), xScale(pierData02?.latitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "30px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(ttData));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", (() => handleSelectTracker(ttData)))
                    }
                    // ttEdge(downloadRef, xScale(pierData01.latitude), yScale(pierData01.longitude), xScale(pierData02.latitude), yScale(pierData02.longitude), torqueTubeInProgressData.includes(ttData?.id))
                }
                else {
                    // ttExterior(downloadRef, xScale(ttData.latitude), yScale(pierData01.longitude), yScale(pierData02.longitude))
                    ttExterior(containerRef, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
            }
        }

        for (let pierData in pierObjs) {
            for (let data of pierObjs[pierData]) {
                let activityId = userSelection.selection;
                let pk = userSelection.selection === "All" ? `${parseInt(data?.tracker_row_id)}_all` : `${parseInt(data?.tracker_row_id)}_${toLower(activityId)}`
                let trackerRowWiseValue = typeData[pk]
                let piersInProgressData = []
                if (userSelection.selection !== "All" && trackerRowWiseValue?.status === 1 && trackerRowWiseValue?.piers?.length > 0 && trackerRowWiseValue?.torque_tubes?.length > 0) {
                    piersInProgressData = trackerRowWiseValue?.piers
                }
                if (piersInProgressData.length > 0) {
                    for (let pierIndex in piersInProgressData) {
                        if (!selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) && piersInProgressData[pierIndex].status === 1 && piersInProgressData[pierIndex].pier_id === data?.id) {
                            d3.select(containerRef.current)
                                .append("rect")
                                .attr("class", "react-pier-in-progress")
                                .attr("x", data.latitude - (userSelection.showType === "pier" ? 7.5 : 20))
                                .attr("y", data.longitude - (userSelection.showType === "pier" ? 7.5 : 3))
                                .attr("width", userSelection.showType === "pier" ? "15px" : 40)
                                .attr("height", userSelection.showType === "pier" ? "15px" : 25)
                                .attr("stroke", selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) ? "#F6BE00" : "#66f1ad")
                                .attr("fill", selectedRow.includes(`trow-${data?.tracker_row_id}-${data?.tracker_type_id}-${data?.row}-${data?.section}`) ? "#F6BE00" : "#66f1ad")

                        }
                    }
                }
                if ((type === "CP" && userSelection.showType === "pier") || type === "lock" || type === "QC") {
                    if (type === "QC") {
                        getColorCodeQCIndi(data)
                    }
                    if (pierData.split("-")[1] === "Square") {
                        d3.select(containerRef.current)
                            .append("rect")
                            .attr("class", `pier-${data.id} cursor-pointer`)
                            .attr("id", `pier-${data.id}-${pierData.split("-")[3]}`)
                            .attr("height", 7)
                            .attr("width", 7)
                            .attr("x", data.latitude - 7 / 2)
                            .attr("y", data.longitude - 7 / 2)
                            .attr("stroke", data.stroke)
                            .attr("fill", data.fill)
                            .attr("stroke-width", `${data.stroke_width}px`)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    else if (pierData.split("-")[1] === "Diamond") {
                        d3.select(containerRef.current)
                            .append("rect")
                            .attr("class", `pier-${data?.id} cursor-pointer`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("height", 7)
                            .attr("width", 7)
                            .attr("x", data?.latitude - 7 / 2)
                            .attr("y", data?.longitude - 7 / 2)
                            .attr("transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(45) translate(-${data?.latitude}, -${data?.longitude})`)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", `${data?.stroke_width}px`)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    else if (pierData?.split("-")[1] === "Circle") {
                        d3.select(containerRef.current)
                            .append("circle")
                            .attr("class", `pier-${data?.id} cursor-pointer`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("cx", data?.latitude)
                            .attr("cy", data?.longitude)
                            .attr("r", 4)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", data?.stroke_width)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    else {
                        d3.select(containerRef.current)
                            .append("circle")
                            .attr("class", `pier-${data?.id} cursor-pointer`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("cx", data?.latitude)
                            .attr("cy", data?.longitude)
                            .attr("r", 4)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", data?.stroke_width)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    d3.select(containerRef.current)
                        .append("line")
                        .attr("class", "cursor-pointer")
                        .attr("x1", data?.latitude - 1)
                        .attr("y1", data?.longitude - 1)
                        .attr("x2", data?.latitude + 1)
                        .attr("y2", data?.longitude + 1)
                        .attr(
                            "transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(130) translate(-${data?.latitude}, -${data?.longitude})`
                        )
                        .attr("stroke", "black")
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "32px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(data));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", () => handleSelectTracker(data))

                    if (type === "CP" && userSelection?.showType === "pier" && data?.damper_label === "true") {
                        d3.select(containerRef.current)
                            .append("line")
                            .attr("class", "cursor-pointer")
                            .attr("x1", data?.latitude - 6)
                            .attr("y1", data?.longitude - 6)
                            .attr("x2", data?.latitude + 6)
                            .attr("y2", data?.longitude + 6)
                            .attr(
                                "transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(180) translate(-${data?.latitude}, -${data?.longitude})`
                            )
                            .attr("stroke", "black")
                            .attr("stroke-width", 2)

                        d3.select(containerRef.current)
                            .append("line")
                            .attr("class", "cursor-pointer")
                            .attr("x1", data?.latitude - 6)
                            .attr("y1", data?.longitude - 6)
                            .attr("x2", data?.latitude + 6)
                            .attr("y2", data?.longitude + 6)
                            .attr(
                                "transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(90) translate(-${data?.latitude}, -${data?.longitude})`
                            )
                            .attr("stroke", "black")
                            .attr("stroke-width", 2)
                    }
                }
                else {
                    let colorCountData = getColorCode(pierData?.split("-")[3], data)
                    let colorCountArr = colorCountData?.split("-")
                    if (colorCountArr?.length > 0) {
                        colorCountArr.map((i, index) => {
                            return (
                                d3.select(containerRef.current)
                                    .append("rect")
                                    .attr("class", `nonpier-${data?.id} cursor-pointer`)
                                    .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                                    .attr("height", 20 / (colorCountArr.length))
                                    .attr("width", 35)
                                    .attr("x", (data?.latitude - 35 / 2))
                                    .attr("y", data?.longitude + (index * (20 / (colorCountArr.length))))
                                    .attr("stroke", () => {
                                        // const value = getColorCode(pierData?.split("-")[3])
                                        return i
                                    })
                                    .attr("fill", () => {
                                        // const value = getColorCode(pierData?.split("-")[3])
                                        return i
                                    })
                                    .attr("stroke-width", data?.stroke_width)
                                    .on("mouseover", (evt) => {
                                        const [mx, my] = d3.pointer(evt);
                                        tooltip
                                            .attr("x", mx + 2)
                                            .attr("y", my + 32)
                                            .attr("fill", "black")
                                            .style("font-size", "32px")
                                            .style("font-weight", "700")
                                            .text(trackerHoverText(data));
                                    })
                                    .on("mouseout", () => {
                                        tooltip.text("");
                                    })
                                    .on("click", () => handleSelectTracker(data))
                            )
                        })
                    }
                    else {
                        d3.select(containerRef.current)
                            .append("rect")
                            .attr("class", `nonpier-${data?.id} cursor-pointer`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("height", 20)
                            .attr("width", 35)
                            .attr("x", data?.latitude - 35 / 2)
                            .attr("y", data?.longitude)
                            .attr("stroke", () => {
                                const value = getColorCode(pierData?.split("-")[3], data)
                                return value
                            })
                            .attr("fill", () => {
                                const value = getColorCode(pierData?.split("-")[3], data)
                                return value
                            })
                            .attr("stroke-width", data?.stroke_width)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }

                    d3.select(containerRef.current)
                        .append("text")
                        .attr("class", "cursor-pointer")
                        .attr("fill", getContrastColor(getColorCode(pierData?.split("-")[3], data)))
                        .attr("x", data?.latitude)
                        .attr("y", data?.longitude + 13)
                        .attr("text-anchor", "middle")
                        .style("font-size", "9px")
                        .style("font-weight", "600")
                        .text(() => {
                            if (type === "CP" && userSelection.showType === 'bha') {
                                if (data?.bha_sg_labels === 'Extra Heavy') {
                                    return 'E. Heavy'
                                } else {
                                    return data?.bha_sg_labels
                                }
                            } else {
                                return pierData?.split("-")[3]
                            }
                        })
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "32px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(data));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", () => handleSelectTracker(data))
                }
                if ((type === "CP" && userSelection.showType === "torque tube")) {
                    let motarData = bulkAllData?.piers[parseInt(data?.tracker_row_id)].find(o => o?.pier_type_motor === 1)
                    d3.select(containerRef.current)
                        .append("circle")
                        .attr("class", `motar-pier-circle`)
                        .attr("id", `motar-pier-${motarData?.id}-${parseInt(data?.tracker_row_id)}`)
                        .attr("r", 8)
                        .attr("cx", xScale(motarData?.latitude))
                        .attr("cy", yScale(motarData?.longitude) + 10)
                        .attr("fill", "#F16253")

                    d3.select(containerRef.current)
                        .append("text")
                        .attr("class", `motar-pier-text`)
                        .attr("fill", "white")
                        .attr("id", `motar-pier-text-${motarData?.id}-${parseInt(data?.tracker_row_id)}`)
                        .attr("x", xScale(motarData?.latitude))
                        .attr("y", yScale(motarData?.longitude) + 13)
                        .attr("text-anchor", "middle")
                        .style("font-size", "9px")
                        .style("font-weight", "600")
                        .text("M")
                }

            }
        }

        if (deviceType !== "web" && enableGPSLocation) {
            d3.select(containerRef.current)
                .append("circle")
                .attr("fill", "#A8BEE2")
                .attr("cx", xScale(position?.latitude))
                .attr("cy", yScale(position?.longitude))
                .attr("r", 9);
            d3.select(containerRef.current)
                .append("circle")
                .attr("fill", "white")
                .attr("cx", xScale(position?.latitude))
                .attr("cy", yScale(position?.longitude))
                .attr("r", 5);
            d3.select(containerRef.current)
                .append("circle")
                .attr("fill", "#3A72B6")
                .attr("cx", xScale(position?.latitude))
                .attr("cy", yScale(position?.longitude))
                .attr("r", 4); // Adjust the radius as needed
        }

        const tooltip = d3
            .select(containerRef.current)
            .append("text")
            .attr("class", "tooltip")
            .attr("fill", "white")
            .style("pointer-events", "none")

        await new Promise(resolve => setTimeout(resolve, 1500));
        setLoading(false)

    };

    const renderMapDownload = (renderData) => {
        if (downloadRef.current) {
            downloadRef.current.innerHTML = "";
        }

        let wrapper = document.getElementById("wrapper");

        let xScalerangeVal = (type === "CP" && userSelection.showType === "pier") || type === "lock" ? 100 : 50

        let xScale = scaleLinear()
            .domain([
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.minimum_latitude : mapSize?.piers?.minimum_latitude,
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.maximum_latitude : mapSize?.piers?.maximum_latitude,
            ])
            .range([xScalerangeVal, wrapper.offsetWidth - xScalerangeVal]);

        let yScalerangeVal = (type === "CP" && userSelection.showType === "pier") || type === "lock" ? 100 : 50

        let yScale = scaleLinear()
            .domain([
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.maximum_longitude : mapSize?.piers?.maximum_longitude,
                (type === "CP" && userSelection.showType === "torque tube") ? mapSize?.tt?.minimum_longitude : mapSize?.piers?.minimum_longitude,
            ])
            .range([yScalerangeVal, wrapper.offsetHeight - yScalerangeVal]);

        for (let pr in bulkAllData?.tt) {
            ttObjs[`trow-${pr}`] = bulkAllData?.tt[pr]
        }

        for (let pr in renderData) {
            for (let pierIndex in renderData[pr]) {
                let pierData = renderData[pr][pierIndex]

                let lineObj = lineObjs[`trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}-${pierData["tracker_row_type"]}`]
                if (!lineObj) {
                    lineObjs[`trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}-${pierData["tracker_row_type"]}`] = {
                        x: [xScale(pierData["latitude"])],
                        y: [yScale(pierData["longitude"])]
                    }
                }
                if (lineObj) {
                    lineObjs[`trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}-${pierData["tracker_row_type"]}`] = {
                        x: lineObj?.x.concat([xScale(pierData["latitude"])]),
                        y: lineObj?.y.concat([yScale(pierData["longitude"])])
                    }
                }

                let rowObj =
                    rowObjs[
                    `trow-${pierData["row"]}`
                    ];

                if (!rowObj) {
                    rowObjs[
                        `trow-${pierData["row"]}`
                    ] = {
                        x: [xScale(pierData["latitude"])],
                        y: [yScale(pierData["longitude"])]
                    };
                }
                if (rowObj) {
                    rowObjs[
                        `trow-${pierData["row"]}`
                    ] = {
                        x: rowObj?.x.concat([xScale(pierData["latitude"])]),
                        y: rowObj?.y.concat([yScale(pierData["longitude"])]),
                    };
                }

                let selectObj =
                    selectObjs[
                    `trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}`
                    ];

                if (!selectObj) {
                    selectObjs[
                        `trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}`
                    ] = {
                        x: [xScale(pierData["latitude"])],
                        y: [yScale(pierData["longitude"])],
                    };
                }
                if (selectObj) {
                    selectObjs[
                        `trow-${pr}-${pierData["tracker_type_id"]}-${pierData["row"]}-${pierData["section"]}`
                    ] = {
                        x: selectObj?.x.concat([xScale(pierData["latitude"])]),
                        y: selectObj?.y.concat([yScale(pierData["longitude"])]),
                    };
                }

                let sectionObj =
                    sectionObjs[
                    `tsection-${pierData["section"]}`
                    ];

                if (!sectionObj) {
                    sectionObjs[
                        `tsection-${pierData["section"]}`
                    ] = [{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"]),
                    }];
                }
                if (sectionObj) {
                    sectionObjs[
                        `tsection-${pierData["section"]}`
                    ] = sectionObj.concat([{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"]),
                    }]);
                }

                let labelObj = labelObjs[`tlabel-${pierData["section"]}-${pierData["label"]}`]

                if (!labelObj) {
                    labelObjs[`tlabel-${pierData["section"]}-${pierData["label"]}`] = [{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"])
                    }]
                }
                if (labelObj) {
                    labelObjs[
                        `tlabel-${pierData["section"]}-${pierData["label"]}`
                    ] = labelObj.concat([{
                        x: xScale(pierData["latitude"]),
                        y: yScale(pierData["longitude"])
                    }]);
                }

                let pierObj = pierObjs[`pier-${getPrLegend(partNumberSplit(pierData.part_number)).shape}-${getPrLegend(partNumberSplit(pierData.part_number)).concentric}-${partNumberSplit(pierData.part_number)}`]

                let pierDatas = {
                    bha_sg_labels: pierData?.bha_sg_labels,
                    block: pierData?.block,
                    block_id: pierData?.block_id,
                    id: pierData?.id,
                    latitude: xScale(pierData?.latitude),
                    longitude: yScale(pierData?.longitude),
                    pier_part_order: pierData?.pier_part_order,
                    row: pierData?.row,
                    section: pierData?.section,
                    torque_tube_label: pierData?.torque_tube_label,
                    laydown_id: pierData?.laydown_id ? pierData?.laydown_id : null,
                    tracker_row_id: pr,
                    damper_label: pierData?.damper_label,
                    pier_type_motor: pierData?.pier_type_motor,
                    tracker_type_id: pierData?.tracker_type_id,
                    stroke: getPrLegend(partNumberSplit(pierData?.part_number))?.border ? getPrLegend(partNumberSplit(pierData?.part_number))?.border : "black",
                    fill: getPrLegend(partNumberSplit(pierData?.part_number))?.background ? getPrLegend(partNumberSplit(pierData?.part_number))?.background : "white",
                    stroke_width: getPrLegend(partNumberSplit(pierData?.part_number))?.width ? getPrLegend(partNumberSplit(pierData?.part_number))?.width : 1,
                    map_colour: getPrLegend(partNumberSplit(pierData?.part_number))?.map_colour ? getPrLegend(partNumberSplit(pierData?.part_number))?.map_colour : "#4287f5"
                }

                if (!pierObj) {
                    pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`] = [
                        pierDatas
                    ]
                }

                if (pierObj) {
                    pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`] = pierObjs[`pier-${getPrLegend(partNumberSplit(pierData?.part_number))?.shape}-${getPrLegend(partNumberSplit(pierData?.part_number))?.concentric}-${partNumberSplit(pierData?.part_number)}`].concat(pierDatas)
                }
            }
        }

        for (let key in rowObjs) {

            let rowNumber = key.split('-')[1]

            d3.select(downloadRef.current)
                .append("path")
                .attr("d", "M 6 -5 L 15 0 L 15 9 L 6 15 L -3 9 L -3 0 Z")
                .attr("transform", `translate(${Math.min.apply(Math, rowObjs[key]?.x) - 12 / 2}, ${Math.min.apply(Math, rowObjs[key]?.y) - 30 - 12 / 2})`)
                .attr("stroke", "black")
                .attr("stroke-width", "1px")
                .attr("fill", "white")


            d3.select(downloadRef.current)
                .append("text")
                .attr("fill", "black")
                .attr("x", Math.min.apply(Math, rowObjs[key]?.x))
                .attr("y", Math.min.apply(Math, rowObjs[key]?.y) - 27.5)
                .attr("text-anchor", "middle")
                .style("font-size", "10px")
                .style("font-weight", "600")
                .text(rowNumber)


            d3.select(downloadRef.current)
                .append("path")
                .attr("d", "M 6 -5 L 15 0 L 15 9 L 6 15 L -3 9 L -3 0 Z")
                .attr("transform", `translate(${Math.max.apply(Math, rowObjs[key]?.x) - 12 / 2}, ${Math.max.apply(Math, rowObjs[key]?.y) + 40 - 12 / 2})`)
                .attr("stroke", "black")
                .attr("stroke-width", "1px")
                .attr("fill", "white")

            d3.select(downloadRef.current)
                .append("text")
                .attr("fill", "black")
                .attr("x", Math.max.apply(Math, rowObjs[key]?.x))
                .attr("y", Math.max.apply(Math, rowObjs[key]?.y) + 42)
                .attr("text-anchor", "middle")
                .style("font-size", "10px")
                .style("font-weight", "600")
                .text(rowNumber)
        }

        for (let key of selectedRow) {
            d3.select(downloadRef.current)
                .append("rect")
                .attr("x", Math.min.apply(Math, selectObjs[key]?.x) - 7.5)
                .attr("y", Math.min.apply(Math, selectObjs[key]?.y) - 6)
                .attr("width", "15px")
                .attr(
                    "height",
                    Math.max.apply(Math, selectObjs[key]?.y) -
                    Math.min.apply(Math, selectObjs[key]?.y) + 12
                )
                .attr("fill", "#F6BE00");
        }

        if (userSelection.selection !== "None Selected") {
            for (let key in selectObjs) {
                let trackerRowId = key.split("-")[1]
                let activityId = userSelection.selection;
                let pk = userSelection.selection === "All" ? `${trackerRowId}_all` : `${trackerRowId}_${toLower(activityId)}`
                let trackerRowWiseValue = typeData[pk]

                d3.select(downloadRef.current)
                    .append("rect")
                    .attr("x", Math.min.apply(Math, selectObjs[key]?.x) - 7.5)
                    .attr("y", Math.min.apply(Math, selectObjs[key]?.y) - 6)
                    .attr("width", "15px")
                    .attr(
                        "height",
                        Math.max.apply(Math, selectObjs[key]?.y) -
                        Math.min.apply(Math, selectObjs[key]?.y) + 12
                    )
                    .attr("fill", getTorqueTubeColor(key, userSelection.selection, trackerRowWiseValue, trackerRowId));
            }
        }

        for (let ttKey in ttObjs) {
            let key = ttKey.split("-")[1]
            let activityId = userSelection.selection;
            let pk = userSelection.selection === "All" ? `${key}_all` : `${key}_${toLower(activityId)}`
            let trackerRowWiseValue = typeData[pk]
            let torqueTubeInProgressData = []
            if (userSelection.selection !== "All" && trackerRowWiseValue?.status === 1 && trackerRowWiseValue?.piers?.length > 0 && trackerRowWiseValue?.torque_tubes?.length > 0) {
                trackerRowWiseValue?.torque_tubes.map((ttMap) => {
                    if (ttMap.status === 1) {
                        torqueTubeInProgressData.push(ttMap.torque_tube_id)
                    }
                })
            }
            for (let trackerData in ttObjs[ttKey]) {
                let pierData01 = bulkAllData?.piers[key][trackerData]
                let ttData = ttObjs[ttKey][trackerData]
                let pierData02 = bulkAllData?.piers[key][parseInt(trackerData) + 1]
                if (ttData.tt_part_tracker_row_type === 0) {
                    ttExterior(downloadRef, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
                else if (ttData.tt_part_tracker_row_type === 1) {
                    ttInterior(downloadRef, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
                else if (ttData.tt_part_tracker_row_type === 2) {
                    ttEdge(downloadRef, xScale(pierData01?.latitude), yScale(pierData01?.longitude), xScale(pierData02?.latitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
                else {
                    ttExterior(downloadRef, xScale(ttData?.latitude), yScale(pierData01?.longitude), yScale(pierData02?.longitude), torqueTubeInProgressData.includes(ttData?.id), `trow-${key}-${ttData.tracker_type_id}-${ttData.row}-${ttData.section}`)
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "30px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(ttData));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", (() => handleSelectTracker(ttData)))
                }
            }
        }

        for (let pierData in pierObjs) {
            for (let data of pierObjs[pierData]) {
                if ((type === "CP" && userSelection.showType === "pier") || type === "lock" || type === "QC") {
                    if (pierData?.split("-")[1] === "Square") {
                        d3.select(downloadRef.current)
                            .append("rect")
                            .attr("class", `pier-${data?.id}`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("height", 7)
                            .attr("width", 7)
                            .attr("x", data?.latitude - 7 / 2)
                            .attr("y", data?.longitude - 7 / 2)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", `${data?.stroke_width}px`)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    else if (pierData?.split("-")[1] === "Diamond") {
                        d3.select(downloadRef.current)
                            .append("rect")
                            .attr("class", `pier-${data?.id}`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("height", 7)
                            .attr("width", 7)
                            .attr("x", data?.latitude - 7 / 2)
                            .attr("y", data?.longitude - 7 / 2)
                            .attr("transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(45) translate(-${data?.latitude}, -${data?.longitude})`)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", `${data?.stroke_width}px`)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    else if (pierData?.split("-")[1] === "Circle") {
                        d3.select(downloadRef.current)
                            .append("circle")
                            .attr("class", `pier-${data?.id}`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("cx", data?.latitude)
                            .attr("cy", data?.longitude)
                            .attr("r", 4)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", data?.stroke_width)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    else {
                        d3.select(downloadRef.current)
                            .append("circle")
                            .attr("class", `pier-${data?.id}`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("cx", data?.latitude)
                            .attr("cy", data?.longitude)
                            .attr("r", 4)
                            .attr("stroke", data?.stroke)
                            .attr("fill", data?.fill)
                            .attr("stroke-width", data?.stroke_width)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }
                    d3.select(downloadRef.current)
                        .append("line")
                        .attr("x1", data?.latitude - 1)
                        .attr("y1", data?.longitude - 1)
                        .attr("x2", data?.latitude + 1)
                        .attr("y2", data?.longitude + 1)
                        .attr(
                            "transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(130) translate(-${data?.latitude}, -${data?.longitude})`
                        )
                        .attr("stroke", "black");
                    if (type === "CP" && userSelection?.showType === "pier" && data?.damper_label === "true") {
                        d3.select(downloadRef.current)
                            .append("line")
                            .attr("class", "cursor-pointer")
                            .attr("x1", data?.latitude - 6)
                            .attr("y1", data?.longitude - 6)
                            .attr("x2", data?.latitude + 6)
                            .attr("y2", data?.longitude + 6)
                            .attr(
                                "transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(180) translate(-${data?.latitude}, -${data?.longitude})`
                            )
                            .attr("stroke", "black")
                            .attr("stroke-width", 2)

                        d3.select(downloadRef.current)
                            .append("line")
                            .attr("class", "cursor-pointer")
                            .attr("x1", data?.latitude - 6)
                            .attr("y1", data?.longitude - 6)
                            .attr("x2", data?.latitude + 6)
                            .attr("y2", data?.longitude + 6)
                            .attr(
                                "transform", `translate(${data?.latitude}, ${data?.longitude}) rotate(90) translate(-${data?.latitude}, -${data?.longitude})`
                            )
                            .attr("stroke", "black")
                            .attr("stroke-width", 2)
                    }
                }
                else {
                    let colorCountData = getColorCode(pierData?.split("-")[3], data)
                    let colorCountArr = colorCountData?.split("-")
                    if (colorCountArr?.length > 0) {
                        colorCountArr.map((i, index) => {
                            return (
                                d3.select(downloadRef.current)
                                    .append("rect")
                                    .attr("class", `nonpier-${data?.id} cursor-pointer`)
                                    .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                                    .attr("height", 20 / (colorCountArr.length))
                                    .attr("width", 35)
                                    .attr("x", (data?.latitude - 35 / 2))
                                    .attr("y", data?.longitude + (index * (20 / (colorCountArr.length))))
                                    .attr("stroke", () => {
                                        // const value = getColorCode(pierData?.split("-")[3])
                                        return i
                                    })
                                    .attr("fill", () => {
                                        // const value = getColorCode(pierData?.split("-")[3])
                                        return i
                                    })
                                    .attr("stroke-width", data?.stroke_width)
                                    .on("mouseover", (evt) => {
                                        const [mx, my] = d3.pointer(evt);
                                        tooltip
                                            .attr("x", mx + 2)
                                            .attr("y", my + 32)
                                            .attr("fill", "black")
                                            .style("font-size", "32px")
                                            .style("font-weight", "700")
                                            .text(trackerHoverText(data));
                                    })
                                    .on("mouseout", () => {
                                        tooltip.text("");
                                    })
                                    .on("click", () => handleSelectTracker(data))
                            )
                        })
                    }
                    else {
                        d3.select(downloadRef.current)
                            .append("rect")
                            .attr("class", `nonpier-${data?.id} cursor-pointer`)
                            .attr("id", `pier-${data?.id}-${pierData?.split("-")[3]}`)
                            .attr("height", 20)
                            .attr("width", 35)
                            .attr("x", data?.latitude - 35 / 2)
                            .attr("y", data?.longitude)
                            .attr("stroke", () => {
                                const value = getColorCode(pierData?.split("-")[3], data)
                                return value
                            })
                            .attr("fill", () => {
                                const value = getColorCode(pierData?.split("-")[3], data)
                                return value
                            })
                            .attr("stroke-width", data?.stroke_width)
                            .on("mouseover", (evt) => {
                                const [mx, my] = d3.pointer(evt);
                                tooltip
                                    .attr("x", mx + 2)
                                    .attr("y", my + 32)
                                    .attr("fill", "black")
                                    .style("font-size", "32px")
                                    .style("font-weight", "700")
                                    .text(trackerHoverText(data));
                            })
                            .on("mouseout", () => {
                                tooltip.text("");
                            })
                            .on("click", () => handleSelectTracker(data))
                    }

                    d3.select(downloadRef.current)
                        .append("text")
                        .attr("fill", getContrastColor(getColorCode(pierData?.split("-")[3], data)))
                        .attr("x", data?.latitude)
                        .attr("y", data?.longitude + 13)
                        .attr("text-anchor", "middle")
                        .style("font-size", "9px")
                        .style("font-weight", "600")
                        .text(() => {
                            if (type === "CP" && userSelection.showType === 'bha') {
                                if (data?.bha_sg_labels === 'Extra Heavy') {
                                    return 'E. Heavy'
                                } else {
                                    return data?.bha_sg_labels
                                }
                            } else {
                                return pierData?.split("-")[3]
                            }
                        })
                        .on("mouseover", (evt) => {
                            const [mx, my] = d3.pointer(evt);
                            tooltip
                                .attr("x", mx + 2)
                                .attr("y", my + 32)
                                .attr("fill", "black")
                                .style("font-size", "32px")
                                .style("font-weight", "700")
                                .text(trackerHoverText(data));
                        })
                        .on("mouseout", () => {
                            tooltip.text("");
                        })
                        .on("click", () => handleSelectTracker(data))
                }
                if ((type === "CP" && userSelection.showType === "torque tube")) {
                    let motarData = bulkAllData?.piers[parseInt(data?.tracker_row_id)].find(o => o?.pier_type_motor === 1)
                    d3.select(downloadRef.current)
                        .append("circle")
                        .attr("class", `motar-pier-circle`)
                        .attr("id", `motar-pier-${motarData?.id}-${parseInt(data?.tracker_row_id)}`)
                        .attr("r", 8)
                        .attr("cx", xScale(motarData?.latitude))
                        .attr("cy", yScale(motarData?.longitude) + 10)
                        .attr("fill", "#F16253")

                    d3.select(downloadRef.current)
                        .append("text")
                        .attr("class", `motar-pier-text`)
                        .attr("fill", "white")
                        .attr("id", `motar-pier-text-${motarData?.id}-${parseInt(data?.tracker_row_id)}`)
                        .attr("x", xScale(motarData?.latitude))
                        .attr("y", yScale(motarData?.longitude) + 13)
                        .attr("text-anchor", "middle")
                        .style("font-size", "9px")
                        .style("font-weight", "600")
                        .text("M")
                }

            }
        }
        d3.select("#download")
            .on('click', function () {
                let temp
                if (type === "CP" && userSelection.showType === "pier") {
                    temp = "Layout"
                }
                else if (type === "CP" && userSelection.showType === "part number") {
                    temp = "Pier"
                }
                else if (type === "CP" && userSelection.showType === "torque tube") {
                    temp = "TT"
                }
                else if (type === "CP" && userSelection.showType === "bha") {
                    temp = "BHA & SG"
                }
                else {
                    temp = "pier"
                }
                saveSvgAsPng(document.getElementsByClassName("download-svg")[0], `${currentProjectData?.project_number}_${currentBlock?.name}_ ${temp} map.png`, { scale: 3.8 });
            })

        const tooltip = d3
            .select(containerRef.current)
            .append("text")
            .attr("class", "tooltip")
            .attr("fill", "white")
            .style("pointer-events", "none")
    }

    if (deviceType === "web") {
        let transCompHeightVal = userSelection.showType === "part number" ? 500 : userSelection.showType === "torque tube" ? 700 : 0

        let transCompWidthVal = (type === "CP" && userSelection.showType === "pier") ? 0 : findSvgWidth()

        return (
            <div style={{ position: "relative" }}>
                <div className={`${(loading && loader) ? "opacity-50" : "opacity-100"}`} style={{ height: "calc(100vh - 340px)" }} >
                    {(loading && loader) && (
                        <p className="absolute top-[50%] z-[999] left-[50%]">Loading...</p>
                    )}
                    <TransformWrapper minScale={parseFloat(currentProjectData?.svg_dimension?.web?.web_d3_min_scale)} initialScale={parseFloat(currentProjectData?.svg_dimension?.web?.web_d3_initial_scale)} initialPositionX={((window.innerWidth - 710) - (parseFloat(currentProjectData?.svg_dimension?.web?.web_d3_min_scale) * currentProjectData?.svg_dimension?.web?.web_d3_width)) / 2} initialPositionY={((window.innerHeight - 300) - (parseFloat(currentProjectData?.svg_dimension?.web?.web_d3_min_scale) * currentProjectData?.svg_dimension?.web?.web_d3_height)) / 2}>
                        <TransformComponent
                            contentStyle={{ height: `${currentProjectData?.svg_dimension?.web?.web_d3_height + transCompHeightVal + adjust.y}px`, width: `${currentProjectData?.svg_dimension?.web?.web_d3_width + transCompWidthVal + adjust.x}px` }}
                            wrapperStyle={{ height: "100%", width: "100%" }}
                        >
                            <div style={{ height: `${currentProjectData?.svg_dimension?.web?.web_d3_height + transCompHeightVal + adjust.y}px`, width: `${currentProjectData?.svg_dimension?.web?.web_d3_width + transCompWidthVal + adjust.x}px` }} className="flex justify-center m-auto" id="wrapper">
                                <svg ref={containerRef} style={{ width: '100%', height: '100%' }} className="ml-2 view-svg cursor-grab" />
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                </div>

                {controls && (
                    <div className="left-2 bottom-5" style={{ position: "absolute" }}>
                        <FaArrowUp className="cursor-pointer ml-6 border text-black bg-gray-200 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.y = adjust.y + 100 }))} />
                        <div className="flex">
                            <FaArrowLeft className="cursor-pointer border text-black bg-gray-200 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.x = adjust.x - 100 }))} />
                            <FaArrowRight className="cursor-pointer border text-black bg-gray-200 ml-6 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.x = adjust.x + 100 }))} />
                        </div>
                        <FaArrowDown className="cursor-pointer ml-6 border text-black bg-gray-200 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.y = adjust.y - 100 }))} />
                    </div>
                )}

                <div style={{ display: "none" }}>
                    <div className="w-full h-full flex justify-center m-auto">
                        <svg ref={downloadRef} style={{ height: `${currentProjectData?.svg_dimension?.web?.web_d3_height + transCompHeightVal}px`, width: `${currentProjectData?.svg_dimension?.web?.web_d3_width + transCompWidthVal}px` }} className="ml-2 download-svg" />
                    </div>
                </div>
                {showLegend === false ?
                    null
                    :
                    <div className='flex justify-center mt-2'>
                        <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#66f1ad" }}></p>
                        <p className='mr-2'>Completed</p>
                        <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "#FC8EAC" }}></p>
                        <p className='mr-2'>In progress</p>
                        <p className="w-5 h-5 rounded-full mx-2 mt-0.5" style={{ backgroundColor: "#F6BE00" }}></p>
                        <p>Selected Trackers</p>
                    </div>
                }
                {type === 'lock' ?
                    <div className="flex justify-center mt-2">
                        <p className='w-5 h-5 rounded-full mx-2 mt-0.5 border border-solid border-black' style={{ backgroundColor: "#529ef1" }}></p>
                        <p className='mr-2'>Lock</p>
                        <p className='w-5 h-5 rounded-full mx-2 mt-0.5 border border-solid border-black' style={{ backgroundColor: "white" }}></p>
                        <p className='mr-2'>Un Lock</p>
                    </div> : ''
                }
                {type === 'lock' ?
                    <div className="flex items-center justify-center gap-2">
                        <div data-tip data-for="svg">
                            <AiOutlineInfoCircle />
                        </div>
                        <div style={{ width: '600px' }} className="font-semibold text-center">
                            Select Trackers to lock or unlock their QC checklists for edits in Tablet App Locked trackers will trigger creation of ITC that can be downloaded
                        </div>
                    </div> : ''
                }
            </div>
        )
    }

    else {
        // let transCompHeight = userSelection.showType === "part number" ? 850 : userSelection.showType === "torque tube" ? 850 : userSelection.showType === "bha" ? 850 : 0
        let transCompHeight = userSelection.showType === "pier" ? 0 : 850
        let transCompWidth = (type === "CP" && userSelection.showType === "pier") ? 0 : findSvgWidth()

        let transCompHeightVal = ((currentProjectData?.svg_dimension?.tablet?.tab_d3_height) / 2) + transCompHeight

        let transCompWidthVal = ((currentProjectData?.svg_dimension?.tablet?.tab_d3_width + 500) / 2) + transCompWidth

        return (
            <div>
                <TransformWrapper
                    minScale={parseFloat(currentProjectData?.svg_dimension?.tablet?.tab_svg_min_scale)}
                >
                    <TransformComponent
                        contentStyle={{ height: `${transCompHeightVal + adjust.y}px`, width: `${transCompWidthVal + adjust.x}px` }}
                        wrapperStyle={{ height: "100%", width: "95%" }}>
                        <div id="wrapper" >
                            <svg ref={containerRef} style={{ height: `${transCompHeightVal + adjust.y}px`, width: `${transCompWidthVal + adjust.x}px` }} />
                        </div>
                    </TransformComponent>
                </TransformWrapper>

                {controls && (
                    <div className="left-2 bottom-5" style={{ position: "absolute", left: "10px", bottom: "20px" }}>
                        <FaArrowUp
                            style={{ cursor: "pointer", marginLeft: "30px", border: "1px", color: "black", backgroundColor: "rgb(229 231 235)", padding: "8px", fontSize: "24px" }}
                            className="cursor-pointer ml-6 border text-black bg-gray-200 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.y = adjust.y + 100 }))} />
                        <div style={{ display: "flex" }}>
                            <FaArrowLeft style={{ cursor: "pointer", border: "1px", color: "black", backgroundColor: "rgb(229 231 235)", padding: "8px", fontSize: "24px" }}
                                className="cursor-pointer border text-black bg-gray-200 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.x = adjust.x - 100 }))} />
                            <FaArrowRight style={{ cursor: "pointer", marginLeft: "24px", border: "1px", color: "black", backgroundColor: "rgb(229 231 235)", padding: "8px", fontSize: "24px" }}
                                className="cursor-pointer border text-black bg-gray-200 ml-6 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.x = adjust.x + 100 }))} />
                        </div>
                        <FaArrowDown style={{ cursor: "pointer", marginLeft: "30px", border: "1px", color: "black", backgroundColor: "rgb(229 231 235)", padding: "8px", fontSize: "24px" }}
                            className="cursor-pointer ml-6 border text-black bg-gray-200 p-2 text-2xl" onClick={() => setAdjust(produce((draft) => { draft.y = adjust.y - 100 }))} />
                    </div>
                )}
            </div>
        )
    }

}
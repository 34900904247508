import React, { useEffect } from "react";
import { qualityTabs } from "../components/constent";
import CardComponent from "../components/cardComponent";
import projectAtom, { fetchProjectAtom } from "../atoms/projectAtom";
import { useAtom } from "jotai";

const Quality = () => {

    const [listProjects] = useAtom(projectAtom)
    const [, setProjectList] = useAtom(fetchProjectAtom)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await setProjectList()
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleQcCheck = () => {
        let currentProject = listProjects.find(o => o?.id === parseInt(localStorage.getItem("project_id")))
        if(currentProject?.project_settings?.qc){
            return qualityTabs
        }
        else {
            return [qualityTabs[0]]
        }
    }

    return <CardComponent data={handleQcCheck()} title={'Quality'} />
}

export default Quality;
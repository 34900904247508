import React, { useCallback, useEffect, useRef, useState } from 'react'
import dashboardAtom, { createUpdateScopeDataAtom, fetchBlocks, fetchBlockWiseTrackerCount, fetchBulkDashboardDataGet, fetchLaydownScopeSelection, fetchListSections, fetchMapRender, fetchMapSize } from '../../atoms/dashboardAtom'
import { useAtom } from 'jotai'
import projectAtom from '../../atoms/projectAtom'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import produce from 'immer'
import Modal from 'react-modal';
import { BlockMap } from '@product-fusion/surya-fw'
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io"
import SuryaBlockMap from './block'
import { BiArrowBack } from "react-icons/bi"
import { GoDotFill } from "react-icons/go"
import toast from 'react-hot-toast'
import layoutAtom, { addLayoutInventory, deleteInventory, deleteLaydown, downloadLaydown, editInventory, fetchGetLayout, fetchIndividualLayoutData, fetchInventoryBomLayout, fetchLaydownScopeDataAtom, fetchUpdateLayout, getAllLaydownDataAtom, setCurrentLaydownId, setlaydownData, updateRemediatedQuantityAtom } from '../../atoms/layoutAtom'
import Container from '../../components/container'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useNavigate } from 'react-router-dom'
import { MdDelete, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdModeEditOutline } from 'react-icons/md'
import { CgLayoutGrid, CgSoftwareDownload } from 'react-icons/cg'
import { BsChevronDown, BsChevronUp, BsFillFileEarmarkImageFill } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { RiEdit2Fill } from 'react-icons/ri'
import mileStone, { fetchOnsiteQtyAgg, fetchRemediateQty, getDeliveryReferenceAliasNameAtom } from '../../atoms/mileStoneAtom'
import { VscClose } from 'react-icons/vsc'
import NestedTable from './nestedTable'
import { useForm } from 'react-hook-form'
import ViewByDropDown from './components/viewByDropDown'

const useLatest = (value) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

function Laydown() {

    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm()

    const [loading, setLoading] = useState(false)
    const [showToastInfo, setShowToastInfo] = useState(true)
    const [addlayoutLoader, setAddLayoutLoader] = useState(false)
    const [inventoryLoader, setInventoryLoader] = useState({ bool: false, text: "" })
    const [bomLaydownLoader, setBomLaydownLoader] = useState(false)
    const [showScopeTableQty, setShowScopeTableQty] = useState(false)
    const [discardScope, setDiscardScope] = useState(false)
    const [saveScopeModal, setSaveScopeModal] = useState(false)
    const [saveScopeLoader, setSaveScopeLoader] = useState(false)
    const [deleteLaydownLoader, setDeleteLaydownLoader] = useState(false)
    const [viewToggle, setViewToggle] = useState("Inventory")
    const [showRightPanel, setShowRightPanel] = useState(true)
    const [svgScale, setSvgScale] = useState(0.8)
    const [laydown, setLaydown] = useState({ boolean: false, enableAdd: false })
    const [scopeSvg, setScopeMap] = useState(null)
    const [selectedBlocks, setSelectedBlocks] = useState({ bool: true, data: [], initSelectedBlocks: {} })
    const [selectBlockTrackers, setSelectBlockTrackers] = useState({})
    const [individualBlockData, setIndividualBlockData] = useState({ bool: false, block_id: null, trackerData: {}, initLoadTracker: [] })
    const [currentLaydown, setCurrentLaydown] = useState(null)
    const [showSectionList, setShowSectionList] = useState([])
    const [d3xScaleyScale, setD3xScaleyScale] = useState({})
    const [sectionSelect, setSectionSelect] = useState([])
    const [prevSection, setPrevSection] = useState(null)
    const [selectedRow, setSelectedRow] = useState([])
    const [selectAllTrackers, setSelectAllTracker] = useState(false)
    const [clearSt, setClearSt] = useState(false)
    const [finalData, setFinalData] = useState({})
    const [editInventoryNameModal, setEditInventoryNameModal] = useState({ boolean: false, data: {} })
    const [scopeEditMode, setScopeEditMode] = useState(false)
    const [showDescription, setShowDescription] = useState(null)
    const [expanded, setExpanded] = useState(-1)
    const [manage, setManage] = useState(0)
    const [confimationModal, setConfirmationModal] = useState(false)
    const [inventryLaydownId, setInventoryLaydownId] = useState(-1)
    const [remediateQtyBomDetails, setRemediateQtyBomDetails] = useState({})
    const [openEditRemediateQty, setOpenRemediateQty] = useState(false)
    const [bomLaydownId, setBomLaydownId] = useState(null)
    const [addLayoutmodal, setAddLayoutModal] = useState({ boolean: false, data: { name: "", x: null, y: null } })
    const [addEntry, setAddEntry] = useState({ boolean: false, data: [{ bom_id: null, purpose_code: 1, destination: null, comments: "", time: null, issued: null, received_quantity: null }] })
    const [remediateQtyData, setRemediateQtyData] = useState([]);
    const [inventryBomId, setInventryBomId] = useState(0)

    const selectedBlockIdsRef = useLatest(selectedBlocks?.data);
    const finalDataRef = useLatest(finalData)
    const updateLaydownInputRef = useRef(null)
    const viewToggleRef = useRef(viewToggle)
    const scopeEditModeRef = useLatest(scopeEditMode)

    const _ = require("lodash")

    const [listblocks] = useAtom(dashboardAtom)
    const [listLaydown] = useAtom(layoutAtom)
    const [projectsName] = useAtom(projectAtom)
    const [listMileStone] = useAtom(mileStone)
    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setFetchBlockWiseTrackerCount] = useAtom(fetchBlockWiseTrackerCount)
    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
    const [, setSectionList] = useAtom(fetchListSections)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setUpdateCreateScopeTrackers] = useAtom(createUpdateScopeDataAtom)
    const [, setFetchLaydown] = useAtom(fetchGetLayout)
    const [, setUpdateLayout] = useAtom(fetchUpdateLayout)
    const [, setDeleteLaydown] = useAtom(deleteLaydown)
    const [, setDownloadLaydown] = useAtom(downloadLaydown)
    const [, setFetchSingleLayout] = useAtom(fetchIndividualLayoutData)
    const [, setGetAllScopeLaydownData] = useAtom(getAllLaydownDataAtom)
    const [, setFetchBomInventory] = useAtom(fetchInventoryBomLayout)
    const [, setFetchScopeLaydown] = useAtom(fetchLaydownScopeDataAtom)
    const [, setFetchScopeSelection] = useAtom(fetchLaydownScopeSelection)
    const [, fetchInventryLogDeliveryQty] = useAtom(fetchRemediateQty)
    const [, setAddLayoutInventory] = useAtom(addLayoutInventory)
    const [, setFetchDeleteInventory] = useAtom(deleteInventory)
    const [, setEditInventory] = useAtom(editInventory)
    const [, getDeliveryReferenceAliasName] = useAtom(getDeliveryReferenceAliasNameAtom)
    const [, updateRemediatedQuantity] = useAtom(updateRemediatedQuantityAtom);
    const [, setLaydownId] = useAtom(setCurrentLaydownId);
    const [, setlaydownDataValue] = useAtom(setlaydownData);

    let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            setLoading(true)
            await setFetchMap()
            await setFetchBlocks()
            setFetchBlockWiseTrackerCount()
            setFetchLaydown()
            setGetAllScopeLaydownData()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const handleClickOutside = (event) => {
        if (updateLaydownInputRef.current && !updateLaydownInputRef.current.contains(event.target)) {
            setEditInventoryNameModal({ boolean: false, data: {} })
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (listLaydown?.data?.length && !currentLaydown) {
            setLoading(true)
            listLaydown.data.map((laydownData) => {
                handleCreateBeacon(laydownData.latitude, laydownData.longitude, `${laydownData.id}__${laydownData.name}`)
            })
            let data = document.getElementsByClassName("svgParentDiv")[0]
            var svgData = data.querySelectorAll("svg")[0]
            svgData.setAttribute("style", `width: 60vw; height: 60vh`)
            setLoading(false)
        }
    }, [listLaydown?.data, currentLaydown])

    const handleCreateBeacon = (x, y, classId) => {
        let data = document.getElementsByClassName("svgParentDiv")[0]
        var svgData = data.querySelectorAll("svg")[0]
        svgData.setAttribute("style", `width: 60vw; height: 60vh`)
        var z = document.createElement('div')
        z.setAttribute("style", `position: absolute; top: ${y}%; left: ${x}%; color: #F16253; border: 1px solid #F16253; padding: 5px; border-radius: 100%; background-color: #F16253;`)
        z.setAttribute("xml:space", "preserve")
        z.setAttribute("font-family", "Tenor Sans")
        z.setAttribute("font-size", "28")
        if (classId) {
            z.setAttribute("class", classId)
        }
        z.setAttribute("letter-spacing", "0em")
        var layName = document.createElement('div')
        layName.setAttribute("style", `position: absolute; top: ${y - 5}%; left: ${x}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
        if (classId) {
            layName.setAttribute("class", `${classId}__name`)
            layName.innerHTML = classId?.split("__")[1]
        }
        data.appendChild(layName)
        data.appendChild(z)
    }

    function handleCheckZimmerman() {
        let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))
        if (currentProject && currentProject?.type) {
            return true
        }
        else {
            return false
        }
    }

    const handleChangeView = (value) => {
        if (value === "Mechanical Progress") {
            navigate("/dashboard")
        } else {
            navigate("/dashboard/laydown-view")
        }
    }

    useEffect(() => {
        if (currentLaydown) {
            setFinalData({ ...listLaydown?.allScopeBlockData?.[currentLaydown] })
            finalDataRef.current = { ...listLaydown?.allScopeBlockData?.[currentLaydown] }
            let currentSelectedItems = { ...listLaydown?.allScopeBlockData?.[currentLaydown] }
            let currentLaydownCompletedBlocks = []
            if (Object.keys(currentSelectedItems).length) {
                Object.keys(currentSelectedItems).map((block_id) => {
                    console.log(currentSelectedItems[block_id])
                    if (currentSelectedItems[block_id] === -1) {
                        currentLaydownCompletedBlocks.push(parseInt(block_id))
                    }
                })
            }
            selectedBlockIdsRef.current = currentLaydownCompletedBlocks
        }
    }, [currentLaydown, listLaydown])

    const handleBlockClick = useCallback(_.debounce((block, block_svg_id, nonCurrentLaydownBlockCompleted, nonCurrentLaydownBlockInprogress) => {
        // let value = finalDataRef.current;
        // if (block?.id in value) {
        //     delete value[block?.id];
        // } else {
        //     value[block?.id] = -1;
        // }
        // setFinalData(value)
        // finalDataRef.current = value
        if (!(nonCurrentLaydownBlockCompleted.includes(block?.id)) && !((nonCurrentLaydownBlockInprogress.includes(block?.id)))) {
            setSelectedBlocks(() => {
                let newSelectedBlockIds = selectedBlockIdsRef.current;
                if (newSelectedBlockIds.includes(block?.id)) {
                    newSelectedBlockIds = newSelectedBlockIds.filter((item) => item !== block?.id)
                    block_svg_id.style.fill = "";
                } else {
                    newSelectedBlockIds.push(block?.id)
                    block_svg_id.style.fill = "yellow";
                }
                selectedBlockIdsRef.current = newSelectedBlockIds
                return { bool: true, data: newSelectedBlockIds, initSelectedBlocks: {} };
            });
        }
    }, 105), []);

    useEffect(() => {
        if (listblocks.blockTtData && currentLaydown) {
            sectionCallFn()
        }
    }, [listblocks?.blockTtData, individualBlockData?.bool, currentLaydown])

    const sectionCallFn = async () => {
        try {
            if (individualBlockData?.bool) {
                setSectionSelect([])
                await setSectionList({ data: listblocks.blockTtData.piers })
                setShowSectionList([individualBlockData?.block_id])
                let temp = new Set()
                let tempSectionList = new Set()
                let laydownIncludesTracker = new Set()
                let trackerData = {}
                let initLoadSelectedTrackers = []
                let initLoadSelectedTrackers_ids = []
                for (let pr in listblocks?.blockTtData?.piers) {
                    for (let pierIndex in listblocks?.blockTtData?.piers[pr]) {
                        let pierData = listblocks?.blockTtData?.piers[pr][pierIndex]
                        tempSectionList.add(pierData.section)
                        if (pierData.laydown_id === currentLaydown) {
                            initLoadSelectedTrackers.push(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                            initLoadSelectedTrackers_ids.push(pr)
                        }
                        if (pierData.laydown_id === null || pierData.laydown_id === currentLaydown) {
                            if (trackerData[pierData.section]) {
                                let obj = trackerData[pierData.section]
                                obj.push(pr)
                                trackerData[pierData.section] = [...new Set(obj)]
                            }
                            else {
                                trackerData[pierData.section] = [pr]
                            }
                        }
                        else {
                            trackerData[pierData.section] = []
                        }
                        temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                        if (pierData?.laydown_id === currentLaydown) {
                            laydownIncludesTracker.add(pierData)
                        }
                    }
                }
                let finalTemp = finalData
                finalTemp[individualBlockData?.block_id] = finalDataRef.current[individualBlockData?.block_id]?.length ? finalDataRef.current[individualBlockData?.block_id] : initLoadSelectedTrackers_ids
                let tempSelectBlockTrackers = selectBlockTrackers
                tempSelectBlockTrackers[individualBlockData?.block_id] = initLoadSelectedTrackers_ids
                setSelectBlockTrackers(tempSelectBlockTrackers);
                setFinalData(finalTemp)
                finalDataRef.current = finalTemp
                setIndividualBlockData(produce((draft) => {
                    draft.trackerData = trackerData;
                    draft.initLoadTracker = [...new Set(initLoadSelectedTrackers)]
                }))
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (selectedRow?.length) {
            let tempObj = finalData
            tempObj[individualBlockData?.block_id] = selectedRow?.map(item => item?.split('-')?.[1]);
            let currentBlockTrackerLength = listblocks?.blockTrackerCount.find(o => o?.block_id === individualBlockData?.block_id)?.count
            if (currentBlockTrackerLength === selectedRow?.length) {
                // setSelectedBlocks((preValue) => {
                //     let newSelectedBlockIds = new Set(preValue?.data)
                //     newSelectedBlockIds.add(individualBlockData.block_id)
                //     return { bool: true, data: Array.from(newSelectedBlockIds), initSelectedBlocks: {} }
                // })
            }
            setFinalData(tempObj)
            finalDataRef.current = tempObj
            let temp = selectBlockTrackers
            let tempTrackers = []
            selectedRow?.map((row) => {
                tempTrackers.push(row.split("-")[1])
            })
            temp[individualBlockData?.block_id] = tempTrackers
            setSelectBlockTrackers(temp)
        }
    }, [selectedRow])

    useEffect(() => {
        if (individualBlockData?.bool) {
            handleLoadD3Data()
        }
    }, [individualBlockData?.block_id])

    useEffect(() => {
        if (listblocks?.blockTtData?.piers && individualBlockData?.block_id) {
            let temp = new Set()
            for (let pr in listblocks?.blockTtData?.piers) {
                for (let pierIndex in listblocks?.blockTtData?.piers[pr]) {
                    let pierData = listblocks?.blockTtData?.piers[pr][pierIndex]
                    if (pierData?.laydown_id === currentLaydown) {
                        temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                    }
                    if (finalData[individualBlockData?.block_id] !== -1 && finalData[individualBlockData?.block_id]?.includes(pr)) {
                        temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
                    }
                }
            }
            setSelectedRow(Array.from(temp))
        }
    }, [listblocks?.blockTtData, individualBlockData?.block_id])

    const handleLoadD3Data = async () => {
        try {
            setLoading(true)
            setFetchBulkGet({ id: individualBlockData?.block_id })
            setMapSize({ id: individualBlockData?.block_id })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    const getDeliveryReferenceName = () => {
        let name = ""
        if (listMileStone?.deliveryReferenceAliasName !== null && Object.keys(listMileStone?.deliveryReferenceAliasName).length !== 0 && listMileStone?.deliveryReferenceAliasName?.message !== 'Field alias not found') {
            name = listMileStone?.deliveryReferenceAliasName?.field_alias_name
        }
        return name
    }

    const handleDblClick = useCallback(_.debounce((block) => {
        setShowToastInfo(false)
        setIndividualBlockData(produce((draft) => {
            draft.bool = true;
            draft.block_id = block?.id
        }))
    }, 100), []);

    useEffect(() => {
        setScopeMap(listblocks.map)
    }, [listblocks.map])

    const svgBlocksSelect = useCallback(() => {
        if (listblocks?.blocks?.length > 0) {
            let block_svg_id;
            let block_progress_id;

            let nonCurrentLaydownBlockInprogress = []
            let nonCurrentLaydownBlockCompleted = []

            if (scopeEditModeRef.current === true) {
                Object.keys(listLaydown?.allScopeBlockData).map((scope) => {
                    if (parseInt(scope) !== currentLaydown) {
                        if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                            Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                                if (listLaydown?.allScopeBlockData[scope][block_id] === -1) {
                                    nonCurrentLaydownBlockCompleted.push(parseInt(block_id))
                                }
                            })
                        }
                    }
                    if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                        Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                            if (listLaydown?.allScopeBlockData[scope][block_id]?.length) {
                                nonCurrentLaydownBlockInprogress.push(parseInt(block_id))
                            }
                        })
                    }
                })
            }

            listblocks?.blocks.forEach((block) => {
                block_svg_id = document.getElementById(handleCheckZimmerman() ? block?.name : block?.block_svg_id);
                block_progress_id = document.getElementById(block?.progress_svg_id);
                if (block_svg_id && viewToggleRef.current === "Scope") {
                    if (scopeEditModeRef.current === true) {
                        block_svg_id.removeEventListener('click', () => handleBlockClick(block, block_svg_id, nonCurrentLaydownBlockCompleted, nonCurrentLaydownBlockInprogress));
                        block_svg_id.addEventListener('click', () => handleBlockClick(block, block_svg_id, nonCurrentLaydownBlockCompleted, nonCurrentLaydownBlockInprogress));
                    }
                    block_svg_id.addEventListener('dblclick', () => handleDblClick(block));
                }

                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = ``;
                }

                if (block_svg_id) {
                    if (selectedBlockIdsRef.current.includes(block.id)) {
                        block_svg_id.style.fill = "yellow";
                        block_svg_id.style.cursor = "pointer"
                    }
                    else {
                        if (nonCurrentLaydownBlockCompleted.includes(block?.id)) {
                            block_svg_id.style.fill = "green";
                            block_svg_id.style.cursor = "pointer";
                        } else if (nonCurrentLaydownBlockInprogress.includes(block?.id)) {
                            block_svg_id.style.fill = "pink"
                            block_svg_id.style.cursor = "pointer";
                        } else {
                            block_svg_id.style.fill = "white"
                            block_svg_id.style.cursor = "pointer"
                        }
                    }
                }
            });
        }
    }, [listblocks, listblocks.map, viewToggle, currentLaydown, scopeEditMode, finalData]);

    useEffect(() => {
        svgBlocksSelect();
        return () => {
            listblocks?.blocks?.forEach(block => {
                let block_svg_id;
                block_svg_id = document.getElementById(handleCheckZimmerman() ? block?.name : block?.block_svg_id);
                if (block_svg_id) {
                    const handleClick = () => { }; // Dummy function to remove actual listener
                    block_svg_id.removeEventListener('click', handleClick);
                }
            });
        };
    }, [listblocks?.blocks, selectedBlocks, individualBlockData, viewToggle, scopeEditMode, currentLaydown, listblocks.scopeSelectionData])

    const handleAddLaydown = () => {
        if (!laydown.enableAdd) {
            for (let key of listLaydown.data) {
                let data = document.getElementsByClassName(`${key.id}__${key.name}__name`)[0]
                data.remove()
            }
        }
        else {
            for (let key of listLaydown.data) {
                let data = document.getElementsByClassName("svgParentDiv")[0]
                var layName = document.createElement('div')
                layName.setAttribute("style", `position: absolute; top: ${key.longitude - 5}%; left: ${key.latitude}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
                layName.setAttribute("class", `${key.id}__${key.name}__name`)
                layName.innerHTML = key.name
                data.appendChild(layName)
            }
        }
        setLaydown(produce((draft) => { draft.enableAdd = !laydown.enableAdd }))
    }

    const handleSelectAllBlocks = () => {
        if (individualBlockData?.bool) {
            setSelectAllTracker(!selectAllTrackers)
            setSectionSelect(listblocks?.sectionList)
            setPrevSection(null)
        }
        else {
            let nonCurrentLaydownBlockCompleted = []
            Object.keys(listLaydown?.allScopeBlockData).map((scope) => {
                if (parseInt(scope) !== currentLaydown) {
                    if (listLaydown?.allScopeBlockData[scope] && Object.keys(listLaydown?.allScopeBlockData[scope])?.length) {
                        Object.keys(listLaydown?.allScopeBlockData[scope]).map((block_id) => {
                            if (listLaydown?.allScopeBlockData[scope][block_id] === -1) {
                                nonCurrentLaydownBlockCompleted.push(parseInt(block_id))
                            }
                        })
                    }
                }
            })
            const allBlockIds = []
            let blockMinusOne = {}
            listblocks?.blocks.map((block) => {
                if (!nonCurrentLaydownBlockCompleted.includes(block?.id)) {
                    allBlockIds.push(parseInt(block?.id))
                    blockMinusOne[block?.id] = -1
                }
            });
            selectedBlockIdsRef.current = allBlockIds
            setSelectedBlocks(produce((draft) => { draft.data = allBlockIds }));
            setFinalData(blockMinusOne)
            finalDataRef.current = blockMinusOne
        }
    }

    const handleClearAllBlock = () => {
        if (individualBlockData?.bool) {
            setClearSt(!clearSt)
            setSelectAllTracker(false)
            setSectionSelect([])
            setPrevSection(null)
        }
        else {
            setSelectedBlocks(produce((draft) => { draft.data = [] }))
            selectedBlockIdsRef.current = []
            setFinalData({})
            finalDataRef.current = {}
        }
    }

    function handleFindCurrentLaydownName() {
        let temp
        temp = listLaydown?.data?.find(o => o?.id === currentLaydown)?.name
        return temp
    }

    const handleFindLaydownDetail = (data) => {
        let temp
        temp = listLaydown.data.find(o => o.id === data)
        return temp
    }

    function findMinMax(nestedData) {
        const allCoords = [];

        // Flattening the nested data
        Object.values(nestedData).forEach(coordsArray => {
            coordsArray.forEach(coord => {
                allCoords.push(coord);
            });
        });

        const latitudes = allCoords.map(coord => coord.latitude);
        const longitudes = allCoords.map(coord => coord.longitude);

        return {
            minimum_latitude: Math.min(...latitudes),
            maximum_latitude: Math.max(...latitudes),
            minimum_longitude: Math.min(...longitudes),
            maximum_longitude: Math.max(...longitudes)
        };
    }

    function hasNullValues(data) {
        if (data) {
            const values = [
                data?.piers?.minimum_latitude,
                data?.piers?.maximum_latitude,
                data?.piers?.minimum_longitude,
                data?.piers?.maximum_longitude,
                data?.tt?.minimum_latitude,
                data?.tt?.maximum_latitude,
                data?.tt?.minimum_longitude,
                data?.tt?.maximum_longitude
            ];

            return values.some(value => value === null);
        }
    }

    useEffect(() => {
        let laydown_auto_manage = getLaydownAutoManage()
        if (laydown_auto_manage === true) {
            setManage(1)
        }
    }, [])


    const getLaydownAutoManage = () => {
        let currentProject = projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))
        let laydown_auto_manage = false
        if (currentProject?.project_settings && currentProject?.project_settings?.laydown_auto_manage) {
            laydown_auto_manage = currentProject?.project_settings?.laydown_auto_manage
        }
        return laydown_auto_manage
    }

    // const handleToggle = (value) => {
    //     if ((value === 1 && !getLaydownAutoManage()) || (value === 0 && getLaydownAutoManage())) {
    //         return; // Prevent toggle
    //     }
    //     setManage(value);
    //     setExpanded(-1); // You can retain your existing logic here

    // }


    useEffect(() => {
        if (listblocks?.mapSize) {
            const d3xScaleyScaleHasNullValue = hasNullValues(listblocks?.mapSize)
            if (d3xScaleyScaleHasNullValue === true) {
                if (listblocks?.blockTtData) {
                    const piersMinMax = findMinMax(listblocks?.blockTtData?.piers);
                    const ttMinMax = findMinMax(listblocks?.blockTtData?.tt);
                    setD3xScaleyScale({ piers: piersMinMax, tt: ttMinMax })
                }
            }
            else {
                setD3xScaleyScale({ piers: listblocks.mapSize?.piers, tt: listblocks.mapSize?.tt })
            }
        }

    }, [listblocks?.mapSize])

    function getBlockDetails(block_id) {
        let temp
        temp = listblocks?.blocks.find(o => o?.id === block_id)
        return temp
    }

    const handleBackBlockList = () => {
        setShowToastInfo(true)
        setIndividualBlockData(produce((draft) => {
            draft.bool = false;
            draft.block_id = null;
            draft.trackerData = {}
        }))
        setShowSectionList([])
    }

    const handleUpdateSectionShowList = (selectedBlockData) => {
        if (showSectionList.includes(selectedBlockData)) {
            let array = showSectionList;
            let newArray = array.filter(item => item !== selectedBlockData)
            setShowSectionList(newArray)
        }
        else {
            if (selectedBlockData === individualBlockData.block_id) {
                setShowSectionList(produce((draft) => { draft.push(selectedBlockData) }))
            }
        }
    }

    const handleSectionSelect = (data) => {
        if (sectionSelect.includes(data)) {
            setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
            setPrevSection(data)
        }
        else {
            setSectionSelect(produce((draft) => { draft.push(data) }))
            setPrevSection(null)
        }
    }

    const handleCancelScope = () => {
        setScopeEditMode(false)
        setDiscardScope(false)
        scopeEditModeRef.current = false
        let currentLaydownScope = listLaydown?.data.find(o => o?.id === currentLaydown)?.scope
        let tempBlocks = []
        if (currentLaydownScope && Object.keys(currentLaydownScope).length) {
            Object.keys(currentLaydownScope).map((i) => {
                if (currentLaydownScope[i] === -1) {
                    tempBlocks.push(parseInt(i))
                }
            })
        }
        setSelectedBlocks({ bool: true, data: tempBlocks, initSelectedBlocks: {} })
        setSelectedRow(individualBlockData?.initLoadTracker)
    }

    const handleSaveScope02 = async () => {
        let currentLaydownScope = listLaydown?.data.find(o => o?.id === currentLaydown)?.scope
        let missingBlockIds = {}
        let scopeData = {}
        selectedBlocks?.data.map((block) => {
            scopeData[block] = -1
        })
        let missing = Object.keys(currentLaydownScope).filter(num => !selectedBlocks?.data.includes(parseInt(num)))
        if (missing?.length) {
            missing.map((data) => {
                missingBlockIds[data] = {}
            })
        }
        try {
            await setUpdateCreateScopeTrackers({ laydown_id: currentLaydown, removedTracker: false, scopeData: scopeData })
            await setUpdateCreateScopeTrackers({ removedTracker: true, scopeData: missingBlockIds })
            setFetchLaydown()
            setGetAllScopeLaydownData({ currentLaydown: currentLaydown })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleSaveScope = async () => {
        setSaveScopeLoader(true)
        let scopeSelect = finalData
        selectedBlockIdsRef.current.map((block_id) => {
            scopeSelect[block_id] = -1
        })
        let missingKeys = {}
        if (listLaydown?.allScopeBlockData?.[currentLaydown]) {
            let prevMissingData = Object.keys(listLaydown?.allScopeBlockData?.[currentLaydown])
            missingKeys = prevMissingData
                .filter(key => !(key in scopeSelect))
                .reduce((acc, key) => {
                    acc[key] = listLaydown?.allScopeBlockData?.[currentLaydown][key];
                    return acc;
                }, {})
        };
        try {
            if (Object.keys(missingKeys)?.length) {
                await setUpdateCreateScopeTrackers({ removedTracker: true, block_id: individualBlockData?.block_id, scopeData: missingKeys })
                await setUpdateCreateScopeTrackers({ block_id: individualBlockData?.block_id, laydown_id: currentLaydown, scopeData: finalData })
            } else {
                await setUpdateCreateScopeTrackers({ block_id: individualBlockData?.block_id, laydown_id: currentLaydown, scopeData: finalData })
            }
            setFetchLaydown()
            setGetAllScopeLaydownData({ currentLaydown: currentLaydown })
            setScopeEditMode(false)
            scopeEditModeRef.current = false
            setSectionSelect([])
            setSaveScopeModal(false)
            toast.success("Successfully updated scope")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setSaveScopeLoader(false)
        }
    }

    const createLayoutSubmit = async (data) => {
        try {
            let prtSvg = document.getElementsByClassName("svgParentDiv")[0]
            setAddLayoutLoader(true)
            await setUpdateLayout({
                data: {
                    name: data.name,
                    latitude: ((parseInt(addLayoutmodal.data.x)) / prtSvg.offsetWidth) * 100,
                    longitude: ((parseInt(addLayoutmodal.data.y)) / prtSvg.offsetHeight) * 100
                }
            })
            toast.success("Created Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to create layout")
        }
        finally {
            setAddLayoutLoader(false)
            setAddLayoutModal(produce((draft) => { draft.boolean = false }))
            reset()
        }
    }

    const closeAddLayoutModal = async () => {
        setAddLayoutModal(produce((draft) => { draft.boolean = false }))
        setLaydown(produce((draft) => { draft.enableAdd = false }))
        for (let key of listLaydown.data) {
            let data = document.getElementsByClassName("svgParentDiv")[0]
            var layName = document.createElement('div')
            layName.setAttribute("style", `position: absolute; top: ${key.longitude - 5}%; left: ${key.latitude}%; color: #F16253; padding: 2px 4px; font-size: 14px`)
            layName.setAttribute("class", `${key.id}__${key.name}__name`)
            layName.innerHTML = key.name
            data.appendChild(layName)
        }
    }

    const handleUpdateLaydownName = async (data) => {
        try {
            setAddLayoutLoader(true)
            if (data && data !== handleFindLaydownDetail(editInventoryNameModal?.data)?.name) {
                await setUpdateLayout({
                    data: {
                        name: data,
                        latitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.latitude,
                        longitude: handleFindLaydownDetail(editInventoryNameModal?.data)?.longitude,
                        id: editInventoryNameModal?.data
                    }
                })
                toast.success("Updated Successfully")
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to update layout")
        }
        finally {
            setEditInventoryNameModal({ boolean: false, data: {} })
        }
    }

    const handleDownloadLaydown = async () => {
        try {
            await setDownloadLaydown({ laydown_id: currentLaydown, laydownName: handleFindCurrentLaydownName(currentLaydown), project_name: currentProject, formattedDate: formattedDate, details: listLaydown.data })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleDeleteLaydown = async () => {
        try {
            setDeleteLaydownLoader(true)
            await setDeleteLaydown({ laydown_id: currentLaydown })
            let temp = listLaydown.data.filter(o => o.id === currentLaydown)
            let layName = document.getElementsByClassName(`${temp[0].id}__${temp[0].name}__name`)[0]
            let layBeacon = document.getElementsByClassName(`${temp[0].id}__${temp[0].name}`)[0]
            if (layName) {
                layName.remove()
                layBeacon.remove()
            }
            setCurrentLaydown(null)
            setViewToggle("Inventory")
            toast.success("Deleted successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setDeleteLaydownLoader(false)
            setConfirmationModal(false)
        }
    }

    const handleCheckQuantity = () => {
        let temp = []
        for (let data of listLaydown?.bomLayoutData) {
            temp.push(data.count)
        }
        let result
        if (temp.reduce((accumulator, currentValue) => accumulator + currentValue, 0) === 0) {
            result = false
        }
        else {
            result = true
        }
        return result
    }

    const handleClickLaydown = async (laydown_id) => {
        setLaydownId(laydown_id)
        try {
            if (!scopeEditMode && currentLaydown !== laydown_id) {
                setInventoryLoader({ bool: true, text: "Fetching Logs" })
                setCurrentLaydown(laydown_id)
                await setFetchSingleLayout({ laydown_id: laydown_id })
                await setFetchScopeLaydown({ laydown_id: laydown_id })
                await setFetchScopeSelection({ laydown_id: laydown_id })
                setExpanded(-1)
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setInventoryLoader({ bool: false, text: "" })
        }
    }

    const handleOpenEditRemediateQty = async (data) => {
        try {
            setRemediateQtyBomDetails(data)
            setInventoryLaydownId(parseInt(currentLaydown))
            getDeliveryReferenceAliasName()
            setInventryBomId(data?.bom_id)
            await fetchInventryLogDeliveryQty({ bom_id: data?.bom_id })
            setOpenRemediateQty(true)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
    }

    const handleCoordinates = (e) => {
        let rect = e.currentTarget.getBoundingClientRect();
        let x = (e.clientX - rect.left) / (svgScale);
        let y = (e.clientY - rect.top) / (svgScale);
        if (laydown.enableAdd) {
            setLaydown(produce((draft) => { draft.enableAdd = false }))
            setAddLayoutModal(produce((draft) => { draft.boolean = true; draft.data = { name: "", x: x, y: y } }))
        }
    }

    const handleOpenLaydown = (e) => {
        if (laydown.enableAdd) {
            handleCoordinates(e)
        }
        else {
            if (e.target.className && !["svg", "tspan", "path"].includes(e.target.nodeName)) {
                let temp = listLaydown.data.filter(o => o.id === parseInt(e.target?.className?.split("__")[0]))
                if (temp.length > 0) {
                    handleClickLaydown(parseInt(e.target.className?.split("__")[0]))
                }
            }
        }
    }

    const onExitScope = () => {
        setCurrentLaydown(null)
        setSelectedBlocks({ bool: true, data: [], initSelectedBlocks: {} })
        setViewToggle("Inventory")
        viewToggleRef.current = "Inventory"
    }

    // const handleChangeRemediatedQty = (e, index) => {
    //     const { value } = e.target;
    //     const updatedData = [...remediateQtyData]; // Clone the current state
    //     updatedData[index] = {
    //         bom_delivery_laydown_id: listMileStone.remediateQty[index]?.bom_delivery_laydown_id, // You can replace this with the actual laydown ID key from your data
    //         remediated_quantity: parseInt(value, 10) || 0 // Store the updated quantity
    //     };
    //     setRemediateQtyData(updatedData); // Update the state
    //     console.log(updatedData)
    // };

    // const handleChangeRemediatedQty = (e, index) => {
    //     const { value } = e.target;

    //     // Clone the existing state to modify
    //     const updatedData = [...remediateQtyData];
    //     const quantity = value.trim() !== '' ? parseInt(value, 10) : null;

    //     if (quantity !== null) {
    //         updatedData[index] = {
    //             bom_delivery_laydown_id: listMileStone.remediateQty[index]?.bom_delivery_laydown_id,
    //             remediated_quantity: quantity
    //         };
    //     }
    //     // Remove empty entries from the state
    //     console.log(updatedData)
    //     let filteredRemediatedQty = updatedData.filter(item => item !== undefined)
    //     console.log(filteredRemediatedQty)
    //     setRemediateQtyData(filteredRemediatedQty);

    // };

    const handleChangeRemediatedQty = (e, index) => {
        const { value } = e.target;
        const quantity = value === '' ? null : parseInt(value, 10);
        const bomDeliveryLaydownId = listMileStone.remediateQty[index]?.bom_delivery_laydown_id;

        // Clone the current state
        const updatedData = [...remediateQtyData];

        // Check if an entry for this bom_delivery_laydown_id already exists
        const existingIndex = updatedData.findIndex(item => item?.bom_delivery_laydown_id === bomDeliveryLaydownId);

        // If the user entered a value (including 0)
        if (quantity !== null) {
            const newEntry = {
                bom_delivery_laydown_id: bomDeliveryLaydownId,
                remediated_quantity: quantity
            };

            if (existingIndex !== -1) {
                // If the entry exists, update the existing entry
                updatedData[existingIndex] = newEntry;
            } else {
                // If the entry doesn't exist, add it to the array
                updatedData.push(newEntry);
            }
        } else {
            // If the user clears the field, remove the entry from the state
            if (existingIndex !== -1) {
                updatedData.splice(existingIndex, 1);
            }
        }

        console.log(updatedData)
        // Update the state without null entries
        setRemediateQtyData(updatedData);
    };
    const handleSaveRemediatedQty = async () => {
        try {
            if (remediateQtyData.length !== 0) {
                const filteredData = remediateQtyData?.filter(item => item !== undefined && item.remediated_quantity !== null);
                await updateRemediatedQuantity(filteredData)
                await fetchInventryLogDeliveryQty({ bom_id: inventryBomId })
                setRemediateQtyData([])
                toast.success("Saved successfully")
                await setFetchSingleLayout({ laydown_id: currentLaydown })
                await setFetchScopeLaydown({ laydown_id: currentLaydown })
                setOpenRemediateQty(false)
            }

        } catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
    }

    const handleOpenBomNestedTable = async (bomId) => {
        try {
            setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: bomId.bom_id, purpose_code: 1, destination: null, comments: "", time: "", issued: null, received_quantity: null }] }))
            setBomLaydownLoader(true)
            setBomLaydownId(bomId.bom_laydown_id)
            await setFetchBomInventory({ bom_id: bomId.bom_id, laydown_id: currentLaydown })
            setExpanded(bomId.bom_id)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleSaveEdit = async (data, epoch) => {
        try {
            setBomLaydownLoader(true)
            let temp = [
                {
                    bom_id: addEntry.data[0].bom_id,
                    purpose_code: data.data.purpose_code,
                    comments: data.data.comments,
                    time: Math.round(epoch / 1000),
                    issued: data.data.issued_quantity ? data.data.issued_quantity : 0,
                    received_quantity: data.data.received_quantity ? data.data.received_quantity : 0,
                    inventory_log_id: data.data.inventory_log_id
                }
            ]
            if (data.data.purpose_code === 0) {
                temp[0]["destination_laydown_id"] = data.destination
            }
            await setEditInventory({ data: temp, laydown_id: currentLaydown, inventory_log_id: data.data.inventory_log_id, bom_id: addEntry.data[0].bom_id })
            await setFetchSingleLayout({ laydown_id: currentLaydown })
            toast.success("Updated Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleDeleteInventory = async (laydown_id, data) => {
        try {
            setInventoryLoader(true)
            await setFetchDeleteInventory({ laydown_id: laydown_id.laydown_id, data: laydown_id.data })
            await setFetchBomInventory({ bom_id: addEntry.data[0].bom_id, laydown_id: laydown_id.laydown_id })
            toast.success("Deleted Successfully")
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setInventoryLoader(false)
        }
    }

    const handleAddBomInventory = async () => {
        try {
            setBomLaydownLoader(true)
            let temp = {}
            temp.bom_id = addEntry.data[0].bom_id
            temp.issued = addEntry.data[0].issued ? addEntry.data[0].issued : 0
            temp.received_quantity = addEntry.data[0].received_quantity ? addEntry.data[0].received_quantity : 0
            temp.purpose_code = addEntry.data[0].purpose_code
            temp.comments = addEntry.data[0].comments
            if (addEntry.data[0].time) {
                temp.time = Math.round(addEntry.data[0].time / 1000)
            }
            else {
                temp.time = Math.round(new Date().getTime() / 1000)
            }
            if (addEntry.data[0].purpose_code === 0) {
                temp.destination_laydown_id = addEntry.data[0].destination
            }
            await setAddLayoutInventory({ laydownId: currentLaydown, bomLaydownId: bomLaydownId, data: [temp], action: addEntry.data[0].purpose_code, destination: addEntry.data[0].destination, bom_id: temp.bom_id })
            setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: addEntry.data[0].bom_id, count: null, purpose_code: 1, destination: null, comments: "", time: "" }] }))
            await setFetchSingleLayout({ laydown_id: currentLaydown })
        }
        catch (err) {
            console.log(err)
            toast.error("Something went Wrong")
        }
        finally {
            setBomLaydownLoader(false)
        }
    }

    const handleCheckBomInventorySave = () => {
        let temp = true
        let data = addEntry.data[0]
        if (data.purpose_code === 4) {
            if (data.issued || data.received_quantity) {
                temp = false
            }
        }
        else {
            if (data.issued) {
                temp = false
            }
        }
        if (manage === 0) {
            return temp
        }
        else {
            if (addEntry.data[0]?.destination && addEntry.data[0]?.issued) {
                return false
            }
            else {
                return true
            }
        }
    }

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const customStylesEditRemediatyQty = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const customStylesAddLayout = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: "0px 20px 20px",
            background: "white"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleCancel = () => {
        setDiscardScope(false)
    }


    return (
        <Container>
            <div className='flex justify-between overflow-auto w-full h-full'>
                <div className='h-full' style={{ width: showRightPanel ? "calc(100vw - 450px)" : "100%" }}>
                    <div className='flex justify-between'>
                        <div className='flex space-x-4 items-center pl-5'>
                            <p className='my-2 py-2 font-bold text-xl'>Laydown View {currentLaydown ? viewToggle === "Inventory" ? "- Inventory Log" : "- Scope" : ""}</p>
                        </div>
                        <div className='flex items-center space-x-4'>
                            {!currentLaydown && (
                                laydown.enableAdd ?
                                    <button className='rounded-xl border-2 px-3 py-1.5 border-gray-500 bg-white font-medium text-gray-500 hover:bg-gray-500 hover:text-white text-sm cursor-pointer' onClick={handleAddLaydown}>
                                        Cancel
                                    </button>
                                    :
                                    <button className='border-none rounded-xl px-3 py-1.5 text-sm focus:outline-none bg-red-550 text-white hover:bg-gray-700' onClick={handleAddLaydown}>
                                        + Add new laydown
                                    </button>
                            )}
                            <div className='flex space-x-4 items-center mr-4'>
                                <ViewByDropDown onSelect={handleChangeView} defaultValue="Laydown" />
                            </div>
                            {showRightPanel ?
                                <div className='text-xl text-black border bg-gray-200 mx-2'>
                                    <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowRightPanel(false)} />
                                </div>
                                :
                                <div className='text-xl text-black border bg-gray-200 mx-2'>
                                    <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowRightPanel(true)} />
                                </div>
                            }
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: "calc(100vh - 140px)" }}>
                        {loading ?
                            <div style={{ paddingTop: "20%", paddingLeft: "50%", width: "calc(100vw - 705px)" }}>
                                <p className='loader ml-5 mb-1'></p>
                                <p className='font-bold text-xl'>Fetching...</p>
                            </div>
                            :
                            individualBlockData?.bool ?
                                <div>
                                    <div className='flex justify-between'>
                                        <div className='flex space-x-4 py-2 pl-5 items-center'>
                                            <BiArrowBack className='cursor-pointer' onClick={handleBackBlockList} />
                                            <p className='text-base font-medium'>{getBlockDetails(individualBlockData?.block_id)?.name}</p>
                                        </div>
                                        <div className='flex space-x-4 items-center px-4'>
                                            <div className='my-1'>
                                                <p className='text-gray-400 text-sm font-semibold ml-1'>{`Section(s)`}</p>
                                                <div className='flex flex-wrap justify-left text-center max-w-[500px] overflow-y-auto'>
                                                    {listblocks?.sectionList.map((section) => {
                                                        return (
                                                            <button className={`${scopeEditMode && "cursor-pointer"} m-1 w-10 py-1 text-xs ${sectionSelect?.includes(section) ? "bg-red-550 text-white border-red-550 border" : "text-red-550 border"}`} disabled={!scopeEditMode} onClick={() => handleSectionSelect(section)}>{section}</button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className='flex items-center ml-2 mr-1'>
                                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm'>View Selected Trackers</p>
                                            </div>
                                            <div className='flex items-center ml-2 mr-1'>
                                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleSelectAllBlocks}>Select All</button>
                                            </div>
                                            <div className='flex items-center ml-2 mr-1'>
                                                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleClearAllBlock}>Clear Selection</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <SuryaBlockMap
                                        zimmerMan={handleCheckZimmerman()}
                                        deviceType="web"
                                        loader={true}
                                        bulkAllData={listblocks?.blockTtData}
                                        pierLegends={listblocks?.pierLegends}
                                        type="CP"
                                        typeData={listblocks.cpTrackerRowWise}
                                        userSelection={{ showType: "pier", selection: "None Selected" }}
                                        scopeSelection={true}
                                        showLaydownNameHover={true}
                                        laydownData={listLaydown?.data}
                                        currentLaydownData={currentLaydown}
                                        initialLoadSelectedLaydownTracker={true}
                                        currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(individualBlockData.block_id))}
                                        currentProjectData={projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                        disableSelection={!scopeEditMode}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        showLegend={false}
                                        mapSize={d3xScaleyScale}
                                        selectAll={selectAllTrackers}
                                        section={sectionSelect}
                                        prevSection={prevSection}
                                        clearSelection={clearSt}
                                        controls={true}
                                    />
                                </div>
                                :
                                !currentLaydown ?
                                    <div className='cursor-grab w-full' style={{ height: "calc(100vh - 170px)" }}>
                                        <TransformWrapper
                                            centerZoomedOut
                                            minScale={parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale)}
                                            initialScale={0.8}
                                            initialPositionX={((window.innerWidth - 760) - (parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale) * currentProject?.svg_dimension?.web?.web_svg_width)) / 2}
                                            initialPositionY={((window.innerHeight - 350) - (parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale) * currentProject?.svg_dimension?.web?.web_svg_height) + 100) / 2}
                                            onZoom={e => setSvgScale(e?.state?.scale)}
                                        >
                                            <TransformComponent
                                                contentStyle={{ width: `${currentProject?.svg_dimension?.web?.web_svg_width}px` }}
                                                wrapperStyle={{ height: "100%", width: "100%" }}
                                            >
                                                <div className='m-auto inline-block align-middle'>
                                                    <div className="svgmap svgParentDiv flex items-center justify-center cursor-pointer" onClick={e => handleOpenLaydown(e)} style={{ height: `50%`, width: `50%`, position: "relative" }} dangerouslySetInnerHTML={{ __html: listblocks.map }}></div>
                                                </div>
                                            </TransformComponent>
                                        </TransformWrapper>
                                        <div className={`absolute animate-slide-up flex ${!currentLaydown && !laydown.enableAdd ? "bottom-14" : "bottom-[-150px]"}`} style={{ left: "calc(50vw - 400px)", display: !currentLaydown && !laydown.enableAdd ? "" : "none" }}>
                                            <p className='text-white bg-black border px-4 py-2 rounded-2xl'>Select any area of the map to set the laydown point</p>
                                        </div>
                                        <div className={`absolute animate-slide-up flex ${laydown.enableAdd ? "bottom-14" : "bottom-[-150px]"}`} style={{ left: "calc(50vw - 400px)", display: laydown.enableAdd ? "" : "none" }}>
                                            <p className='text-white bg-black border px-4 py-2 rounded-2xl'>Select any area of the map to set the laydown point</p>
                                        </div>
                                    </div>
                                    :
                                    viewToggle === "Inventory" ?
                                        <div>
                                            <div className='flex justify-between'>
                                                <div className='flex space-x-4 items-center pl-5'>
                                                    <BiArrowBack className='cursor-pointer text-xl' onClick={onExitScope} />
                                                    <div className='flex space-x-4 items-center'>
                                                        {editInventoryNameModal?.boolean ?
                                                            <input ref={updateLaydownInputRef} className='border rounded-xl px-1.5 py-2 my-2 font-medium text-xl' defaultValue={handleFindCurrentLaydownName()} onBlur={(e) => handleUpdateLaydownName(e.target.value)} onKeyDown={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    handleUpdateLaydownName(e.target.value)
                                                                }
                                                            }} />
                                                            :
                                                            <p className='font-medium border border-white py-2 px-1.5 my-2 font-bold text-xl'>{handleFindCurrentLaydownName(currentLaydown)}</p>
                                                        }
                                                        <MdModeEditOutline className='cursor-pointer' onClick={() => setEditInventoryNameModal({ boolean: true, data: currentLaydown })} />
                                                    </div>
                                                </div>
                                                <div className='flex space-x-4 items-center mr-5'>
                                                    <div className='flex items-center space-x-4'>
                                                        {/* <div className='flex text-sm cursor-pointer border rounded-full font-medium p-1 bg-red-50 border-red-100 mr-4'>
                                                            <div className={`flex items-center ${manage === 0 ? "bg-red-550 border-none rounded-full" : ""} px-1`}
                                                                onClick={() => { handleToggle(0) }}
                                                                style={{ cursor: getLaydownAutoManage() ? 'not-allowed' : 'pointer' }}
                                                            >
                                                                <p className={`${manage === 0 ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} mr-1`}>Manage as a Store</p>
                                                            </div>
                                                            <div className={`flex ${manage === 1 ? "bg-red-550 border-none rounded-full" : ""}`}
                                                                onClick={() => { handleToggle(1) }}
                                                                style={{ cursor: !getLaydownAutoManage() ? 'not-allowed' : 'pointer' }}
                                                            >
                                                                <p className={`${manage === 1 ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`}>
                                                                    <p>Auto-Manage through Scope</p>
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        {getLaydownAutoManage() === false ?
                                                            <div className={`flex items-center bg-red-550 border-none rounded-full px-1`}
                                                            >
                                                                <p className={`text-white pr-3 pl-2 py-2 pt-2 mr-1`}>Manage as a Store</p>
                                                            </div>
                                                            :
                                                            <div className={`flex bg-red-550 border-none rounded-full`}
                                                            >
                                                                <p className={`text-white pr-3 pl-2 py-2 pt-2 ml-1`}>
                                                                    <p>Auto-Manage through Scope</p>
                                                                </p>
                                                            </div>}
                                                    </div>
                                                    {manage === 0 ?
                                                        <button className='cursor-pointer flex font-semibold text-sm' onClick={handleDownloadLaydown}>
                                                            <CgSoftwareDownload className='w-7 h-7' />
                                                        </button>
                                                        :
                                                        <button disabled className='flex font-semibold text-sm text-gray-300'>
                                                            <CgSoftwareDownload className='w-7 h-7' />
                                                        </button>
                                                    }
                                                    <button onClick={() => setConfirmationModal(true)}>
                                                        <MdDelete className='w-7 h-7' />
                                                    </button>
                                                </div>
                                            </div>
                                            <hr />
                                            {inventoryLoader?.bool ?
                                                <div style={{ paddingTop: "20%", paddingLeft: "50%", width: "calc(100vw - 705px)" }}>
                                                    <p className='loader ml-5 mb-1'></p>
                                                    <p className='font-bold text-xl'>{inventoryLoader?.text}...</p>
                                                </div>
                                                :
                                                manage === 0 ?
                                                    <div className='overflow-y-auto max-h-[71vh]'>
                                                        <table className='table table-compact z-0 relative overflow-auto w-full'>
                                                            <thead>
                                                                <tr className='sticky z-20 top-0'>
                                                                    <th className='normal-case w-[13%]'>Part Number</th>
                                                                    <th className='normal-case w-[41%]'>Part Description</th>
                                                                    <th className='normal-case w-[14%]'>
                                                                        <div className='flex space-x-2 items-center'>
                                                                            <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_current_qty`} />
                                                                            <p>Current Qty</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className='normal-case w-[14%]'>
                                                                        <div className='flex space-x-2 items-center'>
                                                                            <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_damaged_qty`} />
                                                                            <p>Damaged Qty</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className='normal-case w-[14%]'>
                                                                        <div className='flex space-x-2 items-center'>
                                                                            <AiOutlineInfoCircle className='cursor-pointer' data-tip data-for={`dashboard_laydown_damaged_qty`} />
                                                                            <p>Remediated Qty</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className='normal-case w-[4%]'></th>
                                                                </tr>
                                                            </thead>
                                                            <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_current_qty`}>
                                                                <p className='w-[300px]'>
                                                                    {`Current quantity is total quantity of the part currently available in the laydown, it includes damaged quantity, calculated by subtracting built (issued) quantities from delivered quantities`}
                                                                </p>
                                                            </ReactTooltip>
                                                            <ReactTooltip effect='solid' place='bottom' id={`dashboard_laydown_damaged_qty`}>
                                                                <p className='w-[300px]'>
                                                                    {`Damaged quantity is the total quantity of the part reported as damaged while adding delivery records in Bill of materials page`}
                                                                </p>
                                                            </ReactTooltip>
                                                            {listLaydown.bomLayoutData.length === 0 ?
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={5} className='py-14'>
                                                                            <div className='m-auto'>
                                                                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                :
                                                                listLaydown.bomLayoutData?.map((i) => {
                                                                    return (
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{i?.part_name}</td>
                                                                                <td className='pr-12 relative max-w-[26vw]' onMouseEnter={() => { setShowDescription(i?.bom_laydown_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                                                    {showDescription === i?.bom_laydown_id ?
                                                                                        <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                                            {i?.description}
                                                                                        </p>
                                                                                        :
                                                                                        <p className='max-w-[26vw]' style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", zIndex: 0 }}>
                                                                                            {i?.description}
                                                                                        </p>
                                                                                    }
                                                                                </td>
                                                                                <td>{i?.count}</td>
                                                                                <td>{i?.damaged ? i?.damaged : 0}</td>
                                                                                <td>
                                                                                    <div className='flex items-center' onClick={() => handleOpenEditRemediateQty(i)} >
                                                                                        <p className='pr-5'>{i?.remediated_quantity ? i?.remediated_quantity : 0}</p>
                                                                                        <RiEdit2Fill className='cursor-pointer' />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='flex space-x-4'>
                                                                                        {expanded === i?.bom_id ?
                                                                                            <BsChevronUp className='cursor-pointer' onClick={() => { setExpanded(-1); setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" }] })) }} />
                                                                                            :
                                                                                            <BsChevronDown className='cursor-pointer' onClick={() => handleOpenBomNestedTable(i)} />
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            {expanded === i?.bom_id && !bomLaydownLoader ?
                                                                                <td colSpan="6">
                                                                                    {expanded === i?.bom_id && !bomLaydownLoader ?
                                                                                        <div className='border pb-3 w-full'>
                                                                                            <NestedTable
                                                                                                className="min-h-[265px]"
                                                                                                bom_id={addEntry.data[0].bom_id}
                                                                                                handleSaveEdit={(e, epoch) => handleSaveEdit(e, epoch)}
                                                                                                handleDeleteInventory={(laydown_id, data) => handleDeleteInventory(laydown_id, data)}
                                                                                                laydownId={currentLaydown}
                                                                                                nestedData={listLaydown.bomInventoryData.Inventory_logs}
                                                                                                addEntry={addEntry.boolean}
                                                                                                destinationLaydown={listLaydown.data.filter(o => o.id !== currentLaydown)}
                                                                                                onAdjustmentChange={e => setAddEntry(produce((draft) => { draft.data[0].received_quantity = e }))}
                                                                                                onIssuedChange={e => setAddEntry(produce((draft) => { draft.data[0].issued = e }))}
                                                                                                onActionChange={e => setAddEntry(produce((draft) => { draft.data[0].purpose_code = e }))}
                                                                                                onDesitantionChange={e => setAddEntry(produce((draft) => { draft.data[0].destination = e }))}
                                                                                                onCommentsChange={e => setAddEntry(produce((draft) => { draft.data[0].comments = e }))}
                                                                                                onDateChange={e => setAddEntry(produce((draft) => { draft.data[0].time = e }))}
                                                                                            />
                                                                                            <div className='flex justify-between py-3 w-[95%] m-auto'>
                                                                                                <div className='flex py-3 rounded-2xl space-x-10 items-center'>
                                                                                                    <p className='text-sm pl-3 font-bold'>Current Quantity</p>
                                                                                                    <p className='text-sm'>{listLaydown.bomInventoryData.current_quantity}</p>
                                                                                                </div>
                                                                                                {addEntry.boolean ?
                                                                                                    <div className='flex space-x-5 px-2 justify-end'>
                                                                                                        <button className='btn bg-red-550 text-white rounded-full px-6 border-none' onClick={handleAddBomInventory} disabled={handleCheckBomInventorySave()}>Save</button>
                                                                                                        <button className='btn bg-red-550 text-white rounded-full px-4 border-none' onClick={() => setAddEntry(produce((draft) => { draft.boolean = false; draft.data[0] = { bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" } }))}>Cancel</button>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                {!addEntry.boolean && <button className='btn bg-red-550 text-white rounded-full px-4 mx-2 border-none' onClick={() => { setAddEntry(produce((draft) => { draft.boolean = true })) }}>+ Add Entry</button>}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </td>
                                                                                :
                                                                                <hr />
                                                                            }
                                                                        </tbody>
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                                    :
                                                    <div className='overflow-y-auto' style={{ maxHeight: "calc(100vh - 210px)" }}>
                                                        <table className='table table-compact z-0 relative overflow-auto w-full'>
                                                            <thead>
                                                                <tr className='sticky top-0 z-30'>
                                                                    <th className={`normal-case w-[13%]`} rowSpan='2'>Part Number</th>
                                                                    <th className={`normal-case ${showScopeTableQty ? "w-[17%]" : "w-[47%]"}`} rowSpan='2'>Part Description</th>
                                                                    <th className={`normal-case text-center ${showScopeTableQty ? "w-[70%]" : "w-[40%]"}`} colspan={showScopeTableQty ? "7" : "5"}>Quantities</th>
                                                                    <th></th>
                                                                </tr>
                                                                <tr className='sticky top-9 border-t z-20 border-white'>
                                                                    <th className={`normal-case`}>Required Qty</th>
                                                                    <th className={`normal-case flex space-x-3 items-center cursor-pointer`} onClick={() => setShowScopeTableQty(!showScopeTableQty)}>
                                                                        <p>Delivered Qty</p>
                                                                        {showScopeTableQty ? <MdKeyboardArrowLeft className='text-base border border-white' /> : <MdKeyboardArrowRight className='text-base border border-white' />}
                                                                    </th>
                                                                    {showScopeTableQty && <th className={`normal-case`}>Damaged Qty</th>}
                                                                    {showScopeTableQty && <th className={`normal-case`}>Remediate Qty</th>}
                                                                    <th className={`normal-case`}>Distributed Qty</th>
                                                                    <th className={`normal-case`}>Installed Qty</th>
                                                                    <th className={`normal-case`}>Current Qty</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            {listLaydown.scopeLaydownData?.bom_laydown_data && listLaydown.scopeLaydownData?.bom_laydown_data?.length === 0 ?
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={10} className='py-14'>
                                                                            <div className='m-auto'>
                                                                                <BsFillFileEarmarkImageFill className='m-auto text-gray-300 text-6xl' />
                                                                                <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                :
                                                                <tbody>
                                                                    {listLaydown?.scopeLaydownData?.bom_laydown_data && listLaydown?.scopeLaydownData?.bom_laydown_data?.map((scopeData) => {
                                                                        return (
                                                                            <>
                                                                                <tr className='hover'>
                                                                                    <td className='sticky z-20 left-0'>{scopeData?.part_name}</td>
                                                                                    <td className={`relative py-2 ${showScopeTableQty ? `${showRightPanel ? "max-w-[10vw]" : "max-w-[30vw]"}` : `${showRightPanel ? "max-w-[23vw]" : "max-w-[43vw]"}`}`} onMouseEnter={() => { setShowDescription(scopeData?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                                                                                        {showDescription === scopeData?.bom_id &&
                                                                                            <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                                                                                {scopeData?.description}
                                                                                            </p>
                                                                                        }
                                                                                        <p className={`${showScopeTableQty ? `${showRightPanel ? "max-w-[10vw]" : "max-w-[30vw]"}` : `${showRightPanel ? "max-w-[23vw]" : "max-w-[43vw]"}`}`} style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", zIndex: 0 }}>{scopeData?.description}</p>
                                                                                    </td>
                                                                                    <td>{scopeData?.required_qty ? scopeData?.required_qty : 0}</td>
                                                                                    <td>{scopeData?.delivered_qty ? scopeData?.delivered_qty : 0}</td>
                                                                                    {showScopeTableQty && <td>{scopeData?.damaged ? scopeData?.damaged : 0}</td>}
                                                                                    {showScopeTableQty && <td>
                                                                                        <div className='flex items-center' onClick={() => handleOpenEditRemediateQty(scopeData)} >
                                                                                            <p className='pr-5'>{scopeData?.remediated_quantity ? scopeData?.remediated_quantity : 0}</p>
                                                                                            <RiEdit2Fill className='cursor-pointer' />
                                                                                        </div>
                                                                                    </td>}
                                                                                    <td>{scopeData?.distributed_qty ? scopeData?.distributed_qty : 0}</td>
                                                                                    <td>{scopeData?.installed_qty ? scopeData?.installed_qty : 0}</td>
                                                                                    <td>{scopeData?.current_qty ? scopeData?.current_qty : 0}</td>
                                                                                    <td>
                                                                                        <div className='flex space-x-4'>
                                                                                            {expanded === scopeData?.bom_id ?
                                                                                                <BsChevronUp className='cursor-pointer' onClick={() => { setExpanded(-1); setAddEntry(produce((draft) => { draft.boolean = false; draft.data = [{ bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" }] })) }} />
                                                                                                :
                                                                                                <BsChevronDown className='cursor-pointer' onClick={() => handleOpenBomNestedTable(scopeData)} />
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    {expanded === scopeData?.bom_id && !bomLaydownLoader ?
                                                                                        <td colSpan="10">
                                                                                            {expanded === scopeData?.bom_id && !bomLaydownLoader ?
                                                                                                <div className='border pb-3 w-full'>
                                                                                                    <NestedTable
                                                                                                        autoScope={true}
                                                                                                        bom_id={addEntry.data[0].bom_id}
                                                                                                        handleSaveEdit={(e, epoch) => handleSaveEdit(e, epoch)}
                                                                                                        handleDeleteInventory={(laydown_id, data) => handleDeleteInventory(laydown_id, data)}
                                                                                                        laydownId={currentLaydown}
                                                                                                        nestedData={listLaydown.bomInventoryData.Inventory_logs}
                                                                                                        addEntry={addEntry.boolean}
                                                                                                        destinationLaydown={listLaydown.data.filter(o => o.id !== currentLaydown)}
                                                                                                        onAdjustmentChange={e => setAddEntry(produce((draft) => { draft.data[0].received_quantity = e }))}
                                                                                                        onIssuedChange={e => setAddEntry(produce((draft) => { draft.data[0].issued = e }))}
                                                                                                        onActionChange={e => setAddEntry(produce((draft) => { draft.data[0].purpose_code = e }))}
                                                                                                        onDesitantionChange={e => setAddEntry(produce((draft) => { draft.data[0].destination = e }))}
                                                                                                        onCommentsChange={e => setAddEntry(produce((draft) => { draft.data[0].comments = e }))}
                                                                                                        onDateChange={e => setAddEntry(produce((draft) => { draft.data[0].time = e }))}
                                                                                                    />
                                                                                                    <div className='flex justify-between py-3 w-[95%] m-auto'>
                                                                                                        <div className='flex py-3 rounded-2xl space-x-10 items-center'>
                                                                                                            <p className='text-sm pl-3 font-bold'>Current Quantity</p>
                                                                                                            <p className='text-sm'>{listLaydown.bomInventoryData.current_quantity}</p>
                                                                                                        </div>
                                                                                                        {addEntry.boolean ?
                                                                                                            <div className='flex space-x-5 px-2 justify-end'>
                                                                                                                <button className='btn bg-red-550 text-white rounded-full px-6 border-none' onClick={handleAddBomInventory} disabled={handleCheckBomInventorySave()}>Save</button>
                                                                                                                <button className='btn bg-red-550 text-white rounded-full px-4 border-none' onClick={() => setAddEntry(produce((draft) => { draft.boolean = false; draft.data[0] = { bom_id: null, count: null, purpose_code: 1, destination: null, comments: "" } }))}>Cancel</button>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                        }
                                                                                                        {!addEntry.boolean && <button className='btn bg-red-550 text-white rounded-full px-4 mx-2 border-none' onClick={() => { setAddEntry(produce((draft) => { draft.boolean = true })) }}>+ Add Entry</button>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </td>
                                                                                        :
                                                                                        <hr />
                                                                                    }
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            }
                                                        </table>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <div className='flex justify-between'>
                                                <div className='flex space-x-4 items-center pl-5'>
                                                    <BiArrowBack className='cursor-pointer text-xl' onClick={() => scopeEditMode ? setDiscardScope(true) : onExitScope()} />
                                                    <div className='flex space-x-4 items-center'>
                                                        <p className='font-medium text-base border border-white py-2 px-1.5 my-2 font-bold text-xl'>{handleFindCurrentLaydownName(currentLaydown)}</p>
                                                        <MdModeEditOutline className='cursor-pointer' onClick={() => setEditInventoryNameModal({ boolean: true, data: currentLaydown })} />
                                                    </div>
                                                </div>
                                                <div className='flex space-x-4 items-center mr-5'>
                                                    <div className='flex items-center ml-2 mr-1'>
                                                        <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm'>View Selected Scope</p>
                                                    </div>
                                                    <div className='flex items-center ml-2 mr-1'>
                                                        <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleSelectAllBlocks}>Select All</button>
                                                    </div>
                                                    <div className='flex items-center ml-2 mr-1'>
                                                        <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!scopeEditMode} onClick={handleClearAllBlock}>Clear Selection</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div style={{ height: "calc(100vh - 280px)" }}>
                                                <TransformWrapper
                                                    centerZoomedOut
                                                    minScale={parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale)}
                                                    initialScale={0.5}
                                                    initialPositionX={((window.innerWidth - 760) - (parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale) * currentProject?.svg_dimension?.web?.web_svg_width)) / 2}
                                                    initialPositionY={((window.innerHeight - 350) - (parseFloat(currentProject?.svg_dimension?.web?.web_svg_min_scale) * currentProject?.svg_dimension?.web?.web_svg_height) + 100) / 2}
                                                >
                                                    <TransformComponent
                                                        contentStyle={{ width: `${currentProject?.svg_dimension?.web?.web_svg_width}px` }}
                                                        wrapperStyle={{ height: "100%", width: "100%" }}
                                                    >
                                                        <div className='m-auto inline-block align-middle'>
                                                            <div className="svgmap scopeSelection flex items-center justify-center cursor-grab" style={{ height: `50%`, width: `50%`, position: "relative" }} dangerouslySetInnerHTML={{ __html: scopeSvg }}></div>
                                                        </div>
                                                    </TransformComponent>
                                                </TransformWrapper>
                                            </div>
                                            {!loading && (
                                                <div className='relative w-[100%]'>
                                                    {viewToggle === "Scope" && (
                                                        <div className={`absolute animate-slide-up flex ${showToastInfo ? "bottom-8" : "bottom-[-150px]"}`} style={{ left: "calc(50% - 150px)", display: showToastInfo ? "" : "none" }}>
                                                            <div className='text-white bg-black border px-4 py-1.5 rounded-2xl'>
                                                                <div className='flex justify-center items-center space-x-2'>
                                                                    <span data-tip data-for="svg" className='mt-1.5'>
                                                                        <AiOutlineInfoCircle />
                                                                    </span>
                                                                    <div className='flex flex-col space-y-1 text-white'>
                                                                        <p className='text-sm mt-0.5 ml-1'>
                                                                            Click on any block to select
                                                                        </p>
                                                                        <p className='text-sm mt-0.5 ml-1'>
                                                                            Double click on any block to dive in
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {viewToggle === "Scope" && (
                                                        individualBlockData?.bool ?
                                                            <div className='flex space-x-5 justify-center'>
                                                                <div className='flex justify-center justify-end pt-1'>
                                                                    <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "yellow" }}></p>
                                                                    <p className='mr-2'>Selected Trackers</p>
                                                                </div>
                                                                <div className='flex justify-center justify-end pt-1'>
                                                                    <p className='w-5 h-5 rounded-full mx-2 mt-0.5' style={{ backgroundColor: "green" }}></p>
                                                                    <p className='mr-2'>Assigned Trackers</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='flex space-x-4 justify-center'>
                                                                <div className='flex'>
                                                                    <p className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: "green" }}></p>
                                                                    <p className='mr-2'>Fully assigned block</p>
                                                                </div>
                                                                <div className='flex'>
                                                                    <p className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: "pink" }}></p>
                                                                    <p className='mr-2'>Partially assigned block</p>
                                                                </div>
                                                                <div className='flex'>
                                                                    <p className='w-5 h-5 rounded-full mx-2' style={{ backgroundColor: "yellow" }}></p>
                                                                    <p className='mr-2'>Selected Block</p>
                                                                </div>
                                                            </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                        }
                    </div>
                    <Modal
                        isOpen={addLayoutmodal.boolean}
                        style={customStylesAddLayout}
                        contentLabel="Add Layout table">
                        <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                            <p className='mt-2'>Add Laydown</p>
                        </div>
                        <hr />
                        <form className='w-[400px] m-auto' onSubmit={handleSubmit(createLayoutSubmit)}>
                            <div className='flex justify-between space-x-5'>
                                <p className='mt-7'>Name :</p>
                                <input required className="appearance-none w-[80%] block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7" placeholder='Enter Laydown Name' {...register("name")} />
                            </div>
                            <div className='flex justify-end space-x-5 mt-5'>
                                <p className='btn rounded-full px-4 cursor-pointer bg-white border-2 text-gray-500 hover:text-white' onClick={closeAddLayoutModal}>Cancel</p>
                                {addlayoutLoader ?
                                    <button
                                        className="border-none rounded-3xl bg-red-550 text-white btn loading"
                                    >
                                        Creating
                                    </button>
                                    :
                                    <input type='submit' value={"Create"} className='bg-red-550 text-white px-4 py-2 rounded-3xl cursor-pointer' />
                                }
                            </div>
                        </form>
                    </Modal>
                    <Modal
                        isOpen={openEditRemediateQty}
                        onRequestClose={() => setOpenRemediateQty(false)}
                        style={customStylesEditRemediatyQty}
                        contentLabel="Remediate Qty Modal"
                    >
                        <div className='p-3 w-[50vw]'>
                            <div className='flex justify-between items-center m-2'>
                                <div >
                                    <p className='mt-4 font-bold text-xl'>
                                        {remediateQtyBomDetails?.part_name}
                                    </p>
                                    <p className='mt-2'>{remediateQtyBomDetails?.description}</p>
                                </div>
                                <div className='flex space-x-4 items-center'>
                                    <button className='bg-red-550 text-white border-none rounded-3xl btn drawer-button px-6' onClick={handleSaveRemediatedQty}>Save</button>
                                    <VscClose className='cursor-pointer active:text-white border-none text-xl rounded-full hover:bg-gray-100 border active:bg-red-550' onClick={() => setOpenRemediateQty(false)} />
                                </div>
                            </div>
                            <table className='table table-compact w-full overflow-auto z-0 h-full my-3'>
                                <thead>
                                    <tr>
                                        <th className='normal-case bg-red-200 w-[40%]'>{getDeliveryReferenceName() !== "" ? getDeliveryReferenceName() : "Delivery Reference"}</th>
                                        <th className='normal-case bg-red-200 w-[20%]'>Delivered Qty</th>
                                        <th className='normal-case bg-red-200 w-[20%]'>Damaged Qty</th>
                                        <th className='normal-case bg-red-200 w-[20%]'>Remediated Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listMileStone?.remediateQty?.length && listMileStone?.remediateQty?.map((laydownIdKey, index) => {
                                        return (
                                            <tr>
                                                <td>{laydownIdKey?.delivery_name}</td>
                                                <td>{laydownIdKey?.count}</td>
                                                <td>{laydownIdKey?.damaged}</td>
                                                <td>
                                                    <input className='border w-[98%] p-1'
                                                        placeholder='Remediated Qty'
                                                        defaultValue={laydownIdKey?.remediated_quantity ? laydownIdKey?.remediated_quantity === null ? 0 : laydownIdKey?.remediated_quantity : 0}
                                                        onChange={e => handleChangeRemediatedQty(e, index)} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={confimationModal}
                        style={customConfirmStyle}
                        contentLabel='Laydown Confirmation'
                    >
                        <div className='p-2 w-[40vw]'>
                            <div className='flex justify-between mb-4'>
                                <p className='text-xl font-semibold'>Delete Laydown</p>
                                <VscClose className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} onClick={() => setConfirmationModal(false)} />
                            </div>
                            <hr />
                            {handleCheckQuantity() ?
                                <p className='my-4'>You are deleting the laydown with some current quantities and / or damaged quantities reported, this deletion will modify the initial delivery records accordingly. You may download the inventory log for your reference if required</p>
                                :
                                <p className='my-4'>Deleting the laydown will delete all the associated inventory log as well. Please download the log for your reference if required and proceed with deletion if needed</p>
                            }
                            <hr />
                            <div className='mt-4 flex flex-col space-y-3'>
                                <p className='text-right cursor-pointer text-gray-500 font-semibold' onClick={() => setConfirmationModal(false)}>Cancel</p>
                                <p className='text-right cursor-pointer font-semibold text-black' onClick={handleDownloadLaydown}>Download inventory log</p>
                                <div>
                                    {deleteLaydownLoader ?
                                        <p className='text-right flex justify-end space-x-3 items-center cursor-pointer text-red-550 font-semibold'>
                                            <p className='loader' style={{ width: "25px", height: "25px" }}></p>
                                            <p>Understood, proceed with deletion</p>
                                        </p>
                                        :
                                        <p className='text-right flex space-x-3 justify-end items-center cursor-pointer text-red-550 font-semibold' onClick={handleDeleteLaydown}>
                                            Understood, proceed with deletion
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                {showRightPanel && (
                    <div className='border-x h-full'>
                        <div className='overflow-auto flex flex-col space-y-2 relative h-full pr-2' style={{ height: "calc(100vh - 100px)", width: "445px" }} >
                            <div className='flex justify-between items-center px-4'>
                                <div className='flex justify-center mt-2'>
                                    {currentLaydown && !scopeEditMode ?
                                        <div className={`flex text-sm ${currentLaydown ? "cursor-pointer" : "cursor-auto"} border rounded-full font-medium p-1 bg-red-50 border-red-100 mr-4`}>
                                            <div className={`flex items-center ${viewToggle === "Inventory" ? "bg-red-550 border-none rounded-full" : ""} px-1`} onClick={() => {
                                                if (currentLaydown) {
                                                    setViewToggle("Inventory")
                                                    setSelectedBlocks({ bool: true, data: [], initSelectedBlocks: {} })
                                                    viewToggleRef.current = "Inventory"
                                                    setIndividualBlockData({ bool: false, block_id: null, trackerData: {} })
                                                }
                                            }}>
                                                <p className={`${viewToggle === "Inventory" ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} mr-1`}>Inventory</p>
                                            </div>
                                            <div className={`flex ${viewToggle === "Scope" ? "bg-red-550 border-none rounded-full" : ""}`} onClick={() => {
                                                if (currentLaydown && !inventoryLoader.bool) {
                                                    setViewToggle("Scope")
                                                    viewToggleRef.current = "Scope"
                                                }
                                            }}>
                                                <p className={`${viewToggle === "Scope" ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`}>
                                                    <p>Scope</p>
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        <div className={`flex text-sm cursor-auto border rounded-full font-medium p-1 bg-gray-50 border-gray-100 mr-4`}>
                                            <div className={`flex items-center ${viewToggle === "Inventory" ? "bg-gray-200 border-none rounded-full" : ""} px-1`}>
                                                <p className={`${viewToggle === "Inventory" ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} mr-1`}>
                                                    <p>Inventory</p>
                                                </p>
                                            </div>
                                            <div className={`flex items-center ${viewToggle === "Scope" ? "bg-gray-200 border-none rounded-full" : ""} px-1`}>
                                                <p className={`${viewToggle === "Scope" ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`}>
                                                    <p>Scope</p>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {viewToggle === "Scope" && currentLaydown && (
                                    scopeEditMode ?
                                        <div className='flex space-x-4'>
                                            <div className='mt-2'>
                                                <button className='rounded-xl border-2 px-3 py-1.5 border-gray-500 bg-white font-medium text-gray-500 hover:bg-gray-500 hover:text-white text-sm cursor-pointer' onClick={() => setDiscardScope(true)}>Cancel</button>
                                            </div>
                                            <div className='mt-2'>
                                                <button className='cursor-pointer border-2 border-red-550 px-3 py-1.5 hover:bg-gray-700 hover:border-gray-700 text-sm bg-red-550 text-white rounded-xl' onClick={() => setSaveScopeModal(true)}>Save Scope</button>
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-2'>
                                            <button className='cursor-pointer border-none px-3 py-1.5 hover:bg-gray-700 text-sm bg-red-550 text-white rounded-xl' onClick={() => { setScopeEditMode(true); scopeEditModeRef.current = true }}>Edit Scope</button>
                                        </div>
                                )}
                            </div>
                            <hr />
                            <div className='mx-5 flex flex-col space-y-3'>
                                <p className='font-semibold text-base'>Laydown List</p>
                                {listLaydown?.data?.map((laydown) => {
                                    return (
                                        <div className={`${!scopeEditMode && "cursor-pointer"} text-sm flex justify-between items-center px-3 py-2 my-2 rounded-full ${currentLaydown === laydown?.id ? "font-semibold text-base bg-gray-200" : `font-medium ${!scopeEditMode && "hover:bg-gray-100"}`}`} onClick={() => handleClickLaydown(laydown?.id)}>
                                            <div className='flex space-x-2 items-center'>
                                                {currentLaydown === laydown?.id ?
                                                    <IoIosArrowBack />
                                                    :
                                                    <IoIosArrowForward />
                                                }
                                                <p>{laydown?.name}</p>
                                            </div>
                                            {currentLaydown === laydown?.id && <GoDotFill className='text-green-500 text-xl' />}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
                }
            </div >
            <Modal
                isOpen={discardScope}
                style={customConfirmStyle}
                contentLabel="Discard Scope Alert Box"
            >
                <div className='rounded-xl flex flex-col space-y-4 px-6 py-4'>
                    <p className='text-xl font-medium'>Are you sure want to discard your changes?</p>
                    <hr />
                    <div className='flex justify-end space-x-4 text-sm font-medium'>
                        <button className='border rounded-xl border-2 border-gray-500 px-4 py-1.5 hover:text-white hover:bg-gray-500' onClick={handleCancel}>Cancel</button>
                        <button className='rounded-xl bg-red-550 text-white hover:bg-gray-700 px-4 py-1.5' onClick={handleCancelScope}>Proceed</button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={saveScopeModal}
                style={customConfirmStyle}
                contentLabel="Save Scope Alert Box"
            >
                <div className='rounded-xl flex flex-col space-y-4 px-6 py-4'>
                    <p className='text-xl font-medium'>Are you sure want to Save your changes?</p>
                    <hr />
                    <div className='flex justify-end space-x-4 text-sm font-medium'>
                        <button className='border rounded-xl border-2 border-gray-500 px-4 py-1.5 hover:text-white hover:bg-gray-500' onClick={() => setSaveScopeModal(false)}>Cancel</button>
                        {saveScopeLoader ?
                            <button className='rounded-xl flex items-center space-x-2 bg-red-550 text-white hover:bg-gray-700 px-4 py-1.5'>
                                <p className='loader' style={{ width: "25px", height: "25px" }}></p>
                                <p>Proceed</p>
                            </button>
                            :
                            <button className='rounded-xl bg-red-550 text-white hover:bg-gray-700 px-4 py-1.5' onClick={handleSaveScope}>Proceed</button>
                        }
                    </div>
                </div>
            </Modal>
        </Container >
    )
}

export default Laydown

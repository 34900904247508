import React from 'react'

export function DateFormat(props) {

    const date = () => {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        
        let date = new Date(props.date);
        let day = date.getDate();
        let month = months[date.getMonth()];
        let year = date.getFullYear().toString().slice(-4);
        if(day < 10){
          day = `0${day}`
        }
      
        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

  return (
    <div>
      {date()}
    </div>
  )
}

export function forecastFormatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}



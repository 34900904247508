import React, { useEffect, useState } from 'react';
import 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/build/pdf.worker.entry';
import Modal from "react-modal";
import { MdOutlineFileDownload } from "react-icons/md";
import { saveAs } from 'file-saver';
import { VscClose } from "react-icons/vsc";

const PDFViewer = (props) => {
    const { isModalShow, pdfUrl, docname, setIsModalShow } = props;
    const [pdfLoaded, setPdfLoaded] = useState(false);

    useEffect(() => {
        setPdfLoaded(true)
    }, [])

    const closeViewer = () => {
        setIsModalShow(false)
    };

    const closeModal = () => {
        setIsModalShow(false)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px",
            width: '700px',
            height: '750px'
        }
    };

    const handleSavePdf = async (data) => {
        saveAs(data, docname)
    }

    return (
        <Modal
            isOpen={isModalShow}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Logout Modal"
        >
            <div style={{ width: '100%' }}>
                {pdfLoaded && (
                    <div className="viewer visible ">
                        <header className="viewer__toolbar">
                            <div className={'flex justify-end items-center'}>
                                <div className={'font-bold flex gap-8 items-center'}>
                                    <div>
                                        <MdOutlineFileDownload className={'cursor-pointer font-bold text-2xl'} onClick={() => handleSavePdf(pdfUrl)} />
                                    </div>
                                    <VscClose onClick={closeViewer} className={'cursor-pointer font-bold text-2xl'} />
                                    {/* <button className="close-preview" onClick={closeViewer}>X</button> */}
                                </div>
                            </div>
                        </header>
                        <iframe
                            title="PDF Viewer"
                            target="_blank"
                            src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                            style={{ width: '100%', height: '650px', border: 'none', marginTop: '20px' }}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};
export default PDFViewer;
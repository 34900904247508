import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios'
import App from './app';
import reportWebVitals from './reportWebVitals';
import { getToken } from './utils/auth';
import { Provider as StoreProvider } from "jotai";
import AuthProvider from './providers/auth';
import ProjectProvider from './providers/project';
import { Toaster } from 'react-hot-toast';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e868857b5d35415d01ff67e4f685a808@o4507889727832064.ingest.us.sentry.io/4507889764532224",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


axios.interceptors.request.use(async (request) => {
  request.baseURL = process.env["REACT_APP_API"] + "/api";
  let token = await getToken();
  request.headers["X-Device"] = "web";
  if (token && request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
    // request.headers["Tenant"] = `${localStorage.getItem("tenant-name")}`;
  }
  if (localStorage.getItem("project_id")) {
    request.headers["project_id"] = `${localStorage.getItem("project_id")}`
  }
  return request;
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StoreProvider>
    <Sentry.ErrorBoundary>
      <AuthProvider>
        <ProjectProvider>
          <App />
          <Toaster position='bottom-center' toastOptions={{ style: { color: "white", background: "#262223" } }} />
        </ProjectProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from "axios"


// const get_construction_activities = async (constructionActivities) => {
//     let constructionActivity = {}
//     for (let i of constructionActivities) {
//         constructionActivity[i?.activity_id] = i
//         for (let j of i?.sub_activity) {
//             constructionActivity[j?.activity_id] = j
//         }
//     }
//     return constructionActivity
// }
// const get_quality_check_activities = async (qcActivities) => {
//     let qcActivity = {}
//     for (let i of qcActivities) {
//         qcActivity[i?.activity_id] = i
//         for (let j of i?.sub_activity) {
//             qcActivity[j?.activity_id] = j
//         }
//     }
//     return qcActivity
// }
// const get_work_hours_activities = async (workHoursActivities) => {
//     let workHoursActivity = {}
//     for (let i of workHoursActivities) {
//         workHoursActivity[i?.id] = i
//     }
//     return workHoursActivity
// }
// export async function get_all_activities() {
//     let orderedActivities = {}
//     let activities = await axios.get(`/projects/fetch/activities?is_ordered=True`)
//     activities = activities?.data
//     let constructionActivities = await get_construction_activities(activities?.Construction)
//     let qcActivities = await get_quality_check_activities(activities?.Qc)
//     let workHoursActivities = await get_work_hours_activities(activities?.workhours_activity)
//     orderedActivities["construction"] = constructionActivities
//     orderedActivities["qc"] = qcActivities
//     orderedActivities["work_hours"] = workHoursActivities
//     console.log(orderedActivities)
//     return orderedActivities
// }
export async function get_all_activities() {
    let activities = await axios.get(`/projects/fetch/activities/group`)
    activities = activities?.data
    return activities
}
import axios from "axios";
import { atom, useAtom } from "jotai";
import produce from "immer"
import toast from "react-hot-toast";
import projectAtom from "./projectAtom";
import { getForecasteDateWithCount } from "../dal/forecast/forecast";

const  project_id = JSON.parse(localStorage.getItem("project_id"))
const statAtom = atom({
    data: [],
    downloadData: false,
    forecastTemplateDownloadData: false
})

export const fetchStatAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/progress/installed_stats/activity?activity_id=${args.actId}&start_date=${args.startDate}&end_date=${args.endDate}`)
        set(statAtom, produce((draft) => { draft.data = res.data }))
    }
)

export const fetchForecastAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/progress/forecast?part_id=${args.partId}&start_date_epoch=${args.startDate}&end_date_epoch=${args.endDate}`)
        let getForecastData = await getForecasteDateWithCount(res?.data)
        // let currentDate = new Date().toISOString().split("T")[0]
        // let currentDateIndex = res?.data.findIndex(o => o.date === `${currentDate.split("-")[1]}/${currentDate.split("-")[2]}/${currentDate.split("-")[0]}`)
        for (let data in getForecastData) {
            let dateRes = getForecastData[data]?.date
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            let monthIndex = dateRes.split("/")[0][1]
            getForecastData[data]["formattedDate"] = `${dateRes.split("/")[1]}-${months[parseInt(monthIndex) - 1]}-${dateRes.split("/")[2][2]}${dateRes.split("/")[2][3]}`
            // if (currentDateIndex <= data && currentDateIndex !== -1) {
            //     res.data[data]["eodc"] = res.data[currentDateIndex]?.eodc
            //     res.data[data]["forecasted_count"] = res.data[currentDateIndex]?.forecasted_count
            // }
        }
        set(statAtom, produce((draft) => { draft.data = getForecastData }))
    }
)

export const fetchDownloadData = atom(
    null,
    async (get, set, args) => {
        set(statAtom, produce((draft) => { draft.downloadData = true }))
        let response = await axios.get(`/progress/installed_stats_download?activity_id=all`)
        let projectData = await axios.get(`/project/get_projects`)
        let project_name = projectData.data.find(p => p.id === parseInt(localStorage.getItem("project_id")))
        let csv = response.data
        const element = document.createElement("a");
        const file = new Blob([csv], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = `${project_name?.project_number}_Installed_Stats_${project_name?.name}`
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        set(statAtom, produce((draft) => { draft.downloadData = false }))
        toast.success("Download Completed")
    }
)

export const fetchForecastTemplateDownloadData = atom(
    null,
    async (get, set, args) => {
        
        let response = await axios.get(`/progress/forecast_population/create?project_id=${project_id}`, {
            responseType: 'blob',  // Important: this tells axios to expect a binary response
        })
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.href = url;
        // Set the download attribute to trigger a download with the desired filename
        link.setAttribute('download', 'Forecast_Population_Template.xlsx');
        // Append the link to the body
        document.body.appendChild(link);
        // Programmatically click the link to trigger the download
        link.click();
        // Clean up by revoking the object URL and removing the link
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
        toast.success("Download Completed")
    }
)
export const uploadForecastTemplateFileAtom = atom(
    null,
    async (get, set, args) => {
   
        const formData = new FormData();
        formData.append('file', args);
        let uploadForecastTemplateData = await axios.post(`/file_upload/upload/image`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        let populateForecast = await axios.get(`/progress/forecast_population?project_id=${project_id}&upload_id=${uploadForecastTemplateData?.data}`)

    })

export default statAtom
import React from 'react'
import { useLocation } from 'react-router-dom'

function PdfViewerComp() {
    const location = useLocation()
    return (
        <div>
            {/* <embed src={location.search.split("?url=")[1]} type="application/pdf" width="100%" height="100vh" /> */}
            <iframe
                title="PDF Viewer"
                target="_blank"
                src={`https://docs.google.com/gview?url=${encodeURIComponent(location.search.split("?url=")[1])}&embedded=true`}
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </div>
    )
}

export default PdfViewerComp

import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const MultiSelect = ({onChange, defaultValue, bool, edit}) => {

    const [isOpen, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(defaultValue[0] === 3 ? [1,2] : defaultValue)
    const dropdownRef = useRef(null);
    const initialLoadRef = useRef(false)

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if(initialLoadRef.current === true && edit){
            setSelectedItem([])
        }
        if(!edit){
            setSelectedItem([])
        }
        initialLoadRef.current = false
    }, [bool])

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setOpen(!isOpen)
    }

    const formatValue = () => {
        let temp
        if(selectedItem?.length === 2){
            temp = "Web & Tablet"
        }
        else if(selectedItem[0] === 1){
            temp = "Web App"
        }
        else if(selectedItem[0] === 2){
            temp = "Tablet App"
        }
        else{
            temp = ""
        }
        return temp ? temp : ""
    }

    const handleItemChange = (id) => {
        let temp = []
        if(selectedItem.includes(id)){
            temp = selectedItem.filter((item) => item !== id)
        }
        else{
            temp = [...selectedItem, id]
        }
        temp = [...new Set(temp)]
        setSelectedItem(temp)
        onChange(temp)
    }

    return(
        <div className='relative flex' ref={dropdownRef}>
            <div>
                <input type='text' className='w-36 p-3 border rounded-2xl border-gray-300 cursor-pointer' style={{minWidth:"170px", maxWidth: "250px"}} placeholder='Select App' onClick={handleClick} value={formatValue()} readOnly />
                {isOpen && (
                    <ul className='absolute z-10 bg-white shadow mt-1 py-2 px-5 w-36 rounded-xl max-h-24 border border-gray-300'>
                        <li className='font-medium flex items-center py-1.5 cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-black' onClick={() => handleItemChange(1)} style={{textTransform: "capitalize"}}>
                            <input type='checkbox' className='mr-2' checked={selectedItem.includes(1)} onChange={() => handleItemChange(1)} />
                            <span>Web App</span>
                        </li>
                        <li className='font-medium flex items-center py-1.5 cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-black' onClick={() => handleItemChange(2)} style={{textTransform: "capitalize"}}>
                            <input type='checkbox' className='mr-2' checked={selectedItem.includes(2)} onChange={() => handleItemChange(2)} />
                            <span>Tablet App</span>
                        </li>
                    </ul>
                )}
            </div>
            {isOpen ?
                <BsChevronUp className='absolute right-5 top-4 text-gray-400 cursor-pointer' onClick={handleClick} />
            :                
                <BsChevronDown className='absolute right-5 top-4 text-gray-400 cursor-pointer' onClick={handleClick}/>
            }
        </div>
    )
}

export default MultiSelect

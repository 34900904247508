export default function InputBox (props) {
    let type = "text"
    if(props?.type === "number"){
        type = "number"
    }
    return (
        <input
            className= {`appearance-none ${props.block} text-gray-700 border border-gray-300 ${props.rounded} py-3 px-4 mt-4 ${props.mb} ${props.mr} leading-tight`}
            style={{width:`${props.width}`, margin: `${props.margin}`}}
            name={`${props?.name}`}
            type={type}
            placeholder={`${props?.placeholder}`}
            value={props?.value}
            disabled={props?.disabled}
            onChange={props?.onChange} />
    )
}
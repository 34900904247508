import { useAtom } from 'jotai';
import React, { useState, useEffect } from 'react'
import partsAtom, { fetchPartsAtom } from '../../../atoms/partsAtom';
import setupAtom, { editConstructionSetupAtom, fetchSetupAtom } from '../../../atoms/setupAtom';
import { MdModeEditOutline, MdDelete } from "react-icons/md"
import { RiEdit2Line, RiEdit2Fill } from "react-icons/ri"
import { BsThreeDots } from "react-icons/bs"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import produce from 'immer';
import { toast } from 'react-hot-toast';

function ConstructionCreated(props) {

    const [loading, setLoading] = useState(false)

    const [showOptions, setShowOptions] = useState(false)
    const [optionIndex, setShowIndex] = useState(null)
    const [optionSubIndex, setOptionSubIndex] = useState(null)
    const [initialNotes, setInitialNotes] = useState([])
    const [noteEditIndex, setNoteEditIndex] = useState(null);
    const [subNoteEditIndex, setSubNoteEditIndex] = useState(null);
    const [showBox, setShowBox] = useState(false)
    const [subShowBox, setSubShowBox] = useState(false)
    const [triggerUpdate, setTriggerUpdate] = useState(false)
    const [dumNotes, setDumNotes] = useState("")

    const [listParts] = useAtom(partsAtom)
    const [, fetchPartsInit] = useAtom(fetchPartsAtom)
    const [listSetupAtom] = useAtom(setupAtom)
    const [, fetchSetupInit] = useAtom(fetchSetupAtom)
    const [, fetchEditConstructionSetupInit] = useAtom(editConstructionSetupAtom)

    useEffect(() => {
        if (props?.name?.tracker_type_id) {
            init()
        }
    }, [props?.name?.tracker_type_id])

    const init = async () => {
        try {
            setLoading(true)
            await fetchSetupInit({ type: "Construction", tracker_type_id: props?.name?.tracker_type_id })
            await fetchPartsInit()
        }
        catch (err) {
            console.log(err)
        }
        finally{ 
            setLoading(false)
        }
    }

    useEffect(() => {
        setInitialNotes(listSetupAtom.data)
    }, [listSetupAtom.data])

    useEffect(() => {
        if(triggerUpdate === true){
            handleSubmit()
        }
    }, [triggerUpdate])

    const handleNotesSubmit = (index) => {
        setInitialNotes(produce((draft) => {draft[index].notes = dumNotes }))
        setNoteEditIndex(null)
        setShowBox(true)
        setDumNotes("")
        setTriggerUpdate(true)
    }

    const handleSubNotesSubmit = (index, sub_index) => {
        setInitialNotes(produce((draft) => {
            draft[index].sub_activity[sub_index].notes = dumNotes
        }))
        setSubNoteEditIndex(null)
        // setSubShowBox(false)
        setDumNotes("")
        setTriggerUpdate(true)
    }

    const showSubactivity = (e, index, bool) => {
        setInitialNotes(produce((draft) => {draft[index].showSub = !bool}))
    }

    const mouseEnter = (index) => {
        setShowOptions(true)
        setShowIndex(index)
        setOptionSubIndex(index)
    }
    const mouseLeave = () => {
        setShowOptions(false)
        setShowIndex(null)
        setOptionSubIndex(null)
    }

    const handleSubmit = async () => {
        try {
            await fetchEditConstructionSetupInit({ value: initialNotes, tracker_type_id: props.name.tracker_type_id })
            toast.success("Notes Updated");
            setShowBox(false)
            setSubShowBox(false)
        } catch (err) {
            toast.error("unable to update notes");
        }
        finally{
            setTriggerUpdate(false)
        }
    }

    const handleNoteDelete = (index) => {
        setInitialNotes(produce((draft) => { draft[index].notes = "" }))
        setTriggerUpdate(true)
    }

    const handleSubNotesDelete = (index, sub_index) => {
        setInitialNotes(produce((draft) => { draft[index].sub_activity[sub_index].notes = "" }))
        setTriggerUpdate(true)
    }

    return (
        <div className="w-full" style={{ height: "calc(100vh - 170px)" }}>
            <div className="flex justify-between border-b-px border-gray-200 pt-4">
                <div className='mt-2 mb-5 font-bold text-lg p-2'>
                    {props?.name?.name}
                </div>
                {/* <div className='p-2 pr-10 pb-4'>
                    {btnLoading ? 
                        <button className='btn loading border-none rounded-3xl bg-red-550 text-white' style={{ width: '125px' }} >Saving</button>:
                        <button className='btn border-none rounded-3xl bg-red-550 text-white' style={{ width: '85px' }} disabled={!btnDisable} onClick={handleSubmit}>Save</button>
                    }
                </div> */}
            </div>
            <hr />
            {loading ? 
                <div style={{marginTop:"20%", marginLeft:"47%"}}>
                    <p className='loader ml-3 mb-1'></p>
                    <p className='font-bold text-xl'>Fetching...</p>
                </div>
            :
                <div className="overflow-auto h-full">
                    <table className="table w-full z-0 ">
                        <thead>
                            <tr className='sticky top-0 z-20 '>
                                <th className='normal-case' style={{ width: '45px' }}></th>
                                <th className='normal-case text-sm' style={{ width: '400px' }}>Activity</th>
                                <th className='normal-case text-sm' style={{ width: '350px' }}>Linked Part Category</th>
                                <th className='normal-case text-sm' style={{ width: '120px' }}>Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            {initialNotes?.map((i, index) => {
                                return <>
                                    <tr className="hover" key={index} onMouseEnter={e => mouseEnter(index)} onMouseLeave={mouseLeave}>
                                        <td className='cursor-pointer' onClick={e => showSubactivity(e, index, i?.showSub)}>
                                            {i?.sub_activity.length > 0 ? i?.showSub ? <>{i.sub_activity.length > 0 ? <IoIosArrowDown className='mb-36' /> : <IoIosArrowDown />}</> : <IoIosArrowForward /> : <IoIosArrowForward color="#DBDBDB" />}
                                        </td>
                                        <td>
                                            <div className='font-medium' style={{ textTransform: 'capitalize'}}>
                                                {i?.activity}
                                            </div>
                                            <div className='flex'>
                                                {i?.notes ?
                                                    <div className='flex'>
                                                        <RiEdit2Fill className='mt-1 mr-1' style={{ width: '19px', height: '20px' }} />
                                                        <p className='text-gray-500 ml-1 underline underline-offset-2 font-medium' style={{maxWidth: "14vw"}}>
                                                            <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "14vw", textTransform: "capitalize"}}>{i.notes}</p>
                                                        </p>
                                                        {showOptions && optionIndex === index ?
                                                            <div tabIndex={0} className="dropdown dropdown-right">
                                                                <div className='ml-2 cursor-pointer bg-white border-2 border-solid px-2 text-grey'>
                                                                    <BsThreeDots size={20} />
                                                                </div>
                                                                <ul tabIndex={0} className="dropdown-content menu p-2 shadow-lg bg-base-100 rounded-sm">
                                                                    {noteEditIndex !== index ?
                                                                        <>
                                                                            <li onClick={() => { setDumNotes(initialNotes[index].notes); setNoteEditIndex(index) }}>
                                                                                <a className="active:bg-red-550 active:text-white">
                                                                                    <MdModeEditOutline style={{ width: "20px", height: '20px' }} />
                                                                                    <p>Edit</p>
                                                                                </a>
                                                                            </li>
                                                                            <li onClick={() => handleNoteDelete(index)}>
                                                                                <a className="active:bg-red-700 active:text-white">
                                                                                    <MdDelete style={{ width: "20px", height: '20px' }} />
                                                                                    <p>Delete</p>
                                                                                </a>
                                                                            </li>
                                                                        </> : null}

                                                                    {noteEditIndex === index ?
                                                                        <form onSubmit={(e) => { e.preventDefault()}} onMouseLeave={() => setNoteEditIndex(null)}>
                                                                            <textarea className='appearance-none outline-none text-gray-700 border border-gray-300 p-2 h-20 text-sm' placeholder='Add a note..' name="notes" value={dumNotes} onChange={e => setDumNotes(e.target.value)} />
                                                                            <div className='flex justify-end mt-2 mr-2'>
                                                                                <button onClick={() => { setNoteEditIndex(null); setDumNotes("") }}>Cancel</button>
                                                                                <button className='text-blue-500 ml-3 cursor-pointer' onClick={() => handleNotesSubmit(index)}>Add</button>
                                                                                {/* <input type="submit" value="Add" className='text-blue-500 ml-3 cursor-pointer' /> */}
                                                                            </div>
                                                                        </form>
                                                                    :
                                                                        null
                                                                    }

                                                                </ul>
                                                            </div>
                                                            : 
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                        <div className="dropdown dropdown-bottom">
                                                            <label tabIndex={0} className="cursor-pointer flex" onClick={() => setShowBox(true)}>
                                                                <RiEdit2Line className='mt-1 mr-2 text-blue-500' style={{ width: '15px', height: '15px' }} />
                                                                <p className='cursor-pointer font-semibold text-blue-500' style={{ textTransform: 'capitalize'}}>Add Notes</p>
                                                            </label>
                                                            {showBox ? 
                                                                <ul tabIndex={0} className="dropdown-content menu p-2 shadow-lg bg-base-100 rounded-sm">
                                                                    <form onSubmit={(e) => { e.preventDefault()}}>
                                                                        <textarea className='appearance-none outline-none text-gray-700 border border-gray-300 p-2 h-20 text-sm' placeholder='Add a note..' name="notes" value={dumNotes} onChange={e => setDumNotes(e.target.value)} />
                                                                        <div className='flex justify-end mt-2 mr-2'>
                                                                            <button type='reset' onClick={() => { setDumNotes(""); setShowBox(false) }}>Cancel</button>
                                                                            <button className='text-blue-500 ml-3 cursor-pointer' onClick={() => handleNotesSubmit(index)}>Add</button>
                                                                            {/* <input type="submit" value="Add" className='text-blue-500 ml-3 cursor-pointer' /> */}
                                                                        </div>
                                                                    </form>
                                                                </ul>
                                                            :
                                                                null
                                                            }
                                                        </div>
                                                    }
                                            </div>
                                            {i.showSub && i.sub_activity.length > 0 ?
                                                <>{i.sub_activity.map((j, sub_index) => {
                                                    return <>
                                                        <div>
                                                            <div className='ml-10'>
                                                                <div className=' mt-5 mb-4'>
                                                                    <div className='flex' style={{ textTransform: 'capitalize'}}>
                                                                        <i style={{ width: '64px', border: 'solid gray', borderWidth: '0 0px 2px 0', display: 'inline-block', transform: 'rotate(90deg)', margin: '0px -43px 0px 0px' }}></i>
                                                                        <i style={{ width: '25px', border: 'solid gray', borderWidth: '0 0px 2px 0', display: 'inline-block', margin: '0px 8px 12px 0px' }}></i>
                                                                        <p className='font-medium'>{j.activity}</p>
                                                                    </div>
                                                                    <div className='flex ml-14'>
                                                                        {j?.notes ?
                                                                            <div className='flex'>
                                                                                <RiEdit2Fill className='mt-1 mr-1' style={{ width: '19px', height: '20px' }} />
                                                                                <p className='text-gray-500 ml-1 underline underline-offset-2 font-medium' style={{maxWidth: "12vw"}}>
                                                                                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "12vw", textTransform: "capitalize"}}>{j.notes}</p>
                                                                                </p>
                                                                                {showOptions && optionSubIndex === index ?
                                                                                    <div tabIndex={0} className="dropdown dropdown-end">
                                                                                        <div className='ml-2 cursor-pointer bg-white border-2 border-solid px-2 text-grey'>
                                                                                            <BsThreeDots size={20} />
                                                                                        </div>
                                                                                        <ul tabIndex={0} className="dropdown-content menu p-2 shadow-lg bg-base-100 rounded-sm">
                                                                                            {subNoteEditIndex !== sub_index ? <>
                                                                                                <li onClick={() => { setDumNotes(initialNotes[index].sub_activity[sub_index].notes); setSubNoteEditIndex(sub_index) }}>
                                                                                                    <a className='flex active:bg-red-550 active:text-white'>
                                                                                                        <MdModeEditOutline style={{ width: "20px", height: '20px' }} />
                                                                                                        <p>Edit</p>
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li onClick={() => handleSubNotesDelete(index, sub_index)}>
                                                                                                    <a className='flex active:bg-red-700 active:text-white'>
                                                                                                        <MdDelete style={{ width: "20px", height: '20px' }} />
                                                                                                        <p>Delete</p>
                                                                                                    </a>
                                                                                                </li>
                                                                                            </> : null}
                                                                                            {subNoteEditIndex === sub_index ? 
                                                                                                <form onSubmit={(e) => { e.preventDefault(); handleSubNotesSubmit(index, sub_index)}} onMouseLeave={() => setNoteEditIndex(null)} >
                                                                                                    <textarea className='appearance-none outline-none text-gray-700 border border-gray-300 p-1 h-20 w-52 text-sm' placeholder='Add a note..' name="notes" value={dumNotes} onChange={e => setDumNotes(e.target.value)} />
                                                                                                    <div className='flex justify-end mt-2 mr-2'>
                                                                                                        <button onClick={() => { setSubNoteEditIndex(null); setDumNotes("") }}>Cancel</button>
                                                                                                        <input type="submit" value="Add" className='text-blue-500 ml-3 cursor-pointer' />
                                                                                                    </div>
                                                                                                </form>
                                                                                            :
                                                                                                null
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                    :
                                                                                        null
                                                                                    }
                                                                            </div>
                                                                            :
                                                                                <div className="dropdown dropdown-bottom">
                                                                                    <label tabIndex={0} className="cursor-pointer flex" onClick={() => setSubShowBox(true)}>
                                                                                        <RiEdit2Line className='mt-1 mr-2 text-blue-500' style={{ width: '15px', height: '15px' }} />
                                                                                        <p className='cursor-pointer font-semibold text-blue-500' style={{ textTransform: 'capitalize'}}>Add Notes</p>
                                                                                    </label>
                                                                                    {subShowBox ?
                                                                                        <ul tabIndex={0} className="dropdown-content menu p-2 shadow-lg bg-base-100 rounded-sm">
                                                                                            <form onSubmit={(e) => { e.preventDefault(); handleSubNotesSubmit(index, sub_index) }}>
                                                                                                <textarea className='appearance-none outline-none text-gray-700 border border-gray-300 p-1 h-20 text-sm' placeholder='Add a note..' name="notes" value={dumNotes} onChange={e => setDumNotes(e.target.value)} />
                                                                                                <div className='flex justify-end mt-2 mr-2'>
                                                                                                    <button onClick={() => { setDumNotes(""); setSubShowBox(false) }}>Cancel</button>
                                                                                                    <input type="submit" value="Add" className='text-blue-500 ml-3 cursor-pointer' />
                                                                                                </div>
                                                                                            </form>
                                                                                        </ul>
                                                                                    :
                                                                                        null
                                                                                    }
                                                                                </div>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                })}</>
                                                :
                                                    null
                                                }
                                        </td>
                                        <td>
                                            <p className='font-medium' style={{ textTransform: 'capitalize'}}>
                                                {listParts.find((p) => p.id === i.part_id) ? listParts.find((p) => p.id === i.part_id).part_name : null}
                                            </p>
                                            {i?.showSub ?
                                                i?.sub_activity.map((j, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <p className='my-10 font-medium' style={{ textTransform: 'capitalize'}}>{listParts.find((p) => p.id === j.part_id) ? listParts.find((p) => p.id === j.part_id).part_name : null}</p>
                                                        </div>
                                                    )
                                                })
                                            : 
                                                null
                                            }
                                        </td>
                                        <td>
                                            <p className='font-medium'>{i?.weight_decimal} %</p>
                                            {i?.showSub ?
                                                i?.sub_activity.map((j, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <p className='my-10 font-medium'>{j?.weight_decimal} %</p>
                                                        </div>
                                                    )                                                    
                                                })
                                            : 
                                                null
                                            }
                                        </td>
                                    </tr>
                                </>
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default ConstructionCreated
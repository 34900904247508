import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userAtom from "../../atoms/userAtom";

export default function Root() {

    const navigate = useNavigate();
    const [user] = useAtom(userAtom);

    useEffect(() => {
        if(user.loggedIn) {
            navigate("/dashboard")
        }
        else{
            navigate("/login")
        }
    }, [])

    return <></>

}

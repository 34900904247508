import React from 'react'
import { VscClose } from 'react-icons/vsc';
import ActivitySelectBox from './activityBox';
import SelectUser from './selectUser';
import InputBox from '../../../components/inputBox';
import Drawer from 'react-modern-drawer'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import SelectBox from '../../../components/selectBox';

function DrawerComp({
    open,
    onClose,
    activity,
    staff,
    plant,
    btnLoading,
    onChangeAct,
    onChangeStaff,
    onChangePlant,
    onChangeHour,
    onClickCancel,
    newActLoading,
    onSubmitSave,
    handleAddNewAct }) {

    const [submitCount, setSubmitCount] = useState(0)
    const { handleSubmit, reset } = useForm()

    const onSubmit = (data) => {
        onSubmitSave(data)
        // onChangePlant(data.plant_id)
        setSubmitCount(submitCount + 1)
        reset()
        // onClickCancel()
        onChangePlant()
    }

    return (
        <div>
            <Drawer open={open} onClose={onClose} direction='right' size='580px' className='absolute'>
                <form onSubmit={handleSubmit(onSubmit)} className='menu p-4 bg-base-100 text-base-content'>
                    <div className='mb-72' >
                        <div className='flex justify-between mt-4 mb-6'>
                            <p className='text-xl font-bold ml-4 mt-2'>Work Hours</p>
                            <div onClick={onClose}>
                                <VscClose className='cursor-pointer mr-2 ' style={{ width: '40px', height: '40px' }} />
                            </div>
                        </div>
                        <label>
                            <ActivitySelectBox activities={activity} placeholder="Select Activity" onChange={e => onChangeAct(e)} bool={submitCount} handleAddNewAct={e => handleAddNewAct(e)} newActLoading={newActLoading} />
                        </label>
                        <label>
                            <SelectUser StaffList={staff} onChange={e => onChangeStaff(e)} bool={submitCount} />
                        </label>
                        <label>
                            <SelectBox
                                onChange={e => { onChangePlant(e) }}
                                hidden="Add Plant (if applicable)"
                                className="cursor-pointer pl-4 py-2 mt-7 ml-4 rounded-xl text-base border"
                                width="480px"
                                value={plant}
                                bool={submitCount}
                            />
                            {/* <select className='mt-8 mr-4 ml-4 py-3 px-4 border border-gray-300 rounded-xl' name='work_activity' style={{ width: '480px' }} onChange={e => {console.log(e.target.value)}} {...register("plant_id")}>
                                    <option hidden>Add Plant (if applicable)</option>
                                    {plant.map((a, i) => {
                                        return <option className='py-2' key={`act-${i}`} value={`${a.id}`}>{a.name}</option>
                                    })}
                                </select> */}
                        </label>
                        <label>
                            <p className='font-semibold text-s ml-4 mt-10'>Work Hours</p>
                            <div className='flex ml-4 mt-1'>
                                <InputBox rounded='rounded-l-xl' mb='3' mr='0' width='70px' name='work_hours' placeholder='0' onChange={e => onChangeHour(parseInt(e.target.value))} />
                                <InputBox rounded='rounded-r-xl' mb='3' mr='0' width='58px' value={"Hrs"} />
                            </div>
                        </label>
                    </div>
                    <hr />
                    <div className='flex justify-end mt-5 mr-3'>
                        <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' style={{ width: '100px' }} type='reset' onClick={() => { onClickCancel(); setSubmitCount(submitCount + 1); onChangePlant() }}>
                            Cancel
                        </button>
                        {btnLoading ?
                            <button className='border-none rounded-3xl bg-red-550 text-white btn loading' style={{ width: '126px' }} type="submit">
                                Saving
                            </button>
                            :
                            <button className='border-none rounded-3xl bg-red-550 text-white btn' style={{ width: '96px' }} type="submit">
                                Save
                            </button>
                        }
                    </div>
                </form>
            </Drawer>
        </div>
    )
}

export default DrawerComp

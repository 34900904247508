import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LoginContainer from '../../components/loginContainer'

function FpasswordSuccess() {

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div>
      <LoginContainer />
      <div className='w-screen h-screen flex flex-col items-center justify-center'>
        <img src='/Success.jpg' alt='' />
        <p className='text-2xl font-bold mt-2 mb-4'>Email Sent Successfully</p>
        <p className='font-medium text-sm text-gray-400 text-center'>Please check your inbox or spam folder at<br /> {location.state.resetform} for a link to reset your password.</p>
        <p className='text-red-550 mt-4 text-base font-bold cursor-pointer' onClick={() => navigate("/login")}>Go Back to Log in Page</p>
      </div>
    </div>
  )
}

export default FpasswordSuccess
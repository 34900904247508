import axios from "axios";
import { atom } from "jotai";
import produce from "immer";
import { get_progress_activitywise_for_project } from "../dal/constructionProgress";

function sortedBomData(resData) {
    let stringData = [
        "PRS : 72618",
        "PRS : 72684",
        "PRS : 74902",
        "PRS : 75271",
        "PRS : 75287",
        "PRS : 75330",
        "BHA : 25460",
        "BHA : 25468",
        "SGM : 2054",
        "SGM : 21149",
        "DMP : 21185",
        "DMP : 21186",
        "FAS : 50858",
        "FAS : 50857",
        "SGM : 21010",
        "SGM : 21094",
        "TTU : 402332",
        "TTU : 49200",
        "TTU : 410228",
        "TTU : 49196",
        "TTU : 404359",
        "TTU : 404357",
        "TTU : 49201",
        "TTU : 404192",
        "TTU : 49197",
        "TTU : 48211",
        "TTU : 402333",
        "TTU : 49198",
        "TTU : 404355",
        "TTU : 402334",
        "TTU : 49199",
        "FAS : 5275",
        "FAS : 5070",
        "URA : 23843",
        "URA : 23039",
        "DMP : 21656",
        "CTL : 22712",
        "CTL : 31011",
        "CTL : 25056",
        "MOD : 61414",
        "CTL : 51574",
        "FAS : 5066",
        "FAS : 51680",
        "FAS : 51760",
        "TTU : 46423",
        "MSC : 20791",
        "MOD : 60557",
        "CTL : 50554",
        "CTL : 40582",
        "CTL : 20828",
        "CTL : 20687",
        "CTL : 20844",
        "CTL : 20860",
        "CTL : 41855"
    ]
    let temp = []
    let objData = {}
    for (let key in resData) {
        objData[resData[key].part_name] = resData[key]
    }
    for (let key in stringData) {
        if (objData[stringData[key]]) {
            temp.push(objData[stringData[key]])
        }
    }
    if (parseInt(localStorage.getItem("project_id")) === 153 || parseInt(localStorage.getItem("project_id")) === 162) {
        if (parseInt(localStorage.getItem("project_id")) === 162) {
            let arr = []
            temp.map((i) => {
                arr.push(i?.part_name)
            })
            let missingData = {}
            resData.map((i) => {
                if (!arr.includes(i?.part_name)) {
                    missingData = i
                }
            })
            temp.push(missingData)
        }
        return temp
    }
    else {
        return resData
    }
}

function reArrangeBlockPierData(data) {
    let temp = []
    let trackerRows = []
    for (let key in data.piers) {
        trackerRows.push(key)
    }
    for (let tracker in trackerRows) {
        let pierData = data.piers[trackerRows[tracker]]
        temp = temp.concat(pierData)
    }
    return temp
}

const dashboardAtom = atom({
    blockData: [],
    blockTrackerCount: [],
    blockTtData: [],
    pierLegends: {},
    blocks: [],
    overAllSVG: [],
    overallCP: [],
    blocksCP: [],
    overallQC: [],
    blocksQC: [],
    overallTracker: [],
    blocksTracker: [],
    bomBlock: [],
    trackerName: [],
    block_id: [],
    selectedRow: [],
    sectionList: [],
    rowList: [],
    selectedSection: [],
    selectedTrackersBom: [],
    totalBOM: [],
    blocksBOM: [],
    map: [],
    count: [],
    countOverall: [],
    cpTrackerRowWise: {},
    qcTrackerRowWise: {},
    selectedActivity: "",
    blockProgress: [],
    checksheetCP: [],
    checksheetQC: [],
    QcPunchlist: {},
    torqueTube: {},
    mapSize: {},
    qcStatus: {},
    piersList: [],
    tableCoordinates: {},
    scopeSelectionData: []
})

const _ = require("lodash")

export const fetchMapRender = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/svg_details`)
        let tempRes = await axios.get(`/file_upload/download?id=${res.data?.upload_id}`)
        let newRes = await fetch(tempRes.data)
        let text = await newRes.text()
        set(dashboardAtom, produce((draft) => { draft.map = text }))
    }
)

export const fetchBlockWiseTrackerCount = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/get_block_id_wise_count`)
        set(dashboardAtom, produce((draft) => { draft.blockTrackerCount = res.data }))
    }
)

export const fetchSelectedRow = atom(
    null,
    async (get, set, args) => {
        set(dashboardAtom, produce((draft) => { draft.selectedRow = args }))
    }
)

//list of sections available in a block
export const fetchListSections = atom(
    null,
    async (get, set, args) => {
        let temp = new Set()
        for (let pr in args.data) {
            for (let pierIndex in args.data[pr]) {
                let pierData = args.data[pr][pierIndex]
                temp.add(pierData?.section)
            }
        }
        set(dashboardAtom, produce((draft) => { draft.sectionList = Array.from(temp).sort(function (a, b) { return a - b }) }))
    }
)

export const fetchListRows = atom(
    null,
    async (get, set, args) => {
        let temp = new Set()
        for (let pierData of args.data) {
            temp.add(pierData?.section)
        }
        let arrValue = Array.from(temp).sort(function (a, b) { return a - b })
        let tempData = []
        arrValue.map((i, index) => {
            tempData.push({ label: i, value: i, index: index })
        })
        set(dashboardAtom, produce((draft) => { draft.rowList = tempData }))
    }
)

//list of sections selected in a block
export const fetchSelectedSection = atom(
    null,
    async (get, set, args) => {
        set(dashboardAtom, produce((draft) => { draft.selectedSection = args }))
    }
)

export const fetchBlocks = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/blocks?is_svg=True`, {
            "is_svg": true
        })
        set(dashboardAtom, produce((draft) => { draft.blocks = res.data }))
    }
)

export const fetchOverallCpSVG = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/progress/get_progress_activity_wise?activity_type=construction&block_id=all`)
        set(dashboardAtom, produce((draft) => { draft.overAllSVG = res.data }))
    }
)

export const fetchoverallCP = atom(
    null,
    async (get, set, args) => {
        let data = await get_progress_activitywise_for_project()
        set(dashboardAtom, produce((draft) => { draft.overallCP = data }))
    }
)


export const fetchoverallQC = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/progress/get_progress_activity_wise?activity_type=qc`)
        set(dashboardAtom, produce((draft) => { draft.overallQC = res.data }))
    }
)

export const fetchLockDetails = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/tracker_row_lock?block_id=${args}`)
        set(dashboardAtom, produce((draft) => { draft.lockDetail = res.data }))
    }
)

export const addLockDetail = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/projects/tracker_row_lock`, args);
        set(dashboardAtom, produce((draft) => { draft.lock = res.data }))
    }
)

export const fetchoverallTracker = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/get_count_tracker_type_wise_row_for_project`)
        set(dashboardAtom, produce((draft) => { draft.overallTracker = res.data }))
    }
)

export const fetchTrackerName = atom(
    null,
    async (get, set, async) => {
        let res = await axios.get(`/tracker/get_tracker_types`)
        set(dashboardAtom, produce((draft) => { draft.trackerName = res.data }))
    }
)

// To get the BOM for a block

export const fetchBomBlock = atom(
    null, async (get, set, args) => {
        let res = await axios.post(`/projects/bom-status/activitymilestone/`, {
            "block_id": args.block_id,
        })

        let sortedArr = sortedBomData(res.data)
        let filteredData = sortedArr?.filter((data) => data?.project_quantity_calc !== 0)
        set(dashboardAtom, produce((draft) => { draft.bomBlock = filteredData }))
    }
)

export const fetchSelectedTrackerBOM = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/projects/bom-status/activitymilestone/`, {
            "tracker_row_ids": args.selected_rows
        })
        let sortedBlockBOM = _.sortBy(res.data,
            [function (o) { return o.part_name }])

        let sortedArr = sortedBomData(sortedBlockBOM)
        let filteredArr = []
        sortedArr.map((i) => {
            if (i?.project_quantity_calc) {
                filteredArr.push(i)
            }
        })
        args.selected_rows.map((i, index) => {
            if (index >= filteredArr.length) {
                filteredArr.push({ row_id: i })
            }
            else {
                filteredArr[index].row_id = i
            }
        })
        args.selected_rowsNo.map((i, index) => {
            filteredArr[index].row_no = i
        })
        args.tSelectedSec.map((i, index) => {
            filteredArr[index].section = i
        })
        set(dashboardAtom, produce((draft) => { draft.selectedTrackersBom = filteredArr }))
    }
)

//To get the BOM for project

export const fetchBOMproject = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/projects/bom-status/activitymilestone/`, {})
        let sortedBlockBOM = _.sortBy(res.data,
            [function (o) { return o.part_name }])
        let sortedArr = sortedBomData(sortedBlockBOM)
        let filteredData = sortedArr?.filter((data) => data?.project_quantity_calc !== 0)
        set(dashboardAtom, produce((draft) => { draft.totalBOM = filteredData }))
    }
)

export const fetchBomBlocks = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/projects/bom-status/activitymilestone/`, {
            "block_ids": args.selectedBlocks
        })
        let sortedBlockBOM = _.sortBy(res.data,
            [function (o) { return o.part_name }])
        let sortedArr = sortedBomData(sortedBlockBOM)
        let filteredData = sortedArr?.filter((data) => data?.project_quantity_calc !== 0)
        set(dashboardAtom, produce((draft) => { draft.blocksBOM = filteredData }))
    }
)

export const fetchCount = atom(
    null,
    async (get, set, args) => {
        if (args?.block_id) {
            if (args.tracker.length === 0) {
                if (args?.activity_id) {
                    let res = await axios.post(`/progress/get_deployed_part_for_activity_in_block?block_id=${args.block_id}&activity_id=${args?.activity_id}&part_id=${args?.part_id}`)
                    set(dashboardAtom, produce((draft) => { draft.count = res.data }))
                }
            }
            else {
                let res = await axios.post(`/progress/get_deployed_part_for_activity_in_block?block_id=${args.block_id}&activity_id=${args?.activity_id}&part_id=${args?.part_id}`, args.tracker)
                set(dashboardAtom, produce((draft) => { draft.count = res.data }))
            }

        }
        else {
            let res = await axios.post(`/progress/get_deployed_part_for_activity_in_block?activity_id=${args?.activity_id}&part_id=${args?.part_id}`)
            set(dashboardAtom, produce((draft) => { draft.count = res.data }))
        }
    }
)

export const fetchCountOverall = atom(
    null,
    async (get, set, args) => {
        if (args) {
            let res = await axios.post(`/progress/get_deployed_part_for_project?block_id=${args.block_id}`, args.tracker)
            set(dashboardAtom, produce((draft) => { draft.countOverall = res.data }))
        }
        else {
            let res = await axios.post(`/progress/get_deployed_part_for_project`)
            set(dashboardAtom, produce((draft) => { draft.countOverall = res.data }))
        }
    }
)

export const fetchSelectedActId = atom(
    null,
    async (get, set, args) => {
        set(dashboardAtom, produce((draft) => { draft.selectedActivity = args }))
    }
)

// To get the CP status for a tracker row

export const fetchCpTrackerRowAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/progress/get_construction_progress_tracker_row_wise?block_id=${args?.block_id}`)
        set(dashboardAtom, produce((draft) => { draft.cpTrackerRowWise = res.data }))
    }
)

// To get the progress percentage for a selected activity in svg

export const fetchBlockProgress = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/progress/get_progress_block_wise?activity_type=construction&activity_id=${args.act_id}&part_id=${args.part_id}`)
        set(dashboardAtom, produce((draft) => { draft.blockProgress = res.data }))
    }
)


export const fetchMapSize = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/pier-tt/x-y-min-max?block_id=${args.id}`)

        set(dashboardAtom, produce((draft) => { draft.mapSize = res.data }))
    }
)

export const fetchTorqueTube = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker/part_number_tracker_type`)
        set(dashboardAtom, produce((draft) => { draft.torqueTube = res.data }))
    }
)

export const fetchQCStatus = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/projects/tracker_row_pier_tt_status_wise_color?checklist_type=${args.checklist_type}&block_id=${args.block_id}&visibility=${args.visibility}`)
        set(dashboardAtom, produce((draft) => { draft.qcStatus = res.data }))
    }
)

export const createUpdateScopeDataAtom = atom(
    null,
    async (get, set, args) => {
        if (args?.removedTracker) {
            await axios.post(`/tracker_row/update_laydown_id_for_tracker_rows`, args?.scopeData)
        }
        else {
            await axios.post(`/tracker_row/update_laydown_id_for_tracker_rows?laydown_id=${args?.laydown_id}`, args?.scopeData)
        }
        if (args?.block_id) {
            let res = await axios.get(`/tracker_row/bulk/all?block=${args?.block_id}`)
            set(dashboardAtom, produce((draft) => { draft.blockTtData = res.data }))
        }
    }
)

export const fetchLaydownScopeSelection = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/get_scope_for_given_laydown?laydown_id=${args?.laydown_id}`)
        set(dashboardAtom, produce((draft) => { draft.scopeSelectionData = res.data }))
    }
)

export const fetchCoordinates = atom(
    null,
    async (get, set, args) => {
        let endpoints = [
            `/tracker_row/bulk/zimmerman?block=${args?.id}`,
            `/projects/get_all_tracker_coordinates?block_id=${args.id}`,
            `/tracker/get_tracker_types`,
            `/projects/pierslenged`,
            `/tracker_row/get_count_tracker_type_wise_row_for_block?block=${args?.id}`,
        ]

        axios.all(endpoints.map((promise) => axios.get(promise))).then(
            axios.spread((zimmerBlock, tableCoordinates, trackerType, pierLegends, trackerBlock) => {
                set(dashboardAtom, produce((draft) => {
                    draft.piersList = zimmerBlock.data;
                    draft.tableCoordinates = tableCoordinates.data;
                    draft.trackerName = trackerType.data;
                    draft.pierLegends = pierLegends.data;
                    draft.blocksTracker = trackerBlock.data
                }))
            })
        );
    }
)

export const fetchBulkDashboardDataGet = atom(
    null,
    async (get, set, args) => {
        let endpoints = [
            `/tracker_row/bulk/all?block=${args?.id}`,
            `/tracker/get_tracker_types`,
            `/projects/pierslenged`,
            `/tracker_row/get_count_tracker_type_wise_row_for_block?block=${args?.id}`,
        ]

        axios.all(endpoints.map((promise) => axios.get(promise))).then(
            axios.spread((fetchBlockDataSpecific, fetchTrackerName, fetchPierLegends, fetchBlocksTracker) => {
                set(dashboardAtom, produce((draft) => { draft.blockTtData = fetchBlockDataSpecific.data }))
                set(dashboardAtom, produce((draft) => { draft.trackerName = fetchTrackerName.data }))
                set(dashboardAtom, produce((draft) => { draft.pierLegends = fetchPierLegends.data }))
                set(dashboardAtom, produce((draft) => { draft.blocksTracker = fetchBlocksTracker.data }))
            })
        );
    }
)

export const fetchBulkDashboardDataPost = atom(
    null,
    async (get, set, args) => {
        let endpoints = [
            `/progress/get_progress_activity_wise?activity_type=construction&block_id=${args?.id}`,
            `/progress/get_progress_activity_wise?activity_type=qc&block_id=${args?.id}`
        ]

        axios.all(endpoints.map((promise) => axios.post(promise))).then(
            axios.spread((fetchBlocksCP, fetchBlocksQC) => {
                set(dashboardAtom, produce((draft) => { draft.blocksCP = fetchBlocksCP.data }))
                set(dashboardAtom, produce((draft) => { draft.blocksQC = fetchBlocksQC.data }))

            })
        );
    }
)

export default dashboardAtom;

import { useAtom } from 'jotai';
import React, { useState } from 'react'
import { useEffect } from 'react';
import activitiesAtom, { fetchActivitiesAtom } from '../../../atoms/activitiesAtom';
import reportsAtom, { fetchReportsAtom } from '../../../atoms/reportsAtom';
import summaryAtom, { fetchBlockID, fetchCPAtom, fetchQCAtom } from '../../../atoms/summaryAtom';
import { Parser } from "json2csv"
import { toast } from 'react-hot-toast';
import { BiChevronDown } from "react-icons/bi"
import { RiFolderDownloadFill } from 'react-icons/ri'
import ActivitySelect from '../../../utils/activitySelect';
import projectAtom from '../../../atoms/projectAtom';


function ConstructionProgressSummary() {

    const _ = require("lodash");

    const [loading, setLoading] = useState(false)

    const [cpFilter, setCPFilter] = useState(['All'])
    const [selectedOption, setSelectedOption] = useState(false)

    const [reportsAtoms] = useAtom(reportsAtom)
    const [project] = useAtom(projectAtom)
    const [listSummaryAtom] = useAtom(summaryAtom)
    const [, setFetchCPAtom] = useAtom(fetchCPAtom)
    const [listId] = useAtom(activitiesAtom)
    const [, setfetchActivitiesAtom] = useAtom(fetchActivitiesAtom)
    const [, setFetchBlockId] = useAtom(fetchBlockID)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await setfetchActivitiesAtom()
            setLoading(true)
            await setFetchCPAtom()
            await setFetchBlockId()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading(false)
        }
    }

    let project_name = project.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    let newArr = []

    newArr = Object.keys(listSummaryAtom.Construction)

    let newSortBlock = []
    newArr.map((i) => {
        newSortBlock.push(listSummaryAtom.block_name[i])
    })
    newSortBlock = _.sortBy(newSortBlock)

    const sortByNumberAfterSpace = (a, b) => {
        const numberA = parseInt(a.split('-')[1]);
        const numberB = parseInt(b.split('-')[1]);
        return numberA - numberB;
    };

    newSortBlock.sort(sortByNumberAfterSpace);

    let block_list = []
    newSortBlock.map((ar) => {
        Object.keys(listSummaryAtom.block_name).map((bl) => {
            if (listSummaryAtom.block_name[bl] === ar) {
                block_list.push(bl)
            }
        })
    })

    let n_activity_id = []
    block_list.map((i) => listSummaryAtom.Construction[i].map((j) => n_activity_id.push(j.activity_id)))
    let activity_id = []
    let tempArr = []

    n_activity_id = n_activity_id.filter((i, index) => n_activity_id.indexOf(i) === index)

    if (listId.construction) {
        Object.keys(listId.construction).map((mp) => {
            n_activity_id.map((ac) => {
                if (ac === parseInt(mp)) {
                    if (!listId.construction[mp].parent_activity_id) {
                        tempArr.push(parseInt(mp))
                    }
                }
            })
        })
    }
    tempArr.map((j) => {
        activity_id.push(j)
        Object.keys(listId.construction).map((k) => {
            if (listId.construction[k].parent_activity_id === j) {
                activity_id.push(parseInt(k))
            }
        })
    })

    const findBlkName = (data) => {
        let idName
        Object.keys(listSummaryAtom.block_name).map((kl) => {
            if (kl === data) {
                idName = listSummaryAtom.block_name[kl]
            }
        })
        return `${data}---${idName}`
    }

    let allArr = {}
    let downloadAllData = {}

    block_list.map((i) => {
        let arr = []
        for (let id of activity_id) {
            let acti = listSummaryAtom.Construction[i].find(act => act.activity_id === id)
            if (acti) {
                arr.push(acti)
            } else {
                arr.push({
                    activity_id: id,
                    deployed_count: "none",
                    total_count: "none",
                    part_id: "none",
                    progress: "none",
                })
            }
        }
        allArr[findBlkName(i)] = arr
        downloadAllData[i] = arr
    })


    let filteredTemp = {}

    block_list.map((i) => {
        let arr = []
        if (cpFilter[0] !== 'All') {
            cpFilter[0]?.map((cp) => {
                for (let id of activity_id) {
                    let acti = listSummaryAtom.Construction[i].find(act => act.activity_id === parseInt(cp))
                    if (acti) {
                        arr.push(acti)
                    } else {
                        arr.push({
                            activity_id: id,
                            deployed_count: "none",
                            total_count: "none",
                            part_id: "none",
                            progress: "none"
                        })
                    }
                }
            })
        }
        filteredTemp[i] = arr
    })

    let newFilteredArr = []

    block_list.map((i) => {
        let newArr = filteredTemp[i].filter((ele, index) => index === filteredTemp[i].findIndex(elem => elem.activity_id === ele.activity_id))
        newFilteredArr[i] = newArr
    })

    const handleChange = (e) => {
        if (e === 'All') {
            setSelectedOption(false)
        }
        else {
            setSelectedOption(true)
        }
        console.log(e)
        setCPFilter([e])
    }

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date();
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;

    function tableToCSV(downloadAllData) {
        var table = document.querySelector(downloadAllData);
        var rows = table.querySelectorAll('tr');
        var csvData = [];

        var occupiedCells = [];

        for (var i = 0; i < rows.length; i++) {
            var cells = rows[i].querySelectorAll('td,th');
            var csvRow = [];
            var rowIndex = 0;

            for (var j = 0; j < cells.length; j++) {
                var cell = cells[j];
                var cellValue = cell.textContent;
                var colSpan = cell.colSpan;
                var rowSpan = cell.rowSpan;

                while (occupiedCells[rowIndex] && occupiedCells[rowIndex][j]) {
                    rowIndex++;
                }

                csvRow.push(cellValue);

                for (var k = 0; k < rowSpan; k++) {
                    if (!occupiedCells[rowIndex + k]) {
                        occupiedCells[rowIndex + k] = [];
                    }
                    occupiedCells[rowIndex + k][j] = true;
                }

                for (var k = 1; k < colSpan; k++) {
                    csvRow.push('');
                    occupiedCells[rowIndex][j + k] = true;
                }
            }

            csvData.push(csvRow.join(','));
        }

        csvData = csvData.join('\n');

        csvData = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", CP Summary\n` + `"Report Date", ${formattedDate}\n` + csvData

        var blob = new Blob([csvData], { type: 'text/csv' });
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${project_name?.project_number}_CP Summary_${project_name?.name}.csv`
        link.click();
    }

    const downloadAll = async () => {
        try {
            tableToCSV("#download-table")
            toast("Download queued")
        }
        catch (err) {
            toast.error("Download failed")
            console.log(err)
        }
    }

    function tableToCSVCurrentData(newFilteredArr) {
        var table = document.querySelector(newFilteredArr);
        var rows = table.querySelectorAll('tr');
        var csvData = [];

        var occupiedCells = [];

        for (var i = 0; i < rows.length; i++) {
            var cells = rows[i].querySelectorAll('td,th');
            var csvRow = [];
            var rowIndex = 0;

            for (var j = 0; j < cells.length; j++) {
                var cell = cells[j];
                var cellValue = cell.textContent;
                var colSpan = cell.colSpan;
                var rowSpan = cell.rowSpan;

                while (occupiedCells[rowIndex] && occupiedCells[rowIndex][j]) {
                    rowIndex++;
                }

                csvRow.push(cellValue);

                for (var k = 0; k < rowSpan; k++) {
                    if (!occupiedCells[rowIndex + k]) {
                        occupiedCells[rowIndex + k] = [];
                    }
                    occupiedCells[rowIndex + k][j] = true;
                }

                for (var k = 1; k < colSpan; k++) {
                    csvRow.push('');
                    occupiedCells[rowIndex][j + k] = true;
                }
            }

            csvData.push(csvRow.join(','));
        }

        csvData = csvData.join('\n');

        csvData = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", CP Summary\n` + `"Report Date", ${formattedDate}\n` + csvData

        var blob = new Blob([csvData], { type: 'text/csv' });
        var link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${project_name?.project_number}_CP Summary_${project_name?.name}.csv`;
        link.click();
    }

    const downloadCurrent = async () => {
        try {
            tableToCSVCurrentData("#download-table")
            toast("Download queued")
        }
        catch (err) {
            toast.error("Download failed")
            console.log(err)
        }
    }

    return (
        <div>
            <div className='flex justify-between mx-6 mt-5 mb-3'>
                <p className='text-xl font-bold mt-2'>Construction Progress Summary</p>
                <div className='flex space-x-4 mr-4'>
                    <div>
                        <ActivitySelect
                            placeholder="All Activities"
                            className="py-1 pl-4 w-[250px] border rounded-xl border-gray-200 focus:outline-none"
                            from="CP"
                            activities={listId?.construction}
                            activities_disabled={false}
                            sub_activities_disabled={false}
                            multiselect={true}
                            onChange={e => handleChange(e)}
                        />
                    </div>
                    <div className="dropdown dropdown-bottom dropdown-end">
                        <label tabIndex={0} className='flex text-gray-500 font-semibold text-sm mt-2'>
                            <RiFolderDownloadFill className='mr-1 cursor-pointer' style={{ width: '20px', height: '20px' }} />
                            <p className='cursor-pointer'>Download</p>
                        </label>
                        {/* <label tabIndex={0} className='btn border-none rounded-3xl bg-red-550 text-white' style={{width:'145px'}}>Download<span><BiChevronDown className='text-xl ml-1'/></span></label> */}
                        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-44 pl-6">
                            <li onClick={selectedOption ? downloadCurrent : downloadAll}><a>Current View</a></li>
                            <li onClick={downloadAll}><a>All Data</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            {loading ?
                <div style={{ marginTop: "12%", marginLeft: "44%" }}>
                    <p className='loader ml-3 mb-1'></p>
                    <p className='font-bold text-xl'>Fetching...</p>
                </div>
                :
                <div className='border overflow-scroll' style={{ height: 'calc( 100vh - 270px )' }}>
                    {block_list.length > 0 ?
                        <table id="download-table" className="table table-compact w-full z-0">
                            <thead>
                                <tr className='text-center sticky top-0 z-20'>
                                    <th className='normal-case sticky top-0 left-0 z-20 border-none'>Blocks</th>
                                    {cpFilter[0] === 'All' ?
                                        activity_id.map((i) => {
                                            return (
                                                <th className={`${listId?.construction[i]?.parent_activity_id ? "text-gray-500 font-medium" : "text-black font-bold"} normal-case`} colSpan="2">{listId?.construction[i]?.name}</th>
                                            )
                                        })
                                        :
                                        cpFilter[0].map((cp) => {
                                            return (
                                                <th className={`${listId?.construction[cp]?.parent_activity_id ? "text-gray-500 font-medium" : "text-black font-bold"} normal-case`} colSpan="2">{listId?.construction[cp]?.name}</th>
                                            )
                                        })
                                    }
                                </tr>
                                <tr className='text-center sticky top-9 z-20'>
                                    <th className=""></th>
                                    {cpFilter[0] === 'All' ?
                                        activity_id.map((i) => {
                                            return <>
                                                <th className={`${listId?.construction[i]?.parent_activity_id ? "text-gray-500 font-medium" : "text-black font-bold"} normal-case`}>Progress(%)</th>
                                                <th className={`${listId?.construction[i]?.parent_activity_id ? "text-gray-500 font-medium" : "text-black font-bold"} normal-case`}>Counts</th>
                                            </>
                                        })
                                        :
                                        cpFilter[0].map((i) => {
                                            return (<>
                                                <th className={`${listId?.construction[i]?.parent_activity_id ? "text-gray-500 font-medium" : "text-black font-bold"} normal-case`}>Progress(%)</th>
                                                <th className={`${listId?.construction[i]?.parent_activity_id ? "text-gray-500 font-medium" : "text-black font-bold"} normal-case`}>Counts</th>
                                            </>)
                                        })
                                    }

                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {cpFilter[0] === 'All' ?
                                    block_list.map((i) => {
                                        return (
                                            <tr>
                                                <td className='sticky left-0' style={{ textTransform: 'capitalize' }}>{findBlkName(i).split("---")[1]}</td>
                                                {allArr[findBlkName(i)].map((j) => {
                                                    return <>
                                                        {j.progress === "none" ?
                                                            <>
                                                                <td>-</td>
                                                                <td>-</td>
                                                            </> :
                                                            <>
                                                                <td>{Math.round(j.progress)}%</td>
                                                                <td>{j.deployed_count} of {j.total_count}</td>
                                                            </>
                                                        }
                                                    </>
                                                })}
                                            </tr>
                                        )
                                    })
                                    :
                                    block_list.map((i) => {
                                        return (
                                            <tr>
                                                <td className='sticky left-0' style={{ textTransform: 'capitalize' }}>{listSummaryAtom.block_name[i]}</td>
                                                {newFilteredArr[i].map((j) => {
                                                    return <>
                                                        {j.progress === "none" ?
                                                            <>
                                                                <td>-</td>
                                                                <td>-</td>
                                                            </>
                                                            :
                                                            <>
                                                                <td>{Math.round(j.progress)}%</td>
                                                                <td>{j.deployed_count} of {j.total_count}</td>
                                                            </>}
                                                    </>
                                                })}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <div>
                            <img src='/EmptyStat.svg' alt='' className='m-auto' style={{ marginTop: "15%" }} />
                            <p className='text-gray-400 mt-4 text-center font-medium'>No Records Available</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default ConstructionProgressSummary
import axios from "axios";
import { atom } from "jotai";
import {produce} from "immer"

const setupAtom = atom({
    checklist_type: "",
    checklist_id: null,
    tracker_type_id:null,
    data:[],
})
// construction Progress Checklist...
// if Trackerid type id is given? it belongs to tracker and contains notes
// if no tracker type id is given.. it is template
// All tracker type id has same JSON.. only notes Vary

// QC Checklist tracker ...
// tracjer type id cannot be null
// the JSON vary for every tracker type id

export const fetchSetupAtom = atom(
    null,
    async(get, set, args) => {
        if(args.tracker_type_id) {
            let res = await axios.get(`/checklist/get_checklist?type=${args.type}&tracker_type_id=${args.tracker_type_id}`)
            for(let data of res?.data?.data){
                if(data?.sub_activity.length > 0){
                    data["showSub"] = true
                }
            }
            set(setupAtom, res.data)
            set(setupAtom, produce((draft) => {draft.tracker_type_id = args.tracker_type_id}))
        }
        else{
            let res = await axios.get(`/checklist/get_checklist?type=${args.type}`)
            for(let data of res?.data?.data){
                if(data.sub_activity.length > 0){
                    data["showSub"] = true
                }
            }
            if(args?.type === "Construction"){
                set(setupAtom, res.data)
                let localData = localStorage.getItem("cp-checklist") ? JSON.parse(localStorage.getItem("cp-checklist")) : []
                if(res.data.data.length < 1){
                    set(setupAtom, produce((draft) => {draft.data = localData}))
                }
                
            }
            else{
                set(setupAtom, res.data)
                let localData = localStorage.getItem("qc-checklist") ? JSON.parse(localStorage.getItem("qc-checklist")) : []
                if(res.data.data.length < 1){
                    set(setupAtom, produce((draft) => {draft.data = localData}))
                }
            }
        }        
    }
)

export const editConstructionSetupAtom = atom(
    null,
    async(get, set, args) => {
        let temp = {...get(setupAtom)}
        temp.data = args?.value
        temp.tracker_type_id = args?.tracker_type_id
        temp.name = args?.name?.name
        if(args?.name?.name){
            await axios.patch(`/checklist/update-checklist/${temp.checklist_id}`, args.name)
        }
        await axios.post(`/checklist/create_construction_checklist`, temp)
        if(temp.tracker_type_id) {
            let res = await axios.get(`/checklist/get_checklist?type=Construction&tracker_type_id=${temp.tracker_type_id}`)
            for(let data of res?.data?.data){
                if(data.sub_activity.length > 0){
                    data["showSub"] = true
                }
            }
            set(setupAtom, res.data)
        }
        else{
            let res = await axios.get(`/checklist/get_checklist?type=Construction`)
            for(let data of res?.data?.data){
                if(data.sub_activity.length > 0){
                    data["showSub"] = true
                }
            }
            set(setupAtom, res.data)
        }
    }
) 

export const editQCSetupAtom = atom(
    null,
    async(get, set, args) => {
        let temp = {...get(setupAtom)}
        temp.data = args.value
        // temp.name = args.name.name
        if(args?.tracker_type_id){
            temp.tracker_type_id = args?.id
        }
        if(args?.name?.name){
            await axios.patch(`/checklist/update-checklist/${temp.checklist_id}`, args.name)
        }
        await axios.post(`/checklist/create_qc_checklist`, temp)
        if(temp?.tracker_type_id){
            let res = await axios.get(`/checklist/get_checklist?type=QC&tracker_type_id=${args.id}`)
            for(let data of res?.data?.data){
                if(data.sub_activity.length > 0){
                    data["showSub"] = true
                }
            }
            set(setupAtom, res.data)
        }
        else{
            let res = await axios.get(`/checklist/get_checklist?type=QC`)
            for(let data of res.data.data){
                if(data?.sub_activity?.length > 0){
                    data["showSub"] = true
                }
            }
            set(setupAtom, res.data)
        }
    }
)

export default setupAtom
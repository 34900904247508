import { useEffect, useState } from "react";
import { settingsMenu } from "../../components/constent";
import ProfileSettings from "../profileSettings";
import { startCase } from "lodash";
import Container from "../../components/container";
import AccessRights from "../accessRights/accessRights";
import { useAtom } from "jotai";
import projectAtom, { fetchProjectAtom } from "../../atoms/projectAtom";

const Index = () => {
    const [active, setActive] = useState('profile');

    const [listProjects] = useAtom(projectAtom)
    const [, setFetchProjects] = useAtom(fetchProjectAtom)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await setFetchProjects()
        }
        catch (err) {
            console.log(err)
        }
    }

    function accessFlag() {
        let currentProject = listProjects.find(o => o.id === parseInt(localStorage.getItem("project_id")))
        return currentProject?.project_settings?.access_rights
    }

    return (
        <Container>
            {accessFlag() && (
                <div className="flex gap-5 px-14 py-1 border-b-2 border-red-100 bg-[#fef6f6]">
                    {settingsMenu.map((item, index) => (
                        <div className="flex gap-5">
                            <div className={`text-xl px-5 cursor-pointer flex gap-4 justify-center items-center text-base ${active === item.title ? "text-red-550 rounded-full border py-2 px-4 border-red-550" : 'border-red-100'}`} onClick={() => setActive(item?.title)} key={index}>
                                <div>{item.icon}</div>
                                <div>{startCase(item?.title)}</div>
                            </div>
                            {index + 1 !== settingsMenu.length ? <div className="bg-[#f16253] p-1"></div> : ''}
                        </div>

                    ))}
                </div>
            )}
            <div>
                {active === 'profile' ? <ProfileSettings /> : ''}
                {active === 'access rights' ? <AccessRights /> : ''}
            </div>
        </Container>
    )
}

export default Index;
import React, { useCallback, useState, useRef } from 'react'
import ImageViewer from "react-simple-image-viewer";
import { useAtom } from 'jotai';
import { IoMdRemoveCircleOutline } from "react-icons/io"
import { AiOutlineInfoCircle } from "react-icons/ai"
import Drawer from "react-modern-drawer";
import { useDropzone } from "react-dropzone";
import axios from 'axios';
import Modal from "react-modal"
import { useForm } from "react-hook-form";
import { MdModeEditOutline, MdDelete } from "react-icons/md"
import { VscClose } from "react-icons/vsc";
import { BsThreeDots } from "react-icons/bs"
import staffAtom, { deAllocateAtom, deleteStaff, fetchImportStaffAtom, fetchStaffAtom, updateStaffAtom } from '../../../atoms/staffAtom';
import { v4 as uuid } from "uuid";
import { TbCameraPlus } from "react-icons/tb";
import { FaUserAlt } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri"
import { toast } from 'react-hot-toast';
import produce from 'immer';
import ReactTooltip from 'react-tooltip'
import projectAtom from '../../../atoms/projectAtom';
import userAtom from '../../../atoms/userAtom';
import { countryCode } from './countryCode';
import { MdOutlineFileDownload } from "react-icons/md"
import { saveAs } from 'file-saver';
import PDFViewer from "../PDFViewer";

function StaffTable(props) {

    const _ = require("lodash")

    const [loading, setLoading] = useState(false);

    const [listProject] = useAtom(projectAtom)
    const [listUser] = useAtom(userAtom)
    const [staffDetails] = useAtom(staffAtom);
    const [, fetchStaff] = useAtom(fetchStaffAtom);
    const [, setFetchDelete] = useAtom(deleteStaff);
    const [, setUpdataStaff] = useAtom(updateStaffAtom)
    const [, setImportStaffs] = useAtom(fetchImportStaffAtom)
    const [, setDeAllocate] = useAtom(deAllocateAtom)

    const [isOpen, setIsOpen] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [imageBoolean, setImageBoolean] = useState(false);
    const [profileImgURL, setProfileImgURL] = useState(null);
    const [profileImg, setProfileImg] = useState(null);
    const [idProof, setIdProof] = useState([])
    const [document, setDocument] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false)
    const [editImageHover, setEditImageHover] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [deleteStaffId, setDeleteStaffId] = useState()

    const [idName, setIdName] = useState([])
    const [docName, setDocName] = useState([])

    const [idCount, setIdCount] = useState([])
    const [docCount, setDocCount] = useState([])

    const [renameId, setRenameId] = useState()
    const [renameDoc, setRenameDoc] = useState()
    const [idRenameBox, setIdRenameBox] = useState([])
    const [docRenameBox, setDocRenameBox] = useState([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [docname, setDocname] = useState('');

    let sortedStaff = _.sortBy(props.value,
        [function (o) { return o.user.name }])

    const handleDelete = async (i) => {

        try {
            setLoading(true)

            await setFetchDelete({ id: i?.user?.id, forceDelete: false })
        }
        catch (err) {
            console.log(err)
            setModalActivityOpen(true)
            setDeleteStaffId(i?.user?.id)
            // toast.error(err?.response?.data?.detail?.msg)
        }
        finally {
            setLoading(false)
        }
    }

    const handleForceDelete = async (i) => {
        try {
            setLoading(true)
            if (props?.orgView) {
                await setFetchDelete({ id: deleteStaffId, forceDelete: true })
            }
            else {
                await setDeAllocate({ id: [deleteStaffId], forceDelete: true })
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setModalActivityOpen(false)
            setLoading(false)
        }
    }

    const handleDeAllocate = async (i) => {
        try {
            setLoading(true)

            let temp = []
            temp.push(i?.staff_project_id)
            await setDeAllocate({ id: temp })
        }
        catch (err) {
            console.log(err)
            setModalActivityOpen(true)
            setDeleteStaffId(i?.staff_project_id)
            // toast.error(err?.response?.data?.detail?.msg)
        }
        finally {
            setLoading(false)
        }
    }

    const handleFindProjectName = (data) => {
        let temp
        listProject.map((i) => {
            if (i?.id === data) {
                temp = i?.name
            }
        })
        return temp
    }


    const toggleDrawer = () => {
        setDocument([])
        setIdProof([])
        setIsOpen((prevState) => !prevState);
        setIdRenameBox([])
        setDocRenameBox([])
        setDisableSubmit(false)
    };

    const { register, handleSubmit, setValue } = useForm();

    const onDrop = useCallback((acceptedFiles) => { }, []);

    // const profileImageRef = useRef()

    // const profileImageChangeRef = useRef(false)

    // useEffect(() => {
    //     if (profileImgURL) {
    //         profileImageRef.current = profileImgURL
    //     }
    // }, [profileImgURL])

    const handleEditOnClick = async (i) => {
        let temp
        if (i?.user?.profile_data?.other_documents) {
            temp = i?.user?.profile_data?.other_documents.find(k => k.type === 3)
        }
        if (temp?.upload_id) {
            axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`).then((res) => {
                setProfileImgURL(res.data)
                setValue("profile", res.data)
            })
        }
        else {
            setProfileImgURL()
            setValue("profile", null)
        }

        setIsOpen((prevState) => !prevState);
        setValue("first_name", i?.user?.name)
        setValue("last_name", i?.user?.profile_data?.last_name)
        setValue("role_auth", i?.user?.profile_data?.role_auth)
        setValue("number", i?.user?.profile_data?.number)
        setValue("email", i?.user?.email)
        setValue("user_app_id", i?.user?.id)
        setValue("country_code", i?.user?.profile_data?.country_code)

        let profile_oth

        if (i?.user?.profile_data?.other_documents) {
            profile_oth = i?.user?.profile_data?.other_documents.find((pi) => pi.type === 3)
        }

        if (profile_oth) {
            setProfileImg(profile_oth)
        } else {
            setProfileImg()
        }

        if (i?.user?.profile_data?.other_documents) {
            let tempIdCount = []
            let tempId = []
            let tempIdName = []
            let tempDocCount = []
            let tempDoc = []
            let tempDocName = []
            i?.user?.profile_data?.other_documents?.map((k) => {
                if (k.type === 1 && k?.name) {
                  
                    tempDocCount.push(tempDocCount.length + 1)
                    tempDoc.push(k)
                    tempDocName.push(k?.name.split("_")[0])
                    setDocument(tempDoc)
                    setDocName(tempDocName)
                    setDocCount(tempDocCount)
                }
                if (k.type === 1 && k?.name === null) {
                    setDocCount([1])
                }
                if (k.type === 0 && k?.name) {
                    tempIdCount.push(tempIdCount.length + 1)
                    tempId.push(k)
                    tempIdName.push(k?.name.split("_")[0])
                    setIdProof(tempId)
                    setIdName(tempIdName)
                    setIdCount(tempIdCount)
                }
                if (k.type === 0 && k?.name === null) {
                    setIdCount([1])
                }
            })
        }
        setCurrentData(i);
    }


    const imageHover = () => {
        setImageBoolean(true);
    };

    const imageLeave = () => {
        setImageBoolean(false);
    };

    const onSubmit = async (data) => {
        try {
            setBtnLoading(true)

            let tempData = {}

            tempData.email = data?.email
            tempData.name = data?.first_name
            tempData.profile_data = {}
            tempData.profile_data.last_name = data?.last_name
            tempData.profile_data.number = data?.number
            tempData.profile_data.role_auth = data?.role_auth
            tempData.profile_data.country_code = data?.country_code

            tempData.profile_data.other_documents = []

            if (idProof.length > 0) {
                for (let key in idProof) {
                    if (idProof[key]?.upload_id) {
                        tempData.profile_data.other_documents.push(
                            {
                                type: 0,
                                upload_id: idProof[key]?.upload_id,
                                name: idName[key]
                            }
                        )
                    }
                    else {
                        let idProofFile = new File([idProof[key]], `${idName[key]}_${listUser?.data.id}_${listUser?.data.org_id}_${localStorage.getItem("project_id")}_${uuid()}.${idProof[key].type}`, { type: idProof[key].type });
                        let idFormData = new FormData()
                        idFormData.append("file", idProofFile)
                        let idRes = await axios.post(`/file_upload/upload/image?org=True`, idFormData, { headers: { "Content-Type": "multipart/form-data" } })
                        data.id_proof = idRes.data
                        tempData.profile_data.other_documents.push({
                            type: 0,
                            upload_id: idRes.data,
                            name: idName[key]
                        })
                    }
                }

            }
            else {
                tempData.profile_data.other_documents.push(
                    {
                        type: 0,
                        upload_id: null,
                        name: null
                    }
                )
            }
            if (document.length > 0) {
                for (let key in document) {
                    if (document[key]?.upload_id) {
                        tempData.profile_data.other_documents.push(
                            {
                                type: 1,
                                upload_id: document[key]?.upload_id,
                                name: docName[key]
                            }
                        )
                    }
                    else {
                        let OtDocFile = new File([document[key]], `${docName[key]}_${listUser?.data.id}_${listUser?.data.org_id}_${localStorage.getItem("project_id")}_${uuid()}.${document[key].type}`, { type: document[key].type })
                        let OtDocDate = new FormData()
                        OtDocDate.append("file", OtDocFile)
                        let OtDocRes = await axios.post(`/file_upload/upload/image?org=True`, OtDocDate, { headers: { "Content-Type": "multipart/form-data" } })
                        tempData.profile_data.other_documents.push({
                            type: 1,
                            upload_id: OtDocRes.data,
                            name: docName[key]
                        })
                    }
                }
            }
            else {
                tempData.profile_data.other_documents.push(
                    {
                        type: 1,
                        upload_id: null,
                        name: null
                    }
                )
            }
            if (profileImg) {
                if (profileImg?.lastModified) {
                    let ProfileImageFile = new File([profileImg], `${listUser?.data.id}_${listUser?.data.org_id}_${localStorage.getItem("project_id")}_${uuid()}.${profileImg.type}`, { type: profileImg.type });
                    let ProfileFormData = new FormData()
                    ProfileFormData.append("file", ProfileImageFile)
                    let ProfileRes = await axios.post(`/file_upload/upload/image?org=True`, ProfileFormData, { headers: { "Content-Type": "multipart/form-data" } })
                    data.profile_photo = ProfileRes.data
                    tempData.profile_data.other_documents.push({
                        type: 3,
                        upload_id: ProfileRes.data,
                        name: profileImg?.name
                    })
                }
                else {
                    tempData.profile_data.other_documents.push(
                        {
                            type: 3,
                            upload_id: profileImg?.upload_id,
                            name: profileImg?.name
                        }
                    )
                }
            }
            else {
                tempData.profile_data.profile_photo = null
                tempData.profile_data.other_documents.push({
                    type: 3,
                    upload_id: null,
                    name: "ProfilePic"
                })
            }
            await setUpdataStaff({ id: currentData?.user?.id, data: tempData })
            await setImportStaffs()
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        } finally {
            setDocument([])
            setIdProof([])
            setBtnLoading(false)
            setIsOpen((prevState) => !prevState);
            setIdRenameBox([])
            setDocRenameBox([])
        }
    }

    const handleFindCountryCode = (data) => {
        let temp = countryCode.filter(i => i.name === data)
        return temp[0]?.code
    }

    const handleProfileImageChange = (e) => {
        setProfileImg(e.target.files[0]);
        setProfileImgURL(URL.createObjectURL(e.target.files[0]))
        // profileImageChangeRef.current = true
    };

    const handleEditIdChange = (e, index) => {
        let temp = e.target.files[0].name.split(".")[1]
        if (temp === "png" || temp === "jpg" || temp === "pdf") {
            let data = [...idProof]
            data[index] = e.target.files[0]
            setIdProof(data)
            let dataName = [...idName]
            dataName[index] = e.target.files[0].name
            setIdName(dataName)
        }
        else {
            toast.error("File type must be png or jpg or pdf")
        }
    }

    const handleEditDocumentChange = (e, index) => {
        let temp = e.target.files[0].name.split(".")[1]
        if (temp === "png" || temp === "jpg" || temp === "pdf") {
            let data = [...document]
            data[index] = e.target.files[0]
            setDocument(data)
            let dataName = [...docName]
            dataName[index] = e.target.files[0].name
            setDocName(dataName)
        }
        else {
            toast.error("File type must be png or jpg or pdf")
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleRemoveID = (count, index) => {
        let temp = idCount.filter(item => item !== count)
        if (idProof.length > 0) {
            let tempId = idProof.filter((_, item) => item !== index)
            setIdProof(tempId)
            // idProof.splice(index, 1)
            let tempName = idName.filter((_, item) => item !== index)
            setIdName(tempName)
            // idName.splice(index, 1)
        }
        setIdCount(temp)
    }

    const handleRemoveDoc = (count, index) => {
        let temp = docCount.filter(item => item !== count)
        if (document.length > 0) {
            let tempDoc = document.filter((_, item) => item !== index)
            setDocument(tempDoc)
            let tempName = docName.filter((_, item) => item !== index)
            setDocName(tempName)
            // idName.splice(index, 1)
        }
        setDocCount(temp)
    }

    const handleAddID = () => {
        let temp = [1]
        for (let i = 0; i < idCount.length;) {
            temp.push(i + 2)
            i++
        }
        setIdCount(temp)
    }

    const handleAddDoc = () => {
        let temp = [1]
        for (let i = 0; i < docCount.length;) {
            temp.push(i + 2)
            i++
        }
        setDocCount(temp)
    }

    const handleIdNameSave = (index) => {
        setIdRenameBox(produce((draft) => { draft[index] = false }))
        setIdName(produce((draft) => { draft[index] = renameId }))
        setDisableSubmit(false)
    }

    const handleDocNameSave = (index) => {
        setDocRenameBox(produce((draft) => { draft[index] = false }))
        setDocName(produce((draft) => { draft[index] = renameDoc }))
        setDisableSubmit(false)
    }

    const [isIdOpen, setIsIdOpen] = useState(false)
    const [idDocOpen, setIsDocOpen] = useState(false)

    const [images, setImages] = useState([])
    const [downloadImg, setDownloadImg] = useState()

    const openImageViewer = async (type, data) => {
        setDownloadImg(data)
        if (type === "id") {
            setIsIdOpen(true)
        }
        if (type === "doc") {
            setIsDocOpen(true)
        }
        let temp = []
        if (data?.upload_id) {
            let res = await axios.get(`/file_upload/download?id=${data?.upload_id}&org=True`)
            temp.push(res.data)
        }
        setDocname(data?.name)
        setIsModalShow(true)
        setImages(temp)
    }

    const closeImageViewer = (type) => {
        if (type === "id") {
            setIsIdOpen(false)
        }
        if (type === "doc") {
            setIsDocOpen(false)
        }
        setDownloadImg()
        setImages([])
    }

    const handleProfileDownload = (data) => {
        setIsIdOpen(true)
        setImages([data?.user?.profile_data?.profile_url])
        data?.user?.profile_data?.other_documents.map((i) => {
            if (i?.type === 3) {
                setDownloadImg(i)
            }
        })
    }

    const handleSavePdf = async (data) => {
        if (data?.upload_id) {
            let res = await axios.get(`/file_upload/download?id=${data?.upload_id}&org=True`)
            saveAs(res.data, data?.name);
        }
    }

    let subtitle;
    const [modalActivityOpen, setModalActivityOpen] = useState(false)

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setModalActivityOpen(false)
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px",
            width: "40vw"
        },
    };

    return (
        <div>
            {loading ?
                <div className='' style={{ marginTop: "20%", marginLeft: "48%" }}>
                    <p className='loader ml-3 mb-1'></p>
                    <p className='font-bold text-xl'>Fetching...</p></div>
                :
                <div className="overflow-auto" style={{ height: 'calc(100vh - 190px)' }}>
                    {sortedStaff.length > 0 ?
                        <table className='table table-compact w-full p-2'>
                            <thead>
                                <tr className='text-left font-semibold text-xs text-gray-400 sticky top-0 z-5 w-full'>
                                    <th className='normal-case pl-[60px]' style={{ width: "w-[1/5]" }}>Staff List</th>
                                    <th className='normal-case' style={{ width: "w-[1/5]" }}>Mobile Number</th>
                                    <th className='normal-case' style={{ width: "w-[1/5]" }}>Job Title</th>
                                    {props?.orgView ?
                                        <th className='normal-case' style={{ width: "w-[1/5]" }}>Projects</th>
                                        :
                                        null
                                    }
                                    <th className='normal-case' style={{ width: "w-[1/5]" }}>Photo ID</th>
                                    <th className='normal-case' style={{ width: "w-[1/5]" }}>Other Docs</th>
                                    <th className='normal-case' style={{ width: "w-[50px] z-10" }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedStaff.map((i) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className='flex pl-4'>
                                                    {i?.user?.profile_data?.profile_url === null ?
                                                        <div className="">
                                                            <div className="mt-1.5 text-neutral-content rounded-full w-7 text-center p-0.5 bg-yellow-400 text-white text-base">
                                                                <span className="text-base" style={{ textTransform: 'capitalize' }}>{i?.user?.name?.charAt(0).toUpperCase()}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <img src={i?.user?.profile_data?.profile_url} alt='' className='border-none rounded-full mt-2 cursor-pointer' style={{ width: '25px', height: '25px' }} onClick={() => { handleProfileDownload(i) }} />
                                                    }
                                                    <div className='ml-2'>
                                                        <p style={{ textTransform: 'capitalize' }} className='font-normal text-sm'>{i?.user?.name} {i?.user?.profile_data?.last_name}</p>
                                                        <p className='font-normal text-xs text-gray-400'>{i?.user?.email}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p><span className='mr-3'>{handleFindCountryCode(i?.user?.profile_data?.country_code)}</span>{i?.user?.profile_data?.number}</p>
                                            </td>
                                            <td style={{ textTransform: 'capitalize' }}>{i?.user?.profile_data?.role_auth}</td>
                                            {props?.orgView ?
                                                <td style={{ textTransform: 'capitalize' }}>{i?.projects.map((i) => {
                                                    return (<p>{handleFindProjectName(i?.project_id)}</p>)
                                                })}</td>
                                                :
                                                null
                                            }
                                            <td className='underline underline-offset-2 decoration-sky-500'>{i?.user?.profile_data?.other_documents?.map((doc) => {
                                                if (doc.type === 0 && doc?.name) {
                                                    return (
                                                        <div className={'flex items-center gap-4'}>
                                                            <p className='cursor-pointer text-blue-400' onClick={() => openImageViewer("id", doc)}>{doc?.name.split('.')[0]}</p>
                                                            {isIdOpen && (
                                                                <div className='flex'>
                                                                    {downloadImg?.name.split(".")[1] === "png" || downloadImg?.name.split(".")[1] === "jpg" ?
                                                                        <MdOutlineFileDownload className='text-gray-400 hover:text-white fixed right-14 top-4 opacity-50 hover:opacity-100 cursor-pointer z-50 text-2xl' onClick={() => handleSavePdf(downloadImg)} />
                                                                        :
                                                                        null
                                                                    }
                                                                    {downloadImg?.name.split(".")[1] === "png" || downloadImg?.name.split(".")[1] === "jpg" ?
                                                                        <ImageViewer
                                                                            src={images}
                                                                            onClose={() => closeImageViewer("id")}
                                                                            disableScroll={false}
                                                                            backgroundStyle={{
                                                                                backgroundColor: "rgba(0,0,0,0.9)"
                                                                            }}
                                                                            closeOnClickOutside={true}
                                                                        />
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            })}

                                            </td>
                                            <td className='underline underline-offset-2 decoration-sky-500'>{i?.user?.profile_data?.other_documents?.map((doc) => {
                                                if (doc.type === 1 && doc?.name) {
                                                    return (
                                                        <div className={'flex items-center gap-4'}>
                                                            <p
                                                                className=" text-blue-400 cursor-pointer"
                                                                onClick={() => openImageViewer("doc", doc)}
                                                            >
                                                                {doc?.name.split(".")[0]}
                                                            </p>
                                                            {idDocOpen && (
                                                                <div className='flex'>
                                                                    {downloadImg?.name.split(".")[1] === "png" || downloadImg?.name.split(".")[1] === "jpg" ?
                                                                        <MdOutlineFileDownload className='text-gray-400 hover:text-white fixed right-14 top-4 opacity-50 hover:opacity-100 cursor-pointer z-50 text-2xl' onClick={() => handleSavePdf(downloadImg)} />
                                                                        :
                                                                        null
                                                                    }
                                                                    {downloadImg?.name.split(".")[1] === "png" || downloadImg?.name.split(".")[1] === "jpg" ?
                                                                        <ImageViewer
                                                                            src={images}
                                                                            onClose={() => closeImageViewer("doc")}
                                                                            disableScroll={false}
                                                                            backgroundStyle={{
                                                                                backgroundColor: "rgba(0,0,0,0.9)"
                                                                            }}
                                                                            closeOnClickOutside={true}
                                                                        />
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            })}

                                            </td>
                                            <td>
                                                <div className="dropdown dropdown-end" style={{ textTransform: "capitalize" }}>
                                                    <BsThreeDots tabIndex={0} alt='' className='cursor-pointer z-0' style={{ fontSize: "20px" }} />
                                                    <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box">
                                                        <li><a className='flex active:bg-red-550 active:text-white' htmlFor="my-drawer" onClick={() => handleEditOnClick(i)}>
                                                            <MdModeEditOutline style={{ width: "20px", height: '20px' }} />
                                                            <p>Edit</p></a></li>
                                                        {props?.orgView ?
                                                            <li><a className='flex active:bg-red-700 active:text-white' onClick={() => handleDelete(i)}>
                                                                <MdDelete style={{ width: "20px", height: '20px' }} />
                                                                <p>Remove from Database</p>
                                                            </a></li>
                                                            :
                                                            <li><a className='flex active:bg-red-700 active:text-white' onClick={() => handleDeAllocate(i)}>
                                                                <MdDelete style={{ width: "20px", height: '20px' }} />
                                                                <p>Remove from Project</p>
                                                            </a></li>
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>

                        </table>
                        :
                        <div className='flex justify-center'>
                            <div style={{ marginTop: "15%" }}>
                                <img src='/EmptyStat.svg' alt='' className='m-auto' style={{ marginTop: "15%" }} />
                                <p className='text-gray-400 mt-4 text-center font-medium'>Use Import or Add staff to start</p>
                            </div>
                        </div>
                    }
                    <Modal
                        isOpen={modalActivityOpen}
                        onAfterOpen={afterOpenModal}
                        // onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Delete Staff">
                        <p className='text-lg font-bold my-2'>Delete Staff</p>
                        <p className='text-base font-medium text-gray-500 mt-2'>There are work hours recorded against this staff on project(s), removing the staff will remove all the associated work hours captured in SURYA. Please confirm</p>
                        <div className="flex justify-end mt-4">
                            <button className='btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500' onClick={() => setModalActivityOpen(false)}>Cancel</button>
                            <button className='btn border-none rounded-3xl bg-red-550 text-white px-5 mr-4' onClick={() => handleForceDelete()}>Confirm</button>
                        </div>
                    </Modal>
                    <Drawer
                        open={isOpen}
                        onClose={toggleDrawer}
                        direction="right"
                        size="600px"
                        className="absolute"
                    >
                        <div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="menu p-4 bg-base-100 text-base-content"
                                style={{ width: "635px" }}>
                                <div className='overflow-y-scroll mr-5' style={{ height: "85vh" }}>
                                    <div className='flex justify-between mt-4'>
                                        <p className='text-xl font-bold ml-4 mt-2'>Edit Employee Details</p>
                                        <VscClose
                                            className="cursor-pointer mt-1 mr-3"
                                            onClick={toggleDrawer}
                                            style={{ width: "40px", height: "40px" }}
                                        />
                                    </div>

                                    <div className='w-28'>
                                        <label className="mt-8 ml-4 w-40">
                                            <div
                                                className="border-2 rounded-full ml-4 h-20 w-20 cursor-pointer"
                                                onMouseEnter={imageHover}
                                                onMouseLeave={imageLeave}
                                            >
                                                <input
                                                    name="profile"
                                                    type="file"
                                                    onChange={handleProfileImageChange}
                                                    style={{ display: "none" }}
                                                />
                                                {profileImgURL ? (
                                                    <div className='relative' onMouseEnter={() => { setEditImageHover(true) }} onMouseLeave={() => { setEditImageHover(false) }}>
                                                        {editImageHover ?
                                                            <p className='absolute text-sm font-semibold bottom-4 left-4'>Update<br />Photo</p>
                                                            : null}
                                                        <img src={profileImgURL} alt="" className="h-20 w-20 rounded-full" style={{ opacity: editImageHover ? 0.5 : 1 }} />
                                                    </div>
                                                ) : imageBoolean ? (
                                                    <TbCameraPlus
                                                        className="m-auto mt-7 text-gray-400"
                                                        style={{ width: "20px", height: "20px" }}
                                                    />
                                                ) : (
                                                    <FaUserAlt
                                                        className="m-auto mt-7 text-gray-400"
                                                        style={{ width: "20px", height: "20px" }}
                                                    />
                                                )}
                                            </div>
                                        </label>
                                    </div>

                                    <div className="flex mt-2 mb-3 ml-4">
                                        <input
                                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7"
                                            style={{ width: "250px" }}
                                            placeholder="First Name"
                                            {...register("first_name")}
                                        />
                                        <input
                                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                                            style={{ width: "250px" }}
                                            placeholder="Last Name"
                                            {...register("last_name")}
                                        />
                                    </div>
                                    <div className="ml-4">
                                        <input
                                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                                            style={{ width: "528px" }}
                                            placeholder="Job Title"
                                            {...register("role_auth")}
                                        />
                                    </div>
                                    <div className="ml-4">
                                        <input
                                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                                            style={{ width: "528px" }}
                                            placeholder="Email"
                                            {...register("email")}
                                        />
                                    </div>
                                    <div className='flex ml-4 mb-5'>
                                        <select className="select select-bordered w-full max-w-xs appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-7 focus:outline-none" style={{ width: "250px" }} {...register("country_code")}>
                                            <option selected>Country Code</option>
                                            {countryCode.map((i) => {
                                                return (
                                                    <option value={i.name}>{i.name} ({i.code})</option>
                                                )
                                            })}
                                        </select>
                                        {/* <input
                                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mr-4"
                                            style={{ width: "130px" }}
                                            placeholder="country code"
                                        // {...register("number")}
                                        /> */}
                                        <input
                                            className="appearance-none block text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4"
                                            style={{ width: "250px" }}
                                            placeholder="Mobile Number"
                                            {...register("number")}
                                        />
                                    </div>

                                    <div className='text-xl font-bold ml-4'>
                                        {/* <label className='text-xl font-bold '> */}
                                        <p className='flex'>
                                            Photo ID
                                            <AiOutlineInfoCircle data-tip data-for="photo-id" className="mt-1 ml-2 text-gray-400 cursor-pointer" />
                                            <ReactTooltip effect='solid' place='top' id="photo-id">
                                                <div>You can add upto 5 documents</div>
                                            </ReactTooltip>
                                        </p>
                                        {idCount?.map((count, index) => {
                                            return (
                                                <div className='mt-5'>
                                                    {idProof[index] ?
                                                        <div className='my-5'>
                                                            <div className="flex justify-between ">
                                                                <div className='flex'>
                                                                    <p className="text-sm font-semibold">{idName[index]}</p>
                                                                    <RiEdit2Fill className="text-gray-400 ml-4 cursor-pointer" style={{ width: "20px", height: "20px" }} onClick={() => { setIdRenameBox(produce((draft) => { draft[index] = true })); setDisableSubmit(true) }} />
                                                                </div>
                                                                <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => { setIdProof(produce((draft) => { draft.splice(index, 1) })); setIdName(produce((draft) => { draft.splice(index, 1) })); setDisableSubmit(false) }} />
                                                            </div>
                                                            {idRenameBox[index] ?
                                                                <div className='flex'>
                                                                    <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={idName[index]?.split(".")[0]} onChange={e => setRenameId(e.target.value + "." + idName[index]?.split(".")[1])} />
                                                                    <p className="text-sm text-gray-400 mt-7 cursor-pointer ml-5" onClick={() => { setIdRenameBox(produce((draft) => { draft[index] = false })); setRenameId(); setDisableSubmit(false) }}>Cancel</p>
                                                                    <p className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleIdNameSave(index)}>Save</p>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        :
                                                        <div
                                                            className=" mt-5 border-2 border-dashed border-gray rounded-2xl"
                                                            style={{ width: "528px", height: "155px" }}
                                                        >
                                                            <div className='flex justify-end'>
                                                                {idCount.length > 1 ?
                                                                    <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleRemoveID(count, index)} />
                                                                    :
                                                                    <p className="m-4" />
                                                                }
                                                            </div>
                                                            <label className="cursor-pointer">
                                                                <input
                                                                    {...getInputProps()}
                                                                    type="file"
                                                                    style={{ display: "none" }}
                                                                    onChange={e => handleEditIdChange(e, index)}
                                                                />
                                                                {isDragActive ? (
                                                                    <div className="font-normal text-center">
                                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                                        <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                        <p className="text-xs mt-4">
                                                                            Supported files : JPG, PNG, PDF
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="font-normal text-center">
                                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                                        <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                        <p className="text-xs mt-4">
                                                                            Supported files : JPG, PNG, PDF
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {idCount.length < 5 ?
                                            <p className="text-gray-400 flex justify-end font-medium text-base cursor-pointer m-5 mr-8" onClick={handleAddID}>+ Add More</p>
                                            :
                                            null
                                        }
                                        {/* </label> */}
                                    </div>

                                    <div className='mt-5 text-xl font-bold ml-4'>
                                        {/* <label className='text-xl font-bold '> */}
                                        <p className='flex'>
                                            Other Documents
                                            <AiOutlineInfoCircle data-tip data-for="other-documents" className="mt-1 ml-2 text-gray-400 cursor-pointer" />
                                            <ReactTooltip effect='solid' place='top' id="other-documents">
                                                <div>You can add upto 5 documents</div>
                                            </ReactTooltip>
                                        </p>
                                        {docCount?.map((count, index) => {
                                            return (
                                                <div className='mt-5'>
                                                    {document[index] ?
                                                        <div className='mt-5 mb-5'>
                                                            <div className="flex justify-between ">
                                                                <div className="flex">
                                                                    <p className="text-sm font-semibold">{docName[index]}</p>
                                                                    <RiEdit2Fill className="text-gray-400 ml-4 cursor-pointer" style={{ width: "20px", height: "20px" }} onClick={() => { setDocRenameBox(produce((draft) => { draft[index] = true })); setDisableSubmit(true) }} />
                                                                </div>
                                                                <VscClose className="mr-10 cursor-pointer" style={{ width: "25px", height: "25px" }} onClick={() => { setDocument(produce((draft) => { draft.splice(index, 1) })); setDocName(produce((draft) => { draft.splice(index, 1) })); setDisableSubmit(false) }} />
                                                            </div>
                                                            {docRenameBox[index] ?
                                                                <div className='flex'>
                                                                    <input className="text-sm appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 leading-tight w-80" defaultValue={docName[index]?.split(".")[0]} onChange={e => setRenameDoc(e.target.value + "." + docName[index]?.split(".")[1])} />
                                                                    <p className="text-sm text-gray-400 mt-7 cursor-pointer ml-5" onClick={() => { setDocRenameBox(produce((draft) => { draft[index] = false })); setRenameDoc(); setDisableSubmit(false) }}>Cancel</p>
                                                                    <p className="text-sm text-red-550 mt-7 cursor-pointer ml-5" onClick={() => handleDocNameSave(index)}>Save</p>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        :
                                                        <div
                                                            className=" mt-5 border-2 border-dashed border-gray rounded-2xl"
                                                            style={{ width: "528px", height: "155px" }}
                                                        >
                                                            <div className='flex justify-end'>
                                                                {docCount.length > 1 ?
                                                                    <IoMdRemoveCircleOutline className='cursor-pointer m-2' onClick={() => handleRemoveDoc(count, index)} />
                                                                    :
                                                                    <p className="m-4" />
                                                                }
                                                            </div>
                                                            <label className="cursor-pointer">
                                                                <input
                                                                    {...getInputProps()}
                                                                    type="file"
                                                                    style={{ display: "none" }}
                                                                    onChange={e => handleEditDocumentChange(e, index)}
                                                                />
                                                                {isDragActive ? (
                                                                    <div className="font-normal text-center">
                                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                                        <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                        <p className="text-xs mt-4">
                                                                            Supported files : JPG, PNG, PDF
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="font-normal text-center">
                                                                        <img src="/Upload.jpg" alt="" className="m-auto" />
                                                                        <p className="text-sm mt-4">Click or Drop Files here</p>
                                                                        <p className="text-xs mt-4">
                                                                            Supported files : JPG, PNG, PDF
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </label>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {docCount.length < 5 ?
                                            <p className="text-gray-400 flex justify-end font-medium text-base cursor-pointer m-5 mr-8" onClick={handleAddDoc}>+ Add More</p>
                                            :
                                            null
                                        }
                                        {/* </label> */}
                                    </div>

                                </div>
                                <div className="flex mt-11 justify-end mr-16">
                                    <button
                                        className="btn mr-3 border-2 rounded-3xl bg-white text-gray-500 border-gray-500"
                                        style={{ width: "100px" }}
                                        type="reset"
                                        onClick={() => {
                                            setIsOpen((prevState) => !prevState)
                                            setDocument([])
                                            setIdProof([])
                                            setIdRenameBox([])
                                            setDocRenameBox([])
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    {btnLoading ?
                                        <button
                                            className="border-none rounded-3xl bg-red-550 text-white btn loading"
                                            style={{ width: "126px" }}
                                        >
                                            Updating
                                        </button>
                                        :
                                        <input
                                            className="border-none rounded-3xl bg-red-550 text-white btn"
                                            style={{ width: "96px" }}
                                            disabled={disableSubmit}
                                            value="Update"
                                            type="submit"
                                        />
                                    }
                                </div>
                            </form>
                        </div>
                    </Drawer>
                    {console.log(images)}
                    {downloadImg?.name.split(".")[1] === "pdf" ?
                        <PDFViewer
                            isModalShow={isModalShow}
                            setIsModalShow={setIsModalShow}
                            pdfUrl={images?.[0]}
                            docname={docname}
                        />
                        :
                        null
                    }
                </div>}
        </div>
    )
}

export default StaffTable
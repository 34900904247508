import axios from "axios";
import { atom } from "jotai";
import produce from "immer";

const summaryAtom = atom({
    Construction: [],
    QC: [],
    block_name: []
})

export const fetchCPAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/progress/test`, {
            "activity_type": "construction",
            "is_summary": true
        })
        // let res = await axios.get(`/progress/get_construction_progress_deployed_part_count_block_wise_activity_wise`)
        set(summaryAtom, produce((draft) => { draft.Construction = res.data }))
    }
)

export const fetchQCAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.post(`/progress/test`, {
            "activity_type": "QC",
            "is_summary": true
        })

        // let res = await axios.get(`/progress/get_qc_progress_block_wise_activity_wise`)
        set(summaryAtom, produce((draft) => { draft.QC = res.data }))
    }
)

export const fetchBlockID = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/tracker_row/block_id_names`)
        set(summaryAtom, produce((draft) => { draft.block_name = res.data }))
    }
)

export default summaryAtom
import React, { useState } from 'react'
import Container from '../../components/container'
import { BiArrowBack } from "react-icons/bi"
import { useNavigate } from 'react-router-dom'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { VscClose } from "react-icons/vsc"
import { useAtom } from 'jotai';
import { AiOutlineInfoCircle } from "react-icons/ai"
import projectAtom from '../../atoms/projectAtom';
import dashboardAtom, { fetchBlocks, fetchBulkDashboardDataGet, fetchCoordinates, fetchListSections, fetchMapRender, fetchMapSize, fetchSelectedRow, fetchSelectedSection } from '../../atoms/dashboardAtom';
import { useEffect } from 'react';
import { fetchAuditAtom, fetchChecklistBlockAtom, fetchChecklistTrackerAtom, fetchStatusCheckAtom } from '../../atoms/logsAtom';
import toast from 'react-hot-toast';
import project_orgAtom, { fetchProjOrgAtom } from '../../atoms/permissionAtom'
import produce from 'immer';
import Modal from 'react-modal';
import { BlockMap } from '@product-fusion/surya-fw';

function Index() {

    const navigate = useNavigate()

    const [projectsName] = useAtom(projectAtom)
    const [listblocks] = useAtom(dashboardAtom)
    const [, setMapSize] = useAtom(fetchMapSize)
    const [, setCoordinates] = useAtom(fetchCoordinates)
    const [, setSelectedSection] = useAtom(fetchSelectedSection)
    const [, setSectionList] = useAtom(fetchListSections)
    const [, setFetchMap] = useAtom(fetchMapRender)
    const [, setFetchBlocks] = useAtom(fetchBlocks)
    const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
    const [, setFetchSelectedRow] = useAtom(fetchSelectedRow)
    const [, setFetchAuditLogs] = useAtom(fetchAuditAtom)
    const [, setFetchQcPunchlist] = useAtom(fetchChecklistTrackerAtom)
    const [, setFetchQcPunchlistBlock] = useAtom(fetchChecklistBlockAtom)
    const [, setStatusCheck] = useAtom(fetchStatusCheckAtom)
    const [, fetchProjOrgPermission] = useAtom(fetchProjOrgAtom)
    const [getPermission] = useAtom(project_orgAtom)

    const [clearSt, setClearSt] = useState(false)
    const [showBlock, setShowBlock] = useState({ bool: true, block: "" })
    const [selectAll, setSelectAll] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedBlock, setSelectedBlock] = useState([])
    const [sectionSelect, setSectionSelect] = useState([])
    const [prevSection, setPrevSection] = useState(null)
    const [dum, setDum] = useState({ bool: false, id: null, name: null })
    const [selectedRow, setSelectedRow] = useState([])

    const [downloadModal, setModalDownload] = useState({ boolean: false, data: null })

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        svgInit()
    }, [listblocks.blocks, showBlock?.bool, selectedBlock])

    useEffect(() => {
        if (listblocks.blockTtData) {
            sectionCallFn()
        }
    }, [listblocks?.blockTtData])

    const sectionCallFn = async () => {
        try {
            await setSectionList({ data: listblocks.blockTtData.piers })
        }
        catch (err) {
            console.log(err)
        }
    }

    const init = async () => {
        try {
            setLoading(true)
            await setFetchMap()
            await setFetchBlocks()
            await fetchProjOrgPermission()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (dum.bool) {
            handleDblClick()
        }
    }, [dum.id])

    const handleDblClick = async () => {
        try {
            setLoading(true)
            await setSelectedSection([])
            await setMapSize({ id: dum.id })
            if (project_name?.type) {
                await setCoordinates({ id: dum.id })
            }
            else {
                await setFetchBulkGet({ id: dum.id })
            }
            setShowBlock({ bool: false, block: dum.name })
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const svgInit = () => {
        if (listblocks?.blocks.length > 0) {
            let block_svg_id
            let block_progress_id
            listblocks?.blocks.map((i) => {
                block_svg_id = document.getElementById(project_name?.type ? i?.name : i?.block_svg_id)
                block_progress_id = document.getElementById(i?.progress_svg_id)
                if (block_svg_id) {
                    block_svg_id.addEventListener("click", () => {
                        let temp = []
                        if (selectedBlock.length > 0 && selectedBlock.includes(i?.id)) {
                            temp = selectedBlock.filter((item) => item !== i?.id)
                        }
                        else {
                            temp = [...selectedBlock, i?.id]
                        }
                        setSelectedBlock(temp)
                    })
                    block_svg_id.addEventListener("dblclick", () => {
                        setDum({ bool: true, id: i?.id, name: i?.name })
                    })
                }
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = null
                    if (selectedBlock.includes(i?.id)) {
                        block_svg_id.style.fill = "#F6BE00"
                    }
                    else {
                        block_svg_id.style.fill = "white"
                    }
                }
            })
        }
    }

    const handleSelectAll = () => {
        if (showBlock.bool) {
            if (selectedBlock.length === listblocks?.blocks.length) {
                setSelectedBlock([])
            }
            else {
                let temp = []
                listblocks?.blocks.map((i) => {
                    temp.push(i?.id)
                })
                setSelectedBlock(temp)
                if (listblocks?.sectionList) {
                    setSectionSelect(listblocks?.sectionList)
                    setPrevSection(null)
                }
            }
        }
        else {
            setSelectAll(!selectAll)
        }
    }

    const handleSectionSelect = (data) => {
        if (sectionSelect.includes(data)) {
            setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
            setPrevSection(data)
        }
        else {
            setSectionSelect(produce((draft) => { draft.push(data) }))
            setPrevSection(null)
        }
    }

    const handleClear = () => {
        if (showBlock.bool) {
            setSelectedBlock([])
            setSelectAll(false)
        }
        else {
            setSectionSelect([])
            setClearSt(!clearSt)
            setFetchSelectedRow([])
            setSelectAll(false)
        }
    }

    const handleDownload = async (data) => {
        try {
            toast("Download queued")
            if (showBlock.bool) {
                if (selectedBlock.length > 0) {
                    await setFetchAuditLogs({ activity_type: data.type, block_ids: selectedBlock })
                }
                else {
                    await setFetchAuditLogs({ activity_type: data.type })
                }
            }
            else {
                if (selectedRow.length > 0) {
                    let temp = []
                    selectedRow.map((i) => {
                        temp.push(parseInt(i.split("-")[1]))
                    })
                    await setFetchAuditLogs({ activity_type: data.type, tracker_row_ids: temp })
                }
                else {
                    await setFetchAuditLogs({ activity_type: data.type })
                }
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Download failed")
        }
    }

    const handleQcChecklistDownload = async () => {
        try {
            if (showBlock.bool) {
                await setFetchQcPunchlistBlock({ block_id: selectedBlock[0] })
            }
            else {
                let temp = []
                selectedRow.map((i) => {
                    temp.push(i.split("-")[1])
                })
                await setFetchQcPunchlist({ tracker_id: temp, block_id: dum.id })
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Unable to download")
        }
    }

    const handleDownloadDocs = async () => {
        try {
            await setStatusCheck()
        }
        catch (err) {
            console.log(err)
        }
    }

    let project_name = projectsName.find(p => p.id === parseInt(localStorage.getItem("project_id")))

    const handleNavigate = () => {
        if (showBlock.bool) {
            navigate("/quality")
        }
        else {
            setShowBlock({ bool: true, block: "" })
            setSelectAll(false)
            setDum({ bool: false, id: null, name: null })
            setSelectedBlock([])
        }
    }

    let subtitle;

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeTrackerModal() {
        setModalDownload({ boolean: false, data: null })
    }

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
            maxHeight: "650px",
            padding: "0px 20px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }


    return (
        <Container>
            <div className='overflow-auto' style={{ width: "calc(100vw-250px)", height: "calc(100vh - 80px)" }}>
                <div className='flex justify-between mb-6'>
                    <div>
                        <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={handleNavigate} />
                        <p className='ml-6 mt-5 text-xl font-bold'>ITCs & ITRs</p>
                    </div>
                    {showBlock.bool ?
                        <div className='flex'>
                            {/* Svg */}
                            <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm mr-5 mt-10' onClick={handleSelectAll} >{selectedBlock.length === listblocks?.blocks.length ? "Unselect All" : "Select All"}</button>
                            <div className='flex items-center mr-10 mt-6'>
                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedBlock.length > 0} onClick={handleClear}>Clear Selection</p>
                            </div>
                        </div>
                        :
                        <div className='flex'>
                            <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm mr-5 mt-10' onClick={handleSelectAll} >Select All</button>
                            <div className='flex items-center mr-10 mt-6'>
                                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedRow?.length > 0} onClick={handleClear}>Clear Selection</p>
                            </div>
                        </div>
                    }
                </div>
                <hr />
                <div className='flex w-full'>
                    {loading ?
                        <div className='w-[75%] flex flex-col justify-center items-center'>
                            <p className='loader mb-1'></p>
                            <p className='font-bold ml-4 text-xl'>Fetching...</p>
                        </div>
                        :
                        showBlock.bool ?
                            <div className='w-[75%]' style={{ height: "calc(100vh - 390px)" }}>
                                <div className='cursor-grab' style={{ height: "calc(100vh - 250px)" }}>
                                    <TransformWrapper
                                        centerZoomedOut
                                        minScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale)}
                                        initialScale={parseFloat(project_name?.svg_dimension?.web?.web_svg_initial_scale)}
                                        initialPositionX={((window.innerWidth - 760) - (parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale) * project_name?.svg_dimension?.web?.web_svg_width)) / 2}
                                        initialPositionY={((window.innerHeight - 350) - (parseFloat(project_name?.svg_dimension?.web?.web_svg_min_scale) * project_name?.svg_dimension?.web?.web_svg_height) + 100) / 2}
                                    >
                                        <TransformComponent
                                            contentStyle={{ width: `${project_name?.svg_dimension?.web?.web_svg_width}px` }}
                                            wrapperStyle={{ height: "100%", width: "100%" }}
                                        >
                                            <div className='m-auto inline-block align-middle'>
                                                <div className="svgmap svgSiblingDiv flex items-center justify-center" style={{ height: `${project_name?.svg_dimension?.web?.web_svg_height}px`, width: `${project_name?.svg_dimension?.web?.web_svg_width}px`, position: "relative" }} dangerouslySetInnerHTML={{ __html: listblocks.map }}></div>
                                            </div>
                                        </TransformComponent>
                                    </TransformWrapper>
                                </div>
                                <div className='flex justify-center mt-3'>
                                    <span data-tip data-for="svg" className='mt-1 ml-4'>
                                        <AiOutlineInfoCircle />
                                    </span>
                                    <p className='text-sm text-gray-400 mt-0.5 ml-2'>Double click on any block to dive in</p>
                                </div>
                            </div>
                            :
                            <div className='w-[75%]' style={{ height: "calc(100vh - 300px)" }}>
                                <div>
                                    <div className='flex justify-between ml-5'>
                                        <p className='ml-1 font-semibold my-3'>{showBlock?.block}</p>
                                        <div className='flex items-center mr-4'>
                                            <p className='text-gray-400 text-sm font-semibold'>{project_name?.type ? "Row" : "Section"}</p>
                                            {listblocks?.sectionList.map((i) => {
                                                return (
                                                    <p className={`cursor-pointer text-center m-1 w-10 py-1 text-xs ${sectionSelect?.includes(i) ? "bg-red-550 text-white border-red-550 border" : "bg-white text-red-550 border"}`} onClick={() => handleSectionSelect(i)}>{i}</p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div>
                                    <BlockMap
                                        deviceType="web"
                                        zimmerMan={project_name?.type}
                                        bulkAllData={listblocks?.blockTtData}
                                        bulkPierData={listblocks?.piersList}
                                        bulkAllTTData={listblocks?.tableCoordinates}
                                        pierLegends={listblocks?.pierLegends}
                                        type="CP"
                                        typeData={listblocks.cpTrackerRowWise}
                                        userSelection={{ showType: "pier", selection: "all" }}
                                        currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(dum.id))}
                                        currentProjectData={projectsName.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        showLegend={false}
                                        mapSize={listblocks.mapSize}
                                        selectAll={selectAll}
                                        section={sectionSelect}
                                        prevSection={prevSection}
                                        clearSelection={clearSt}
                                    />
                                </div>
                            </div>
                    }
                    {loading ?
                        <div className='w-[75%] flex flex-col justify-center items-center'>
                            <p className='loader mb-1'></p>
                        </div>
                        : <div className='pl-5 flex flex-col pt-16 space-y-5 border-l-2 w-[25%]' style={{ height: "calc(100vh - 200px)" }}>
                            {/* {getPermission["mech-Construction Progress"]?<p className='text-blue-500 cursor-pointer font-medium' onClick={() => handleDownload({ type: "cp" })}>
                            Download CP log
                        </p>:null}
                        {getPermission["mech-Quality Check"]?<p className={`font-medium ${checkQcEnable() ? "text-blue-500 cursor-pointer" : "cursor-not-allowed text-gray-400"}`} onClick={() => checkQcEnable() ? handleDownload({ type: "qc" }) : null}>
                            Download QC log
                        </p>:null} */}
                            <p className='text-blue-500 font-medium cursor-pointer' onClick={() => setModalDownload(produce((draft) => { draft.boolean = true; draft.data = "ITC" }))}>Download ITC</p>
                            <p className='text-blue-500 cursor-pointer font-medium' onClick={() => setModalDownload(produce((draft) => { draft.boolean = true; draft.data = "ITR" }))}>Generate and Download ITR</p>
                        </div>}
                    <Modal
                        isOpen={downloadModal.boolean}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeTrackerModal}
                        style={customStyle}
                        contentLabel='Download Model'
                    >
                        <div className='w-full'>
                            <div className='flex justify-between items-center my-5'>
                                <p className='text-xl font-medium'>Download {downloadModal.data}</p>
                                <VscClose className='cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full w-[25px] h-[25px]' onClick={closeTrackerModal} />
                            </div>
                            <hr />
                            {downloadModal.data === "ITC" ?
                                <div className='flex flex-col space-y-5 w-full my-5'>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>Punchlist Generation</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]'>
                                            <option disabled selected hidden>Select an option</option>
                                            <option>Internal</option>
                                            <option>External</option>
                                        </select>
                                    </div>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>ITC module</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]'>
                                            <option disabled selected hidden>Select an option</option>
                                            <option>Piling ITC</option>
                                            <option>Structure ITC</option>
                                            <option>Modules ITC</option>
                                        </select>
                                    </div>
                                    <hr />
                                    <div className='flex justify-end space-x-5'>
                                        <p className='btn border text-gray-500 rounded-full bg-white hover:text-white' onClick={closeTrackerModal}>Cancel</p>
                                        <button className='btn border-none rounded-full px-4 py-2 cursor-pointer text-white bg-red-550'>Proceed</button>
                                    </div>
                                </div>
                                :
                                <div className='flex flex-col space-y-5 w-full my-5'>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>Punchlist Generation</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]'>
                                            <option disabled selected hidden>Select an option</option>
                                            <option>Internal</option>
                                            <option>External</option>
                                        </select>
                                    </div>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>ITR reference number</p>
                                        <input className='border px-2 py-1 rounded-xl w-[50%]' placeholder='Enter ITR reference number' />
                                    </div>
                                    <div className='flex justify-between w-[80%]'>
                                        <p className='text-gray-500 font-medium'>ITC module</p>
                                        <select className='border cursor-pointer rounded-xl px-4 py-1 w-[50%]'>
                                            <option disabled selected hidden>Select an option</option>
                                            <option>Piling ITR</option>
                                            <option>Structure ITR</option>
                                            <option>Modules ITR</option>
                                        </select>
                                    </div>
                                    <hr />
                                    <div className='flex justify-end space-x-5'>
                                        <p className='btn border text-gray-500 rounded-full bg-white hover:text-white' onClick={closeTrackerModal}>Cancel</p>
                                        <button className='btn border-none rounded-full px-4 py-2 cursor-pointer text-white bg-red-550'>Proceed</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal>
                </div>
            </div>
        </Container>
    )
}

export default Index;
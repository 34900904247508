import { atom } from "jotai";
import produce from "immer";

const themeAtom = atom({
    theme : 'light'
})

export const fetchThemeAtom = atom(
    null,
    async(get, set, args) => {
        set(themeAtom, produce((draft) => {draft.theme = args}))
    }
)

export default themeAtom
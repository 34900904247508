import axios from "axios";
import { atom } from "jotai";

const reportsAtom = atom([])

export const fetchReportsAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/reports/summary?type=${args.type}${args.type==="work_hours"? `&start_date=${args.start_date}&end_date=${args.end_date}`:""}`)
        set(reportsAtom, res.data)
    }
)

export default reportsAtom
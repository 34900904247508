import React from "react";
import { IoIosArrowDropright } from "react-icons/io";
import Container from "./container";
import { useNavigate } from "react-router-dom";

const CardComponent = (props) => {

    const { data, title } = props;
    const navigate = useNavigate()

    const handleClick = (item) => {
        navigate(`${item.path}`)
    }

    return (
        <Container>
            <div className={`px-7`}>
                <p className='pt-10 px-12 font-bold leading-7 text-xl'>
                    {title}
                </p>
                <div className='flex gap-16 flex-wrap p-10'>
                    {data.map((item, index) => {
                        return (
                            <div key={index} onClick={() => handleClick(item)} className={`w-[320px] border-2 border-gray rounded-2xl cursor-pointer`}>
                                <img src={item.image} className="rounded-t-2xl" />
                                <div className="flex items-center justify-between p-5">
                                    <p className="font-semibold">{item.title}</p>
                                    <div><IoIosArrowDropright className='text-xl text-gray-400' /></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Container>)
}

export default CardComponent;
import axios from "axios"
import { CREATE_UPDATE_QC_CHECKPOINTS } from "../../atoms/QcChecklistAtom/constant"

function rearrangeQcCheckpoints(checklistData, newId, applyStatus, projectLevel, itr) {
    let tempArr = []
    for (let value of checklistData.data) {
        let tempData = {
            name: value.checkpoint_name,
            acceptance_criteria: value.acceptance_criteria,
            inspection_type: value.inspection_type
        }
        if (projectLevel && !itr) {
            if (!value.delete) {
                tempArr.push(tempData)
            }
        }
        else {
            if (!applyStatus) {
                if (value.checkpoint_id && !checklistData?.project_mapped_checklist) {
                    console.log(value.checkpoint_id, newId)
                    tempData.checkpoint_id = value.checkpoint_id
                }
            }
            if (value.delete && value.checkpoint_id) {
                tempData.delete = true
            }
            if (!value.delete || (value.delete && value.checkpoint_id)) {
                tempArr.push(tempData)
            }
        }
    }
    return tempArr
}

function arrangeCustomInputs(customData, checklistData, newId, applyStatus, projectLevel, itr, manageChecklist) {
    let data = []
    customData.map((i) => {
        let temp = {
            type: i.custom_input_type,
            name: i.custom_input_name,
            values: { values: [] }
        }
        if (manageChecklist) {
            if (projectLevel) {
                if (itr) {
                    if (checklistData.checklist_id) {
                        if (i?.custom_input_id && !checklistData?.project_mapped_checklist) {
                            temp.id = i.custom_input_id
                            temp.checklist_id = i.custom_input_checklist_id
                        }
                    }
                }
            }
            else {
                if (checklistData.checklist_id) {
                    if (i?.custom_input_id && !checklistData?.project_mapped_checklist) {
                        temp.id = i.custom_input_id
                        temp.checklist_id = i.custom_input_checklist_id
                    }
                }
            }
        }
        else {
            if (!applyStatus) {
                if (checklistData.checklist_id) {
                    if (i?.custom_input_id && !checklistData?.project_mapped_checklist) {
                        temp.id = i.custom_input_id
                        temp.checklist_id = i.custom_input_checklist_id
                    }
                }
            }
        }
        if (i?.custom_input_values?.values?.length > 0) {
            temp.values = { values: i.custom_input_values?.values }
        }
        if(i?.delete && i?.custom_input_id){
            temp.delete = true
        }
        if(!i?.delete || (i?.delete && i?.custom_input_id)){
            data.push(temp)
        }
    })
    console.log(data)
    return data
}

//manage checklist & tracker checklist save 
export async function create_update_qc(checklistData, selectedItem, projectLevel, tracker_type_id, newId, itr) {
    let tempArr = rearrangeQcCheckpoints(checklistData, newId, null, projectLevel, itr)
    let temp = {
        name: checklistData.lable,
        checkpoints: tempArr,
        type: checklistData.tab.toLowerCase(),
        checklist_revision: 0,
        custom_inputs: arrangeCustomInputs(checklistData.custom_inputs, checklistData, newId, false, projectLevel, itr, true)
    }
    if (checklistData?.project_mapped_checklist) {
        temp.project_mapped_checklist = checklistData?.project_mapped_checklist
    }
    let quaryParams
    if (projectLevel) {
        temp.checklist_map_type = 1
        temp.checklist_status = 1
    }
    else {
        temp.checklist_map_type = checklistData.type ? checklistData.type : 0
        temp.checklist_status = checklistData.type ? checklistData.type : 0
    }
    if (checklistData.tab === "Piers") {
        quaryParams = `&pier_part_id=${selectedItem}`
    }
    if (checklistData.tab === "Structure") {
        quaryParams = `&torque_tube_part_id=${selectedItem}`
    }
    if (checklistData.tab === "Module") {
        quaryParams = `&torque_tube_part_id=${selectedItem}`
    }
    if (checklistData.checklist_id && !checklistData?.project_mapped_checklist && (!projectLevel || itr)) {
        temp.checklist_id = checklistData.checklist_id
    }
    if (projectLevel) {
        if (itr) {
            let checklistRes = await axios.post(`${CREATE_UPDATE_QC_CHECKPOINTS}`, temp)
            if (!checklistData.checklist_id) {
                await axios.post(`/projects/map_checklist?checklist_id=${checklistRes.data}&checklist_type=${checklistData.tab.toLowerCase()}`)
            }
            await axios.get(`/projects/update_project_checklist_itr?checklist_id=${checklistRes.data}&itr=1`)
        }
        else {
            await axios.post(`${CREATE_UPDATE_QC_CHECKPOINTS}?map_all_tracker_types=True`, temp)
        }
    }
    else {
        let checklistRes = await axios.post(`${CREATE_UPDATE_QC_CHECKPOINTS}`, temp)
        if (!checklistData.checklist_id) {
            await axios.post(`/projects/map_checklist?checklist_id=${checklistRes.data}&tracker_type_id=${tracker_type_id}&checklist_type=${checklistData.tab.toLowerCase()}&map_type=${checklistData.type}${quaryParams}`)
        }
        if (checklistData?.project_mapped_checklist || newId) {
            await axios.post(`/projects/map_checklist?checklist_id=${checklistRes.data}&tracker_type_id=${tracker_type_id}&checklist_type=${checklistData.tab.toLowerCase()}&map_type=${checklistData.type}${quaryParams}`)
        }
    }
}

//only save and apply
export async function create_update_apply_qc(checklistData, tracker_type_id, applyStatus, newId) {
    let tempArr = rearrangeQcCheckpoints(checklistData, newId, applyStatus)
    let temp = {
        name: checklistData.lable,
        checkpoints: tempArr,
        type: checklistData.tab.toLowerCase(),
        checklist_status: checklistData.type ? checklistData.type : 0,
        checklist_map_type: checklistData.type ? checklistData.type : 0,
        checklist_revision: 0,
        custom_inputs: arrangeCustomInputs(checklistData.custom_inputs, checklistData, newId, applyStatus, false, true, false)
    }
    if (checklistData?.project_mapped_checklist) {
        temp.project_mapped_checklist = checklistData?.project_mapped_checklist
    }
    if (!applyStatus) {
        if (checklistData.checklist_id && !checklistData?.project_mapped_checklist) {
            temp.checklist_id = checklistData.checklist_id
        }
    }
    if (applyStatus) {
        await axios.post(`${CREATE_UPDATE_QC_CHECKPOINTS}?map_all=True&tracker_type_id=${tracker_type_id}`, temp)
    }
    else {
        let checklistRes = await axios.post(`${CREATE_UPDATE_QC_CHECKPOINTS}`, temp)
        if (!checklistData.checklist_id || checklistData?.project_mapped_checklist || newId) {
            await axios.post(`/projects/map_checklist?checklist_id=${checklistRes.data}&tracker_type_id=${tracker_type_id}&checklist_type=${checklistData.tab.toLowerCase()}&map_type=${checklistData.type ? checklistData.type : 0}`)
        }
    }
}
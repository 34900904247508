import React, { useEffect, useRef, useState } from 'react'

function ActionDropDown(props) {

    const {
        icon,
        iconClassName,
        options,
        onChangeValue
    } = props;

    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function activeColor(data) {
        let temp
        if (data === "Edit") {
            temp = "active:bg-blue-700 active:text-white"
        }
        if (data === "Delete") {
            temp = "active:bg-red-500 active:text-white"
        }
        return temp
    }

    return (
        <div className="relative" ref={dropdownRef}>
            <p className={`cursor-pointer ${iconClassName}`} onClick={() => setIsOpen(!isOpen)}>{icon}</p>
            {isOpen && options && (
                <ul className='border border-gray-300 bg-white rounded-2xl absolute z-50 shadow right-[10px] p-2 min-w-[130px] bottom-[0px]'>
                    {options.map((data) => {
                        return (
                            <li className={`cursor-pointer hover:bg-gray-100 rounded-xl m-0.5 py-1.5 px-3 ${activeColor(data?.name)}`} onClick={() => onChangeValue(data?.name)}>{data.name}</li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}
export default ActionDropDown
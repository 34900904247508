import React from 'react'

function LoginContainer() {
  return (
    <div>
        <img src='/vectorRight.png' alt='' className='absolute top-0 left-0' />
        <img src='/vectorLeft.png' alt='' className='absolute top-0 right-0' />
        <img src='/vectorLeftBottom.png' alt='' className='absolute bottom-0 left-0' />
        <img src='/vectorRightBottom.png' alt='' className='absolute bottom-0 right-0' />
    </div>
  )
}

export default LoginContainer
import axios from "axios";
import { atom } from "jotai";

const partsAtom = atom([])

export const fetchPartsAtom = atom(
    null,
    async(get, set, args) => {
        let res = await axios.get(`/part/get_parts`)
        set(partsAtom, res.data)
    }
)

export default partsAtom
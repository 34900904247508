export default function AddFormButton (props) {
    return (
        <div className="avatar">
            <button
                className={`w-12 h-12 mt-6 mr-6 rounded-full border-nonetext-white text-4xl ${props.className} ${props.disabled ? "btn btn-disabled bg-gray-550 " : "bg-red-550 text-white"}`}
                onClick={props?.onClick}>
                    +
            </button>
        </div>
    )
}
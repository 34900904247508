import axios from "axios";
import { atom } from "jotai";
import produce from "immer";
// import toast from "react-hot-toast";

const staffAtom = atom({
    users: [],
    importStaffs: []
})

export const fetchStaffAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/staff/only_project`)

        for (let pic of res?.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }
       set(staffAtom, produce((draft) => { draft.users = res.data }))
    }
)

export const fetchImportStaffAtom = atom(
    null,
    async (get, set, args) => {
        let res = await axios.get(`/staff/`)
        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }
        set(staffAtom, produce((draft) => { draft.importStaffs = res.data }))
    }
)

export const updateImportStaffsAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/staff/importToProject`, args)
        let res = await axios.get(`/staff/only_project`)
        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }
        set(staffAtom, produce((draft) => { draft.users = res.data }))
    }
)

export const updateStaffAtom = atom(
    null,
    async (get, set, args) => {
        await axios.post(`/staff/update/${args?.id}`, args.data)
        let res = await axios.get(`/staff/only_project`)

        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(staffAtom, produce((draft) => { draft.users = res.data }))
    }
)

export const addNewUserAtom = atom(
    null,
    async (get, set, args) => {
        if (args?.org === false) {
            await axios.post(`/staff/create?only_org=True`, args.data)
        }
        else {
            await axios.post(`/staff/create`, args.data)
        }
        let res = await axios.get(`/staff/only_project`);

        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(staffAtom, produce((draft) => { draft.users = res.data }))
    }
)

export const deleteStaff = atom(
    null,
    async (get, set, args) => {
        if (args?.forceDelete) {
            await axios.delete(`/staff/delete?id=${args.id}&force=True`)
        }
        else {
            await axios.delete(`/staff/delete?id=${args.id}`)
        }
        let res = await axios.get(`/staff/only_project`);

        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(staffAtom, produce((draft) => { draft.users = res.data }))

        let importRes = await axios.get(`/staff/`)
        for (let pic of importRes.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }
        set(staffAtom, produce((draft) => { draft.importStaffs = importRes.data }))
    }
)

export const deAllocateAtom = atom(
    null,
    async (get, set, args) => {
        if (args?.forceDelete) {
            await axios.post(`/staff/deallocateToProject?force=True`, args.id)
        }
        else {
            await axios.post(`/staff/deallocateToProject`, args.id)
        }
        let res = await axios.get(`/staff/only_project`);

        for (let pic of res.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }

        set(staffAtom, produce((draft) => { draft.users = res.data }))

        let importRes = await axios.get(`/staff/`)
        for (let pic of importRes.data) {
            let temp = {}
            if (pic?.user?.profile_data?.other_documents) {
                temp = pic?.user?.profile_data?.other_documents.find(k => k.type === 3)
            }
            else {
                temp = {}
            }
            if (temp?.upload_id) {
                // pic.user.profile_data["profile_url"] = null
                let pisRes = await axios.get(`/file_upload/download?id=${temp?.upload_id}&org=True`)
                pic.user.profile_data["profile_url"] = pisRes.data
            }
            else {
                pic.user.profile_data["profile_url"] = null
            }
        }
        set(staffAtom, produce((draft) => { draft.importStaffs = importRes.data }))

    }
)

export default staffAtom
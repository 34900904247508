import React from 'react'
import LoginContainer from '../../components/loginContainer'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAtom } from 'jotai'
import userAtom from '../../atoms/userAtom'

function CreatePassword() {

    const { register, handleSubmit } = useForm()
    const location = useLocation()
    const navigate = useNavigate()

    const [listUser] = useAtom(userAtom)

    const onSubmit = async (data) => {
        try {
            let temp = {}
            if (data.new_password !== data.re_new_password) {
                toast.error("Password mismatch")
            }
            else {
                if (location.search.slice(1)?.split("&")[1]?.split("=")[0] === 'email') {
                    temp.email = location.search.slice(1).split("&")[1].split("=")[1]
                }
                else {
                    temp.email = listUser?.data?.email
                }
                // temp.code = location.search.slice(1).split("&")[1].split("=")[1]
                // temp.password = data.re_new_password
                temp.new_password = data.re_new_password
                let res = await axios.post(`auth/reset-password`, temp)
                if (res.status === 200) {
                    toast.success("Password Updated Successfully")
                    navigate("/login")
                }
                else {
                    toast.error("Something went wrong")
                }
            }
        }
        catch (err) {
            toast.error("Something went wrong")
            console.log(err)
        }
    }

    return (
        <div>
            <LoginContainer />
            <div className='w-screen h-screen flex flex-col items-center justify-center'>
                {/* <p className=''>Password Re-set</p> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <p className='font-bold text-2xl mb-5'>Change Password</p>
                        <label>
                            <p>New Password</p>
                            <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-9 ' required style={{ width: '432px', height: '64px' }} type="password" placeholder="Enter New Password" {...register('new_password')} />
                        </label>
                        <label>
                            <p>Confirm Password</p>
                            <input className='appearance-none text-gray-700 border border-gray-300 rounded-xl py-3 px-4 mt-4 mb-9 ' required style={{ width: '432px', height: '64px' }} type="password" placeholder="Re-Enter New Password" {...register('re_new_password')} />
                        </label>
                        <p></p>
                        <input type="submit" value="Save" className='border-none rounded-full bg-red-550 text-white btn' style={{ width: '432px', height: '59px' }} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreatePassword

export async function getForecasteDateWithCount(data) {
    // Reverse the array
    const reversedData = [...data].reverse();

    // Find the first non-zero values and their indices
    let firstNonZeroEODCIndex = -1;
    let firstNonZeroForecastedIndex = -1;

    reversedData.forEach((item, index) => {
        if (firstNonZeroEODCIndex === -1 && item.eodc !== 0) {
            firstNonZeroEODCIndex = index;
        }
        if (firstNonZeroForecastedIndex === -1 && item.forecasted_count !== 0) {
            firstNonZeroForecastedIndex = index;
        }
    });

    // Convert indices back to original array order
    const originalEODCIndex = data.length - 1 - firstNonZeroEODCIndex;
    const originalForecastedIndex = data.length - 1 - firstNonZeroForecastedIndex;

    // Construct a new array
    const updatedData = data.map((item, index) => {
        const newItem = { ...item };

        if (index > originalEODCIndex) {
            newItem.eodc = data[originalEODCIndex].eodc;
        }

        if (index > originalForecastedIndex) {
            newItem.forecasted_count = data[originalForecastedIndex].forecasted_count;
        }

        return newItem;
    });

    return updatedData;
}

